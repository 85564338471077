/* eslint-disable no-use-before-define */
class SubscriptionStatus {
  static Active = new SubscriptionStatus('active')

  static Inactive = new SubscriptionStatus('inactive')

  static ReachedTalkTimeLimit = new SubscriptionStatus('reachedTalkTimeLimit')

  static EndedFreeTrial = new SubscriptionStatus('endedFreeTrial')

  static ExpiredPlan = new SubscriptionStatus('expiredPlan')

  static NoPlan = new SubscriptionStatus('noPlan')

  constructor(name = 'inactive') {
    this.name = name
  }

  isActive() {
    return this.name === 'active'
  }
}

export default SubscriptionStatus
