import React from 'react'
import { useSelector } from 'react-redux'
import useDialog from 'hooks/useDialog'
import User from 'services/User'
import useUploadPhoto from 'hooks/useUploadPhoto'
import useUserAvatar from 'hooks/useUserAvatar'
import config from 'utils/config'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import { fileType } from 'utils/constants'
import EditPhoto from 'components/EditPhoto/EditPhoto'

const EditProfilePhoto = ({ spinnerSize = 48 }) => {
  const {
    onOpenDialog: onOpenEditPhotoDialog,
    onCloseDialog: onCloseEditPhotoDialog,
    displayDialog: displayEditPhotoDialog,
  } = useDialog()

  const { data: userData } = useSelector((state) => state.user)

  const updateUser = User.updateUserProfileImage

  const {
    loading,
    s3KeyRef,
    handleChange,
  } = useUploadPhoto(
    updateUser,
    userData?.id,
    'avatar',
    fileType.USER_AVATAR,
  )

  const profilePicture = `${config.ASSETS_URL}/${s3KeyRef.current === undefined ? userData?.avatar : s3KeyRef.current}`

  const {
    renderProfileImage,
  } = useUserAvatar(
    userData,
    { profilePicture },
    { icommonIcon: 'avatar', icommonSize: '72' },
  )

  return (
    <div className="d-flex profile-settings__profile-photo">
      {loading
        ? (
          <div className="profile-settings__profile-photo__spinner">
            <LoadingSpinner spinnerSize={spinnerSize} />
          </div>
        )
        : (
          <div className="profile-settings__profile-photo__container">
            {renderProfileImage}
          </div>
        )}

      <div className="profile-settings__profile-photo__buttons-container">
        <div
          onClick={onOpenEditPhotoDialog}
          aria-hidden="true"
          className="profile-settings__profile-photo__btn profile-settings__profile-photo__btn--success"
        >
          Change Photo
        </div>
        <EditPhoto
          handleChange={handleChange}
          show={displayEditPhotoDialog}
          onClose={onCloseEditPhotoDialog}
        />
        <div
          className="profile-settings__profile-photo__btn profile-settings__profile-photo__btn--danger"
        >
          Delete current photo
        </div>
      </div>
    </div>
  )
}

export default EditProfilePhoto
