/* eslint-disable no-underscore-dangle */
import axios from 'axios'
import config from 'utils/config'
import store from 'redux/store'
import userActions from 'redux/user/actions'
import Auth from 'services/Auth'
import { Mutex } from 'async-mutex'

const API_URL = '/api/'

const refreshingMutex = new Mutex()
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })
  failedQueue = []
}

const refreshAccessToken = async () => {
  try {
    const { data: user } = await store.getState()?.user
    const { headers } = await axios.post(`${config.SERVER_URL}${API_URL}refresh_token`, {}, {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
        'Refresh-Token': user.refreshToken,
      },
    })
    return {
      user,
      headers,
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

const http = axios.create()
// We setup and interceptor that modifies the request object before it gets executed
// eg. to avoid adding the Authorization header on every http call
http.interceptors.request.use(async (axiosRequestConfig) => {
  const modifiedRequestConfig = axiosRequestConfig
  const { data: user } = await store.getState()?.user
  if (user && user.accessToken) {
    modifiedRequestConfig.headers.Authorization = `Bearer ${user.accessToken}`
  }

  return modifiedRequestConfig
}, (error) => Promise.reject(error))

http.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config
    const isRememberMeChecked = JSON.parse(localStorage.getItem('isRememberMeChecked'))

    if (error.response && error.response.status === 401 && !originalRequest._retry
      && !originalRequest.url.includes('refresh_token') && isRememberMeChecked) {
      if (refreshingMutex.isLocked()) {
        try {
          const token = await new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject })
          })
          originalRequest.headers.Authorization = `Bearer ${token}`
          return http(originalRequest)
        } catch (err) {
          return Promise.reject(err)
        }
      }

      await refreshingMutex.acquire()
      originalRequest._retry = true

      // Make a request to refresh the token
      return new Promise((resolve, reject) => {
        let accessToken = null
        // Replace the endpoint and parameters with your own
        refreshAccessToken()
          .then(async ({ user, headers }) => {
            accessToken = headers['access-token']
            store.dispatch(userActions.setUser({
              ...user, refreshToken: headers['refresh-token'], accessToken, tokensUpdatedAt: Date.now(),
            }))
            originalRequest.headers.Authorization = `Bearer ${accessToken}`
            resolve(http(originalRequest))
          })
          .catch((e) => {
            processQueue(e, null)
            Auth.logout()
            reject(e)
          })
          .finally(() => {
            // give breath space other requests to finish and state to update
            setTimeout(() => {
              refreshingMutex.release()
              processQueue(null, accessToken)
            }, 1000)
          })
      })
    }
    if (error.response && error.response.status === 401 && (!isRememberMeChecked || originalRequest.url.includes('refresh_token'))) {
      return Auth.logout()
    }
    return Promise.reject(error)
  },
)

export default http
