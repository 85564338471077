export default {
  GET_CURRENT_COMPANY_REQUEST: 'GET_CURRENT_COMPANY_REQUEST',
  GET_CURRENT_COMPANY_SUCCESS: 'GET_CURRENT_COMPANY_SUCCESS',
  GET_CURRENT_COMPANY_ERROR: 'GET_CURRENT_COMPANY_ERROR',
  UPDATE_COMPANY_SETTINGS: 'UPDATE_COMPANY_SETTINGS',
  UPDATE_COMPANY_SETTINGS_SUCCESS: 'UPDATE_COMPANY_SETTINGS_SUCCESS',
  UPDATE_COMPANY_SETTINGS_ERROR: 'UPDATE_COMPANY_SETTINGS_ERROR',
  RESET_COMPANY: 'RESET_COMPANY',
  RESET_UPDATE_SETTINGS_STATES: 'RESET_UPDATE_SETTINGS_STATES',
}
