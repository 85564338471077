const development = {
  SERVER_URL: 'http://localhost:5000',
  FRONTEND_URL: 'http://localhost:3000',
  ASSETS_URL: 'https://assets.stg.joinswytchboard.com',
  API_ASSETS_URL: 'https://api.stg.joinswytchboard.com',
  API_URL: '/api/v1',
  PUBLIC_API_URL: '/api/public',
  WEBSOCKETS_URL: 'ws://localhost:5000/cable',
  DEBUG: true,
}

const staging = {
  SERVER_URL: 'https://engine.stg.joinswytchboard.com',
  SENTRY_TUNNEL_SERVICE: 'https://api.joinswytchboard.com/sentry-tunnel',
  FRONTEND_URL: 'https://stg.joinswytchboard.com',
  ASSETS_URL: 'https://assets.stg.joinswytchboard.com',
  API_ASSETS_URL: 'https://api.stg.joinswytchboard.com',
  API_URL: '/api/v1',
  PUBLIC_API_URL: '/api/public',
  WEBSOCKETS_URL: 'wss://engine.stg.joinswytchboard.com/cable',
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  SENTRY_SAMPLE_RATE: 1,
  SENTRY_SAMPLE_RATE_REPLAYS: 0.1,
  SENTRY_SAMPLE_RATE_REPLAYS_ON_ERROR: 1.0,
  DEBUG: true,
}

const production = {
  SERVER_URL: 'https://engine.joinswytchboard.com',
  SENTRY_TUNNEL_SERVICE: 'https://api.joinswytchboard.com/sentry-tunnel',
  FRONTEND_URL: 'https://app.joinswytchboard.com',
  ASSETS_URL: 'https://assets.joinswytchboard.com',
  API_ASSETS_URL: 'https://api.joinswytchboard.com',
  API_URL: '/api/v1',
  PUBLIC_API_URL: '/api/public',
  WEBSOCKETS_URL: 'wss://engine.joinswytchboard.com/cable',
  GTM_CONTAINER_ID: process.env.GTM_CONTAINER_ID,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  SENTRY_SAMPLE_RATE: 1,
  SENTRY_SAMPLE_RATE_REPLAYS: 0.1,
  SENTRY_SAMPLE_RATE_REPLAYS_ON_ERROR: 1.0,
}

// eslint-disable-next-line
const config = process.env.REACT_APP_STAGE === 'production'
  ? production
  : process.env.REACT_APP_STAGE === 'staging'
    ? staging : development

export default config
