import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'
import Image from 'services/Image'
import DateRangePicker from 'components/form/DateRangePicker'
import { isoDateToJsDate } from 'utils/helpers'

const UserIntro = ({ children, setCustomersFeedBackFilter }) => {
  const [hideFeedbackDatePicker, setHideFeedbackDatePicker] = useState(false)
  const { full_name: fullName, avatar } = useSelector((state) => state.user.data) || {}
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const profilePicture = Image.get(avatar)

  const renderProfileImage = avatar === null ? (
    <IcomoonReact
      iconSet={iconSet}
      size={52}
      className="mt-2"
      icon="avatar"
    />
  ) : (
    <img
      src={profilePicture}
      alt="avatar"
      className="user-intro__img"
    />
  )

  const onDateRangeChange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }

  const onHandleClick = () => {
    setHideFeedbackDatePicker(true)

    if (endDate !== null) {
      const isoStartDate = isoDateToJsDate(startDate)
      const isoEndDate = isoDateToJsDate(endDate)
      setCustomersFeedBackFilter({ StartDate: isoStartDate, EndDate: isoEndDate })
    }
  }

  return (
    <section className="user-intro">
      <div className="user-intro__img">
        {renderProfileImage}
      </div>
      <div className="user-intro__content">
        <div>
          <h2 className="fw-bolder">{`Welcome ${fullName}`}</h2>
        </div>
        <div>
          <p className="card-title">
            Let&apos;s see how you&apos;ve been doing this week.&nbsp;
          </p>
          <div className={`${hideFeedbackDatePicker === true
            ? 'user-intro__datepicker-popper' : ''} user-intro__input-container`}
          >
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              onChange={onDateRangeChange}
              shouldCloseOnSelect={false}
              maxDate={new Date()}
            >
              <button
                type="button"
                className="user-intro__button"
                onClick={() => onHandleClick(startDate, endDate)}
              >
                Apply
              </button>
            </DateRangePicker>
          </div>
          {// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <label
              onClick={() => setHideFeedbackDatePicker(false)}
              onKeyDown={() => setHideFeedbackDatePicker(false)}
              className="user-intro__label"
              htmlFor="date-picker"
            >
              {children}
            </label>
          }
        </div>
      </div>
    </section>
  )
}

export default UserIntro
