import React from 'react'
import { useSelector } from 'react-redux'
import config from 'utils/config'
import useUserAvatar from 'hooks/useUserAvatar'
import ConsumerMessageBox from 'components/UI/ConsumerMessageBox/ConsumerMessageBox'
import WorkerMessageBox from 'components/UI/WorkerMessageBox/WorkerMessageBox'

const Messages = ({ conversation }) => {
  const { data: userData } = useSelector((state) => state.user)
  const profilePicture = `${config.ASSETS_URL}/${userData.avatar}`
  const {
    renderAvatar,
  } = useUserAvatar(
    userData,
    { profilePicture, rounded: 'rounded' },
  )

  return (
    <div className="chat-message-dashboard conversation-messages-list pe-3 ps-1">
      {
        conversation?.map((detail) => (detail.author.startsWith('worker_') ? (
          <WorkerMessageBox
            key={detail.index}
            className="pb-2 pe-2 pb-3"
            content={detail.body}
            date={new Date(detail.date_created)}
            image={renderAvatar}
          />
        ) : (
          <ConsumerMessageBox
            key={detail.index}
            className="pb-2"
            content={detail.body}
            date={new Date(detail.date_created)}
          />
        )))
}
    </div>
  )
}

export default Messages
