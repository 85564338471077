import React from 'react'
import { Col } from 'react-bootstrap'
import { ChatPersonalInfoConst } from 'routes/ChatSettingsEntryPoint/constants'
import ToggleButton from 'routes/ChatSettingsEntryPoint/components/Buttons/ToggleButton'
import StepField from 'routes/ChatSettingsEntryPoint/components/Field/StepField'

const Editable = ({
  requestName,
  setRequestName,
  requestEmailAddress,
  setRequestEmailAddress,
  requestPhoneNumber,
  setRequestPhoneNumber,
  requestHelpInformation,
  setRequestHelpInformation,
  setModifiedState,
}) => {
  const handleRequestName = () => {
    setRequestName(!requestName)
    setModifiedState(true)
  }

  const handleRequestEmailAddress = () => {
    setRequestEmailAddress(!requestEmailAddress)
    setModifiedState(true)
  }

  const handleRequestPhoneNumber = () => {
    setRequestPhoneNumber(!requestPhoneNumber)
    setModifiedState(true)
  }

  const handleRequestHelpInformation = () => {
    setRequestHelpInformation(!requestHelpInformation)
    setModifiedState(true)
  }

  return (
    <>
      <Col className="chat-personal-info-editable">
        <StepField
          title={ChatPersonalInfoConst.requestName.title}
          subTitle={ChatPersonalInfoConst.requestName.subTitle}
          inputClassContainer="toggle-button"
          inputClassContent="toggle-button__content"
        >
          <ToggleButton
            state={requestName}
            onClick={handleRequestName}
          />
        </StepField>
        <StepField
          title={ChatPersonalInfoConst.requestEmailAddress.title}
          subTitle={ChatPersonalInfoConst.requestEmailAddress.subTitle}
          inputClassContainer="toggle-button"
          inputClassContent="toggle-button__content"
        >
          <ToggleButton
            state={requestEmailAddress}
            onClick={handleRequestEmailAddress}
          />
        </StepField>
        <StepField
          title={ChatPersonalInfoConst.requestPhoneNumber.title}
          subTitle={ChatPersonalInfoConst.requestPhoneNumber.subTitle}
          inputClassContainer="toggle-button"
          inputClassContent="toggle-button__content"
        >
          <ToggleButton
            state={requestPhoneNumber}
            onClick={handleRequestPhoneNumber}
          />
        </StepField>
        <StepField
          title={ChatPersonalInfoConst.requestHelpInformation.title}
          subTitle={ChatPersonalInfoConst.requestHelpInformation.subTitle}
          inputClassContainer="toggle-button"
          inputClassContent="toggle-button__content"
        >
          <ToggleButton
            state={requestHelpInformation}
            onClick={handleRequestHelpInformation}
          />
        </StepField>
      </Col>
    </>
  )
}

export default Editable
