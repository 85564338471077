import React from 'react'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'
import { useChatConfiguration } from 'routes/ChatSettingsEntryPoint/provider/ChatConfigurationProvider'

const StepperWrapper = () => {
  const { activeStep } = useChatConfiguration()

  const stepper = (field, step) => (
    <>
      <div className={`stepper-item ${activeStep >= step ? 'completed' : 'active'}`}>
        <div className="step-counter">
          {activeStep >= step + 1 ? (
            <IcomoonReact
              iconSet={iconSet}
              icon="icon-check"
              color="white"
              size="8"
            />
          ) : null}
        </div>
        <div className={`step-name ${activeStep >= step ? 'completed' : 'active'}`}>{field}</div>
      </div>
    </>
  )

  return (
    <div className="stepper-wrapper">
      {stepper('Chat Basics', 0)}
      {stepper('Intro', 1)}
      {stepper('Personal Info', 2)}
      {stepper('Queue', 3)}
      {stepper('Ratings', 4)}
      {stepper('Thanks', 5)}
    </div>
  )
}

export default StepperWrapper
