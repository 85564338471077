import React from 'react'
import FullStar from 'components/UI/FullStar'
import { connect, getIn } from 'formik'

const RatingsFilter = ({
  field,
  formik,
}) => {
  const stars = [...Array(5)
    .keys()] // creates an array of 5 elements, zero indexed, to display the star ratings

  const selectedRating = getIn(formik.values, field.name)

  const onRatingClick = (newRating) => {
    const shouldDeselectRating = selectedRating === newRating

    if (shouldDeselectRating) {
      formik.setFieldValue(field.name, '')
      return
    }

    formik.setFieldValue(field.name, newRating)
  }

  return (
    (
      <div className="conversations__filter-field__ratings-container">
        {
        stars.map((index) => {
          const currentRating = index + 1

          return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            (
              <div
                key={currentRating}
                className={`
              conversations__filter-field__rating
              ${selectedRating === currentRating ? 'conversations__filter-field__rating--selected' : ''}
              `}
                onClick={() => onRatingClick(currentRating)}
              >
                <span>
                  {currentRating}
                </span>
                <FullStar size={10} />
              </div>
            )
          )
        })
      }
      </div>
    )
  )
}

export default connect(RatingsFilter)
