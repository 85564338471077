import React from 'react'
import { Button } from 'react-bootstrap'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'

const VideoCallButton = ({
  onClick,
  icon,
  className,
  color,
  size = 15,
  disabled,
}) => (
  <Button
    variant="light"
    className={className}
    onClick={onClick}
    disabled={disabled}
    style={{ ...(disabled && icon.includes('share-screen') && { pointerEvents: 'none' }) }}
  >
    <IcomoonReact
      iconSet={iconSet}
      size={size}
      icon={icon}
      color={color}
    />
  </Button>
)

export default VideoCallButton
