import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Interweave } from 'interweave'
import ReactTimeAgo from 'react-time-ago'

const WorkerMessage = ({
  className,
  content,
  date,
  image,
}) => (
  <Row className="m-0 p-0 worker justify-content-end">
    <Col className="text-break">
      <div className="w-100 p-4 message br-2 shadow-default">
        <Interweave content={content} />
      </div>
      <div className={`w-100 timestamp font-italic pt-2 ${className}`}>
        <ReactTimeAgo date={date} locale="en-US" />
      </div>
    </Col>
    <Col xs={2} className="avatar m-0 p-0 mt-1 text-center">
      {image}
    </Col>
  </Row>
)

export default WorkerMessage
