import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { chatConfigurationSteps } from 'routes/ChatSettingsEntryPoint/constants'
import { useChatConfiguration } from 'routes/ChatSettingsEntryPoint/provider/ChatConfigurationProvider'
import TopChatConfigurationButtons from 'routes/ChatSettingsEntryPoint/components/Buttons/TopChatConfigurationButtons'
import BottomChatConfigurationButtons from
  'routes/ChatSettingsEntryPoint/components/Buttons/BottomChatConfigurationButtons'
import Editable from 'routes/ChatSettingsEntryPoint/steps/ChatPersonalInfo/Editable'
import Viewable from 'routes/ChatSettingsEntryPoint/steps/ChatPersonalInfo/Viewable'

const ChatPersonalInfo = () => {
  const {
    entryPoint,
    handleStateChange,
    modifiedState,
    setModifiedState,
  } = useChatConfiguration()

  const {
    name_enabled: nameEnabled,
    email_enabled: emailEnabled,
    phone_enabled: phoneEnabled,
    request_help_enabled: requestHelpEnabled,
  } = entryPoint?.data.config.personal_page

  const {
    accent_color: accentColor,
  } = entryPoint?.data.config.visual

  const [requestName, setRequestName] = useState(nameEnabled === 'true')
  const [requestEmailAddress, setRequestEmailAddress] = useState(emailEnabled === 'true')
  const [requestPhoneNumber, setRequestPhoneNumber] = useState(phoneEnabled === 'true')
  const [requestHelpInformation, setRequestHelpInformation] = useState(requestHelpEnabled === 'true')

  const handleSaveButton = () => {
    handleStateChange({
      [chatConfigurationSteps.PERSONAL_PAGE]: {
        name_enabled: Boolean(requestName),
        email_enabled: Boolean(requestEmailAddress),
        phone_enabled: Boolean(requestPhoneNumber),
        request_help_enabled: Boolean(requestHelpInformation),
      },
    })
    setModifiedState(false)
  }

  const handleCancelButton = () => {
    setRequestName(nameEnabled === 'true')
    setRequestEmailAddress(emailEnabled === 'true')
    setRequestPhoneNumber(phoneEnabled === 'true')
    setRequestHelpInformation(requestHelpEnabled === 'true')
    setModifiedState(false)
  }

  return (
    <>
      <Row>
        <Col className="chat-configuration__buttons top">
          <TopChatConfigurationButtons
            handleSaveButton={handleSaveButton}
            handleCancelButton={handleCancelButton}
            modifiedState={modifiedState}
          />
        </Col>
      </Row>
      <Row className="h-100">
        <Col md={8} className="pe-4 m-0">
          <Editable
            requestName={requestName}
            setRequestName={setRequestName}
            requestEmailAddress={requestEmailAddress}
            setRequestEmailAddress={setRequestEmailAddress}
            requestPhoneNumber={requestPhoneNumber}
            setRequestPhoneNumber={setRequestPhoneNumber}
            requestHelpInformation={requestHelpInformation}
            setRequestHelpInformation={setRequestHelpInformation}
            setModifiedState={setModifiedState}
          />
        </Col>
        <Col md={4} className="p-0 m-0">
          <Viewable
            requestName={requestName}
            requestEmailAddress={requestEmailAddress}
            requestPhoneNumber={requestPhoneNumber}
            requestHelpInformation={requestHelpInformation}
            accentColor={accentColor}
          />
        </Col>
      </Row>
      <Row>
        <Col className="chat-configuration__buttons">
          <BottomChatConfigurationButtons
            modifiedState={modifiedState}
            setModifiedState={setModifiedState}
          />
        </Col>
      </Row>
    </>
  )
}

export default ChatPersonalInfo
