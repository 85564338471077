import aes from 'crypto-js/aes'
import enc from 'crypto-js/enc-utf8'

// DECRYPT
export function decrypt(cipher, secret) {
  let decipher = aes.decrypt(cipher, secret)
  decipher = decipher.toString(enc)
  return decipher
}

// This is the same for all of the below, and
// you probably won't need it except for debugging
// in most cases.
function bytesToHex(bytes) {
  return Array.from(
    bytes,
    (byte) => byte.toString(16).padStart(2, '0'),
  ).join('')
}

// Natively supported UTF-8 encoding
function stringToUTF8Bytes(string) {
  return new TextEncoder().encode(string)
}

function hexToBytes(hex) {
  const bytes = new Uint8Array(hex.length / 2)
  for (let i = 0; i !== bytes.length; i += 1) {
    bytes[i] = parseInt(hex.substr(i * 2, 2), 16)
  }
  return bytes
}

export function stringToHex(string) {
  return bytesToHex(stringToUTF8Bytes(string))
}

export function hexToString(hex) {
  return new TextDecoder('UTF-8').decode(hexToBytes(hex))
}

export function stringToBoolean(str) {
  return str.toLowerCase() === 'true'
}

export default { stringToHex, hexToString, stringToBoolean }
