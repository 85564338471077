/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ChatStatuses, ConversationTypes } from 'utils/constants'
import useRedirectChatRequest from 'features/qrflow/hooks/useRedirectChatRequest'
import chatRequestOperations from 'redux/chatRequest/operations'
import useLocalAudioTrack from 'providers/VideoProvider/useLocalAudioTrack/useLocalAudioTrack'
import ChatApp from 'features/qrflow/components/chat/ChatApp'
import VideoChatApp from 'features/qrflow/components/video/VideoChatApp'
import useConsumerConversationChannel from 'features/qrflow/channels/useConsumerConversationChannel'
import { useToasts } from 'react-toast-notifications'
import { updateConsumerConversation, setConsumerConversation } from 'redux/consumerConversations/actions'
import { useParams, useHistory } from 'react-router-dom'
import { decodeBase64AsUnicode } from 'utils/helpers'
import useQuery from 'hooks/useQuery'
import { Intent } from '@swytchboard/client-sdk-ts'
import useConsumerConversations from '../hooks/useConsumerConversations'

const ConversationScreen = () => {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const history = useHistory()
  const { chatRequestId: chatReqId } = useParams()
  const {
    id: chatRequestId, roomToken, roomName, conversationSID, requestType, activeConversation,
  } = useConsumerConversations()

  const { sbclid = '' } = useQuery()

  useEffect(() => {
    if (sbclid && !chatRequestId) {
      const { consumerId, consumerToken, requestType: rRequestType } = (() => {
        try {
          return JSON.parse(decodeBase64AsUnicode(sbclid))
        } catch (e) {
          // history.replace({
          //   search: '',
          // })
          throw new Error("Couldn't parse 'sbclid' param. Make sure it contains valid data")
        }
      })()

      if (consumerId && consumerToken && rRequestType) {
        dispatch(setConsumerConversation({}, {
          consumerId: Number(consumerId),
          consumerToken,
          id: Number(chatReqId),
          requestType: rRequestType,
          status: ChatStatuses.accepted,
          settings: {
            [`customer-${consumerId}`]: {
              isJoyrideClosed: false,
              isTwoWayVideoEnabled: false,
              isAudioEnabled: null,
            },
            expiry: null,
          },
        }))
      } else {
        throw new Error("Couldn't parse 'sbclid' param. Make sure it contains valid data")
      }
    }
  }, [chatReqId, chatRequestId, dispatch, history, sbclid])

  const [joinInitiated, setJoinInitiated] = useState(false)

  const { removeLocalAudioTrack } = useLocalAudioTrack()

  useRedirectChatRequest()

  const onReceiveWorkerEvent = (response) => {
    if (response.message.intent === Intent.RATE_CHAT_REQUEST) {
      removeLocalAudioTrack()
      const { rate_token: rateToken } = response?.message?.data
      return dispatch(updateConsumerConversation(chatRequestId, { rateToken, status: ChatStatuses.completed, submitRating: null }))
    }

    if (response.message.intent === Intent.JOIN_CHAT) {
      return dispatch(updateConsumerConversation(chatRequestId, { requestType: response?.message?.request_type }))
    }

    if (response.message.event === 'conversation-status') {
      if (response?.message?.data?.status === ChatStatuses.accepted) {
        return dispatch(updateConsumerConversation(chatRequestId, {
          status: ChatStatuses.accepted,
          workerName: response?.message?.data?.answered_by_name,
        }))
      }
      return dispatch(updateConsumerConversation(chatRequestId, {
        status: response?.message?.data?.status,
      }))
    }

    if (response.message.intent === Intent.NOTIFY_APPEARANCE) {
      const {
        type,
        status,
      } = response?.message?.message
      return dispatch(updateConsumerConversation(chatRequestId, { [type]: { status } }))
    }

    if (Array.isArray(response?.message)) {
      const onlineUsers = response?.message
      return onlineUsers.forEach(({ type }) => dispatch(updateConsumerConversation(chatRequestId, { [type]: { status: 'online' } })))
    }
    return []
  }

  useConsumerConversationChannel(onReceiveWorkerEvent)

  const isVideoCall = requestType === ConversationTypes.videoText

  useEffect(() => {
    dispatch(chatRequestOperations.joinConversation(chatRequestId))
    // Whenever the request type changes (eg. from chat to video, we need to invoke the Join Conversation endpoint
    // That is why requestType is listed in the dependency array
    setJoinInitiated(true)

    // Disable the mic after call is ended
    return () => removeLocalAudioTrack()
  }, [chatRequestId, dispatch, removeLocalAudioTrack, requestType])

  useEffect(() => {
    if (joinInitiated && activeConversation?.error) {
      addToast(`${activeConversation.error} Please try refreshing page.`,
        {
          appearance: 'error',
          autoDismiss: false,
        })
    }
  }, [activeConversation, addToast, joinInitiated])

  if (isVideoCall) {
    return (
      <VideoChatApp
        token={roomToken}
        roomName={roomName}
        conversationSid={conversationSID}
      />
    )
  }

  return (
    <ChatApp
      token={roomToken}
      conversationSid={conversationSID}
    />
  )
}

export default ConversationScreen
