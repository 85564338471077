import React from 'react'
import VideoControlsButton from 'components/VideoControlsButton/VideoControlsButton'
import useScreenShareParticipant from 'hooks/useScreenShareParticipant/useScreenShareParticipant'
import { useVideoContext } from 'providers/VideoProvider'

const ToggleScreenShareButton = (disabled) => {
  const {
    isSharingScreen,
    toggleScreenShare,
  } = useVideoContext()

  const screenShareParticipant = useScreenShareParticipant()
  const disableScreenShareButton = Boolean(screenShareParticipant)
  const isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia
  const isDisabled = disabled || disableScreenShareButton || !isScreenShareSupported

  return (
    <VideoControlsButton
      name={isSharingScreen ? 'icon-share-screen' : 'icon-share-screen-end'}
      onClick={toggleScreenShare}
      disabled={isDisabled}
    />
  )
}

export default ToggleScreenShareButton
