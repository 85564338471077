import ConversationTypes from '../conversation.types'

export const setServiceIntroMessage = (serviceIntroMessage) => ({
  type: ConversationTypes.SET_SERVICE_INTRO_MESSAGE,
  serviceIntroMessage,
})

export const resetServiceIntroMessage = () => ({
  type: ConversationTypes.RESET_SERVICE_INTRO_MESSAGE,
  serviceIntroMessage: null,
})
