import { useEffect, useState } from 'react'
import { useVideoContext } from './index'

export const RoomStateType = {
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnected',
  RECONNECTING: 'reconnecting',
}

export default function useRoomState() {
  const { room } = useVideoContext()
  const [roomState, setRoomState] = useState(RoomStateType.DISCONNECTED)

  useEffect(() => {
    if (room) {
      const onRoomStateChange = () => setRoomState(room.state)
      onRoomStateChange()

      room
        .on('disconnected', onRoomStateChange)
        .on('reconnected', onRoomStateChange)
        .on('reconnecting', onRoomStateChange)
      return () => {
        room
          .off('disconnected', onRoomStateChange)
          .off('reconnected', onRoomStateChange)
          .off('reconnecting', onRoomStateChange)
      }
    }
    return undefined
  }, [room])

  return roomState
}
