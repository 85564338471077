import React from 'react'
import IcomoonReact from 'icomoon-react'
import { isMobile } from 'utils/helpers'
import iconSet from 'assets/fonts/selection.json'

const MaximizeIcon = ({ onClick = () => {}, disabled }) => (
  <div className={`${disabled && isMobile ? 'bg-danger' : 'bg-white'} rounded-pill m-2`} style={{ padding: '10px 13px 12px 13px' }}>
    <IcomoonReact
      onClick={onClick}
      iconSet={iconSet}
      size={15}
      icon={disabled ? 'icon-minimize' : 'icon-maximize'}
      color={(isMobile && disabled) ? 'white' : 'black'}
    />
  </div>
)

export default MaximizeIcon
