const ConversationTypes = {
  SET_ROOM_NAME: 'SET_ROOM_NAME',
  RESET_ROOM_NAME: 'RESET_ROOM_NAME',
  SET_TOKEN: 'SET_TOKEN',
  RESET_TOKEN: 'RESET_TOKEN',
  SET_CONVERSATION_SID: 'SET_CONVERSATION_SID',
  RESET_CONVERSATION_SID: 'RESET_CONVERSATION_SID',
  SET_CONSUMER_NAME: 'SET_CONSUMER_NAME',
  RESET_CONSUMER_NAME: 'RESET_CONSUMER_NAME',
  SET_CONSUMER_MESSAGE: 'SET_CONSUMER_MESSAGE',
  RESET_CONSUMER_MESSAGE: 'RESET_CONSUMER_MESSAGE',
  SET_SERVICE_INTRO_MESSAGE: 'SET_SERVICE_INTRO_MESSAGE',
  RESET_SERVICE_INTRO_MESSAGE: 'RESET_SERVICE_INTRO_MESSAGE',
  SET_PARTICIPANT: 'SET_PARTICIPANT',
  SET_CONVERSATION_STATUS: 'SET_CONVERSATION_STATUS',
  SET_ACCEPTED_REQUEST_ID: 'SET_ACCEPTED_REQUEST_ID',
  RESET_ACCEPTED_REQUEST_ID: 'RESET_ACCEPTED_REQUEST_ID',
  SET_TEAM_TOKEN: 'SET_TEAM_TOKEN',
  RESET_TEAM_TOKEN: 'RESET_TEAM_TOKEN',
  SET_TEAM_CONVERSATION_SID: 'SET_TEAM_CONVERSATION_SID',
  RESET_TEAM_CONVERSATION_SID: 'RESET_TEAM_CONVERSATION_SID',
  SET_AUDIO_PERMISSION: 'SET_AUDIO_PERMISSION',
  SET_VIDEO_PERMISSION: 'SET_VIDEO_PERMISSION',
  SET_CONSUMER_REQUEST_TYPE: 'SET_CONSUMER_REQUEST_TYPE',
}
export default ConversationTypes
