import React from 'react'
import { createRoot } from 'react-dom/client'
import 'assets/styles/index.scss'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/browser'
import config from 'utils/config'
import App from './App'
import reportWebVitals from './reportWebVitals'

if (config.SENTRY_DSN) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    tunnel: config.SENTRY_TUNNEL_SERVICE,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: config.SENTRY_SAMPLE_RATE,
    // Capture Replay for 10% of all sessions,
    // and for 100% of sessions with an error
    replaysSessionSampleRate: config.SENTRY_SAMPLE_RATE_REPLAYS,
    replaysOnErrorSampleRate: config.SENTRY_SAMPLE_RATE_REPLAYS_ON_ERROR,
    integrations: [new BrowserTracing(), Sentry.replayIntegration()],
  })
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)
/* ReactDOM.render(
  <App />,
  document.getElementById('root'),
) */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
