import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const ChatConfigurationLayout = ({
  children,
}) => (
  <Container fluid>
    <Row className="App">
      <Col className="App-content bg-white mx-0 px-0 d-flex flex-column">
        {children}
      </Col>
    </Row>
  </Container>
)

export default ChatConfigurationLayout
