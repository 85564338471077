import React, { useEffect } from 'react'
import useLocalAudioToggle from 'hooks/useLocalAudioToggle/useLocalAudioToggle'
import { useVideoContext } from 'providers/VideoProvider'
import VideoCallButton from 'features/qrflow/components/VideoCallButton'
import useRequestMediaPermission from 'providers/VideoProvider/useRequestMediaPermission/useRequestMediaPermission'
import { useDispatch } from 'react-redux'
import useMediaPermissions from 'hooks/useMediaPermissions'
import { updateConsumerConversationTracksPermit } from 'redux/consumerConversations/actions'
import useConsumerConversations from '../hooks/useConsumerConversations'

const hasAcquiredLocalTracks = (localTracks) => localTracks.length === 2
const ToggleMicrophoneButton = () => {
  const [isMicrophoneEnabled, toggleMicrophone] = useLocalAudioToggle()
  const { isMicrophoneEnabled: isMicrophonePermissionGranted } = useMediaPermissions()
  const { id, settings: { isAudioEnabled, customerKey } } = useConsumerConversations()

  const { askForMicrophonePermissions } = useRequestMediaPermission()
  const dispatch = useDispatch()
  const {
    room,
    isAudioTrackPublished,
    localTracks,
    audioTrack,
    setIsAudioTrackPublished,
    setMicMediaError,
  } = useVideoContext()

  const localParticipant = room?.localParticipant

  const onToggleMicrophone = () => {
    dispatch(updateConsumerConversationTracksPermit(id, customerKey, { isAudioEnabled: !isMicrophoneEnabled }))
    if (isAudioTrackPublished) {
      return toggleMicrophone()
    }
    if (!isAudioTrackPublished && audioTrack) {
      localParticipant?.publishTrack?.(audioTrack)
      setIsAudioTrackPublished(true)
      return setMicMediaError(null)
    }
    return askForMicrophonePermissions()
  }

  useEffect(() => {
    if (!isAudioTrackPublished && audioTrack) {
      localParticipant?.publishTrack?.(audioTrack)
      return setIsAudioTrackPublished(true)
    }
    return undefined
  }, [audioTrack, isAudioEnabled, isAudioTrackPublished, localParticipant, setIsAudioTrackPublished])

  useEffect(() => {
    if (!hasAcquiredLocalTracks(localTracks) && isMicrophonePermissionGranted && !isAudioTrackPublished) {
      askForMicrophonePermissions()
    }
  }, [isMicrophonePermissionGranted, isAudioTrackPublished, isAudioEnabled, localTracks, askForMicrophonePermissions])

  return (
    <VideoCallButton
      className={`qr-video__buttons__btn ${isAudioTrackPublished && isMicrophoneEnabled ? '' : 'inactive'}`}
      onClick={onToggleMicrophone}
      icon={isAudioTrackPublished && isMicrophoneEnabled ? 'icon-voice' : 'icon-mute'}
      color={isAudioTrackPublished && isMicrophoneEnabled ? '' : 'white'}
    />
  )
}

export default ToggleMicrophoneButton
