import constants from './constants'

const getConversationDetails = () => ({
  type: constants.GET_CONVERSATION_DETAILS,
})

const getConversationDetailsSuccess = (conversationDetails) => ({
  type: constants.GET_CONVERSATION_DETAILS_SUCCESS,
  payload: conversationDetails,
})

const getConversationDetailsError = (error) => ({
  type: constants.GET_CONVERSATION_DETAILS_ERROR,
  payload: error,
})

const setAudioCompositionRequested = (params) => ({
  type: constants.SET_AUDIO_COMPOSITION_REQUESTED,
  payload: params,
})

const setAudioCompositionRequestedError = (error) => ({
  type: constants.SET_AUDIO_COMPOSITION_REQUESTED_ERROR,
  payload: error,
})

export default {
  getConversationDetails,
  getConversationDetailsSuccess,
  getConversationDetailsError,
  setAudioCompositionRequested,
  setAudioCompositionRequestedError,
}
