// import { ActionState } from 'utils/constants'
import { defaultTracksExpiryTime } from 'utils/constants'
import constants from './constants'

const initialState = [
  // {
  //   id: String,
  //   roomName: String,
  //   roomToken: String,
  //   conversationSID: String,
  //   consumerName: String,
  //   consumerRequestType: String,
  //   conversationSettings: {
  //     [`worker`]: {
  //       isAudioEnabled: Boolean,
  //       isVideoEnabled: Boolean,
  //     },
  //     [`customer`]: {
  //       isJoyrideClosed: Boolean,
  //       isTwoWayVideoEnabled: Boolean,
  //       isAudioEnabled: Boolean,
  //     },
  //     expiry: Date,
  //   },
  //   user|consumer|participants: object ({ [p?.identity]: { status: 'on', type: ParticipantTypes.CONSUMER } })
  //   unReadMessages: Integer
  // },
]
const now = new Date()

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_ACTIVE_CONVERSATION:
      return [...state, action.conversation]

    case constants.END_ACTIVE_CONVERSATION:
      return [...state?.filter((item) => Number(item.id) !== Number(action.id))]

    case constants.UPDATE_ACTIVE_CONVERSATION: {
      const updateConversation = state?.map((chat) => {
        if (Number(chat.id) === Number(action.id)) {
          return { ...chat, ...action.data }
        }
        return chat
      })
      return updateConversation
    }

    case constants.UPDATE_ACTIVE_CONVERSATION_TRACKS: {
      const updateConversation = state?.map((chat) => {
        if (chat.id === action.id) {
          return { ...chat, settings: { [action.key]: { ...chat.settings[action.key], ...action.settings }, expiry: now.getTime() + defaultTracksExpiryTime } }
        }
        return chat
      })
      return updateConversation
    }

    case constants.UPDATE_ACTIVE_CONVERSTATIONS:
      return action.data

    case constants.RESET_ACTIVE_CONVERSATIONS:
      return initialState

    case constants.SET_ACTIVE_SCREEN_SHARE:
      return [...state, action.conversation]

    case constants.UPDATE_ACTIVE_SCREEN_SHARE: {
      const updateConversation = state?.map((chat) => {
        if (Number(chat.id) === Number(action.id)) {
          return { ...chat, ...action.data }
        }
        return chat
      })
      return updateConversation
    }

    case constants.END_ACTIVE_SCREEN_SHARE:
      return [...state?.filter((item) => Number(item.id) !== Number(action.id))]

    default:
      return state
  }
}

export default reducer
