import * as Yup from 'yup'
import { alphaNumeric } from 'utils/regex'

const passwordConfirmationSchema = {
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password is too short - should be 8 chars minimum')
    .matches(alphaNumeric, 'Password must be alphanumeric'),
  password_confirmation: Yup.string()
    .min(8, 'Password is too short - should be 8 chars minimum')
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Password confirmation is required'),
}

export default passwordConfirmationSchema
