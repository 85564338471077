import { ActionState } from 'utils/constants'
import constants from './constants'

const initialState = {
  data: null,
  error: null,
  updateSettings: ActionState.Initial,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_CURRENT_COMPANY_REQUEST:
      return {
        ...state,
        error: null,
      }
    case constants.UPDATE_COMPANY_SETTINGS:
      return {
        ...state,
        error: null,
        updateSettings: ActionState.InProgress,
      }
    case constants.UPDATE_COMPANY_SETTINGS_SUCCESS:
      return {
        ...state,
        error: null,
        data: action.payload,
        updateSettings: ActionState.Completed,
      }
    case constants.UPDATE_COMPANY_SETTINGS_ERROR:
      return {
        ...state,
        error: action.payload,
        updateSettings: ActionState.Initial,
      }
    case constants.GET_CURRENT_COMPANY_SUCCESS:
      return {
        ...state,
        error: null,
        data: action.payload,
      }
    case constants.GET_CURRENT_COMPANY_ERROR:
      return {
        ...state,
        data: null,
        error: action.payload,
      }
    case constants.RESET_COMPANY:
      return {
        data: null,
        error: action.payload,
      }
    case constants.RESET_UPDATE_SETTINGS_STATES:
      return {
        ...state,
        updateSettings: ActionState.Initial,
        error: null,
      }
    default:
      return state
  }
}

export default reducer
