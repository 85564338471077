export default {
  SET_ACTIVE_CONVERSATION: 'SET_ACTIVE_CONVERSATION',
  UPDATE_ACTIVE_CONVERSATION: 'UPDATE_ACTIVE_CONVERSATION',
  UPDATE_ACTIVE_CONVERSATION_TRACKS: 'UPDATE_ACTIVE_CONVERSATION_TRACKS',
  END_ACTIVE_CONVERSATION: 'END_ACTIVE_CONVERSATION',
  RESET_ACTIVE_CONVERSATIONS: 'RESET_ACTIVE_CONVERSATIONS',
  UPDATE_ACTIVE_CONVERSTATIONS: 'UPDATE_ACTIVE_CONVERSTATIONS',

  SET_ACTIVE_SCREEN_SHARE: 'SET_ACTIVE_SCREEN_SHARE',
  UPDATE_ACTIVE_SCREEN_SHARE: 'UPDATE_ACTIVE_SCREEN_SHARE',
  END_ACTIVE_SCREEN_SHARE: 'END_ACTIVE_SCREEN_SHARE',
}
