import { useState } from 'react'

export default function useDialog() {
  const [displayDialog, setDisplayDialog] = useState(false)

  const onOpenDialog = () => setDisplayDialog(true)
  const onCloseDialog = () => setDisplayDialog(false)

  return {
    displayDialog,
    onOpenDialog,
    onCloseDialog,
  }
}
