import constants from './constants'

const createChatRequest = (formData) => ({
  type: constants.CREATE_CHAT_REQUEST,
  payload: formData,
})

const createChatRequestSuccess = (chatRequestData) => ({
  type: constants.CREATE_CHAT_REQUEST_SUCCESS,
  payload: chatRequestData,
})

const createChatRequestError = (error) => ({
  type: constants.CREATE_CHAT_REQUEST_ERROR,
  payload: error,
})

const cancelChatRequest = () => ({
  type: constants.CANCEL_CHAT_REQUEST,
})

const cancelChatRequestSuccess = (chatRequestData) => ({
  type: constants.CANCEL_CHAT_REQUEST_SUCCESS,
  payload: chatRequestData,
})

const cancelChatRequestError = (error) => ({
  type: constants.CANCEL_CHAT_REQUEST_ERROR,
  payload: error,
})

const preselectChatRequest = ({ id, status }) => ({
  type: constants.PRESELECT_CHAT_REQUEST,
  payload: { id, status },
})

const requestVideo = () => ({
  type: constants.REQUEST_VIDEO,
})

const requestVideoSuccess = () => ({
  type: constants.REQUEST_VIDEO_SUCCESS,
})

const requestVideoError = (error) => ({
  type: constants.REQUEST_VIDEO_ERROR,
  payload: error,
})

const endConversation = () => ({
  type: constants.END_CONVERSATION,
})

const endConversationSuccess = (rateToken) => ({
  type: constants.END_CONVERSATION_SUCCESS,
  payload: rateToken,
})

const endConversationError = (error) => ({
  type: constants.END_CONVERSATION_ERROR,
  payload: error,
})

const joinChat = (data) => ({
  type: constants.JOIN_CHAT,
  payload: data,
})

const submitRatings = () => ({
  type: constants.SUBMIT_RATINGS,
})

const submitRatingsSuccess = () => ({
  type: constants.SUBMIT_RATINGS_SUCCESS,
})

const submitRatingsError = (error) => ({
  type: constants.SUBMIT_RATINGS_ERROR,
  payload: error,
})

const joinConversation = () => ({
  type: constants.JOIN_CONVERSATION,
})

const joinConversationSuccess = (conversation) => ({
  type: constants.JOIN_CONVERSATION_SUCCESS,
  payload: conversation,
})

const joinConversationError = (error) => ({
  type: constants.JOIN_CONVERSATION_ERROR,
  payload: error,
})

const joinWidgetConversationSuccess = (conversation, activeChatRequestId) => ({
  type: constants.JOIN_WIDGET_CONVERSATION_SUCCESS,
  payload: {
    conversation,
    activeChatRequestId,
  },
})

const joinWidgetConversationError = (error, activeChatRequestId) => ({
  type: constants.JOIN_WIDGET_CONVERSATION_ERROR,
  payload: {
    error,
    activeChatRequestId,
  },
})

const setConsumerToken = (chatRequestId, token) => ({
  type: constants.SET_CONSUMER_TOKEN,
  payload: {
    chatRequestId,
    token,
  },
})

const resetChatRequests = () => ({
  type: constants.RESET_CHAT_REQUESTS,
})

/**
 * @deprecated Since version 4.0.0 Use useConsumerConversation for QRFlow or useActiveConversation for Workers instead.
 */
export default {
  createChatRequest,
  createChatRequestSuccess,
  createChatRequestError,

  cancelChatRequest,
  cancelChatRequestSuccess,
  cancelChatRequestError,

  preselectChatRequest,

  requestVideo,
  requestVideoSuccess,
  requestVideoError,

  joinConversation,
  joinConversationSuccess,
  joinConversationError,

  joinWidgetConversationSuccess,
  joinWidgetConversationError,

  endConversation,
  endConversationSuccess,
  endConversationError,

  joinChat,

  submitRatings,
  submitRatingsSuccess,
  submitRatingsError,

  setConsumerToken,

  resetChatRequests,
}
