import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Formik } from 'formik'
import useChatSettings from 'routes/ChatSettingsEntryPoint/useChatSettings'
import withProviders from 'hoc/withProviders'
import ChatConfigurationProvider, { useChatConfiguration } from
  'routes/ChatSettingsEntryPoint/provider/ChatConfigurationProvider'
import StepperWrapper from 'routes/ChatSettingsEntryPoint/components/StepperWrapper/StepperWrapper'
import Steps from 'routes/ChatSettingsEntryPoint/steps'
import ChatBasics from 'routes/ChatSettingsEntryPoint/steps/ChatBasics/ChatBasics'
import ChatIntro from 'routes/ChatSettingsEntryPoint/steps/ChatIntro/ChatIntro'
import ChatPersonalInfo from 'routes/ChatSettingsEntryPoint/steps/ChatPersonalInfo/ChatPersonalInfo'
import ChatQueue from 'routes/ChatSettingsEntryPoint/steps/ChatQueue/ChatQueue'
import ChatRatings from 'routes/ChatSettingsEntryPoint/steps/ChatRatings/ChatRatings'
import ChatThanks from 'routes/ChatSettingsEntryPoint/steps/ChatThanks/ChatThanks'

function renderStep(step) {
  switch (step) {
    case Steps.ChatBasics:
      return <ChatBasics />
    case Steps.ChatIntro:
      return <ChatIntro />
    case Steps.ChatPersonalInfo:
      return <ChatPersonalInfo />
    case Steps.ChatQueue:
      return <ChatQueue />
    case Steps.ChatRatings:
      return <ChatRatings />
    case Steps.ChatThanks:
      return <ChatThanks />
    default:
      return <ChatBasics />
  }
}

const ChatConfiguration = () => {
  const {
    activeStep,
  } = useChatConfiguration()

  const {
    initialValues,
    updateSettings,
  } = useChatSettings()

  return (
    <Row className="chat-configuration">
      <Col md={8} className="chat-configuration__container">
        <Col md={12} className="chat-configuration__stepper">
          <StepperWrapper />
        </Col>
        <Col className="chat-configuration__content">
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => updateSettings(values)}
          >
            {renderStep(activeStep)}
          </Formik>
        </Col>
      </Col>
    </Row>
  )
}

export default withProviders(ChatConfigurationProvider)(ChatConfiguration)
