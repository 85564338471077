import handleResponse from 'utils/handleResponse'
import config from 'utils/config'
import http from 'utils/http'
import parseAndRethrowError from 'utils/parseAndRethrowError'

const TARGET_URL = `${config.SERVER_URL}${config.API_URL}`
const PUBLIC_TARGET_URL = `${config.SERVER_URL}${config.PUBLIC_API_URL}`

class Company {
  current = () => (
    http
      .get(`${TARGET_URL}/companies/current`)
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  createCompany = (name, website) => (
    http
      .post(`${TARGET_URL}/companies`, {
        company_attributes: {
          name,
          website,
        },
      })
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  updateSettings = (companyId, formData) => (
    http
      .patch(`${TARGET_URL}/companies/${companyId}`, {
        ...formData,
      })
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  updateCompanyLogo = (companyId, s3Key) => (
    http
      .patch(`${TARGET_URL}/companies/${companyId}`, {
        company_attributes: {
          company_detail_attributes: {
            logo: s3Key,
            logo2: '',
          },
        },
      })
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  members = (companyId) => (
    http
      .get(`${TARGET_URL}/companies/${companyId}/members`)
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  sortMembers = (companyId, field, order) => (
    http
      .get(`${TARGET_URL}/companies/${companyId}/members/?${field}=true&opt=${order}`)
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  inviteMember = () => (
    http
      .get(`${TARGET_URL}/invitations/me`)
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  createInviteMember = (companyId, email, role) => (
    http
      .post(`${TARGET_URL}/companies/${companyId}/invite`, {
        email,
        role,
      })
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  reInviteMember = (companyId, email) => (
    http
      .post(`${TARGET_URL}/companies/${companyId}/reinvite`, {
        email,
      })
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  acceptInviteMember = (token) => (
    http
      .post(`${TARGET_URL}/invitations/${token}/accept`)
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  completeInviteMember = (token, fullName, email, password) => (
    http
      .post(`${TARGET_URL}/invitations/${token}/complete`, {
        full_name: fullName,
        email,
        password,
      })
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  editMember = (companyId, memberId, role) => (
    http
      .patch(`${TARGET_URL}/companies/${companyId}/members/${memberId}?role=${role}`)
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  removeMember = (companyId, memberId) => (
    http
      .delete(`${TARGET_URL}/companies/${companyId}/members/${memberId}`)
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  removePendingMember = (companyId, invitationId) => (
    http
      .patch(`${TARGET_URL}/companies/${companyId}/invitations/${invitationId}/cancel`)
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  getConversations = (companyId, oneIndexedPage, filterParams) => (
    http
      .get(`${TARGET_URL}/companies/${companyId}/conversations?page=${oneIndexedPage}`, {
        params: filterParams,
      })
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  getConversationDetails = (companyId, chatRequestId) => (
    http
      .get(`${TARGET_URL}/companies/${companyId}/conversations/${chatRequestId}`, {
      })
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  getWorkers = (companyId) => (
    http
      .get(`${TARGET_URL}/companies/${companyId}/members/search`)
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  createComposition = (companyId, videoCallId) => (
    http
      .post(`${TARGET_URL}/companies/${companyId}/compositions`, {
        video_call_id: videoCallId.toString(),
      })
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  getCompositionFile = (companyId, compositionId) => (
    http
      .get(`${TARGET_URL}/companies/${companyId}/compositions/${compositionId}/file`)
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  createCompanyEntryPoint = (name, companyId) => (
    http
      .post(`${TARGET_URL}/company_services`, {
        name,
        company_id: companyId,
      })
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  listCompanyEntryPoints = () => (
    http
      .get(`${TARGET_URL}/company_services`)
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  getCompanyEntryPoint = (companyServiceId) => (
    http
      .get(`${TARGET_URL}/company_services/${companyServiceId}`)
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  getPublicCompanyEntryPoint = (publicEntryPointUid) => (
    http
      .get(`${PUBLIC_TARGET_URL}/company_services/${publicEntryPointUid}`)
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  updateCompanyEntryPoint = (companyServiceId, formData) => (
    http
      .patch(`${TARGET_URL}/company_services/${companyServiceId}/config`, {
        ...formData,
      })
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  updateCompanyEntryPointName = (companyServiceId, name, companyId) => (
    http
      .patch(`${TARGET_URL}/company_services/${companyServiceId}`, {
        name,
        company_id: companyId,
      })
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  removeCompanyEntryPoint = (companyServiceId) => (
    http
      .delete(`${TARGET_URL}/company_services/${companyServiceId}`)
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  getAvailableUsers = (serviceUid) => (
    http
      .get(`${PUBLIC_TARGET_URL}/company_services/${serviceUid}/available`)
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  getMessagesLeft = (companyId, status, search, oneIndexedPage) => {
    if (!companyId) {
      return Promise.reject()
    }
    return http
      .get(`${TARGET_URL}/companies/${companyId}/messages_left?page=${oneIndexedPage}&status=${status}&search=${search}`)
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  }

  closeMessagesLeft = (companyId, messageId) => (
    http
      .put(`${TARGET_URL}/companies/${companyId}/messages_left/${messageId}`)
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )
}

export default new Company()
