import React, { useRef, useState } from 'react'
import { Alert } from 'react-bootstrap'
import * as yup from 'yup'
import User from 'services/User'
import { Field, Formik } from 'formik'
import WorkerDialog from 'components/WorkerDialog'
import InputField from 'components/form/InputField'
import { useAuth } from 'providers/AuthProvider/AuthProvider'

const DeleteAccountSchema = yup.object()
  .shape({
    password: yup.string('Your password is required')
      .required('Your password is required'),
  })

const initialValues = {
  password: '',
}

const DeleteAccountDialog = ({
  show,
  onClose,
}) => {
  const { logout } = useAuth()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const formRef = useRef()

  const onDeleteAccount = async ({ password }) => {
    setErrorMessage('')
    setIsSubmitting(true)
    try {
      await User.deleteMe(password)
      logout()
    } catch (e) {
      setErrorMessage(e.message || e.error || 'Oops, an unexpected error occurred')
    }
    setIsSubmitting(false)
  }

  const resetStateOnExited = () => {
    setIsSubmitting(false)
    setErrorMessage('')
    formRef.current?.resetForm()
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={DeleteAccountSchema}
      onSubmit={onDeleteAccount}
      innerRef={formRef}
    >
      {({ isValid, dirty, handleSubmit }) => (
        <WorkerDialog
          show={show}
          onClose={onClose}
          onExited={resetStateOnExited}
          onConfirm={handleSubmit}
          loading={isSubmitting}
          disabled={!(isValid && dirty) || isSubmitting}
          title="Delete Account Permanently"
        >
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          <p>
            Are you sure you want to delete your account
            {' '}
            <span className="fw-bold">
              permanently
            </span>
            ?
          </p>
          <p className="fw-bold">
            This action is not reversible.
          </p>
          <p>
            Please enter your password to confirm.
          </p>
          <Field
            type="password"
            placeholder="Enter your password"
            name="password"
            inputClasses="profile-settings__field__input"
            groupClasses="m-0"
            component={InputField}
          />
        </WorkerDialog>
      )}
    </Formik>
  )
}

export default DeleteAccountDialog
