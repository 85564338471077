import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import useEntryPoints from 'routes/ChatSettingsEntryPoint/hooks/useEntryPoints'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import EntryPoint from 'routes/ChatSettingsEntryPoint/components/ListEntryPoints/EntryPoint'
import EntryPointSearchBar from 'routes/ChatSettingsEntryPoint/components/ListEntryPoints/EntryPointSearchBar'

const ListEntryPoints = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [searchResults, setSearchResults] = useState([])

  const {
    data,
    isLoading,
  } = useEntryPoints()

  const searchHandler = (searchValue) => {
    setSearchTerm(searchValue)

    if (searchTerm !== '') {
      const filteredEntryPointList = data.filter(
        (item) => item.name.toLowerCase().includes(searchValue.toLowerCase()),
      )
      setSearchResults(filteredEntryPointList)
    }
  }

  const listOfEntryPoints = searchTerm.length < 1 ? data : searchResults

  if (isLoading) {
    return <LoadingSpinner spinnerSize={50} className="text-center mt-5" />
  }

  return (
    <>
      <Row>
        <Col>
          <EntryPointSearchBar
            searchHandler={searchHandler}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {listOfEntryPoints?.map((item) => (
            <EntryPoint key={item?.id} item={item} />
          ))}
        </Col>
      </Row>
    </>
  )
}
export default ListEntryPoints
