import React, { useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import EntryPointDialog from 'components/WorkerDialog'
import useDialog from 'hooks/useDialog'
import InputField from 'components/form/InputField'
import useCreateEntryPoint from 'routes/ChatSettingsEntryPoint/hooks/useCreateEntryPoint'

const AddEntryPoint = () => {
  const [createEntryPoint, setCreateEntryPoint] = useState('')
  const history = useHistory()

  const {
    displayDialog: displayAddEntryPoint,
    onCloseDialog: onCloseAddEntryPoint,
    onOpenDialog: onOpenAddEntryPoint,
  } = useDialog()

  const {
    addEntryPoint,
  } = useCreateEntryPoint()

  const handleChatConfiguration = () => {
    addEntryPoint.mutate({
      name: createEntryPoint,
    }, {
      onSuccess: (data) => {
        history.push(`/admin/chat-configuration/${data?.id}`)
      },
      onError: () => {
        onCloseAddEntryPoint()
      },
    })
  }

  const initialValues = {
    name: '',
  }

  return (
    <>
      <Button
        type="submit"
        id="add-button"
        onClick={onOpenAddEntryPoint}
        className="add-entry-point btn btn-success d-block w-100 btn-auth fw-bold"
      >
        Add Entry Point
      </Button>
      <Formik
        initialValues={initialValues}
        enableReinitialize
      >
        {() => (
          <EntryPointDialog
            show={displayAddEntryPoint}
            onClose={onCloseAddEntryPoint}
            title="Add New Entry Point"
            confirmText="Configure"
            onConfirm={handleChatConfiguration}
            loading={addEntryPoint.isLoading}
          >
            <Row>
              <Col md={5}>
                <p className="fw-bold">Name</p>
              </Col>
              <Col md={7}>
                <InputField
                  name="name"
                  onChange={(e) => setCreateEntryPoint(e.target.value)}
                  placeholder="Choose an entry point name"
                />
              </Col>
            </Row>
          </EntryPointDialog>
        )}
      </Formik>
    </>
  )
}

export default AddEntryPoint
