import React from 'react'
import OnBoardingLayout from 'layouts/OnBoardingLayout'
import ProjectInfoConfirmation from './ProjectInfoConfirmation'

const ProjectInfoConfirmationRoute = () => (
  <OnBoardingLayout>
    <ProjectInfoConfirmation />
  </OnBoardingLayout>
)

export default ProjectInfoConfirmationRoute
