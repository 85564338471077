import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import EndConversationDialog from 'components/WorkerDialog/EndConversationDialog'
import useDialog from 'hooks/useDialog'
import ConsumerInfo from 'routes/ChatConversation/components/ConsumerInfo'
import CustomersInQueue from 'routes/ChatConversation/components/CustomersInQueue'
import { isMobile } from 'utils/helpers'
import { useHistory, useParams } from 'react-router-dom'
import useActiveConversations from 'hooks/useActiveConversations'
import { ConversationTypes } from 'utils/constants'
import BackToDashboardDialog from './BackToDashboardDialog'
import RequestVideoCallDialog from './RequestVideoCallDialog'

const ConversationHeader = () => {
  const [isBackToDashboard, setIsBackToDashboard] = useState(false)
  const history = useHistory()

  const handleBackToDashboard = async () => {
    setIsBackToDashboard(true)
    history.push({ pathname: '/dashboard', state: { from: 'conversation', allowNavigation: true } })
  }

  const { chatRequestId } = useParams()
  const { isAnyVideoCallActive, requestType } = useActiveConversations(chatRequestId)

  const {
    onOpenDialog: onOpenEndConversationDialog,
    onCloseDialog: onCloseEndConversationDialog,
    displayDialog: displayEndConversationDialog,
  } = useDialog()

  const {
    onCloseDialog: onCloseBackToDashboardDialog,
    displayDialog: displayBackToDashboardDialog,
  } = useDialog()

  const {
    displayDialog: displayRequestVideoDialog,
    onCloseDialog: onCloseRequestVideoDialog,
    onOpenDialog: onOpenRequestVideoDialog,
  } = useDialog()

  return (
    <div className="conversation-header__wrapper">
      <div className="conversation-header__content">
        <ConsumerInfo />
        {!isMobile && <CustomersInQueue />}
        {requestType === ConversationTypes.text && (
          <Button
            variant="primary"
            className="conversation-header__request-conversation-button"
            onClick={onOpenRequestVideoDialog}
            disabled={isAnyVideoCallActive}
            title={isAnyVideoCallActive ? 'You can only have one video call at a time' : ''}
          >
            Start Video
          </Button>
        )}
        <Button
          id="end-conversation-dialog-button"
          className="my-2 conversation-header__end-conversation-button text-white"
          onClick={onOpenEndConversationDialog}
        >
          End Conversation
        </Button>
        <EndConversationDialog
          show={displayEndConversationDialog}
          onClose={onCloseEndConversationDialog}
        />
        <BackToDashboardDialog
          show={displayBackToDashboardDialog}
          onClose={onCloseBackToDashboardDialog}
          onConfirm={handleBackToDashboard}
          loading={isBackToDashboard}
        />
        <RequestVideoCallDialog
          show={displayRequestVideoDialog}
          onClose={onCloseRequestVideoDialog}
        />
      </div>
      <hr />
    </div>
  )
}

export default ConversationHeader
