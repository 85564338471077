import http from 'utils/http'
import config from 'utils/config'
import handleErrors from 'utils/handleErrors'
import handleResponse from 'utils/handleResponse'

const TARGET_URL = `${config.SERVER_URL}${config.API_URL}`

export default {
  newScreenShare: (password) => http
    .post(`${TARGET_URL}/meet/screen_share`, password && { password })
    .then((response) => handleResponse(response))
    .catch((error) => handleErrors(error)),

  join: ({ id, meetingAccessKey, consumer }) => http.put(`${TARGET_URL}/meet/${id}/join`, { meeting_access_key: meetingAccessKey, consumer })
    .then((response) => handleResponse(response))
    .catch((error) => handleErrors(error)),

  askToJoin: (meetingLink) => http.get(`${TARGET_URL}/meet/${meetingLink}/ask_to_join`)
    .then((response) => handleResponse(response))
    .catch((error) => handleErrors(error)),

  endScreenSharing: (id) => http.put(`${TARGET_URL}/meet/screen_share/${id}`)
    .then((response) => handleResponse(response))
    .catch((error) => handleErrors(error)),
}
