import React from 'react'
import LoadingButton from 'components/buttons/LoadingButton'
import { useFormikContext } from 'formik'

const Header = ({
  isButtonDisabled,
  isSubmitting,
}) => {
  const {
    handleSubmit,
  } = useFormikContext()

  return (
    <div className="chat-settings__header">
      <div>
        <div className="chat-settings__header__title">
          Chat Settings
        </div>
        <div className="chat-settings__header__subtitle">
          Set up your chat settings.
        </div>
      </div>
      <div>
        <LoadingButton
          className="chat-settings__header__button"
          onClick={handleSubmit}
          disabled={isButtonDisabled}
          loading={isSubmitting}
        >
          Save Settings
        </LoadingButton>
      </div>
    </div>
  )
}

export default Header
