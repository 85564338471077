import React from 'react'
import { Modal } from 'react-bootstrap'
import LoadingButton from 'components/buttons/LoadingButton'

const QRDialog = ({
  show,
  onConfirm,
  onClose,
  title,
  disabled = false,
  loading = false,
  displayButtons = false,
  closeText = 'Cancel',
  confirmText = 'Confirm',
  confirmTextColor,
  disableCloseButton = false,
  loadingCloseButton = false,
  children,
  disableIndirectClosing = false,
  isJoinConversation = false,
}) => (
  <Modal
    show={show}
    onHide={onClose}
    contentClassName="qr-dialog"
    centered
    keyboard={!disableIndirectClosing}
    backdrop={disableIndirectClosing ? 'static' : true}
    {...isJoinConversation && { ...{ dialogClassName: 'dialog_container' } }}
  >
    <Modal.Header className="qr-dialog__header">{title}</Modal.Header>
    <Modal.Body className="qr-dialog__body">
      {children}
      <div className="qr-dialog__action-buttons">
        {!displayButtons && (
          <>
            <LoadingButton
              onClick={onClose}
              className={
            `qr-dialog__button qr-dialog__button--white
             ${disableCloseButton ? 'qr-dialog__button--white--disabled' : ''}          `
          }
              disabled={disableCloseButton}
              loading={loadingCloseButton}
            >
              {closeText}
            </LoadingButton>
            <LoadingButton
              onClick={onConfirm}
              className="qr-dialog__button"
              style={{
                backgroundColor: confirmTextColor,
              }}
              loading={loading}
              disabled={disabled}
            >
              {confirmText}
            </LoadingButton>
          </>
        )}
      </div>
    </Modal.Body>
  </Modal>
)

export default QRDialog
