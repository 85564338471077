/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react'
import { useVideoContext } from 'providers/VideoProvider'
import useScreenShareParticipant from 'hooks/useScreenShareParticipant/useScreenShareParticipant'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import VideoCallButton from './VideoCallButton'
import useConsumerConversations from '../hooks/useConsumerConversations'

const ToggleScreenShareButton = () => {
  const {
    isSharingScreen,
    toggleScreenShare,
  } = useVideoContext()
  const { consumerEmail } = useConsumerConversations()
  const isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia
  const screenShareParticipant = useScreenShareParticipant()

  const isDisabled = !isScreenShareSupported || (!isSharingScreen && screenShareParticipant && screenShareParticipant?.identity !== `consumer_${consumerEmail}`)

  const CustomToolTip = (
    <Tooltip id="tooltip-disabled" hidden={!isDisabled}>
      {!isScreenShareSupported
        ? 'Make sure your browser supports screen sharing'
        : isSharingScreen && screenShareParticipant?.identity !== `consumer_${consumerEmail}`
          ? 'Your screen sharing has been stopped.'
          : 'Screen sharing participant detected. Please make sure that no one else is currently sharing their screen.'}
    </Tooltip>
  )

  useEffect(() => {
    if (isSharingScreen && screenShareParticipant?.identity !== `consumer_${consumerEmail}`) {
      toggleScreenShare()
    }
  }, [consumerEmail, isDisabled, isSharingScreen, screenShareParticipant, toggleScreenShare])

  return (
    <OverlayTrigger
      overlay={CustomToolTip}
      delay={{ hide: 500, show: 100 }}
      defaultShow={isDisabled}
    >
      <span className="d-inline-block">
        <VideoCallButton
          icon={isSharingScreen ? 'icon-share-screen-end' : 'icon-share-screen'}
          className={`qr-video__buttons__btn ${!isSharingScreen ? '' : 'inactive'}`}
          color={!isSharingScreen ? '' : 'white'}
          onClick={toggleScreenShare}
          disabled={isDisabled}

        />
      </span>
    </OverlayTrigger>
  )
}

export default ToggleScreenShareButton
