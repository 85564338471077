import React from 'react'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'

const EmptyStar = () => (
  <IcomoonReact
    iconSet={iconSet}
    icon="star-empty"
    size={16}
  />
)

export default EmptyStar
