/* eslint-disable */
import React from 'react'
import { Form } from 'react-bootstrap'
import Select from 'react-select'
import { useField } from 'formik'

function SelectField({
  label,
  groupClasses,
  labelClasses, 
  field: selectField,
  ...props 
}) {
  const [field, meta, helpers] = useField(selectField.name)
  const { options } = props
  const { touched, error, value } = meta
  const { setValue, setTouched } = helpers

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: `${error ? '1px solid red' : 'none'}`,
      borderStyle: 'none',
      backgroundColor: '#F5F5F5',
      borderRadius: '30px',
      fontSize: '16px',
      paddingLeft: '10px',
      height: '46px',
    }),
    option: (provided) => ({
      ...provided,
      fontSize: '16px',
      textAlign: 'left',
      padding: '15px',
    }),
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap',
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: '0px 2px 8px rgba(100, 100, 100, 0.5)',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      background: 'none',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: 'black',
    }),
  }

  return (
    <div className="d-flex flex-column">
      <Form.Group className={groupClasses}>
        {label && <Form.Label className={labelClasses}>{label}</Form.Label>}
        <Select
          {...props}
          aria-labelledby="aria-label"
          styles={customStyles}
          value={options.find((option) => option.value === value) ?? ''}
          onChange={(option) => setValue(option.value)}
          onBlur={setTouched}
        />
      </Form.Group>
    </div>
  )
}

export default SelectField
