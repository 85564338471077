import React from 'react'
import { Button, Spinner } from 'react-bootstrap'

const LoadingButton = ({
  children,
  type,
  disabled = false,
  loading = false,
  onClick,
  className,
  spinnerSize,
  ...others
}) => (
  <Button
    onClick={onClick}
    disabled={disabled}
    className={className}
    type={type}
    {...others}
  >
    {
      loading
        ? (
          <Spinner
            animation="border"
            className="d-flex m-auto"
            style={{
              width: spinnerSize,
              height: spinnerSize,
            }}
          />
        )
        : children
    }
  </Button>
)

export default LoadingButton
