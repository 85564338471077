import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(utc)
dayjs.extend(customParseFormat)

const DateRangePicker = ({
  startDate: initialStartDate = null,
  endDate: initialEndDate = null,
  dateFormat = 'yyyy-MM-dd',
  maxDate,
  placeholder,
  onChange,
  hideInput = false,
  shouldCloseOnSelect = true,
  ...others
}) => {
  const [dateRange, setDateRange] = useState([initialStartDate, initialEndDate])
  const [startDate, endDate] = dateRange

  return (
    <div className="date-range-picker date-picker">
      <DatePicker
        id="date-picker"
        placeholderText={placeholder}
        selected={startDate}
        onChange={(dates) => {
          // To get the date ranges in parent component, pass an onChange prop which will contain the date range values
          if (onChange) {
            onChange(dates)
          }
          setDateRange(dates)
        }}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        shouldCloseOnSelect={shouldCloseOnSelect}
        maxDate={maxDate}
        dateFormat={dateFormat}
        className={hideInput ? 'hide-input' : ''}
        {...others}
      />
    </div>
  )
}

export default DateRangePicker
