import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import CustomerFeedback from 'routes/Feedback/CustomerFeedback'
import UserIntro from 'components/UI/UserIntro/UserIntro'

const Feedback = () => {
  const [customersFeedBackFilter, setCustomersFeedBackFilter] = useState([])
  return (
    <div className="bg-white ps-4 customer-feedback">
      <Row className="m-0 p-0">
        <Col md={12}>
          <UserIntro setCustomersFeedBackFilter={setCustomersFeedBackFilter}>
            Change stats period
          </UserIntro>
          <hr className="mb-4" />
        </Col>
        <Col md={12}>
          <h4 className="fw-bolder ">Rating Summary</h4>
          <p>
            This week you have an
            {' '}
            <strong>increase</strong>
            {' '}
            in total number of clients engaged, your professional rating and total earnings.
            {' '}
            <strong>Great job, keep it going!</strong>
          </p>
          <p>
            We have noticed a slight
            {' '}
            <strong>decrease</strong>
            {' '}
            of your helpfulness rating.
            <span
              className="customer-feedback__link"
            >
              Learn how to improve this skill.
            </span>
          </p>
          <hr className="mb-4" />
        </Col>
        <Col md={12}>
          <h4 className="fw-bolder ">Customer Feedback</h4>
          <CustomerFeedback filters={customersFeedBackFilter} />
        </Col>
      </Row>
    </div>
  )
}
export default Feedback
