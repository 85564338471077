import React from 'react'
import swytchboardLogo from 'assets/images/swytchboard-Y-logo.svg'
import poweredBy from 'assets/images/mct/poweredby.svg'
import { useChatConfiguration } from 'routes/ChatSettingsEntryPoint/provider/ChatConfigurationProvider'
import config from 'utils/config'

const ViewableLogoLayout = ({
  children,
}) => {
  const {
    entryPoint,
  } = useChatConfiguration()

  const {
    logo1,
    logo2,
  } = entryPoint?.data.config.visual

  const awsPath = `${config.ASSETS_URL}/`

  const companyLogo = logo1 === null
    ? swytchboardLogo : `${awsPath}${logo1}`
  const companyLogo2 = `${awsPath}${logo2}`

  return (
    <div className="chat-viewable h-100">
      <div className="mb-3">
        <div className="chat-viewable__logos-placeholder">
          <img src={companyLogo} alt="logo" />
          {
          logo2 !== null ? (
            <>
              <span className="chat-viewable__logos-placeholder--separator" />
              <img src={companyLogo2} alt="company-logo-2" />
            </>
          ) : null
        }
        </div>
      </div>
      {children}
      <div className="mx-auto">
        <img src={poweredBy} alt="SwytchBoard" />
      </div>
    </div>
  )
}

export default ViewableLogoLayout
