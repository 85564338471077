import { useState, useRef } from 'react'
import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import Image from 'services/Image'
import { formatSizeUnits } from 'utils/helpers'
import { uploadedMaxSize } from 'utils/constants'
import { useAuth } from 'providers/AuthProvider/AuthProvider'

export default function useUploadPhoto(updateURL, data, fieldValue, fileType) {
  const { user, company } = useSelector((state) => state)
  const { data: userData } = user
  const { user: userAuth } = useAuth()
  const { data: companyData } = company
  const [loading, setLoading] = useState(false)
  const { addToast } = useToasts()
  const s3KeyRef = useRef()

  const {
    setFieldValue,
  } = useFormikContext()

  const updatePhoto = async (s3Key) => {
    try {
      await updateURL(data, s3Key)

      s3KeyRef.current = s3Key

      setLoading(false)
    } catch (error) {
      addToast('Something went wrong please try again!', {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      })
      setLoading(false)
    }
  }

  const handleChange = async (e) => {
    const file = e.target === undefined ? e : (e.target.files && e.target.files[0])

    if (!file) {
      return
    }

    // Checking the type of uploaded file
    const [type] = file.type.split('/')
    if (!type || type !== 'image') {
      addToast('You can only upload image files.', {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 5000,
      })
      return
    }

    // Checking if the uploaded file is larger than 1 MB
    if (file.size > uploadedMaxSize) {
      const formatedSize = formatSizeUnits(file.size)
      addToast(`File is too large, file size is ${formatedSize}, maximum allowed size - 1 MB.`, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 5000,
      })
      return
    }

    setLoading(true)
    const userId = String(userData?.id ?? userAuth.id)
    const strCompanyId = String(companyData?.id)
    try {
      const { s3Key } = await Image.upload({
        file,
        userId,
        fileType,
        contentType: file.type,
        fileExt: file.name.slice((Math.max(0, file.name.lastIndexOf('.')) || Infinity) + 1),
        companyId: strCompanyId,
        companyServiceId: String(data),
      })

      if (updateURL !== null) {
        await updatePhoto(s3Key)
        setFieldValue(fieldValue, s3Key)
      } else {
        s3KeyRef.current = s3Key
        setLoading(false)
      }
    } catch (error) {
      addToast('Something went wrong please try again!', {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      })
      setLoading(false)
    }
  }

  return {
    loading,
    s3KeyRef,
    handleChange,
  }
}
