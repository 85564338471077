import React from 'react'

const ConversationFilter = ({
  label,
  field,
}) => (
  <div className="conversations__filter-field__container">
    <div
      className="conversations__filter-field__label"
    >
      {label}
    </div>
    {field}
  </div>
)

export default ConversationFilter
