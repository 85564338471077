import React from 'react'
import IcomoonReact from 'icomoon-react'
import ReactTimeAgo from 'react-time-ago'
import useDialog from 'hooks/useDialog'
import iconSet from 'assets/fonts/selection.json'
import DeleteTeamMember from 'routes/TeamMembers/components/Members/DeleteTeamMemberDialog/DeleteTeamMember'
import EditTeamMember from 'routes/TeamMembers/components/Members/EditTeamMemberDialog/EditTeamMember'

const MembersTableRow = ({
  id,
  name,
  teamRole,
  email,
  phone,
  lastActive,
}) => {
  const {
    displayDialog: displayDeleteTeamMember,
    onCloseDialog: onCloseDeleteTeamMember,
    onOpenDialog: onOpenDeleteTeamMember,
  } = useDialog()

  const {
    displayDialog: displayEditTeamMember,
    onCloseDialog: onCloseEditTeamMember,
    onOpenDialog: onOpenEditTeamMember,
  } = useDialog()

  const editIcon = (
    <IcomoonReact
      className="worker-dialog__header-content__icon"
      icon="edit"
      iconSet={iconSet}
      onClick={onOpenEditTeamMember}
    />
  )

  const deleteIcon = (
    <IcomoonReact
      className="worker-dialog__header-content__icon"
      icon="bin"
      iconSet={iconSet}
      onClick={onOpenDeleteTeamMember}
    />
  )

  return (
    <>
      <tr className="team-members__table-row">
        <th className="team-members__table-row__header" scope="row" id={id}>
          {name}
        </th>
        <td>{teamRole}</td>
        <td>{email}</td>
        <td>{phone}</td>
        <td>{lastActive ? <ReactTimeAgo date={new Date(lastActive)} locale="en-US" /> : 'Inactive'}</td>
        <td>{editIcon}</td>
        <td>{deleteIcon}</td>
      </tr>
      <EditTeamMember
        id={id}
        fullName={name}
        email={email}
        show={displayEditTeamMember}
        onClose={onCloseEditTeamMember}
      />
      <DeleteTeamMember
        id={id}
        fullName={name}
        show={displayDeleteTeamMember}
        onClose={onCloseDeleteTeamMember}
      />
    </>
  )
}

export default MembersTableRow
