import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { ToastKey } from 'routes/ChatSettingsEntryPoint/constants'
import { QueryKeys } from 'utils/constants'
import { stringToBoolean } from 'utils/conversion'
import Company from 'services/Company'
import useUpdateEntryPoint from 'routes/ChatSettingsEntryPoint/hooks/useUpdateEntryPoint'

export default function useDuplicateEntryPoint(id) {
  const [duplicateEntryPointId, setDuplicateEntryPointId] = useState('')

  const {
    addToast,
    removeToast,
  } = useToasts()

  const companyId = useSelector((state) => state.company.data?.id)
  const queryClient = useQueryClient()
  const { updateEntryPoint } = useUpdateEntryPoint(duplicateEntryPointId)

  const duplicateEntryPoint = useMutation(
    (values) => Company.createCompanyEntryPoint(values.name, String(companyId)), {
      onSuccess: (data) => {
        queryClient.setQueryData([QueryKeys.ENTRY_POINT], (oldEntryPoints) => [...oldEntryPoints, data])
        setDuplicateEntryPointId(data.id)

        const entryPointList = queryClient.getQueryData(QueryKeys.ENTRY_POINT)
        const entryPointCopy = entryPointList.filter((ep) => ep.id === id)
        const copyEntryPoint = entryPointCopy[0].company_service_config

        updateEntryPoint.mutate({
          config_params: {
            config: {
              landing_page: {
                ...copyEntryPoint.landing_page,
              },
              video_chat: {
                ...copyEntryPoint.video_chat,
                enabled: stringToBoolean(copyEntryPoint.video_chat.enabled),
              },
              text_chat: {
                ...copyEntryPoint.text_chat,
                away_message: undefined,
                enabled: stringToBoolean(copyEntryPoint.text_chat.enabled),
              },
              personal_page: {
                ...copyEntryPoint.personal_page,
                name_enabled: stringToBoolean(copyEntryPoint.personal_page.name_enabled),
                email_enabled: stringToBoolean(copyEntryPoint.personal_page.email_enabled),
                phone_enabled: stringToBoolean(copyEntryPoint.personal_page.phone_enabled),
                request_help_enabled: stringToBoolean(copyEntryPoint.personal_page.request_help_enabled),
              },
              ratings: {
                ...copyEntryPoint.ratings,
                enabled: stringToBoolean(copyEntryPoint.ratings.enabled),
                allow_comments: stringToBoolean(copyEntryPoint.ratings.allow_comments),
              },
              queue: {
                ...copyEntryPoint.queue,
              },
              visual: {
                ...copyEntryPoint.visual,
              },
            },
          },
        })
      },
      onError: (error) => {
        removeToast(ToastKey.entryPointError)
        addToast(`Duplicate Entry Point: ${error.message}`, {
          appearance: 'error',
          autoDismiss: 2000,
          id: ToastKey.entryPointError,
        })
      },
    },
  )

  return {
    duplicateEntryPoint,
  }
}
