/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react'
import Joyride, { ACTIONS } from 'react-joyride'
import EndConversationButton from 'features/qrflow/components/EndConversationButton'
import ToggleMicrophoneButton from 'features/qrflow/components/ToggleMicrophoneButton'
import ToggleCustomerVideoButton from 'features/qrflow/components/ToggleCustomerVideoButton'
import ToggleCustomerSettingsButton from 'features/qrflow/components/ToggleCustomerSettingsButton'
import useMediaPermissions from 'hooks/useMediaPermissions'
import { useDispatch } from 'react-redux'
import { updateConsumerConversationTracksPermit } from 'redux/consumerConversations/actions'
import useConsumerConversations from 'features/qrflow/hooks/useConsumerConversations'
import { isMobile } from 'utils/helpers'
import ToggleFlipCameraButton from '../ToggleFlipCameraButton'
import ToggleScreenShareButton from '../ToggleScreenShareButton'

const VideoCallButtons = () => {
  const [countDownTick, setCountDownTick] = useState(7)
  const dispatch = useDispatch()
  const steps = [{
    content: 'Click the video button to enable your camera',
    target: '.toggle-camera-button',
    placement: 'top',
    disableBeacon: true,
    hideFooter: true,
  }]
  const { isCameraEnabled } = useMediaPermissions()
  const { id, settings: { customer: { isJoyrideClosed: isClosed = false }, customerKey } } = useConsumerConversations()
  const handleOnClose = () => dispatch(updateConsumerConversationTracksPermit(id, customerKey, { isJoyrideClosed: true }))
  const handleJoyrideCallback = (data) => {
    const { action } = data

    if (action === ACTIONS.CLOSE) {
      setCountDownTick(0)
      return handleOnClose()
    }
  }
  useEffect(() => {
    async function handleOnCloseEffect() {
      if (isCameraEnabled) {
        if (countDownTick <= 0) {
          return handleOnClose()
        }
        const interval = setInterval(() => {
          setCountDownTick((prev) => prev - 1)
        }, 1000)
        return () => clearInterval(interval)
      }
    }
    handleOnCloseEffect()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countDownTick, isCameraEnabled])

  return (
    <div className="qr-video__buttons">
      <ToggleMicrophoneButton />
      {(!isClosed && isCameraEnabled) && (
      <Joyride
        steps={steps}
        continuous
        run={!(countDownTick <= 0)}
        callback={handleJoyrideCallback}
      />
      )}
      <ToggleCustomerVideoButton />
      <ToggleFlipCameraButton />
      {!isMobile && <ToggleScreenShareButton />}
      <EndConversationButton />
      <ToggleCustomerSettingsButton />
    </div>
  )
}
export default VideoCallButtons
