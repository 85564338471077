import useActiveConversations from 'hooks/useActiveConversations'
import IcomoonReact from 'icomoon-react'
import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { useHistory, generatePath } from 'react-router-dom'
import iconSet from 'assets/fonts/selection.json'
import { ConversationTypes, ParticipantTypes } from 'utils/constants'
import { Client } from '@twilio/conversations'
import { useDispatch } from 'react-redux'
import Chat from 'services/Chat'
import useConversationSettings from 'hooks/useActiveConversations/useConversationSettings'
import { setActiveConversation, updateActiveConversation } from 'redux/activeConversations/actions'
import { useToasts } from 'react-toast-notifications'
import useCustomerName from 'hooks/useCustomerName'
import ErrorMessages from 'providers/ChatProvider/error-messages'

const ConversationCard = ({ name, id, requestType }) => {
  const history = useHistory()
  const { id: selectedChatRequest } = useActiveConversations()
  const { roomToken, conversationSID, state: { unReadMessages = 0 } } = useActiveConversations(id)
  const [conversation, setConversation] = useState()
  const dispatch = useDispatch()
  const isSelected = (id === selectedChatRequest)
  const { workerKey, defaultSettings } = useConversationSettings()
  const { addToast } = useToasts()
  const customerName = useCustomerName()

  useEffect(() => {
    const asyncWrapper = async () => {
      if (roomToken) {
        const newChatClient = new Client(roomToken)
        newChatClient.on('initialized', async () => {
          const newConversation = await newChatClient.getConversationBySid(conversationSID).catch(() => {
            throw new Error(ErrorMessages.CONVERSATION_NOT_FOUND)
          })
          setConversation(newConversation)
        })
        newChatClient.on('initFailed', ({ error }) => {
          console.log('Twilio client initFailed :', error)
        })
      }
    }
    asyncWrapper()
  }, [conversationSID, roomToken])

  useEffect(() => {
    if (conversation && !isSelected) {
      const onMessageAdded = () => {
        dispatch(updateActiveConversation(id, { unReadMessages: unReadMessages + 1 }))
      }
      conversation.on('messageAdded', onMessageAdded)
      return () => {
        conversation.off('messageAdded', onMessageAdded)
      }
    }
    return undefined
  }, [conversation, dispatch, id, isSelected, unReadMessages])

  const handleConversation = async () => {
    try {
      if (roomToken) {
        history.push(generatePath('/conversation/:chatRequestId', { chatRequestId: id }))
      } else {
        const conversationData = await Chat.joinCall(requestType, id)
        dispatch(setActiveConversation(conversationData, {
          [ParticipantTypes.CONSUMER]: { },
          id,
          requestType,
          consumerName: conversationData.consumer_name,
          consumerEmail: conversationData.consumer_email,
          consumerPhone: conversationData.consumer_phone,
          roomName: conversationData.room_name,
          roomToken: conversationData.token,
          conversationSID: conversationData.conversation_sid,
          serviceIntroMessage: conversationData.welcome_message,
          settings: { [workerKey]: defaultSettings[workerKey], expiry: null },
        }))
        history.push(generatePath('/conversation/:chatRequestId', { chatRequestId: id }))
      }
    } catch (error) {
      addToast('There was a problem opening the conversation!', {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      })
    }
  }
  return (
    <Col className={`active-conversation m-2 p-0 ${isSelected ? 'bg-success' : 'bg-white'} br-2 shadow-big cursor-pointer`} disabled={isSelected} onClick={() => handleConversation()}>
      <Col className="info-card">
        <div className="ms-3">
          {requestType === ConversationTypes.videoText
            && (
              <IcomoonReact iconSet={iconSet} icon="icon-video-call" size={15} />
            )}
        </div>
        <p className="title ms-2">{customerName(name)}</p>
        {(!isSelected && unReadMessages > 0) && <p className="notification-circle">{unReadMessages}</p>}
      </Col>
    </Col>
  )
}
export default ConversationCard
