import React, { useEffect } from 'react'
import useActiveConversations from 'hooks/useActiveConversations'
import { useVideoContext } from 'providers/VideoProvider'
import VideoControls from '../VideoControls/VideoControls'
import ToggleAudioButton from '../VideoControlsButton/ToggleAudioButton/ToggleAudioButton'
import ToggleVideoButton from '../VideoControlsButton/ToggleVideoButton/ToggleVideoButton'
import Room from '../Room'
import ToggleSettingsButton from '../VideoControlsButton/ToggleSettingsButton/ToggleSettingsButton'
import ToggleScreenShareButton from '../VideoControlsButton/ToggleScreenShareButton/ToggleScreenShareButton'
/*
 * TODO: Generalize it, wrap Twilio around this wrapper
 * @returns {JSX.Element}
 * @constructor
 */
const VideoChat = () => {
  const { roomToken, roomName, id: acceptedRequest } = useActiveConversations()
  const { room } = useVideoContext()
  useEffect(() => () => room?.disconnect(), [room])
  return (
    <>
      {roomToken && roomName && acceptedRequest && <Room token={roomToken} roomName={roomName} />}
      <VideoControls>
        <ToggleAudioButton />
        <ToggleVideoButton />
        <ToggleScreenShareButton />
        <ToggleSettingsButton />
      </VideoControls>
    </>
  )
}

export default VideoChat
