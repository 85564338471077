import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Col, Row, Dropdown, NavItem,
} from 'react-bootstrap'
import Image from 'services/Image'
import { useToasts } from 'react-toast-notifications'
import { useQueryClient } from '@tanstack/react-query'
import { useAuth } from 'providers/AuthProvider/AuthProvider'
import PuchOutWarningDialog from 'components/PuchOutWarningDialog/PuchOutWarningDialog'
import useUserAvatar from 'hooks/useUserAvatar'
import './header.scss'
import useIsWorkerPunchedIn from 'features/work-logs/useIsWorkerPunchedIn'
import useDialog from 'hooks/useDialog'
import User from 'services/User'

import { QueryKeys } from 'utils/constants'

const Header = () => {
  const {
    isPunchedIn,
  } = useIsWorkerPunchedIn()

  const {
    logout,
    isAdmin,
  } = useAuth()

  const currentCompanyID = useSelector((state) => state.company.data?.id)

  const [displayDropdown, setDisplayDropdown] = useState(false)
  const { full_name: fullName, avatar } = useSelector((state) => state.user.data) || {}
  const history = useHistory()
  const { addToast } = useToasts()

  const {
    onOpenDialog: onOpenPunchOutWarningDialog,
    onCloseDialog: onClosePunchOutWarningDialog,
    displayDialog: displayPunchOutWarningDialog,
  } = useDialog()

  const profilePicture = Image.get(avatar)

  const {
    renderProfileImage,
  } = useUserAvatar(
    avatar,
    { profilePicture },
    { icommonIcon: 'avatar', icommonSize: '40' },
  )

  const queryClient = useQueryClient()

  const signOut = (e) => {
    e.preventDefault()
    return logout()
  }

  const handlePunchOutSignOut = async (e) => {
    try {
      await User.punchOut(currentCompanyID)
      queryClient.setQueryData([QueryKeys.WORK_LOGS, currentCompanyID], { start_date: null, end_date: null })
      signOut(e)
    } catch (error) {
      addToast('Something went wrong please try again!', {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      })
    }
  }

  const navigateToProfileSettings = () => {
    history.push('/profile-settings')
  }

  return (
    <Col
      md={12}
      className="header shadow-default p-0"
      style={{
        position: 'absolute',
        bottom: 0,
      }}
    >
      <hr style={{ border: '1px solid #F7F9FF', marginBottom: 0, backgroundColor: '#F7F9FF' }} />
      <Dropdown
        as={NavItem}
        className="d-flex m-0 p-0 user-profile w-100"
        show={displayDropdown}
        drop="up"
        onClick={() => setDisplayDropdown((prev) => !prev)}
      >
        <Dropdown.Toggle
          id="dropdown-basic"
          className="p-0 m-0 d-flex br-0 pe-5 py-3 w-100"
        >
          <Col className="px-3">
            {renderProfileImage}
          </Col>
          <Row className="text-white text-start d-block">
            <Col>
              <span
                data-testid="header-user-name"
                className="fw-bold card-title"
              >
                {fullName}
              </span>
            </Col>
            <Col>
              <span
                data-testid="header-user-role"
                className="card-subtitle"
              >
                {isAdmin ? 'Manager' : 'Worker'}
              </span>
            </Col>
          </Row>
        </Dropdown.Toggle>
        <Dropdown.Menu
          className="submenu-profile shadow-big
                      br-05 text-start user-submenu w-100 text-primary"
        >
          <Dropdown.Item
            className="submenu-item w-100 m-0 py-3 text-primary px-5 m-0"
            onClick={navigateToProfileSettings}
          >
            Edit Profile
          </Dropdown.Item>
          <Dropdown.Item className="text-danger submenu-item m-0 w-100 m-0 p-0">
            <div
              role="button"
              tabIndex="0"
              className="w-100 h-100 py-3 m-0 p-0"
              onClick={(e) => (isPunchedIn ? onOpenPunchOutWarningDialog() : signOut(e))}
              onKeyDown={(e) => (isPunchedIn ? onOpenPunchOutWarningDialog() : signOut(e))}
            >
              Sign Out
            </div>
          </Dropdown.Item>
          <PuchOutWarningDialog
            show={displayPunchOutWarningDialog}
            onClose={onClosePunchOutWarningDialog}
            onConfirm={handlePunchOutSignOut}
          />
        </Dropdown.Menu>
      </Dropdown>
    </Col>
  )
}

export default Header
