import React from 'react'
import BillingDialog from './BillingDialog'

const ConfirmUpgradeDialog = ({
  show,
  onClose,
  onConfirm,
  plan,
  loading,
  disabled,
}) => (
  <BillingDialog
    show={show}
    onClose={onClose}
    onConfirm={onConfirm}
    title={`Upgrade plan to ${plan}?`}
    confirmText="Yes"
    closeText="No"
    loading={loading}
    disabled={disabled}
  >
    <p>
      This will effect a plan change immediately, and your account will be transferred to the new plan
      {' '}
      {plan}
      .
    </p>
    <p>Based on the plan price change, you will be charged. Do you confirm?</p>
  </BillingDialog>
)

export default ConfirmUpgradeDialog
