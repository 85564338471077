import React from 'react'
import {
  Button, Form,
} from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import LoadingButton from 'components/buttons/LoadingButton'
import Layout from 'routes/AccountSetup/Layout'

const initialValues = {
  route: '',
}

const AccountSetup = () => {
  const history = useHistory()

  const navigateOnSubmit = (({ route }) => history.push(`/account-setup${route}`))

  const onBackClickHandler = () => history.push('/personal-info')

  return (
    <Layout>
      <Formik
        initialValues={initialValues}
        onSubmit={navigateOnSubmit}
      >
        {({
          values: {
            route,
          },
          handleSubmit,
          handleChange,
        }) => (
          <>
            <Form.Group>
              <Form.Check
                name="route"
                label="Setup a new account"
                type="radio"
                value="/new"
                className="mb-2"
                onChange={handleChange}
              />
              <Form.Check
                name="route"
                label="Join an existing team"
                value="/existing"
                type="radio"
                className="mb-2"
                onChange={handleChange}
              />
            </Form.Group>
            <div className="d-flex justify-content-between mt-5">
              <Button
                onClick={onBackClickHandler}
                className="account-setup__button account-setup__button--back"
              >
                Back
              </Button>
              <LoadingButton
                onClick={handleSubmit}
                disabled={!route}
                className="account-setup__button account-setup__button--submit"
              >
                Continue
              </LoadingButton>
            </div>
          </>
        )}
      </Formik>
    </Layout>
  )
}

export default AccountSetup
