import company from 'services/Company'
import conversationDetailsActions from 'redux/conversation/conversationDetails/actions'

const getConversationDetails = (companyId, chatRequestId) => (
  async (dispatch) => {
    dispatch(conversationDetailsActions.getConversationDetails())
    try {
      const conversationDetails = await company.getConversationDetails(companyId, chatRequestId)

      dispatch(conversationDetailsActions.getConversationDetailsSuccess(conversationDetails))
    } catch (e) {
      dispatch(conversationDetailsActions.getConversationDetailsError(e))
    }
  }
)

const setConversationDetailsCompositionRequested = (conversationDetails) => (
  async (dispatch) => {
    dispatch(conversationDetailsActions.getConversationDetails())
    try {
      dispatch(conversationDetailsActions.getConversationDetailsSuccess(conversationDetails))
    } catch (e) {
      dispatch(conversationDetailsActions.getConversationDetailsError(e))
    }
  }
)

const setAudioCompositionRequested = (payload) => (
  async (dispatch) => {
    try {
      dispatch(conversationDetailsActions.setAudioCompositionRequested(payload))
    } catch (e) {
      dispatch(conversationDetailsActions.setAudioCompositionRequestedError(e))
    }
  }
)

export default {
  getConversationDetails,
  setConversationDetailsCompositionRequested,
  setAudioCompositionRequested,
}
