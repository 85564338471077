import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import IcomoonReact from 'icomoon-react'
import { useHistory } from 'react-router-dom'
import iconSet from 'assets/fonts/selection.json'
import Line from 'components/UI/Line/Line'
import useDialog from 'hooks/useDialog'
import EntryPointDetails from 'routes/ChatSettingsEntryPoint/components/ListEntryPoints/EntryPointDetails'
import EntryPointDialog from 'routes/ChatSettingsEntryPoint/components/ListEntryPoints/EntryPointDialog'
import EntryPointButton from 'routes/ChatSettingsEntryPoint/components/ListEntryPoints/EntryPointButton'
import DuplicateEntryPointDialog from
  'routes/ChatSettingsEntryPoint/components/ListEntryPoints/DuplicateEntryPointDialog'
import DeleteEntryPointDialog from '../DeleteEntryPointDialog'
import './entry-point.scss'

const EntryPoint = ({ item }) => {
  const [minimize, setMinimize] = useState(true)
  const [liveChatEnabled, setLiveChatEnabled] = useState(item.company_service_config.text_chat.enabled === 'true')
  const [videoChatEnabled, setVideoChatEnabled] = useState(item.company_service_config.video_chat.enabled === 'true')

  const {
    displayDialog: displayEditEntryPoint,
    onCloseDialog: onCloseEditEntryPoint,
    onOpenDialog: onOpenEditEntryPoint,
  } = useDialog()

  const {
    displayDialog: displayDuplicateEntryPoint,
    onCloseDialog: onCloseDuplicateEntryPoint,
    onOpenDialog: onOpenDuplicateEntryPoint,
  } = useDialog()

  const {
    displayDialog: displayDeleteEntryPoint,
    onCloseDialog: onCloseDeleteEntryPoint,
    onOpenDialog: onOpenDeleteEntryPoint,
  } = useDialog()

  const history = useHistory()

  const handleConfigureButton = () => {
    history.push(`/admin/chat-configuration/${item.id}`)
  }

  return (
    <Row className="my-2">
      <Col className="entry-point__title">
        <h4>{item?.name}</h4>
      </Col>
      <Col className="entry-point__buttons my-4">
        <EntryPointButton
          onClick={onOpenEditEntryPoint}
          color="#767676"
          icon="edit-details-icon"
          title="Edit Details"
        />
        <EntryPointDialog
          show={displayEditEntryPoint}
          onClose={onCloseEditEntryPoint}
          item={item}
        />
        <EntryPointButton
          onClick={handleConfigureButton}
          icon="configure-icon"
          title="Configure"
        />
        <EntryPointButton
          onClick={onOpenDuplicateEntryPoint}
          icon="duplicate-icon"
          title="Duplicate"
        />
        <DuplicateEntryPointDialog
          show={displayDuplicateEntryPoint}
          onClose={onCloseDuplicateEntryPoint}
          item={item}
        />
        <EntryPointButton
          onClick={onOpenDeleteEntryPoint}
          icon="bin"
          title="Delete"
        />
        <DeleteEntryPointDialog
          show={displayDeleteEntryPoint}
          onClose={onCloseDeleteEntryPoint}
          item={item}
        />
        <IcomoonReact
          iconSet={iconSet}
          color="#767676"
          size={28}
          icon={`${minimize ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}`}
          role="button"
          onClick={() => setMinimize(!minimize)}
        />
      </Col>
      <Line />
      <Col>
        {!minimize && (
        <EntryPointDetails
          item={item}
          liveChatEnabled={liveChatEnabled}
          setLiveChatEnabled={setLiveChatEnabled}
          videoChatEnabled={videoChatEnabled}
          setVideoChatEnabled={setVideoChatEnabled}
        />
        )}
      </Col>
    </Row>
  )
}

export default EntryPoint
