import { useState } from 'react'
import { PermissionStatus } from 'utils/constants'

const useDesktopNotifications = () => {
  const isSupported = 'Notification' in window
  const [permission, setPermission] = useState(isSupported ? Notification.permission : null)
  const [isDenied, setIsDenied] = useState(permission === PermissionStatus.denied)

  const requestPermission = async () => {
    if (permission === PermissionStatus.denied || permission === PermissionStatus.default) {
      const perm = await Notification.requestPermission()
      if (perm === PermissionStatus.denied) {
        setIsDenied(true)
      }
      setPermission(perm)
    }
  }

  return {
    isDenied, isSupported, permission, requestPermission,
  }
}

export default useDesktopNotifications
