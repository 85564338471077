import constants from './constants'

const getCurrentCompanyRequest = () => ({
  type: constants.GET_CURRENT_COMPANY_REQUEST,
})

const getCurrentCompanySuccess = (currentCompany) => ({
  type: constants.GET_CURRENT_COMPANY_SUCCESS,
  payload: currentCompany,
})

const getCurrentCompanyError = (error) => ({
  type: constants.GET_CURRENT_COMPANY_ERROR,
  payload: error,
})

const updateCompanySettings = () => ({
  type: constants.UPDATE_COMPANY_SETTINGS,
})

const updateCompanySettingsSuccess = (settings) => ({
  type: constants.UPDATE_COMPANY_SETTINGS_SUCCESS,
  payload: settings,
})

const updateCompanySettingsError = (error) => ({
  type: constants.UPDATE_COMPANY_SETTINGS_ERROR,
  payload: error,
})

const resetCompany = () => ({
  type: constants.RESET_COMPANY,
  payload: null,
})

const resetUpdateSettingsStates = () => ({
  type: constants.RESET_UPDATE_SETTINGS_STATES,
})

export default {
  getCurrentCompanyRequest,
  getCurrentCompanySuccess,
  getCurrentCompanyError,
  updateCompanySettings,
  updateCompanySettingsSuccess,
  updateCompanySettingsError,
  resetCompany,
  resetUpdateSettingsStates,
}
