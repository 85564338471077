import React from 'react'
import ViewableLogoLayout from 'routes/ChatSettingsEntryPoint/components/ViewableLogoLayout'
import { Button } from 'react-bootstrap'
import { ChatThanksConst } from 'routes/ChatSettingsEntryPoint/constants'

const Viewable = ({
  enableThankYou,
  thankYouMessage,
  enabledThankYouButton,
  thankYouLabel,
  accentColor,
}) => (
  <ViewableLogoLayout>
    {enableThankYou && (
    <>
      <div className="text-start flex-fill">
        <h4 className="chat-viewable__title">
          THANK YOU!
          <div
            className="chat-viewable__title__line"
            style={{
              backgroundColor: accentColor,
            }}
          />
        </h4>
        <div className="chat-thanks-viewable--increase-spacing">
          <p>
            {thankYouMessage}
          </p>
        </div>
        {enabledThankYouButton ? (
          <div>
            <Button
              className="qr-btn white w-100"
              style={{ backgroundColor: accentColor }}
            >
              {thankYouLabel || ChatThanksConst.thankYouLabel.defaultValue}
            </Button>
          </div>
        ) : null}
      </div>
    </>
    )}
  </ViewableLogoLayout>
)

export default Viewable
