import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { chatConfigurationSteps, ChatThanksConst } from 'routes/ChatSettingsEntryPoint/constants'
import { useChatConfiguration } from 'routes/ChatSettingsEntryPoint/provider/ChatConfigurationProvider'
import TopChatConfigurationButtons from 'routes/ChatSettingsEntryPoint/components/Buttons/TopChatConfigurationButtons'
import BottomChatConfigurationButtons from
  'routes/ChatSettingsEntryPoint/components/Buttons/BottomChatConfigurationButtons'
import Editable from 'routes/ChatSettingsEntryPoint/steps/ChatThanks/Editable'
import Viewable from 'routes/ChatSettingsEntryPoint/steps/ChatThanks/Viewable'

const ChatThanks = () => {
  const {
    entryPoint,
    handleStateChange,
    modifiedState,
    setModifiedState,
  } = useChatConfiguration()

  const {
    enabled: entryPointEnabledThankYou,
    message: entryPointThankYouMessage,
  } = entryPoint?.data.config.thankyou_page

  const {
    enabled: entryPointEnabledThankYouButton,
    label: entryPointThankYouLabel,
    link: entryPointThankYouLink,
  } = entryPoint?.data?.config?.routing?.thankyou_page_close_link

  const {
    accent_color: accentColor,
  } = entryPoint?.data.config.visual

  const [enableThankYou, setEnableThankYou] = useState(entryPointEnabledThankYou === 'true')
  const [enabledThankYouButton, setEnableThankYouButton] = useState(entryPointEnabledThankYouButton)
  const [thankYouLabel, setThankYouLabel] = useState(entryPointThankYouLabel)
  const [thankYouLink, setThankYouLink] = useState(entryPointThankYouLink)
  const [websiteLinkError, setWebsiteLinkError] = useState(false)
  const [thankYouMessage, setThankYouMessage] = useState(entryPointThankYouMessage === ''
    ? ChatThanksConst.thankYouMessage.message : entryPointThankYouMessage)

  const handleSaveButton = () => {
    handleStateChange({
      [chatConfigurationSteps.THANKYOU_PAGE]: {
        enabled: Boolean(enableThankYou),
        message: thankYouMessage === '' ? ChatThanksConst.thankYouMessage.message : thankYouMessage,
      },
      [chatConfigurationSteps.ROUTING]: {
        [chatConfigurationSteps.THANKYOU_PAGE_CLOSE_LINK]: {
          enabled: Boolean(enabledThankYouButton),
          label: thankYouLabel,
          link: thankYouLink,
        },
      },
    })
    setModifiedState(false)
  }

  const handleCancelButton = () => {
    setEnableThankYou(entryPointEnabledThankYou === 'true')
    setThankYouMessage(entryPointThankYouMessage)
    setEnableThankYouButton(entryPointEnabledThankYouButton)
    setThankYouLabel(entryPointThankYouLabel)
    setThankYouLink(entryPointThankYouLink)
    setModifiedState(false)
  }

  return (
    <>
      <Row>
        <Col className="chat-configuration__buttons top">
          <TopChatConfigurationButtons
            handleSaveButton={handleSaveButton}
            handleCancelButton={handleCancelButton}
            modifiedState={modifiedState}
          />
        </Col>
      </Row>
      <Row className="h-100">
        <Col md={8} className="pe-4 m-0">
          <Editable
            enableThankYou={enableThankYou}
            setEnableThankYou={setEnableThankYou}
            thankYouMessage={thankYouMessage}
            setThankYouMessage={setThankYouMessage}
            enabledThankYouButton={enabledThankYouButton}
            setEnableThankYouButton={setEnableThankYouButton}
            thankYouLabel={thankYouLabel}
            setThankYouLabel={setThankYouLabel}
            thankYouLink={thankYouLink}
            setThankYouLink={setThankYouLink}
            websiteLinkError={websiteLinkError}
            setWebsiteLinkError={setWebsiteLinkError}
            modifiedState={modifiedState}
            setModifiedState={setModifiedState}
          />
        </Col>
        <Col md={4} className="p-0 m-0">
          <Viewable
            enableThankYou={enableThankYou}
            thankYouMessage={thankYouMessage}
            enabledThankYouButton={enabledThankYouButton}
            thankYouLabel={thankYouLabel}
            thankYouLink={thankYouLink}
            accentColor={accentColor}
          />
        </Col>
      </Row>
      <Row>
        <Col className="chat-configuration__buttons">
          <BottomChatConfigurationButtons
            modifiedState={modifiedState}
            setModifiedState={setModifiedState}
          />
        </Col>
      </Row>
    </>
  )
}

export default ChatThanks
