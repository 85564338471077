import config from 'utils/config'
import handleResponse from 'utils/handleResponse'
import http from 'utils/http'
import parseAndRethrowError from 'utils/parseAndRethrowError'

const TARGET_URL = `${config.SERVER_URL}${config.API_URL}`

class Ratings {
  getRatings = (page, startDate, endDate) => {
    const sdate = startDate ? `&start_date=${startDate}` : ''
    const edate = endDate ? `&end_date=${endDate}` : ''
    return http.get(`${TARGET_URL}/ratings/me?page=${page}${sdate}${edate}`)
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  }
}

export default new Ratings()
