import React, { useEffect, useState } from 'react'
import iconSet from 'assets/fonts/selection.json'
import IcomoonReact from 'icomoon-react'

const openInNewTab = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer')
}

const getTemporaryURL = async (media) => {
  try {
    const url = await media.getContentTemporaryUrl()
    openInNewTab(url)
  } catch (error) {
    openInNewTab('/notfound') // default URL if twilio service is down
  }
}

function EmbedChatFiles({ media }) {
  const [fileUrl, setFileUrl] = useState('')

  const { contentType, filename } = media
  const imageTypes = ['image/jpg', 'image/jpeg', 'image/png']
  const audioTypes = ['audio/mpeg']
  const videoTypes = ['video/mp4', 'video/webm']

  useEffect(() => {
    if (media) {
      (async () => {
        try {
          const fileURL = await media.getContentTemporaryUrl()
          setFileUrl(fileURL)
        } catch (error) {
          setFileUrl('')
        }
      })()
    }
  }, [media])

  if (imageTypes.includes(contentType)) {
    return (
      <>
        <a
          target="blank"
          rel="noopener noreferrer"
          style={{ cursor: 'pointer' }}
          onClick={() => getTemporaryURL(media)}
        >
          <img src={fileUrl} alt={filename} style={{ objectFit: 'contain' }} className="h-100 w-100 rounded-1" />
        </a>
        <br />
      </>
    )
  }
  if (audioTypes.includes(contentType)) {
    return (
      <a
        target="blank"
        rel="noopener noreferrer"
        style={{ cursor: 'pointer' }}
        onClick={() => getTemporaryURL(media)}
      >
        <figure>
          <figcaption>{filename}</figcaption>
          <audio
            controls
            src={fileUrl}
            style={{ width: '250px' }}
          >
            <track kind="captions" />
          </audio>
        </figure>
      </a>
    )
  }
  if (videoTypes.includes(contentType)) {
    return (
      <a
        target="blank"
        rel="noopener noreferrer"
        style={{ cursor: 'pointer' }}
        onClick={() => getTemporaryURL(media)}
      >
        <video controls width="250px">
          <source
            src={fileUrl}
            type={contentType}
          />
          <track kind="captions" />
        </video>
      </a>
    )
  }
  return (
    <div
      className="w-100"
      style={{
        cursor: 'pointer',
      }}
      onClick={() => getTemporaryURL(media)}
    >
      <IcomoonReact
        iconSet={iconSet}
        color="grey"
        size={40}
        icon="file-empty"
      />
      <span style={{ paddingLeft: '5px' }}>
        {' '}
        {filename}
        {' '}
      </span>
      <br />
    </div>
  )
}

export default EmbedChatFiles
