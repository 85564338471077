import React from 'react'
import { Button } from 'react-bootstrap'
import { parseURL } from 'utils/helpers'
import ViewableLogoLayout from 'routes/ChatSettingsEntryPoint/components/ViewableLogoLayout'
import { DISPLAY_NOT_AVAILABLE_MESSAGE } from 'features/qrflow/constants'
import { TEXT_CHAT, VIDEO_CHAT } from 'routes/ChatSettingsEntryPoint/constants'

const Viewable = ({
  landingMessage,
  messagingChatEnabled,
  videoChatEnabled,
  awayMessage,
  awayMessagePreviewEnabled,
  websiteLink,
  callToActionText,
  accentColor,
}) => (
  <ViewableLogoLayout>
    <div className="chat-intro-viewable flex-grow-1 d-flex flex-column">
      {awayMessagePreviewEnabled && (
        <>
          <div>
            <p>
              {awayMessage || DISPLAY_NOT_AVAILABLE_MESSAGE}
            </p>
          </div>
          <Button
            disabled={!awayMessagePreviewEnabled}
            className="qr-btn w-100 mb-5"
            style={{
              backgroundColor: accentColor,
            }}
          >
            Leave a Message
          </Button>
        </>
      )}
      <div className="screen-intro-text screen-intro-text--center screen-intro-text--increase-spacing">
        <p className="first-line">
          {awayMessagePreviewEnabled ? !landingMessage : landingMessage}
        </p>
      </div>
      {videoChatEnabled && !awayMessagePreviewEnabled && (
      <Button
        disabled={awayMessagePreviewEnabled}
        className="qr-btn w-100 mb-5"
        style={{
          backgroundColor: accentColor,
        }}
      >
        {VIDEO_CHAT}
      </Button>
      )}
      {messagingChatEnabled && !awayMessagePreviewEnabled && (
      <Button
        disabled={awayMessagePreviewEnabled}
        className="qr-btn w-100 mb-5"
        style={{
          backgroundColor: accentColor,
        }}
      >
        {TEXT_CHAT}
      </Button>
      )}
      <Button
        className="qr-btn white w-100"
        onClick={() => {
          if (websiteLink === '') {
            return
          }
          window.open(parseURL(websiteLink))
        }}
      >
        {callToActionText}
      </Button>
    </div>
  </ViewableLogoLayout>
)

export default Viewable
