import { useAuth } from 'providers/AuthProvider/AuthProvider'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const useUserRedirection = () => {
  const { isLoggedIn } = useAuth()
  const history = useHistory()

  const { data: currentCompany } = useSelector((state) => state.company)
  const currentUser = useSelector((state) => state?.user?.data)
  const currentRole = useMemo(() => currentUser?.roles?.find((role) => role?.company_id === currentCompany?.id)?.role, [currentUser, currentCompany])
  const noProjectSetup = currentUser?.roles?.length === 0

  useEffect(() => {
    if (isLoggedIn) {
      if ('email' in currentUser) {
      // Redirect to personal-info if the user hasn't
        if (!currentUser?.full_name) {
          return history.push('/personal-info')
        }

        if (!currentUser?.confirmed_at) {
          return history.push('/verify-email')
        }

        // If the user is not assigned to a project (has no role), redirect to /account-setup
        if (noProjectSetup) {
          return history.push('/account-setup')
        }

        if (currentCompany?.id && currentRole === 'manager') {
          return history.push('/admin/dashboard')
        }
        return history.push('/dashboard')
      }
    }
    return undefined
  }, [currentCompany?.id, currentRole, currentUser, history, isLoggedIn, noProjectSetup])
}

export default useUserRedirection
