import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import CustomerChat from 'routes/Dashboard/components/chat/customer/CustomerChat'
import TeamChat from 'routes/Dashboard/components/chat/team/TeamChat'
import useRemoteParticipants from 'hooks/useRemoteParticipants/useRemoteParticipants'
import useMainParticipant from 'hooks/useMainParticipant/useMainParticipant'
import useScreenShareParticipant from 'hooks/useScreenShareParticipant/useScreenShareParticipant'
import { useVideoContext } from 'providers/VideoProvider'
import { useSelector } from 'react-redux'
import ParticipantThumbnail from '../../components/Room/ParticipantThumbnail'

const videoInfoContainerClassName = 'connecting'

const ConversationChatWrapper = ({
  chatToggle, handleChatToggle, teamTabActive, isChatHidden,
}) => {
  const { room, isTwoWayEnabled } = useVideoContext()
  const workerName = useSelector((state) => state?.user?.data?.full_name)
  const { remoteParticipants, hasAnyParticipant, isParticipantDisconnected } = useRemoteParticipants()
  const mainParticipant = useMainParticipant()
  const screenShareParticipant = useScreenShareParticipant()
  const localParticipant = room?.localParticipant
  const bothThumbnails = isTwoWayEnabled && Boolean(screenShareParticipant)
  const videoPriority = mainParticipant === screenShareParticipant && mainParticipant !== localParticipant
    ? 'high'
    : null

  return (
    <Col className="m-0 p-0 h-100">
      {!isChatHidden ? (
        <Col>
          <Row className={`m-1 p-3 participant-thumbnail ${bothThumbnails ? 'second-thumbnail' : ''}`}>
            {(hasAnyParticipant && !isParticipantDisconnected) && (
              <ParticipantThumbnail
                className={`${isChatHidden ? 'chat__hidden' : ''}`}
                participant={localParticipant}
                isTwoWayEnabled
                localParticipant={localParticipant}
                screenShareParticipant={screenShareParticipant}
                mainParticipant={mainParticipant}
                videoOnly
                enableScreenShare={mainParticipant !== localParticipant}
                videoPriority={videoPriority}
                isLocalParticipant={mainParticipant === localParticipant}
                participantName={workerName}
              />
            )}
            {Boolean(screenShareParticipant) && remoteParticipants.map((remoteParticipant) => (
              (
                <ParticipantThumbnail
                  className={`${isChatHidden ? 'chat__hidden' : ''}`}
                  key={remoteParticipant}
                  participant={screenShareParticipant ? remoteParticipant : localParticipant}
                  videoConnectionInfoClassName={videoInfoContainerClassName}
                  isTwoWayEnabled={isTwoWayEnabled}
                  worker
                />
              )
            ))}
          </Row>
          <Col className="d-flex px-4 justify-content-between">
            <Button
              variant={`${chatToggle === 'customer' ? 'success' : 'lighter'}`}
              className="px-5 py-2 card-subtitle fw-bold"
              onClick={handleChatToggle}
              id="customer-button"
            >
              Customer Chat
            </Button>
            <Button
              variant={`${chatToggle === 'team' ? 'success' : 'lighter'}`}
              className="px-5 py-2 card-subtitle fw-bold"
              onClick={handleChatToggle}
              id="team-button"
            >
              Team Chat
            </Button>
          </Col>
        </Col>
      ) : (
        <Col>
          <Row className="m-0 p-0 d-flex flex-grow-1">
            {(hasAnyParticipant && !isParticipantDisconnected) && (
            <ParticipantThumbnail
              className={`${isChatHidden ? 'worker__thumbnail__chat__hidden' : ''}`}
              participant={localParticipant}
              isTwoWayEnabled
              localParticipant={localParticipant}
              screenShareParticipant={screenShareParticipant}
              mainParticipant={mainParticipant}
              participantName={workerName}
            />
            )}
            {Boolean(screenShareParticipant) && remoteParticipants.map((participant) => (
              (
                <ParticipantThumbnail
                  key={participant}
                  className={`${isChatHidden ? 'worker__thumbnail__chat__hidden' : ''}`}
                  participant={screenShareParticipant ? participant : localParticipant}
                  videoConnectionInfoClassName={videoInfoContainerClassName}
                  isTwoWayEnabled={isTwoWayEnabled}
                  worker
                />
              )
            ))}
          </Row>
        </Col>
      )}
      <Row className={`m-0 p-3 ${isChatHidden ? 'hide-conversation-screen-chat' : ''}`} style={{ height: '70%' }}>
        <Col
          md={12}
          className={`pb-5 ${chatToggle === 'customer' ? 'd-flex' : 'd-none'}`}
        >
          <CustomerChat />
        </Col>
        <Col md={12} className={`${chatToggle === 'team' ? 'd-flex' : 'd-none'} h-100`}>
          <TeamChat changed={teamTabActive} />
        </Col>
      </Row>
    </Col>
  )
}
export default ConversationChatWrapper
