import React, { useState } from 'react'
import AliceCarousel from 'react-alice-carousel'
import iconSet from 'assets/fonts/selection.json'
import IcomoonReact from 'icomoon-react'
import { useAuth } from 'providers/AuthProvider/AuthProvider'
import { roundToTwo } from 'utils/helpers'
import usePunchedInUsers from '../hooks/usePunchedInUsers'
import 'react-alice-carousel/lib/alice-carousel.css'
import Stats from './Stats'

const UserStatsCarousel = ({ userStats }) => {
  const { isAdmin } = useAuth()
  const [activeIndex, setActiveIndex] = useState(0)

  const {
    data: punchedInUsers,
  } = usePunchedInUsers((data) => data)

  const activePunchedInUsers = punchedInUsers?.length || 0

  const adminItems = isAdmin ? [
    <Stats
      key="1"
      header="# of workers online"
      number={activePunchedInUsers}
      footer={`${roundToTwo(userStats.total_company_users)} total workers in company`}
    />,
    <Stats
      key="2"
      header="# of customers helped in current period"
      percentage="+20"
      number={`${roundToTwo(userStats.total_customers)}`}
      footer={`${roundToTwo(userStats.customers_per_day)} customers per day on average`}
    />,
  ] : []

  const items = [
    ...adminItems,
    <Stats
      key="3"
      header="Total helpfulness rating"
      percentage="-0.3"
      number={roundToTwo(userStats.helpfulness)}
      footer={`${roundToTwo(userStats.total_ratings)} ratings total`}
    />,
    <Stats
      key="4"
      header="Total knowledge rating"
      percentage="0"
      number={roundToTwo(userStats.knowledge)}
      footer={`${roundToTwo(userStats.total_ratings)} ratings total`}
    />,
    <Stats
      key="5"
      header="Total professional rating"
      percentage="+30"
      number={roundToTwo(userStats.professional)}
      footer={`${roundToTwo(userStats.total_ratings)} ratings total`}
    />,
    <Stats
      key="6"
      header="Total customers engaged"
      percentage="+12"
      number={roundToTwo(userStats.customers_engaged)}
      footer={`${roundToTwo(userStats.customers_per_day)} customers per day on average`}
    />,
  ]

  const slidePrev = () => {
    if (activeIndex <= 0) return null

    return setActiveIndex(activeIndex - 1)
  }

  const slideNext = () => {
    if (activeIndex < 3) {
      return setActiveIndex(activeIndex + 1)
    }
    return null
  }

  const syncActiveIndex = ({ item }) => setActiveIndex(item)

  const responsive = {
    0: { items: 1 },
    767: { items: 2 },
    1024: { items: 4 },
    1800: { items: 5 },
  }

  return (
    <div className="pe-4 ps-4">
      <div className="slider-container d-flex">
        <button type="button" className="slider-container--button">
          <IcomoonReact
            iconSet={iconSet}
            color={`${activeIndex === 0 ? '#dbdbdb' : '#767676'}`}
            size={20}
            icon="circle-left"
            role="button"
            onClick={slidePrev}
          />
        </button>
        <AliceCarousel
          disableDotsControls
          responsive={responsive}
          disableButtonsControls
          items={items}
          activeIndex={activeIndex}
          onSlideChanged={syncActiveIndex}
        />
        <button type="button" className="slider-container--button">
          <IcomoonReact
            iconSet={iconSet}
            color={`${activeIndex === 3 ? '#dbdbdb' : '#767676'}`}
            size={20}
            icon="circle-right"
            role="button"
            onClick={slideNext}
          />
        </button>
      </div>
    </div>
  )
}

export default UserStatsCarousel
