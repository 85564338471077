import React from 'react'
import { Link } from 'react-router-dom'
import {
  Col,
  Row,
} from 'react-bootstrap'
import Line from 'components/UI/Line/Line'

const NewPlan = () => (
  <Row className="mt-3 mb-5">
    <Col md={12}>
      <h5 className="mb-3 fw-bold">Currently there is no active subscription.</h5>
      <div className="mb-3">
        <Link
          className="billing__plan-new__btn-choose-plan btn btn-link"
          to="/admin/checkout"
        >
          Choose Plan
        </Link>
      </div>
      <Line />
    </Col>
  </Row>
)

export default NewPlan
