import React from 'react'
import { ParticipantTypes } from 'utils/constants'
import useActiveConversations from 'hooks/useActiveConversations'
import { getInitials } from 'utils/helpers'
import useCustomerName from 'hooks/useCustomerName'
import useCustomerContactIdentifier from 'hooks/useCustomerContactIdentifier'

const ConsumerInfo = (initials = false) => {
  const {
    consumerName, consumerEmail, consumerPhone, requestType, state: { consumer, participants },
  } = useActiveConversations()
  const customerName = useCustomerName()
  const customerIdentifier = useCustomerContactIdentifier()

  const isConsumerOnline = consumer?.status === 'online'
  const isConsumerConnected = participants
    && Object.values(participants).find((p) => p.type === ParticipantTypes.CONSUMER)

  const status = (() => {
    if (isConsumerOnline) {
      if (requestType === 'text' || isConsumerConnected) {
        return 'Active Now'
      }
      return 'Connected, Waiting on other person to join.'
    }
    return 'Other person not connected.'
  })()

  return (
    <div className="conversation-header__consumer">
      {initials && (
        <div className="conversation-header__consumer__circle bg-border rounded-pill">
          <p className="p-0 m-0 text-primary fw-bold">{getInitials(consumerName)}</p>
        </div>
      )}
      <div className="conversation-header__consumer__info">
        <div className="conversation-header__consumer__info__name">
          {customerName(consumerName)}
          <span className="conversation-header__consumer__info__email">
            {' '}
            {customerIdentifier(consumerEmail, consumerPhone)}
          </span>
        </div>
        <div className="conversation-header__consumer__info__activity-status">
          {status}
        </div>
      </div>
    </div>
  )
}

export default ConsumerInfo
