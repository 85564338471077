import React, { Fragment, useEffect } from 'react'
import { useVideoContext } from 'providers/VideoProvider'
import VideoCallButtons from 'features/qrflow/components/video/VideoCallButtons'
import VideoCallHeader from 'features/qrflow/components/header/VideoCallHeader'
import { useSelector } from 'react-redux'
import useScreenShareParticipant from 'hooks/useScreenShareParticipant/useScreenShareParticipant'
import VideoInfoContainer from 'components/Room/VideoInfoContainer'
import RemoteParticipant from 'components/Room/RemoteParticipant'
import useRemoteParticipants from 'hooks/useRemoteParticipants/useRemoteParticipants'
import ScreenShareParticipant from 'components/Room/ScreenShareParticipant'
import { WORKER_VIDEO_NOT_CONNECTED_MESSAGE, ConversationTypes } from 'utils/constants'
import useMediaPermissions from 'hooks/useMediaPermissions'
import useConsumerConversations from 'features/qrflow/hooks/useConsumerConversations'
import { isMobile } from 'utils/helpers'
import ParticipantThumbnail from 'components/Room/ParticipantThumbnail'
import NetworkQualityLevel from 'components/NetworkQualityLevel/NetworkQualityLevel'
import DisplayMediaError from './DisplayMediaError'
import GrantPermissions from '../GrantPermissions'

const videoInfoContainerClassName = 'qr-video--connecting'

const VideoCall = () => {
  const {
    room,
    micMediaError,
    videoMediaError,
  } = useVideoContext()
  const screenShareParticipant = useScreenShareParticipant()

  const { isCameraEnabled, isMicrophoneEnabled } = useMediaPermissions()

  const isChatHidden = useSelector((state) => state.layout.chatHidden)
  const { requestType } = useConsumerConversations()

  const isTextOnlyChat = requestType === ConversationTypes.text

  return (
    <div className={`qr-video ${isChatHidden ? 'full-screen-video' : ''} ${screenShareParticipant ? 'full-screen-screenshare' : ''}`}>
      <VideoCallHeader />
      <Room room={room} />
      {!(isCameraEnabled && isMicrophoneEnabled && !isTextOnlyChat) && !(micMediaError || videoMediaError) && <GrantPermissions />}
      <VideoCallButtons />
    </div>
  )
}

const Room = () => {
  const {
    room,
    isTwoWayEnabled,
    removeLocalVideoTrack,
    removeLocalAudioTrack,
    micMediaError,
    videoMediaError,
    isSharingScreen,
  } = useVideoContext()
  const { consumerName } = useConsumerConversations()
  const { remoteParticipants } = useRemoteParticipants()
  const screenShareParticipant = useScreenShareParticipant()
  // Remove local tracks when we navigate to other routes
  useEffect(() => () => removeLocalVideoTrack(), [removeLocalVideoTrack])
  useEffect(() => () => removeLocalAudioTrack(), [removeLocalAudioTrack])
  useEffect(() => () => room?.disconnect(), [room])

  return (
    <>
      {(isTwoWayEnabled && isMobile && !screenShareParticipant) ? (
        <ParticipantThumbnail
          worker
          className="participant-list"
          participant={room?.localParticipant}
          localParticipant={room?.localParticipant}
          isTwoWayEnabled={isTwoWayEnabled}
          participantName={consumerName}
          isMobile={isMobile}
        />
      ) : null}
      {
        remoteParticipants.length === 0 && (
          <VideoInfoContainer
            message={WORKER_VIDEO_NOT_CONNECTED_MESSAGE}
            displaySpinner={false}
            className={videoInfoContainerClassName}
          />
        )
      }
      {
        (micMediaError || videoMediaError) && (
          <DisplayMediaError
            micMediaError={micMediaError}
            videoMediaError={videoMediaError}
          />
        )
      }
      <div className={`participant-info ${screenShareParticipant ? 'screen-share' : ''}`}>
        {remoteParticipants.map((remoteParticipant) => (
          !screenShareParticipant ? (
            <Fragment key={remoteParticipant}>
              <NetworkQualityLevel participant={remoteParticipant} qrflow />
              <RemoteParticipant
                key={remoteParticipant.sid}
                participant={remoteParticipant}
                videoConnectionInfoClassName={videoInfoContainerClassName}
                enableScreenShare
              />
            </Fragment>
          )
            : (
              <ScreenShareParticipant
                key={isSharingScreen ? room?.localParticipant?.sid : remoteParticipant?.sid}
                participant={isSharingScreen ? room?.localParticipant : remoteParticipant}
                videoConnectionInfoClassName={videoInfoContainerClassName}
                enableScreenShare
              />
            )
        ))}
      </div>
    </>
  )
}

export default VideoCall
