import { useCallback, useState } from 'react'
import Video from 'twilio-video'
import useAvailableDevices from 'hooks/useAvailableDevices/useAvailableDevices'
import { SELECTED_AUDIO_INPUT_KEY } from 'providers/VideoProvider/constants'

export default function useLocalAudioTrack() {
  const {
    audioInputDevices,
    hasAudioInputDevices,
  } = useAvailableDevices()
  const [audioTrack, _setAudioTrack] = useState()
  const [isAcquiringAudioTrack, setIsAcquiringAudioTrack] = useState(false)
  const [micMediaError, setMicMediaError] = useState()

  const setAudioTrack = useCallback((deviceId) => {
    // We are storing all selected media devices ID in localStorage
    localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, deviceId)

    audioTrack?.restart({
      deviceId: { exact: deviceId },
    })
      .then(() => _setAudioTrack(audioTrack))
      .catch((e) => {
        setMicMediaError(e)
      })
  }, [audioTrack])

  // Prompt browser to ask for microphone permissions
  const createNewAudioTrack = useCallback(async () => {
    if (!hasAudioInputDevices) {
      return Promise.resolve()
    }

    if (isAcquiringAudioTrack || audioTrack) {
      return Promise.resolve()
    }

    setIsAcquiringAudioTrack(true)

    const selectedAudioInputDeviceId = localStorage.getItem(SELECTED_AUDIO_INPUT_KEY)

    const hasSelectedAudioDevice = audioInputDevices
      .some((device) => selectedAudioInputDeviceId && device.deviceId === selectedAudioInputDeviceId)

    const options = {}

    if (hasSelectedAudioDevice) {
      options.deviceId = { exact: selectedAudioInputDeviceId }
      // change audio input when another device is connected (i.e bluetooth, audio jack)
      // https://github.com/twilio/twilio-video.js/releases/tag/2.25.0
      options.audio = { defaultDeviceCaptureMode: 'auto', workaroundWebKitBug180748: true }
    }

    return Video.createLocalAudioTrack({ ...options, name: `microphone-${Date.now()}` })
      .then((newTrack) => {
        _setAudioTrack(newTrack)
        localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newTrack.mediaStreamTrack.getSettings().deviceId)
        return newTrack
      })
      .catch((e) => {
        setMicMediaError(e)
      })
      .finally(() => setIsAcquiringAudioTrack(false))
  }, [audioInputDevices, audioTrack, isAcquiringAudioTrack, hasAudioInputDevices])

  const removeLocalAudioTrack = useCallback(() => {
    if (audioTrack) {
      audioTrack.stop()
      // _setAudioTrack(undefined)
    }
  }, [audioTrack])

  return {
    audioTrack,
    micMediaError,
    setMicMediaError,
    setAudioTrack,
    createNewAudioTrack,
    isAcquiringAudioTrack,
    removeLocalAudioTrack,
  }
}
