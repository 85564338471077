import React from 'react'
import { Form } from 'react-bootstrap'
import useAvailableDevices from 'hooks/useAvailableDevices/useAvailableDevices'
import { useVideoContext } from 'providers/VideoProvider'

const AudioOutputList = () => {
  const { audioOutputDevices, hasAudioOutputDevices } = useAvailableDevices()
  const {
    audioOutputId,
    setAudioOutputId,
  } = useVideoContext()

  if (!hasAudioOutputDevices) {
    return null
  }

  return (
    <Form.Group>
      <Form.Label>Speakers</Form.Label>
      <Form.Control as="select" value={audioOutputId} onChange={(e) => setAudioOutputId(e.target.value)}>
        {audioOutputDevices.map(({
          deviceId,
          label,
        }) => <option key={deviceId} value={deviceId}>{label}</option>)}
      </Form.Control>
    </Form.Group>
  )
}

export default AudioOutputList
