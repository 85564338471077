import { useState } from 'react'
import { useDispatch } from 'react-redux'
import Chat from 'services/Chat'
import { useVideoContext } from 'providers/VideoProvider'
import { ChatStatuses, ParticipantTypes } from 'utils/constants'
import { updateConsumerConversation } from 'redux/consumerConversations/actions'
import useConsumerConversations from './useConsumerConversations'

export default function useConsumerEndVideoConversation() {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const { settings: { userId }, id, roomName } = useConsumerConversations()
  const {
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    room,
  } = useVideoContext()
  const dispatch = useDispatch()

  const onEndConversation = async () => {
    setIsSubmitting(true)
    setErrorMessage('')
    try {
      const { rate_token: rateToken } = await Chat.endConversationConsumer(id, roomName, userId)
      setIsSubmitting(false)
      removeLocalAudioTrack()
      removeLocalVideoTrack()
      room?.disconnect()
      dispatch(updateConsumerConversation(id, {
        rateToken, [ParticipantTypes.USER]: {}, status: ChatStatuses.completed, submitRating: null,
      }))
    } catch (e) {
      setErrorMessage(e.message || e.error || 'Something went wrong, please try again.')
      setIsSubmitting(false)
    }
  }

  const onClearErrorMessage = () => setErrorMessage('')

  return {
    isSubmitting,
    errorMessage,
    onEndConversation,
    onClearErrorMessage,
  }
}
