import React from 'react'
import LeaveMessage from '../components/leaveMessage'
import QRMainLayout from '../layouts/QRMainLayout'
import useGetEntryPointDetails from '../hooks/useGetEntryPointDetails'

const LeaveMessageScreen = () => {
  const {
    data,
    error,
    isLoading,
  } = useGetEntryPointDetails()

  const {
    landing_page: landingPageConfig,
    queue,
    visual,
  } = data?.config ?? {}

  return (
    <QRMainLayout>
      {!isLoading && (
        <LeaveMessage
          landingPageConfig={landingPageConfig}
          visualConfig={visual}
          queueConfig={queue}
          entryPointId={data.uid}
          entryPointError={error}
        />
      )}
    </QRMainLayout>
  )
}
export default LeaveMessageScreen
