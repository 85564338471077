/* eslint-disable no-return-await */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/media-has-caption */
import IcomoonReact from 'icomoon-react'
import React, { useEffect, useRef, useState } from 'react'
import { Row } from 'react-bootstrap'
import { TrackPriority } from 'utils/constants'
import iconSet from 'assets/fonts/selection.json'

const VideoTrack = ({
  track,
  priority = TrackPriority.DEFAULT,
  isScreenShareOnly,
  attachLogo = null,
  fullScreenRef = null,
}) => {
  const ref = useRef(null)
  const containerRef = useRef(null)
  const [showControls, setShowControls] = useState(false)

  useEffect(() => {
    if (track) {
      const videoElement = ref.current
      videoElement.muted = true

      // We can override the priority of remote tracks
      if (track.setPriority && priority) {
        track.setPriority(priority)
      }

      videoElement.setAttribute('playsinline', '')
      videoElement.setAttribute('muted', '')
      videoElement.setAttribute('autoplay', '')

      track.attach(videoElement)

      return () => {
        track.detach(videoElement)
        if (track.setPriority) {
          // Set the track's priority to that which it was published with.
          track.setPriority(TrackPriority.DEFAULT)
        }
      }
    }
    return () => {}
  }, [track, priority])

  const handleDoubleClick = () => (fullScreenRef || containerRef)?.current?.requestFullscreen()

  useEffect(() => {
    // Listening mouse moves to hide/show custom control on bottom screen when page is full-screen
    if (isScreenShareOnly) {
      let timeout = 0
      const MouseTrack = () => {
        setShowControls(true)
        clearTimeout(timeout)

        timeout = setTimeout(() => {
          setShowControls(false)
        }, 1500)
      }
      (fullScreenRef || containerRef)?.current?.addEventListener('mousemove', MouseTrack)

      return () => {
        (fullScreenRef || containerRef).current?.removeEventListener('mousemove', MouseTrack)
      }
    }
    return () => []
  }, [fullScreenRef, containerRef, isScreenShareOnly])

  if (isScreenShareOnly) {
    return (
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      (
        <div
          className={`screen-share--only ${attachLogo && 'position-relative'}`}
          ref={fullScreenRef || containerRef}
          onDoubleClick={handleDoubleClick}
        >
          <>
            {attachLogo && (
            <img
              src={attachLogo}
              className={`videoWaterMark ${attachLogo.endsWith('.svg') && 'default'}`}
            />
            )}
            <video ref={ref} playsInline className="screenShareOnly" />
          </>
          {showControls && (
          <Row className="d-flex w-100 full-screen-controls justify-content-end align-items-center px-4 m-0">
            <IcomoonReact
              onClick={() => document?.exitFullscreen()}
              iconSet={iconSet}
              size={20}
              icon="icon-maximize"
              color="white"
              className="cursor-pointer"
            />
          </Row>
          )}
        </div>
      )
    )
  }

  // Browser's autoplay policy will play video elements that have no audio tracks or that are muted
  // <video> elements without playsinline attributes will continue to require fullscreen mode for playback on iPhone.
  return (
    <div className="video">
      <video ref={ref} playsInline />
    </div>
  )
}

export default React.memo(VideoTrack)
