import { Form } from 'react-bootstrap'
import React from 'react'
import ErrorMessage from 'components/UI/ErrorMessage/ErrorMessage'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { getIn } from 'formik'

const InputPhoneField = ({
  label,
  field,
  form,
  errorMessageClassName = 'my-2 mx-2 line-height-sm',
}) => {
  const error = getIn(form.errors, field.name)
  return (
    <div className="d-flex flex-column">
      <Form.Group>
        {label && <Form.Label>{label}</Form.Label>}
        <PhoneInput
          name={field.name}
          value={field.value}
          onChange={(value) => {
            if (!form.touched[field.name]) form.setFieldTouched(field.name)
            form.setFieldValue(field.name, value)
          }}
          placeholder="Enter your phone number"
          defaultCountry="US"
        />
      </Form.Group>
      {error && <ErrorMessage className={errorMessageClassName} name={field.name} />}
    </div>
  )
}

export default InputPhoneField
