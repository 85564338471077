import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import Ratings from 'services/Ratings'
import TableHeader from 'components/UI/TableData/TableHeader/TableHeader'
import CustomerTableRow from 'routes/Feedback/components/CustomerTableRow/CustomerTableRow'
import ArrowLeft from 'components/UI/ArrowLeft'
import ArrowRight from 'components/UI/ArrowRight'
import { useQueryClient } from '@tanstack/react-query'
import { QueryKeys } from 'utils/constants'
import useCustomerFeedback from 'hooks/useCustomerFeedback'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'

const CustomerFeedback = ({ filters }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(0)
  const { StartDate, EndDate } = filters
  const queryClient = useQueryClient()

  const {
    customerFeedback,
  } = useCustomerFeedback(currentPage, StartDate, EndDate)

  useEffect(() => {
    const prefetchCustomerFeedback = async () => {
      if (lastPage && currentPage !== lastPage) {
        await queryClient.prefetchQuery([QueryKeys.CUSTOMER_FEEDBACK, { currentPage: currentPage + 1 }],
          () => Ratings.getRatings(currentPage + 1, StartDate, EndDate))
        const fetchedCustomerFeedback = queryClient.getQueryData(
          [QueryKeys.CUSTOMER_FEEDBACK, { currentPage: currentPage + 1 }],
        )
        setLastPage(fetchedCustomerFeedback?.pagy?.last)
      }
    }
    prefetchCustomerFeedback()
  }, [EndDate, StartDate, currentPage, lastPage, queryClient])

  const headers = {
    customer: 'CUSTOMER',
    entryPointName: 'ENTRY POINT NAME',
    helpfulness: 'HELPFULNESS',
    knowledge: 'KNOWLEDGE',
    professional: 'PROFESSIONAL',
    comment: 'COMMENT',
  }

  if (customerFeedback.isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center my-3 br-2 p-3 mx-0 px-0 h-100"
      >
        <LoadingSpinner spinnerSize={50} />
      </div>
    )
  }

  return (
    <>
      <Row className="w-100">
        <Col md={12} className="text-center ps-5 pe-5">
          <Row className="w-100">
            <Col md={12}>
              <table className="table-data table table-borderless">
                <TableHeader headers={Object.values(headers)} />
                <tbody>
                  {customerFeedback.data?.ratings.map((customer) => (
                    <CustomerTableRow
                      key={customer.id}
                      entryPointName={customer?.company_service?.name}
                      helpfulness={customer.rate?.helpfulness}
                      knowledge={customer.rate?.knowledge}
                      professional={customer.rate?.professional}
                      comment={customer.comment}
                      headers={headers}
                    />
                  ))}
                </tbody>
              </table>
            </Col>
          </Row>
          <Row className="p-0 m-0">
            <Col md={12}>
              <div className="customer-feedback__pagination">
                {customerFeedback.data?.pagy.pages && (
                <ReactPaginate
                  previousLabel={<ArrowLeft />}
                  nextLabel={<ArrowRight />}
                  pageCount={customerFeedback.data?.pagy.pages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={({ selected }) => setCurrentPage(selected + 1)}
                  containerClassName="customer-feedback__pagination__list"
                  activeClassName="customer-feedback__pagination__active"
                />
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default CustomerFeedback
