import React from 'react'
import { Button } from 'react-bootstrap'

const ToggleButton = ({
  state,
  onClick,
  buttonTitle,
  buttonTitleEnabled,
}) => (
  <>
    {buttonTitleEnabled && <div className="chat-editable__toggle-buttons__title">{buttonTitle}</div>}
    <div
      onClick={onClick}
      className="chat-editable__toggle-buttons"
    >
      <Button
        type="button"
        className={`${!state
          ? 'chat-editable__passive chat-editable__btn' : ''} chat-editable__active chat-editable__btn`}
      />
    </div>
  </>
)

export default ToggleButton
