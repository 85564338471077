import React, { useRef, useState } from 'react'
import {
  Col, Row, Overlay, Tooltip,
} from 'react-bootstrap'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'
import Line from 'components/UI/Line/Line'
import Field from 'routes/ChatSettingsEntryPoint/components/Field'
import useUpdateEntryPoint from 'routes/ChatSettingsEntryPoint/hooks/useUpdateEntryPoint'
import ToggleButton from 'routes/ChatSettingsEntryPoint/components/Buttons/ToggleButton'
import './entry-point-details.scss'
import config from 'utils/config'

const EntryPointDetails = ({
  item,
  liveChatEnabled,
  setLiveChatEnabled,
  videoChatEnabled,
  setVideoChatEnabled,
}) => {
  const [show, setShow] = useState(false)
  const { updateEntryPoint } = useUpdateEntryPoint(item.id)
  const target = useRef(null)
  const entryPoint = `${config.FRONTEND_URL}/start/${item.uid}`

  const handleLiveChatEnabled = () => {
    setLiveChatEnabled(!liveChatEnabled)
    updateEntryPoint.mutate({
      config_params: {
        config: {
          text_chat: {
            enabled: Boolean(!liveChatEnabled),
          },
        },
      },
    })
  }

  const handleVideoChatEnabled = () => {
    setVideoChatEnabled(!videoChatEnabled)
    updateEntryPoint.mutate({
      config_params: {
        config: {
          video_chat: {
            enabled: Boolean(!videoChatEnabled),
          },
        },
      },
    })
  }

  const handleClipboard = () => {
    navigator.clipboard.writeText(entryPoint)

    setShow(!show)

    setTimeout(() => {
      setShow(show)
    }, 1000)
  }

  return (
    <Row className="my-4">
      <Col className="entry-point-details">
        <Field
          labelTitle="QR & Entry Point URL"
          formControl={(
            <>
              <div className="entry-point-details__url">
                {entryPoint}
              </div>
              <button
                ref={target}
                type="button"
                className="chat-settings__project-uid__button"
                onClick={handleClipboard}
              >
                <IcomoonReact
                  id="controlledTooltip"
                  iconSet={iconSet}
                  color="#767676"
                  size={20}
                  icon="clipboard"
                  role="button"
                  className="profile-settings__field__icon"
                />
              </button>

              <Overlay
                target={target.current}
                show={show}
                placement="top"
              >
                {(props) => (
                  <Tooltip className="chat-settings__project-uid__tooltip" {...props}>
                    Copied!
                  </Tooltip>
                )}
              </Overlay>

            </>
          )}
        />
        <Line />
        <Field
          labelTitle="Live Chat Enabled"
          subTitle="If you don’t want messaging chat on your website, disable this option."
          formControl={(
            <>
              <ToggleButton
                state={liveChatEnabled}
                onClick={handleLiveChatEnabled}
              />
            </>
          )}
        />
        <Line />
        <Field
          labelTitle="Video Chat Enabled"
          subTitle="If you don’t want video chat on your website, disable this option."
          formControl={(
            <>
              <ToggleButton
                state={videoChatEnabled}
                onClick={handleVideoChatEnabled}
              />
            </>
          )}
        />
        <Line />
      </Col>
    </Row>
  )
}

export default EntryPointDetails
