import ConversationTypes from '../conversation.types'

export const setAcceptedRequestID = (id) => ({
  type: ConversationTypes.SET_ACCEPTED_REQUEST_ID,
  acceptedRequestID: id,
})

export const resetAcceptedRequestID = () => ({
  type: ConversationTypes.RESET_ACCEPTED_REQUEST_ID,
  acceptedRequestID: null,
})
