import React, { useRef, useState } from 'react'
import { Overlay, Tooltip } from 'react-bootstrap'

export const IosWarningMessage = ({ isSafari }) => {
  const copyTextRef = useRef()
  const [showCopiedTooltip, setShowCopiedTooltip] = useState(false)

  const onCopyUrl = () => {
    navigator.clipboard.writeText(window.location)
    setShowCopiedTooltip(true)
    setTimeout(() => setShowCopiedTooltip(false), 1000)
  }

  return (
    <div>
      {isSafari
        ? 'Any iOS 12.1+ can access using Safari.'
        : 'Any iOS 14.3+ can access Safari and Chrome.'}
      {' '}
      Please
      <span
        onClick={onCopyUrl}
        className="cursor-pointer"
        ref={copyTextRef}
      >
        {' '}
        <u>
          copy
        </u>
        {' '}
      </span>
      the link into the Safari
      {' '}
      {!isSafari && 'or Chrome'}
      {' '}
      browser to connect!

      <Overlay
        target={copyTextRef.current}
        show={showCopiedTooltip}
        placement="top"
      >
        {(props) => (
          <Tooltip className="chat-settings__project-uid__tooltip" {...props}>
            Copied!
          </Tooltip>
        )}
      </Overlay>
    </div>
  )
}

export const WebviewWarningMessage = () => {
  const copyTextRef = useRef()
  const [showCopiedTooltip, setShowCopiedTooltip] = useState(false)

  const onCopyUrl = () => {
    navigator.clipboard.writeText(window.location)
    setShowCopiedTooltip(true)
    setTimeout(() => setShowCopiedTooltip(false), 1000)
  }

  return (
    <div>
      It seems that you are connecting to the app from unsupported browser.
      <br />
      Please
      <span
        onClick={onCopyUrl}
        className="cursor-pointer"
        ref={copyTextRef}
      >
        {' '}
        <u>
          copy
        </u>
        {' '}
      </span>
      the link into the Chrome browser (or Safari if you are using iOS) to connect!

      <Overlay
        target={copyTextRef.current}
        show={showCopiedTooltip}
        placement="top"
      >
        {(props) => (
          <Tooltip className="chat-settings__project-uid__tooltip" {...props}>
            Copied!
          </Tooltip>
        )}
      </Overlay>
    </div>
  )
}

export const AndroidWarningMessage = () => {
  const copyTextRef = useRef()
  const [showCopiedTooltip, setShowCopiedTooltip] = useState(false)

  const onCopyUrl = () => {
    navigator.clipboard.writeText(window.location)
    setShowCopiedTooltip(true)
    setTimeout(() => setShowCopiedTooltip(false), 1000)
  }

  return (
    <div>
      Unsupported browser. To continue
      <span
        onClick={onCopyUrl}
        className="cursor-pointer"
        ref={copyTextRef}
      >
        {' '}
        <u>
          copy
        </u>
        {' '}
      </span>
      the link into the Chrome or Firefox
      {' '}
      browser to connect!

      <Overlay
        target={copyTextRef.current}
        show={showCopiedTooltip}
        placement="top"
      >
        {(props) => (
          <Tooltip className="chat-settings__project-uid__tooltip" {...props}>
            Copied!
          </Tooltip>
        )}
      </Overlay>
    </div>
  )
}

export const internetExplorerNotSupported = 'Internet Explorer is not supported by our application'

export const edgeVersionNotSupported = 'Looks like you are running an older version of Microsoft Edge'

export const chromeVersionNotSupported = 'Looks like you are running an older version of Chrome'

export const firefoxVersionNotSupported = 'Looks like you are running an older version of Firefox'

export const androidBrowserVersionNotSupported = 'Looks like you have an older version of Android Browser'

export const defaultWarningMessage = 'Please try using a newer version of Chrome or Firefox'

export const safariVersionNotSupported = 'This version of Safari is not supported, try upgrading to Safari v11+'
