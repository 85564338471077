/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
import { useVideoContext } from 'providers/VideoProvider'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import VideoTrack from 'components/Room/VideoTrack'
import { DEFAULT_WORKER_VIDEO_TRACK_OPTIONS } from 'providers/VideoProvider/constants'
import useMediaPermissions from 'hooks/useMediaPermissions'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'
import { useHistory } from 'react-router-dom'

const WebcamPreview = () => {
  const {
    videoTrack,
    audioTrack,
    micMediaError,
    videoMediaError,
    createNewVideoTrack,
    createNewAudioTrack,
    removeLocalVideoTrack,
    removeLocalAudioTrack,
  } = useVideoContext()
  const { isCameraEnabled, isMicrophoneEnabled } = useMediaPermissions()
  const [requestPermissions, setRequestPermissions] = useState(false)
  const [permissionError, setPermissionError] = useState(false)
  const history = useHistory()

  useEffect(() => {
    const asyncWrapper = async () => {
      try {
        if (![isMicrophoneEnabled, isCameraEnabled].includes(false) || requestPermissions === true) {
          await Promise.all([createNewVideoTrack(DEFAULT_WORKER_VIDEO_TRACK_OPTIONS), createNewAudioTrack()])
        }
        if ([videoTrack?.isStopped, audioTrack?.isStopped].includes(true)) {
          await Promise.all([audioTrack?.restart(), videoTrack?.restart()])
        }
      } catch (error) {
        setPermissionError(error)
      }
      return undefined
    }
    asyncWrapper()
  }, [isCameraEnabled, isMicrophoneEnabled, requestPermissions, videoTrack])

  // We stop the tracks only if we are navigating from Dashboard to any other route than the video call route
  useEffect(() => () => removeLocalVideoTrack(), [history, removeLocalVideoTrack])
  useEffect(() => () => removeLocalAudioTrack(), [history, removeLocalAudioTrack])

  if (!(isCameraEnabled && isMicrophoneEnabled)) {
    return (
      <Row className="w-100 h-100 br-2 bg-white justify-content-center text-center m-0 shadow shadow-intensity-xl p-2">
        <IcomoonReact
          iconSet={iconSet}
          size={50}
          icon="warning-icon"
          color="orange"
          className="align-self-center py-2"
        />
        <p className="card-title fw-bold py-2">Swytchboard needs your permission to enable Camera and Microphone.</p>
        <p className="card-title fw-bold py-2">
          <a className="primary cursor-pointer" onClick={() => setRequestPermissions((prev) => !prev)}>Click here</a>
          &nbsp;or go to browser&apos;s settings and enable them.
        </p>
        {micMediaError || videoMediaError || permissionError ? (
          <p className="text-danger">
            Please reset your browser&apos;s permissions and allow them.
          </p>
        ) : null}
      </Row>
    )
  }

  return (
    <div className="w-100 h-100 br-2">
      {
        videoTrack
          ? (
            <div className="video-wrapper">
              <VideoTrack track={videoTrack} controls="controls" />
            </div>
          )
          : <LoadingSpinner />
      }
    </div>
  )
}

export default WebcamPreview
