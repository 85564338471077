const Steps = {
  ChatBasics: 0,
  ChatIntro: 1,
  ChatPersonalInfo: 2,
  ChatQueue: 3,
  ChatRatings: 4,
  ChatThanks: 5,
}

export default Steps
