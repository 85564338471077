import React from 'react'
import swytchboardLogo from 'assets/images/swytchboard-Y-logo.svg'
import 'features/qrflow/styles/_logos-placeholder.scss'
import useGetEntryPointDetails from 'features/qrflow/hooks/useGetEntryPointDetails'
import config from 'utils/config'

const LogosPlaceholder = () => {
  const {
    data,
  } = useGetEntryPointDetails()

  const {
    visual,
  } = data?.config ?? {}

  const awsPath = `${config.ASSETS_URL}/`

  const companyLogo = visual?.logo1 === null ? swytchboardLogo : `${awsPath}${visual?.logo1}`
  const companyLogo2 = `${awsPath}${visual?.logo2}`

  return (
    <div className="logos-placeholder">
      <img src={companyLogo} alt={visual?.logo1} />
      {
        visual?.logo2 && (
          <>
            <span className="logos-placeholder--separator" />
            <img src={companyLogo2} alt="company-logo-2" />
          </>
        )
      }
    </div>
  )
}

export default LogosPlaceholder
