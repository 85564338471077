import React, { useCallback, useEffect, useState } from 'react'
import Chat from 'services/Chat'
import { Spinner } from 'react-bootstrap'
import ChatProvider, { useChatContext } from 'providers/ChatProvider'
import withProviders from 'hoc/withProviders'
import TeamMessageList from './TeamMessageList'

const TeamChat = ({ changed = null }) => {
  const [teamChatToken, setTeamChatToken] = useState()
  const [teamConversationSid, setTeamConversationSid] = useState()

  const {
    messages,
    loadingMessages,
    connectToChat,
    conversation,
  } = useChatContext()

  const handleSendMessage = useCallback((message, fileFormData) => {
    const newMessage = conversation.prepareMessage()
    if (message) newMessage.setBody(message)
    if (fileFormData instanceof FormData) newMessage.addMedia(fileFormData)
    newMessage.build().send()
  }, [conversation])

  useEffect(() => {
    if (teamChatToken && teamConversationSid) {
      connectToChat(teamChatToken, teamConversationSid)
    }
  }, [teamChatToken, teamConversationSid, connectToChat])

  useEffect(() => {
    (async () => {
      const {
        token,
        conversation_sid: conversationSid,
      } = await Chat.teamChatRequest()
      setTeamChatToken(token)
      setTeamConversationSid(conversationSid)
    })()
  }, [])

  if (loadingMessages) {
    return (
      <div className="w-100 h-100 d-flex align-items-center justify-content-center shadow border-border overflow-hidden">
        <Spinner
          role="status"
          className="chat-loader"
          animation="border"
          variant="primary"
          size="xl"
          style={{ width: '10rem', height: '10rem' }}
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    )
  }

  return (
    <div className="d-flex h-100 br-05 border-border w-100 overflow-hidden shadow">
      <TeamMessageList messages={messages} handleSendMessage={handleSendMessage} changed={changed} />
    </div>
  )
}

export default withProviders(ChatProvider)(TeamChat)
