import React from 'react'
import { Col, Row } from 'react-bootstrap'
import ReactTimeAgo from 'react-time-ago'
import chat from 'assets/images/chat.svg'
import videoChat from 'assets/images/video-chat.svg'
import useCustomerContactIdentifier from 'hooks/useCustomerContactIdentifier'
import useTimerCountdown from './useTimerCountdown'

const QueueItem = ({
  name, message, requestType, entryPointName, onClick, id, isPreselected, createdAt, consumerEmail, phoneNumber, cursor = 'pointer',
}) => {
  const { minutePassed } = useTimerCountdown(createdAt)
  const customerIdentifier = useCustomerContactIdentifier()
  return (
    <Row
      className={`${isPreselected && 'bg-primary text-white rounded-xl'} m-0 p-3 single-queue cursor-${cursor} align-items-center justify-content-between`}
      onClick={() => onClick(id)}
      style={{ gap: 10 }}
    >
      <Col md={3} className="m-0 p-0">
        <div className="w-70 fw-bold queue">
          {name}
        </div>
      </Col>
      {
        minutePassed && (
          <Col md={4} className="m-0 p-0">
            <div className="w-70 queue__timer">
              <ReactTimeAgo date={new Date(createdAt)} locale="en-US" style={{ fontSize: 12, fontSizeAdjust: 0.5 }} />
            </div>
          </Col>
        )
      }
      <Col md={4} className="d-flex m-0 p-0 column">
        <img src={requestType === 'Video Call' ? videoChat : chat} className="pe-2" alt="logo" height={20} width={25} />
        <div className="w-100 fw-bold queue queue__entry-point">{entryPointName}</div>
      </Col>
      <Col md={12} xs={6} className="m-0 p-0">
        <span className="queue queue__email">{customerIdentifier(consumerEmail, phoneNumber)}</span>
      </Col>
      {message && (
        <Col md={12} className="py-2">
          <div className="text-overflow-hidden queue queue__message">
            {message}
          </div>
        </Col>
      )}
    </Row>
  )
}

export default QueueItem
