import React from 'react'
import ChangePasswordForm from 'routes/ProfileSettings/form/ChangePasswordForm'
import UserSettingsForm from 'routes/ProfileSettings/form/UserSettingsForm'
import DeleteAccountDialog from 'components/dialog/DeleteAccountDialog'
import useDialog from 'hooks/useDialog'

const ProfileSettings = () => {
  const {
    onOpenDialog: onOpenDeleteAccountDialog,
    onCloseDialog: onCloseDeleteAccountDialog,
    displayDialog: displayDeleteAccountDialog,
  } = useDialog()

  return (
    <div className="profile-settings">
      <UserSettingsForm />
      <div className="profile-settings__divider" />
      <ChangePasswordForm />
      <div className="profile-settings__divider" />
      <span
        className="profile-settings__delete-account"
        aria-hidden="true"
        onClick={onOpenDeleteAccountDialog}
      >
        Delete account permanently
      </span>
      <DeleteAccountDialog
        show={displayDeleteAccountDialog}
        onClose={onCloseDeleteAccountDialog}
      />
    </div>
  )
}

export default ProfileSettings
