import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import ChatProvider, { useChatContext } from 'providers/ChatProvider'
import ErrorMessages from 'providers/ChatProvider/error-messages'
import { parseRequestVideoMessage } from 'utils/message'
import { defaultServiceIntroMessage } from 'utils/constants'
import withProviders from 'hoc/withProviders'
import store from 'redux/store'
import { resetAcceptedRequestID } from 'redux/conversation/acceptedRequest/acceptedRequest.actions'
import { resetToken } from 'redux/conversation/token/token.actions'
import { resetRoomName } from 'redux/conversation/roomName/roomName.actions'
import { resetConsumerName } from 'redux/conversation/consumer/consumerName.actions'
import { resetServiceIntroMessage } from 'redux/conversation/serviceIntroMessage/serviceIntroMessage.action'
import { resetConversationSID } from 'redux/conversation/conversationSID/conversationSID.actions'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import useActiveConversations from 'hooks/useActiveConversations'
import MessageList from './MessageList'

TimeAgo.addLocale(en)

const onConnectToChatError = (e) => {
  const hasConsumerEndedConversation = e.message === ErrorMessages.CONVERSATION_NOT_FOUND

  if (hasConsumerEndedConversation) {
    store.dispatch(resetAcceptedRequestID())
    store.dispatch(resetToken())
    store.dispatch(resetRoomName())
    store.dispatch(resetConsumerName())
    store.dispatch(resetServiceIntroMessage())
    store.dispatch(resetConversationSID())
  }
}

const CustomerChat = ({ maxHeight }) => {
  const { roomToken, conversationSID, state: { requestedVideoChat } } = useActiveConversations()

  const introMessage = useSelector((state) => state.serviceIntroMessage)

  const hasIntroMessage = introMessage === null ? defaultServiceIntroMessage : introMessage

  const {
    messages,
    connectToChat,
    conversation,
    loadingMessages,
    setMessages,
  } = useChatContext()

  useEffect(() => {
    const displayRequestVideoMessage = !loadingMessages && requestedVideoChat

    if (displayRequestVideoMessage) {
      setMessages((prevMessages) => [...prevMessages, parseRequestVideoMessage()])
    }
  }, [loadingMessages, requestedVideoChat, setMessages])

  const handleSendMessage = useCallback((message, fileFormData) => {
    const newMessage = conversation.prepareMessage()
    if (message) newMessage.setBody(message)
    if (fileFormData instanceof FormData) newMessage.addMedia(fileFormData)
    newMessage.build().send()
  }, [conversation])

  useEffect(() => {
    if (roomToken && conversationSID) {
      connectToChat(roomToken, conversationSID)
    }
  }, [roomToken, conversationSID, connectToChat])

  useEffect(() => {
    const shouldSendIntroMessage = conversation && !loadingMessages && messages.length === 0

    if (shouldSendIntroMessage) {
      conversation.sendMessage(hasIntroMessage)
    }
  }, [messages, conversation, hasIntroMessage, loadingMessages])

  if (loadingMessages) {
    return (
      <div className="w-100 h-100 d-flex align-items-center justify-content-center ">
        <Spinner
          role="status"
          className="chat-loader"
          animation="border"
          variant="primary"
          size="xl"
          style={{ width: '10rem', height: '10rem' }}
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    )
  }
  return (
    <MessageList messages={messages} handleSendMessage={handleSendMessage} maxHeight={maxHeight} />
  )
}

export default withProviders([ChatProvider, { onError: onConnectToChatError }])(CustomerChat)
