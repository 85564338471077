import React from 'react'
import {
  Alert,
  Button,
  Col,
  Row,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as yup from 'yup'
import OnBoardingLayout from 'layouts/OnBoardingLayout'
import TextFormField from 'components/UI/TextFormField/TextFormField'
import LoadingButton from 'components/buttons/LoadingButton'
import useVerifyEmail from 'routes/VerifyEmail/useVerifyEmail'
import useResendVerificationCode from 'routes/VerifyEmail/useResendVerificationCode'
import useQueryString from 'hooks/useQueryString'

const VerifyEmailSchema = yup.object()
  .shape({
    token: yup.string('The code is required')
      .required('The code is required').nullable(),
  })

const initialValues = {
  token: '',
}

const VerifyEmail = () => {
  const {
    isResendingVerificationCode,
    resendCodeErrorMessage,
    resendCodeSuccessMessage,
    onResendVerificationCode,
    clearResendCodeSuccessMessage,
    clearResendCodeErrorMessage,
  } = useResendVerificationCode()

  const {
    userEmail,
    isVerifyingEmail,
    verifyEmailErrorMessage,
    onVerifyEmail,
    onNavigateToPersonalInfo,
    onLogout,
    clearVerifyEmailErrorMessage,
  } = useVerifyEmail()

  const queryString = useQueryString()
  initialValues.token = queryString.get('code')

  return (
    <OnBoardingLayout>
      <Formik
        validateOnMount
        initialValues={initialValues}
        validationSchema={VerifyEmailSchema}
        onSubmit={({ token }) => onVerifyEmail(token)}
      >
        {({
          isValid,
          handleSubmit,
        }) => (
          <Col md={6} className="bg-white text-justify">
            <Row className="m-0 p-0 h-100 justify-content-center align-items-center">
              <Col md={8}>
                <div className="verify-email">
                  <div className="mb-4 d-flex align-items-center">
                    <h3 className="verify-email__h3 text-start flex-grow-1 fw-bold">
                      Verify your e-mail address
                    </h3>
                    <Link className="verify-email__link fw-bold" onClick={onLogout} to="/">
                      Log out
                    </Link>
                  </div>
                  <hr className="mt-3" />
                  <p className="verify-email__text">
                    We&apos;ve sent a verification code to
                    {' '}
                    <strong>
                      {userEmail}
                    </strong>
                    .
                    Enter the verification code in order to continue setting up your account.
                    If you don&apos;t see it, check your spam folder.
                  </p>
                  <Field
                    label="Enter code:"
                    name="token"
                    placeholder="EHCYZJ"
                    component={TextFormField}
                  />
                  <p className="verify-email__text mt-3">
                    Didn&apos;t get an verification code?
                    {' '}
                    <span
                      aria-hidden="true"
                      className={`
                      verify-email__text--green
                       verify-email__text--pointer
                        ${isResendingVerificationCode
                        ? 'verify-email__resend-verification-loading' : ''}
                      `}
                      onClick={() => !isResendingVerificationCode && onResendVerificationCode(userEmail)}
                    >
                      Click here to resend
                    </span>
                  </p>
                  {resendCodeSuccessMessage && (
                    <Alert variant="success" dismissible onClick={clearResendCodeSuccessMessage}>
                      {resendCodeSuccessMessage}
                    </Alert>
                  )}
                  {resendCodeErrorMessage && (
                    <Alert variant="danger" dismissible onClick={clearResendCodeErrorMessage}>
                      {resendCodeErrorMessage}
                    </Alert>
                  )}
                  {verifyEmailErrorMessage && (
                    <Alert className="mt-4" variant="danger" dismissible onClick={clearVerifyEmailErrorMessage}>
                      {verifyEmailErrorMessage}
                    </Alert>
                  )}
                  <div className="d-flex justify-content-between mt-4">
                    <Button
                      className="verify-email__button verify-email__button--back"
                      onClick={onNavigateToPersonalInfo}
                    >
                      Back
                    </Button>
                    <LoadingButton
                      type="submit"
                      className="verify-email__button verify-email__button--submit"
                      onClick={handleSubmit}
                      loading={isVerifyingEmail}
                      disabled={!isValid || isVerifyingEmail}
                    >
                      Verify
                    </LoadingButton>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        )}
      </Formik>
    </OnBoardingLayout>
  )
}

export default VerifyEmail
