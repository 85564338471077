/* eslint-disable arrow-body-style */
import React from 'react'
import { getRequestType } from 'utils/helpers'
import QueueItem from 'routes/Dashboard/components/chat/queue/QueueItem'
import chatRequestActions from 'redux/chatRequest/actions'
import { useDispatch, useSelector } from 'react-redux'
import useCustomerName from 'hooks/useCustomerName'

const ChatRequestsInQueue = ({ chatRequestsInQueue }) => {
  const dispatch = useDispatch()
  const onPreselectHandle = (id) => dispatch(chatRequestActions.preselectChatRequest({ id }))
  const preselectId = useSelector((state) => state.chatRequest.preselectChatRequest)
  const customerName = useCustomerName()

  return (
    <>
      {
        chatRequestsInQueue.map((chatRequest) => {
          const {
            id,
            consumer: {
              name: consumerName,
              email: consumerEmail,
              phone_number: phoneNumber,
            },
            intro_message: introMessage,
            request_type: requestType,
          } = chatRequest

          return (
            <QueueItem
              key={`${consumerName}_${consumerEmail}_${id}`}
              name={customerName(consumerName)}
              message={introMessage}
              id={id}
              isPreselected={id === preselectId}
              requestType={getRequestType(requestType)}
              entryPointName={chatRequest.company_service?.name}
              onClick={onPreselectHandle}
              createdAt={chatRequest.created_at}
              consumerEmail={consumerEmail}
              phoneNumber={phoneNumber}
            />
          )
        })
        }
    </>
  )
}

export default ChatRequestsInQueue
