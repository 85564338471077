// browser supports for twilio.js https://www.twilio.com/docs/voice/client/javascript#supported-browsers
// webRtc browser supports from caniuse

import React from 'react'
import DeviceDetector from 'device-detector-js'
import isWebview from 'is-ua-webview'
import { isSupported } from 'twilio-video'
import {
  IosWarningMessage,
  androidBrowserVersionNotSupported,
  chromeVersionNotSupported,
  defaultWarningMessage,
  edgeVersionNotSupported,
  firefoxVersionNotSupported,
  internetExplorerNotSupported,
  safariVersionNotSupported, WebviewWarningMessage, AndroidWarningMessage,
} from 'utils/browserWarningMessages'

function isWebRTCSupported() {
  return navigator.getUserMedia
  || navigator.webkitGetUserMedia
  || navigator.mozGetUserMedia
  || navigator.msGetUserMedia
  || window.RTCPeerConnection
}

function clientSupportResponse(supportedBrowser, message, deviceInfo, debugInfo = null) {
  return {
    isSupported: supportedBrowser,
    message,
    deviceInfo,
    debugInfo,
  }
}

export default function isClientSupported() {
  const {
    client,
    os,
  } = new DeviceDetector().parse(navigator.userAgent)

  const browserName = client.name
  const browserVersion = Number(client.version)
  const operatingSystemName = os.name
  const webviewBrowser = isWebview(navigator.userAgent)
  const deviceInfo = {
    browserName,
    browserVersion,
    operatingSystemName,
    webviewBrowser,
  }

  if (webviewBrowser) {
    return clientSupportResponse(false, <WebviewWarningMessage />, deviceInfo, 'Webview browser')
  }

  const isSafari = browserName.toLowerCase()
    .includes('safari')

  const isChrome = browserName.toLowerCase()
    .includes('chrome')

  const isFirefox = browserName.toLowerCase()
    .includes('firefox')

  const isEdge = browserName.toLowerCase()
    .includes('microsoft edge')

  const isInternetExplorer = browserName.toLowerCase()
    .includes('internet explorer')

  const isAndroidBrowser = browserName.toLowerCase()
    .includes('android browser')

  const isIOS = operatingSystemName === 'iOS'
  const isAndroid = operatingSystemName === 'Android'

  if (isIOS) {
    if (isSafari && browserVersion < 12.1) {
      return clientSupportResponse(false, <IosWarningMessage isSafari />, deviceInfo, 'Unsupported Safari version (<12.1)')
    }
    if (isChrome && browserVersion < 56) {
      return clientSupportResponse(false, <IosWarningMessage />, deviceInfo, 'Unsupported Chrome version (<56)')
    }
    if (isFirefox) {
      return clientSupportResponse(false, <IosWarningMessage />, deviceInfo, 'Firefox not supported on IOS')
    }
  }

  // for Mac Safari version >11 is supported
  if (isSafari && browserVersion < 11) {
    return clientSupportResponse(false, safariVersionNotSupported, deviceInfo, 'Unsupported Safari (<11)')
  }

  // Internet Explorer is not supported
  if (isInternetExplorer) {
    return clientSupportResponse(false, internetExplorerNotSupported, deviceInfo, 'Internet Explorer is not supported')
  }

  if (isChrome && browserVersion < 56) {
    return clientSupportResponse(false, chromeVersionNotSupported, deviceInfo, 'Unsupported Chrome version (<56)')
  }

  if (isFirefox && browserVersion < 44) {
    return clientSupportResponse(false, firefoxVersionNotSupported, deviceInfo, 'Unsupported Firefox version (<44)')
  }

  if (isAndroidBrowser && browserVersion < 89) {
    return clientSupportResponse(false, androidBrowserVersionNotSupported, deviceInfo)
  }

  // Edge is supported from version >79
  if (isEdge && browserVersion < 79) {
    return clientSupportResponse(false, edgeVersionNotSupported, deviceInfo, 'Unsupported Edge Version (<79)')
  }

  if (!isSupported && isAndroid) {
    return clientSupportResponse(false, <AndroidWarningMessage />, deviceInfo, 'Android browser')
  }

  if (!isSupported && isIOS) {
    return clientSupportResponse(false, <IosWarningMessage />, deviceInfo, 'IOS browser')
  }

  if (!isWebRTCSupported()) {
    return clientSupportResponse(false, isIOS ? <IosWarningMessage /> : defaultWarningMessage, deviceInfo, 'Browser has no support for WebRTC')
  }

  return clientSupportResponse(true, null, deviceInfo, 'Browser supports Swytchboard')
}
