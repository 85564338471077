import { Form } from 'react-bootstrap'
import React from 'react'
import { getIn } from 'formik'
import ErrorMessage from 'components/UI/ErrorMessage/ErrorMessage'

const InputField = ({
  name,
  label,
  id,
  type,
  value,
  placeholder,
  textarea,
  rows,
  onChange,
  onBlur,
  groupClasses,
  labelClasses,
  inputClasses = 'sb-input',
  disabled = false,
  form = {},
  field = {},
  errorMessageClassName = 'my-2 mx-2 line-height-sm',
  hasErrorMessage = true,
  ...others
}) => {
  const error = getIn(form.errors, field.name || name)
  const touch = getIn(form.touched, field.name || name)

  const errorMessage = touch && error ? error : null
  const errorClass = errorMessage ? 'error' : ''

  return (
    <div className="d-flex flex-column">
      <Form.Group className={groupClasses}>
        {label && <Form.Label className={labelClasses}>{label}</Form.Label>}
        <Form.Control
          id={id}
          name={name}
          value={value}
          type={type}
          placeholder={placeholder}
          as={textarea && 'textarea'}
          rows={rows}
          className={`${inputClasses} ${errorClass}`}
          onBlur={onBlur}
          onChange={onChange}
          disabled={disabled}
          {...others}
          {...field}
        />
      </Form.Group>
      {hasErrorMessage && errorClass && <ErrorMessage className={errorMessageClassName} name={name || field.name} />}
    </div>
  )
}

export default InputField
