import React from 'react'
import { Row, Col } from 'react-bootstrap'
import Logo from '../assets/images/checkout/swytchboard-logo.svg'

const CheckoutLayout = ({ children }) => (
  <div className="checkout-layout__bg-images m-0 p-0 vw-100">
    <Row>
      <Col className="my-5">
        <img className="checkout-layout__logo" src={Logo} alt="Logo Swytchboard" />
      </Col>
    </Row>
    {children}
  </div>
)

export default CheckoutLayout
