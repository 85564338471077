import React from 'react'
import { Field } from 'formik'
import TextFormField from 'components/UI/TextFormField/TextFormField'

const AccountInfoForm = () => (
  <>
    <p className="mb-3">
      Enter your email address
    </p>
    <Field
      placeholder="john.doe@example.com"
      name="email"
      component={TextFormField}
    />
  </>
)

export default AccountInfoForm
