import { useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { useToasts } from 'react-toast-notifications'
import User from 'services/User'
import { ToastKey } from 'features/work-logs/constants'
import isPunchedIn from 'features/work-logs/isPunchedIn'
import { QueryKeys } from 'utils/constants'

/**
 * This hook is used to fetch the worker punch in state based on his work logs
 * and display an error notification if the API call fails
 */
export default function useIsWorkerPunchedIn() {
  const companyId = useSelector((state) => state.company.data?.id)

  const {
    addToast,
    removeToast,
  } = useToasts()

  const {
    data: workLog,
    isInitialLoading: isLoading,
    isIdle,
    ...rest
  } = useQuery([QueryKeys.WORK_LOGS, companyId], () => User.getActiveWorkLog(companyId)
    .catch((error) => {
      // We parse the 404-WorkLogNotFound as an empty work log with no start and end date
      // In the onError callback we handle all other errors, but we treat this 'error' as an API call that returns an
      // empty work log
      if (error.status === 404) {
        return {
          start_date: null,
          end_date: null,
        }
      }
      throw error
    }), {
    enabled: !!companyId, // This query is enabled for execution only if the company id is valid,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retryOnMount: false,
    retry: 1,
    onError: (error) => {
      removeToast(ToastKey.isWorkerPunchedInError)
      addToast(`Work Log: ${error.message}`, {
        appearance: 'error',
        autoDismiss: false,
        id: ToastKey.isWorkerPunchedInError,
      })
    },
  })

  // Queries that have the enabled:false property haven't started executing and are in IDLE state.
  // This query will execute only when a valid company id is provided (e.g while we are fetching the company data)
  // Until then, it will be in idle state
  const isLoadingPunchedInState = isLoading || isIdle

  return {
    isPunchedIn: isPunchedIn(workLog),
    isLoadingPunchedInState,
    punchInStartDate: workLog?.start_date,
    ...rest,
  }
}
