import React, {
  createContext,
  useContext,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import useGetEntryPoint from 'routes/ChatSettingsEntryPoint/hooks/useGetEntryPoint'
import useUpdateEntryPoint from 'routes/ChatSettingsEntryPoint/hooks/useUpdateEntryPoint'

const ChatConfigurationContext = createContext(null)

const ChatConfigurationProvider = ({
  children,
}) => {
  const { id } = useParams()
  const { entryPoint } = useGetEntryPoint(id)
  const { updateEntryPoint } = useUpdateEntryPoint(id)

  const [activeStep, setActiveStep] = useState(0)
  const [modifiedState, setModifiedState] = useState(false)
  const firstStep = 0
  const lastStep = 5

  const handleStateChange = (stepState) => {
    updateEntryPoint.mutate({
      config_params: {
        config: stepState,
      },
    })
  }

  const value = {
    activeStep,
    setActiveStep,
    entryPoint,
    firstStep,
    lastStep,
    modifiedState,
    updateEntryPoint,
    setModifiedState,
    handleStateChange,
  }
  return (
    entryPoint.isLoading ? (
      <LoadingSpinner
        spinnerSize={50}
        className="d-flex justify-content-center align-items-center w-100 my-auto"
      />
    ) : (
      <ChatConfigurationContext.Provider value={value}>
        {children}
      </ChatConfigurationContext.Provider>
    )
  )
}

export const useChatConfiguration = () => {
  const context = useContext(ChatConfigurationContext)
  if (context === undefined) {
    throw new Error('useChatConfiguration must be used within a ChatConfigurationProvider')
  }
  return context
}

export default ChatConfigurationProvider
