import React, { useState, useMemo } from 'react'
import { Col, Row } from 'react-bootstrap'
import { capitalizeFirstLetter } from 'utils/helpers'
import TablePagination from 'components/UI/TableData/TablePagination/TablePagination'
import PendingMembersTableRow from 'routes/TeamMembers/components/Pending/PendingMembersTableRow/PendingMembersTableRow'
import { useMembersContext } from 'routes/TeamMembers/context/TeamMembersContext'
import TableHeader from 'components/UI/TableData/TableHeader/TableHeader'

const ITEMS_PER_PAGE = 10

const headers = {
  fullName: 'FULL NAME',
  teamRole: 'TEAM ROLE',
  email: 'E-MAIL ADDRESS',
  status: 'STATUS',
  phone: 'PHONE NUMBER',
  delete: 'DELETE',
  resend: 'RESEND',
}

const TeamPendingMembers = () => {
  const { pendingMembers, companyId } = useMembersContext()
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)

  function getMemberRole(roles) {
    // eslint-disable-next-line
    const role = roles?.find(({ company_id }) => company_id === companyId).role
    return capitalizeFirstLetter(role)
  }

  const pendingMembersData = useMemo(() => {
    const computedPendingMembers = pendingMembers

    setTotalItems(computedPendingMembers?.length)

    // Current Page slice
    return computedPendingMembers?.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE,
    )
  }, [pendingMembers, currentPage])

  return (
    <>
      <Row className="w-100">
        <Col md={12} className="text-center ps-5 pe-5">
          <table className="team-members__table-data table table-borderless">
            <TableHeader headers={Object.values(headers)} />
            <tbody>
              {pendingMembersData?.map((invite) => (
                <PendingMembersTableRow
                  key={invite.id}
                  id={invite.id}
                  name={invite.full_name}
                  teamRole={getMemberRole(invite.roles)}
                  email={invite.email}
                  status={invite.status}
                  phone={invite.phone}
                />
              ))}
            </tbody>
          </table>
          <Row className="p-0 m-0">
            <Col md={12}>
              <div className="d-flex align-items-center justify-content-center">
                <TablePagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default TeamPendingMembers
