import React, { useRef } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Formik, Field, Form } from 'formik'
import { useMembersContext } from 'routes/TeamMembers/context/TeamMembersContext'
import { useToasts } from 'react-toast-notifications'
import SelectField from 'components/UI/SelectFormField/SelectFormField'
import EditTeamMemberDialog from '.'

const options = [
  { label: 'Manager', value: 'manager' },
  { label: 'Worker', value: 'worker' },
]

const EditTeamMember = ({
  id,
  fullName,
  email,
  show,
  onClose,
  onSubmit,
}) => {
  const { editTeamMember } = useMembersContext()
  const { addToast } = useToasts()
  const formikRef = useRef(null)

  const onEditMember = async (e) => {
    e.preventDefault()
    try {
      const role = formikRef.current.values.selectRole
      await editTeamMember(id, role)

      addToast('Team member role is updated!', {
        appearance: 'success',
        autoDismiss: true,
        autoDismissTimeout: 2000,
      })
      onClose()
    } catch (error) {
      addToast('Something went wrong please try again!', {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 2000,
      })
    }
  }

  return (
    <EditTeamMemberDialog
      show={show}
      onClose={onClose}
      onConfirm={(e) => onEditMember(e, id)}
      title="Edit Teammate"
    >
      <div className="m-0 p-0">
        <Row className="team-members__edit-team-memeber d-flex flex-column align-items-center justify-content-start">
          <Col>
            <Row className="pt-3 pb-3">
              <Col className="team-members__edit-team-memeber__label" md={5}>
                Full Name
              </Col>
              <Col className="team-members__edit-team-memeber__input" md="auto">
                {fullName}
              </Col>
            </Row>
          </Col>
          <Col>
            <Row className="pt-3 pb-3">
              <Col className="team-members__edit-team-memeber__label" md={5}>
                Email
              </Col>
              <Col className="team-members__edit-team-memeber__input" md="auto">
                {email}
              </Col>
            </Row>
          </Col>
          <Col>
            <Row className="pt-3 pb-3">
              <Col className="team-members__edit-team-memeber__label" md={5}>
                Select Role
              </Col>
              <Col className="team-members__edit-team-memeber__input" md={7}>
                <Formik
                  innerRef={formikRef}
                  initialValues={{
                    selectRole: '',
                  }}
                  onSubmit={(values, actions) => onSubmit(values,
                    actions.setFieldError)}
                >
                  {({
                    handleBlur,
                  }) => (
                    <Form>
                      <Field
                        name="selectRole"
                        placeholder="Select a team role"
                        classNamePrefix="select"
                        onBlur={handleBlur}
                        component={SelectField}
                        options={options}
                        customStyles
                      />
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </EditTeamMemberDialog>
  )
}

export default EditTeamMember
