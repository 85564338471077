/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { findLastItemInListById } from 'utils/helpers'

const useConversationSettings = (defaultId) => {
  const now = new Date()
  const { chatRequestId: chatReqId } = useParams()
  const chatRequestId = parseInt(defaultId ?? chatReqId, 10)
  const activeChatRequests = useSelector((state) => state.consumerConversations)
  const activeConversation = findLastItemInListById(activeChatRequests, chatRequestId)
  const workerKey = [`worker-${activeConversation?.consumerId}`]
  const customerKey = [`customer-${activeConversation?.consumerId}`]
  const defaultSettings = {
    [workerKey]: {
      isAudioEnabled: true,
      isVideoEnabled: true,
    },
    [customerKey]: {
      isJoyrideClosed: false,
      isTwoWayVideoEnabled: false,
      isAudioEnabled: null,
    },
    expiry: null,
  }
  const stateSettings = findLastItemInListById(activeChatRequests, chatRequestId)
  const conversationSettings = stateSettings?.settings ?? defaultSettings
  const { [workerKey]: worker, [customerKey]: customer, expiry } = conversationSettings
  const isTwoWayVideoEnabled = (() => {
    if (now.getTime() > expiry) {
      return false
    }
    return customer?.isTwoWayVideoEnabled
  })()

  const isAudioEnabled = (() => {
    if ([customer?.isAudioEnabled, expiry].every((e) => e === null)) {
      return true
    }
    if (now.getTime() > expiry) {
      return false
    }
    return customer?.isAudioEnabled
  })()

  return {
    worker,
    customer,
    userId: activeConversation?.consumerId,
    isTwoWayVideoEnabled,
    isAudioEnabled,
    defaultSettings,
    workerKey,
    customerKey,
  }
}
export default useConversationSettings
