import React from 'react'
import useTrack from 'hooks/useTrack/useTrack'
import VideoTrack from 'components/Room/VideoTrack'
import AudioTrack from 'components/Room/AudioTrack'

const Publication = ({
  publication,
  videoPriority,
  displayVideo = true,
  isLocalParticipant,
  isScreenShareOnly,
  attachLogo = null,
  fullScreenRef,
}) => {
  const track = useTrack(publication)

  if (!track) {
    return null
  }

  switch (track.kind) {
    case 'video':
      return displayVideo
      && (
      <VideoTrack
        track={track}
        priority={videoPriority}
        isLocal={!track.name.includes('screen') && isLocalParticipant}
        isScreenShareOnly={isScreenShareOnly}
        attachLogo={attachLogo}
        fullScreenRef={fullScreenRef}
      />
      )
    case 'audio':
      return <AudioTrack track={track} />
    default:
      return null
  }
}

export default Publication
