/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import { useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import { useToasts } from 'react-toast-notifications'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import config from 'utils/config'
import Company from 'services/Company'
import useUploadPhoto from 'hooks/useUploadPhoto'
import { fileType } from 'utils/constants'
import swytchboardLogo from 'assets/images/swytchboard-Y-logo.svg'

const EditCompanyLogo = ({ spinnerSize = 25 }) => {
  const { addToast } = useToasts()
  const { data: companyData } = useSelector((state) => state.company)

  const updateCompany = Company.updateCompanyLogo

  const {
    setFieldValue,
  } = useFormikContext()

  const {
    loading,
    s3KeyRef,
    handleChange,
  } = useUploadPhoto(
    updateCompany,
    companyData.id,
    'company_attributes.company_detail_attributes.logo',
    fileType.COMPANY_LOGO,
  )

  // Handles delete from backend, todo: delete from AWS S3
  const handleDeleteLogo = async () => {
    try {
      await Company.updateCompanyLogo(companyData.id, null)
      setFieldValue('company_attributes.company_detail_attributes.logo', null)
      s3KeyRef.current = null
      addToast('Your company logo was deleted!', {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      })
    } catch (error) {
      console.log(error)
    }
  }

  if (s3KeyRef.current === undefined) {
    s3KeyRef.current = companyData.company_detail?.logo
  }

  const companyLogo = `${config.ASSETS_URL}/${s3KeyRef.current === undefined
    ? companyData?.company_detail?.logo : s3KeyRef.current}`

  const renderLogo = companyData.company_detail === null || s3KeyRef.current === null ? (
    <div className="chat-settings__company-logo__default">
      <img
        src={swytchboardLogo}
        alt="default-logo"
      />
    </div>
  ) : (
    <img
      src={companyLogo}
      alt="company-logo"
      className="chat-settings__company-logo__img"
    />
  )

  return (
    <div className="chat-settings__company-logo">
      {loading
        ? (
          <div className="chat-settings__company-logo__spinner">
            <LoadingSpinner spinnerSize={spinnerSize} />
          </div>
        ) : renderLogo}
      <div className="chat-settings__company-logo__button chat-settings__company-logo__button--success">
        <input
          type="file"
          id="files"
          onChange={handleChange}
        />
        <label htmlFor="files">Change Logo</label>
      </div>
      <div className="chat-settings__company-logo__button ">
        <button
          type="button"
          id="files"
          onClick={handleDeleteLogo}
          className="chat-settings__company-logo__button--danger"
        >
          Delete Current Logo
        </button>
      </div>
    </div>
  )
}

export default EditCompanyLogo
