import React from 'react'
import Dialog from 'components/WorkerDialog'
import { Col, Row } from 'react-bootstrap'
import useDeleteEntryPoint from 'routes/ChatSettingsEntryPoint/hooks/useDeleteEntryPoint'

const DeleteEntryPointDialog = ({
  item,
  show,
  onClose,
}) => {
  const { deleteEntryPoint } = useDeleteEntryPoint(item?.id)

  const handleConfirm = async () => {
    deleteEntryPoint.mutate({
      company_id: item?.company.id,
    })
    onClose()
  }

  return (
    <Dialog
      show={show}
      onClose={onClose}
      title="Delete Entry Point"
      confirmText="Yes"
      closeText="No"
      loading={deleteEntryPoint.isLoading}
      onConfirm={handleConfirm}
    >
      <Row>
        <Col md={12}>
          Do you want to delete the entry point
          {' '}
          <strong>{item?.name}</strong>
          ?
        </Col>
      </Row>
    </Dialog>
  )
}

export default DeleteEntryPointDialog
