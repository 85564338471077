import React, { useEffect, useState } from 'react'
import { Alert, Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useLocation, useParams, generatePath } from 'react-router-dom'
import history from 'services/history'
import chatRequestActions from 'redux/chatRequest/actions'
import Chat from 'services/Chat'
import { ConversationTypes } from 'utils/constants'
import { QrFlowRoutes } from 'features/qrflow/constants'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import { decrypt } from 'utils/conversion'
import useGetEntryPointDetails from 'features/qrflow/hooks/useGetEntryPointDetails'
import useConsumerConversations from '../hooks/useConsumerConversations'

const WidgetScreen = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [displayErrorMessage, setDisplayErrorMessage] = useState(null)
  const dispatch = useDispatch()

  const {
    chatRequestId,
    serviceUid,
  } = useParams()

  const { requestType } = useConsumerConversations()

  const {
    data,
  } = useGetEntryPointDetails()

  const {
    visual,
  } = data?.config ?? {}

  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const tokenParam = query.get('t')
  const decodedURIToken = decodeURIComponent(tokenParam).replace(/%20%/g, '+')
  const consumerToken = decrypt(decodedURIToken, chatRequestId)

  const handleChangeRedirect = () => {
    const navigateToRoute = requestType === ConversationTypes.videoText
      ? QrFlowRoutes.videoText
      : QrFlowRoutes.text
    history.push(generatePath(navigateToRoute, { serviceUid, chatRequestId }))
  }

  const joinConversation = async (type, consumerId) => {
    setIsLoading(true)
    try {
      const conversationData = await Chat.joinCall(type, chatRequestId, consumerId, true)
      dispatch(chatRequestActions.joinWidgetConversationSuccess(conversationData, chatRequestId))
      handleChangeRedirect()
    } catch (error) {
      dispatch(chatRequestActions.joinWidgetConversationError(error, chatRequestId))
      setDisplayErrorMessage('Something went wrong, try please try again')
      setIsLoading(false)
    }
  }

  const fetchChatRequestData = async (token) => {
    try {
      const chatRequestData = await Chat.publicChatRequestData(chatRequestId, token)
      dispatch(chatRequestActions.createChatRequestSuccess(chatRequestData))
      dispatch(chatRequestActions.setConsumerToken(chatRequestData.id, token))
      if (chatRequestData) {
        joinConversation(chatRequestData.request_type, chatRequestData.consumer.id)
      }
    } catch (error) {
      setDisplayErrorMessage('Something went wrong, try please try again')
    }
  }

  useEffect(() => {
    if (consumerToken) {
      fetchChatRequestData(consumerToken)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumerToken, dispatch])

  return (
    <>
      {displayErrorMessage && (
        <div className="d-flex flex-column mt-5 pt-5 mb-auto ms-auto me-auto">
          <Alert
            variant="danger"
          >
            {displayErrorMessage}
          </Alert>
          <Button
            className="qr-btn w-100 mt-5 mb-auto"
            style={{
              backgroundColor: visual?.accent_color,
            }}
            // TODO: is this working???
            onClick={() => joinConversation()}
          >
            Try again
          </Button>
        </div>
      )}
      {isLoading && (
        <LoadingSpinner />
      )}
    </>
  )
}

export default WidgetScreen
