import { useQuery } from '@tanstack/react-query'
import Chat from 'services/Chat'
import { QueryKeys } from 'utils/constants'

export default function useGetAcceptedChatRequests(select) {
  return useQuery([QueryKeys.ACCEPTED_CHAT_REQUESTS], () => Chat.acceptedChatRequests(), {
    select,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    retryOnMount: false,
    retry: 1,
  })
}
