import React, { useEffect, useState } from 'react'
import EndConversationDialog from 'components/WorkerDialog/EndConversationDialog'
import { SECONDS_IN_MS, FIVE_MIN_IN_MS } from 'utils/constants'

const InactivityDetectorDialog = ({ inactivityTriggered }) => {
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [callDropped, setCallDropped] = useState(false)

  useEffect(() => {
    if (inactivityTriggered) {
      let timer // Timer for inactivity detection
      let innerTimer // Timer for warning modal

      const handleWorkerActivity = () => {
        clearTimeout(timer)
        clearTimeout(innerTimer)

        timer = setTimeout(() => {
          setShowWarningModal(true)
          // If no response in 30 seconds, drop the call
          innerTimer = setTimeout(() => {
            setCallDropped(true)
          }, SECONDS_IN_MS * 30)
        }, FIVE_MIN_IN_MS)
      }

      const handleActivityEvent = () => {
        handleWorkerActivity()
      }

      document.addEventListener('mousemove', handleActivityEvent)
      document.addEventListener('keydown', handleActivityEvent)
      document.addEventListener('click', handleActivityEvent)
      handleWorkerActivity()

      return () => {
        clearTimeout(timer)
        clearTimeout(innerTimer)
        document.removeEventListener('mousemove', handleActivityEvent)
        document.removeEventListener('keydown', handleActivityEvent)
        document.removeEventListener('click', handleActivityEvent)
      }
    }
    return () => {}
  }, [inactivityTriggered])

  return (
    <div>
      <EndConversationDialog
        inactivity
        inactivityTriggered={inactivityTriggered}
        show={showWarningModal}
        onClose={() => setShowWarningModal(false)}
        callDropped={callDropped}
      />
    </div>
  )
}

export default InactivityDetectorDialog
