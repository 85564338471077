import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { chatConfigurationSteps } from 'routes/ChatSettingsEntryPoint/constants'
import { useChatConfiguration } from 'routes/ChatSettingsEntryPoint/provider/ChatConfigurationProvider'
import TopChatConfigurationButtons from 'routes/ChatSettingsEntryPoint/components/Buttons/TopChatConfigurationButtons'
import BottomChatConfigurationButtons from
  'routes/ChatSettingsEntryPoint/components/Buttons/BottomChatConfigurationButtons'
import Editable from 'routes/ChatSettingsEntryPoint/steps/ChatIntro/Editable'
import Viewable from 'routes/ChatSettingsEntryPoint/steps/ChatIntro/Viewable'

const ChatIntro = () => {
  const {
    entryPoint,
    handleStateChange,
    modifiedState,
    setModifiedState,
  } = useChatConfiguration()

  const {
    introduction,
    website,
    call_to_action_text: entryPointCallToActionText,
    away_message: entryPointAwayMessage,
  } = entryPoint?.data.config.landing_page

  const {
    enabled: entryPointVideoChatEnabled,
  } = entryPoint?.data.config.video_chat

  const {
    enabled: entryPointTextChatEnabled,
  } = entryPoint?.data.config.text_chat

  const {
    accent_color: accentColor,
  } = entryPoint?.data.config.visual

  const [landingMessage, setLandingMessage] = useState(introduction)
  const [videoChatEnabled, setVideoChatEnabled] = useState(entryPointVideoChatEnabled === 'true')
  const [messagingChatEnabled, setMessagingChatEnabled] = useState(entryPointTextChatEnabled === 'true')
  const [websiteLink, setWebsiteLink] = useState(website)
  const [websiteLinkError, setWebsiteLinkError] = useState(false)
  const [callToActionText, setCallToActionText] = useState(entryPointCallToActionText)
  const [awayMessage, setAwayMessage] = useState(entryPointAwayMessage)
  const [awayMessagePreviewEnabled, setAwayMessagePreviewEnabled] = useState(false)

  const handleSaveButton = () => {
    handleStateChange({
      [chatConfigurationSteps.LANDING_PAGE]: {
        introduction: landingMessage,
        website: websiteLink,
        call_to_action_text: callToActionText,
        away_message: awayMessage,
      },
      [chatConfigurationSteps.VIDEO_CHAT]: {
        enabled: Boolean(videoChatEnabled),
      },
      [chatConfigurationSteps.TEXT_CHAT]: {
        enabled: Boolean(messagingChatEnabled),
      },
    })
    setModifiedState(false)
  }

  const handleCancelButton = () => {
    setLandingMessage(introduction)
    setVideoChatEnabled(entryPointVideoChatEnabled === 'true')
    setMessagingChatEnabled(entryPointTextChatEnabled === 'true')
    setWebsiteLink(website)
    setCallToActionText(entryPointCallToActionText)
    setAwayMessage(entryPointAwayMessage)
    setModifiedState(false)
  }

  return (
    <>
      <Row>
        <Col className="chat-configuration__buttons top">
          <TopChatConfigurationButtons
            handleSaveButton={handleSaveButton}
            handleCancelButton={handleCancelButton}
            modifiedState={modifiedState}
          />
        </Col>
      </Row>
      <Row className="h-100">
        <Col md={8} className="pe-4 m-0">
          <Editable
            landingMessage={landingMessage}
            setLandingMessage={setLandingMessage}
            messagingChatEnabled={messagingChatEnabled}
            setMessagingChatEnabled={setMessagingChatEnabled}
            videoChatEnabled={videoChatEnabled}
            setVideoChatEnabled={setVideoChatEnabled}
            websiteLink={websiteLink}
            setWebsiteLink={setWebsiteLink}
            websiteLinkError={websiteLinkError}
            setWebsiteLinkError={setWebsiteLinkError}
            callToActionText={callToActionText}
            setCallToActionText={setCallToActionText}
            awayMessage={awayMessage}
            setAwayMessage={setAwayMessage}
            awayMessagePreviewEnabled={awayMessagePreviewEnabled}
            setAwayMessagePreviewEnabled={setAwayMessagePreviewEnabled}
            modifiedState={modifiedState}
            setModifiedState={setModifiedState}
          />
        </Col>
        <Col md={4} className="p-0 m-0">
          <Viewable
            landingMessage={landingMessage}
            messagingChatEnabled={messagingChatEnabled}
            videoChatEnabled={videoChatEnabled}
            websiteLink={websiteLink}
            callToActionText={callToActionText}
            awayMessage={awayMessage}
            awayMessagePreviewEnabled={awayMessagePreviewEnabled}
            accentColor={accentColor}
          />
        </Col>
      </Row>
      <Row>
        <Col className="chat-configuration__buttons">
          <BottomChatConfigurationButtons
            modifiedState={modifiedState}
            setModifiedState={setModifiedState}
          />
        </Col>
      </Row>
    </>
  )
}

export default ChatIntro
