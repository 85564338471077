import React from 'react'
import { Button } from 'react-bootstrap'

const ContactUsModal = ({
  title = 'Add Title',
  detail = 'Add detail',
}) => (
  <div className="billing__plan-options-dialog__option shadow rounded-1 p-4 border text-center">
    <div className="mb-5 text-2xl fw-bold">{title}</div>
    <div className="mb-5 text-xl">{detail}</div>
    <Button
      className="btn-success d-block w-100 rounded-xl text-xl fw-bold"
      onClick={(e) => {
        window.location.href = 'mailto:hello@joinswytchboard.com'
        e.preventDefault()
      }}
    >
      Contact us
    </Button>
  </div>
)

export default ContactUsModal
