import React from 'react'
import useDialog from 'hooks/useDialog'
import EndConversationDialog from 'features/qrflow/components/dialog/EndConversationDialog'
import VideoCallButton from 'features/qrflow/components/VideoCallButton'
import useConsumerEndVideoConversation from 'features/qrflow/hooks/useConsumerEndVideoConversation'

const EndConversationButton = () => {
  const {
    displayDialog,
    onOpenDialog,
    onCloseDialog,
  } = useDialog()

  const {
    isSubmitting,
    errorMessage,
    onEndConversation,
  } = useConsumerEndVideoConversation()

  return (
    <>
      <VideoCallButton
        className="qr-video__buttons__btn"
        onClick={onOpenDialog}
        icon="phone-hang-up"
        color="red"
      />
      <EndConversationDialog
        show={displayDialog}
        onClose={onCloseDialog}
        isSubmitting={isSubmitting}
        errorMessage={errorMessage}
        onEndConversation={onEndConversation}
        disabled={isSubmitting}
      />
    </>
  )
}

export default EndConversationButton
