import { useEffect, useState } from 'react'
import DeviceDetector from 'device-detector-js'

export default function useMediaPermissions() {
  const [cameraPermissionStatus, setCameraPermissionStatus] = useState(false)
  const [microphonePermissionStatus, setMicrophonePermissionStatus] = useState(false)
  const [arePermissionBlocked, setArePermissionBlocked] = useState(false)

  const { client, os } = new DeviceDetector().parse(navigator.userAgent)
  const { name } = client
  const isFirefox = name.toLowerCase().includes('firefox')
  const isSafari = name.toLowerCase().includes('safari')
  const isChrome = name.toLowerCase().includes('chrome')
  const isIOS = os.name === 'iOS'

  const handleCameraPermissionMedia = async () => navigator.mediaDevices.getUserMedia({ video: true, audio: false }).then((res) => {
    setCameraPermissionStatus(res.active)
  })

  const handleMicrophonePermissionMedia = async () => navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then((res) => {
    setMicrophonePermissionStatus(res.active)
  })

  const handleCameraPermission = async () => navigator.permissions.query({ name: 'camera' }).then((res) => {
    setCameraPermissionStatus(res.state === 'granted')
    res.onchange = ((e) => {
      // detecting if the event is a change
      if (e.type === 'change') {
        // checking what the new permissionStatus state is
        setCameraPermissionStatus(e.target.state === 'granted')
      }
    })
  })

  const handleMicrophonePermission = async () => navigator.permissions.query({ name: 'microphone' }).then((res) => {
    setMicrophonePermissionStatus(res.state === 'granted')
    res.onchange = ((e) => {
      // detecting if the event is a change
      if (e.type === 'change') {
        // checking what the new permissionStatus state is
        setMicrophonePermissionStatus(e.target.state === 'granted')
      }
    })
  })

  useEffect(() => {
    async function setPermissions() {
      try {
        if (isFirefox || isSafari || (isIOS && isChrome)) {
          await handleCameraPermissionMedia()
          await handleMicrophonePermissionMedia()
        } else {
          await handleCameraPermission()
          await handleMicrophonePermission()
        }
      } catch (error) {
        setArePermissionBlocked(true)
      }
    }
    setPermissions()
  }, [isFirefox, isSafari, isChrome, isIOS])

  return {
    isCameraEnabled: cameraPermissionStatus,
    isMicrophoneEnabled: microphonePermissionStatus,
    arePermissionBlocked,
  }
}
