/* eslint-disable camelcase */
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react'
import Billing from 'services/Billing'
import Stripe from 'services/Stripe'
import { useDispatch, useSelector } from 'react-redux'
import companyOperations from 'redux/company/operations'
import SubscriptionStatus from './SubscriptionStatus'

const BillingContext = createContext()
BillingContext.displayName = 'BillingContext'

const BillingProvider = ({ children }) => {
  const [checkoutPlan, setCheckoutPlan] = useState({
    monthly_plans: [],
  })

  const [subscriptionPlan, setSubscriptionPlan] = useState({
    active: false,
    subscriptionId: null,
    activeUntil: null,
    minutesLeft: 0,
    status: SubscriptionStatus.Inactive,
  })

  const [subscriptionStats, setSubscriptionStats] = useState({
    startDate: null,
    endDate: null,
    totalMinutes: 0,
    minutesLeft: 0,
    minutesSpent: 0,
  })

  const dispatch = useDispatch()
  const fetchSubscriptionPlan = () => dispatch(companyOperations.fetchCurrentCompany())
  const subscriptionData = useSelector((state) => state.company.data?.subscription)

  const selectSubscriptionStatus = ({
    active, minutesLeft, activeUntil, plan,
  }) => {
    const currentTime = new Date().toISOString()
    if (!subscriptionData) return SubscriptionStatus.NoPlan
    if (!active) return SubscriptionStatus.Inactive
    if (plan === 'trial' && minutesLeft <= 0) return SubscriptionStatus.EndedFreeTrial
    if (minutesLeft <= 0) return SubscriptionStatus.ReachedTalkTimeLimit
    if (activeUntil < currentTime && plan !== 'trial') return SubscriptionStatus.ExpiredPlan
    return SubscriptionStatus.Active
  }

  const [isLoadingSubscription, setIsLoadingSubscription] = useState(false)
  const [hasErrorSubscription, setHasErrorSubscription] = useState(false)

  const getPlans = async () => {
    try {
      const plans = await Billing.getPlans()

      setCheckoutPlan({
        monthly_plans: plans.monthly_plans,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  const getCustomerData = async () => {
    try {
      return Billing.getCustomerData()
    } catch (e) {
      throw new Error(e)
    }
  }

  useEffect(() => {
    try {
      const {
        id, active, active_until, minutes_left, plan_name, end_date, start_date, total_minutes, minutes_spent,
      } = subscriptionData || {}
      setSubscriptionPlan({
        subscriptionId: id,
        active,
        activeUntil: active_until,
        minutesLeft: minutes_left,
        plan: plan_name,
        status: selectSubscriptionStatus({
          active, activeUntil: active_until, minutesLeft: minutes_left, plan: plan_name,
        }),
      })
      setSubscriptionStats({
        endDate: end_date,
        startDate: start_date,
        totalMinutes: total_minutes,
        minutesLeft: minutes_left,
        minutesSpent: minutes_spent,
      })
      setIsLoadingSubscription(true)
      setIsLoadingSubscription(false)
    } catch (e) {
      setIsLoadingSubscription(false)
      setHasErrorSubscription(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingSubscription, subscriptionData])

  const createCheckout = async (planId) => {
    try {
      return await Stripe.createCheckout(planId)
    } catch (e) {
      throw new Error(e)
    }
  }

  return (
    <BillingContext.Provider
      value={{
        getPlans,
        getCustomerData,
        createCheckout,
        isLoadingSubscription,
        hasErrorSubscription,
        checkoutPlan,
        subscriptionPlan,
        subscriptionStats,
        fetchSubscriptionPlan,
      }}
    >
      {children}
    </BillingContext.Provider>
  )
}

function useBillingContext() {
  const context = useContext(BillingContext)
  if (context === undefined) {
    throw new Error('useBillingContext must be used within a BillingProvider')
  }
  return context
}

export { BillingProvider, useBillingContext }
