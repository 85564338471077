import React from 'react'
import OnBoardingLayout from 'layouts/OnBoardingLayout'
import InviteSingUp from './InviteSingUp'

const InviteSignUpRoute = () => (
  <OnBoardingLayout>
    <InviteSingUp />
  </OnBoardingLayout>
)

export default InviteSignUpRoute
