import React from 'react'
import { Alert } from 'react-bootstrap'
import QRDialog from 'features/qrflow/components/dialog/QRDialog'
import useGetEntryPointDetails from 'features/qrflow/hooks/useGetEntryPointDetails'

const EndConversationDialog = ({
  show,
  onClose,
  onEndConversation,
  errorMessage,
  isSubmitting,
  disabled,
}) => {
  const {
    data,
  } = useGetEntryPointDetails()

  const {
    accent_color: accentColor,
  } = data?.config.visual ?? {}

  return (
    <QRDialog
      show={show}
      title="End Conversation"
      onClose={onClose}
      onConfirm={onEndConversation}
      confirmTextColor={accentColor}
      loading={isSubmitting}
      disabled={disabled}
    >
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      <p>Are you sure?</p>
      <p>
        If so, please take a moment to rate your experience after clicking confirm,
        and we&apos;ll enter you in our monthly prize pack drawing!
      </p>
    </QRDialog>
  )
}
export default EndConversationDialog
