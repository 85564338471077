import React from 'react'
import LoadingButton from 'components/buttons/LoadingButton'
import useDialog from 'hooks/useDialog'
import EndConversationDialog from 'features/qrflow/components/dialog/EndConversationDialog'
import RequestVideoDialog from 'features/qrflow/components/dialog/RequestVideoDialog'
import useConsumerEndChatConversation from 'features/qrflow/hooks/useConsumerEndChatConversation'
import config from 'utils/config'
import swytchboardLogo from 'assets/images/swytchboard-Y-logo.svg'
import useGetEntryPointDetails from 'features/qrflow/hooks/useGetEntryPointDetails'
import { ChatStatuses } from 'utils/constants'
import useConsumerConversations from '../hooks/useConsumerConversations'

const MessagingLayoutHeader = () => {
  const {
    state: {
      user, workerName, status, requestVideo,
    },
  } = useConsumerConversations()
  const {
    data,
    isLoading,
  } = useGetEntryPointDetails()
  const {
    visual,
    video_chat: videoChat,
  } = data?.config ?? {}

  const isVideoRequestEnabled = (videoChat?.enabled === 'true')

  const {
    displayDialog: displayEndConversationDialog,
    onCloseDialog: onCloseEndConversationDialog,
    onOpenDialog: onOpenEndConversationDialog,
  } = useDialog()

  const {
    displayDialog: displayRequestVideoDialog,
    onCloseDialog: onCloseRequestVideoDialog,
    onOpenDialog: onOpenRequestVideoDialog,
  } = useDialog()
  const isWorkerOnline = user?.status === 'online'

  const {
    isSubmitting,
    onEndConversation,
    errorMessage,
  } = useConsumerEndChatConversation()

  const awsPath = `${config.ASSETS_URL}/`

  const isLoadingConversationData = status !== ChatStatuses.accepted

  const hasRequestedVideo = requestVideo === 'requested'

  const companyLogo = `${awsPath}${visual?.logo1}` || swytchboardLogo

  return (
    <div className="messaging-layout__header">
      <div className="messaging-layout__header__company-info">
        <div className="d-flex align-items-center">
          <div
            className={`activity-indicator 
            ${isWorkerOnline
              ? 'activity-indicator__connected'
              : 'activity-indicator__disconnected'
            }`}
          />
          <div className="messaging-layout__header__company-info__worker-name">
            {workerName}
          </div>
        </div>
        <div>
          <img
            className="messaging-layout__header__company-info__logo"
            src={companyLogo}
            alt="company-logo"
          />
        </div>
      </div>
      <div className={`messaging-layout__btn-container ${!isVideoRequestEnabled && 'right'}`}>
        {!isLoading && (
          <>
            {isVideoRequestEnabled && (
            <LoadingButton
              className="messaging-layout__btn-container__btn"
              loading={isLoading}
              onClick={onOpenRequestVideoDialog}
              style={{
                backgroundColor: visual?.accent_color,
              }}
              disabled={hasRequestedVideo || isLoadingConversationData}
            >
              {
              hasRequestedVideo
                ? 'Waiting for approval'
                : 'Request Video Call'
            }
            </LoadingButton>
            )}

            <LoadingButton
              className={`
          messaging-layout__btn-container__btn messaging-layout__btn-container__btn--danger right
          ${isLoadingConversationData ? 'messaging-layout__btn-container__btn--danger--disabled' : ''}
          `}
              onClick={onOpenEndConversationDialog}
              disabled={isLoadingConversationData}
            >
              End Conversation
            </LoadingButton>
          </>
        )}
      </div>
      <EndConversationDialog
        show={displayEndConversationDialog}
        onClose={onCloseEndConversationDialog}
        isSubmitting={isSubmitting}
        disabled={isSubmitting}
        onEndConversation={onEndConversation}
        errorMessage={errorMessage}
      />
      <RequestVideoDialog
        show={displayRequestVideoDialog}
        onClose={onCloseRequestVideoDialog}
      />
    </div>
  )
}

export default MessagingLayoutHeader
