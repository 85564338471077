import { combineReducers } from 'redux'
import chatRequestReducer from 'redux/chatRequest/reducers'
import companyReducer from 'redux/company/reducers'
import userReducer from 'redux/user/reducers'
import layoutReducer from 'redux/layout/reducers'
import serviceIntroMessageReducer from './conversation/serviceIntroMessage/serviceIntroMessage.reducer'
import conversationStatusReducer from './conversation/conversationStatus/conversationStatus.reducer'
import videoPermissionReducer from './conversation/browserPermissions/videoPermission.reducer'
import audioPermissionReducer from './conversation/browserPermissions/audioPermission.reducer'
import conversationDetailsReducer from './conversation/conversationDetails/reducer'
import activeConversationsReducer from './activeConversations/reducers'
import consumerConversationReducer from './consumerConversations/reducers'

const rootReducer = combineReducers({
  serviceIntroMessage: serviceIntroMessageReducer,
  conversationStatus: conversationStatusReducer,
  videoPermission: videoPermissionReducer,
  audioPermission: audioPermissionReducer,
  chatRequest: chatRequestReducer,
  company: companyReducer,
  user: userReducer,
  activeConversations: activeConversationsReducer,
  consumerConversations: consumerConversationReducer,
  conversationDetails: conversationDetailsReducer,
  layout: layoutReducer,
})
export default rootReducer
