import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import userActions from 'redux/user/actions'
import userOperations from 'redux/user/operation'
import useErrorMessageFromObject from 'hooks/useErrorMessageFromObject'
import { ActionState } from 'utils/constants'

/**
 * Contains logic for user Change Password form in Profile Settings
 */
export default function useChangePasswordForm() {
  const changePasswordFormRef = useRef()

  const [successMessage, setSuccessMessage] = useState('')

  const {
    changePassword,
  } = useSelector((state) => state.user) || {}

  const errorMessage = useErrorMessageFromObject(changePassword)

  const isChangingPassword = changePassword === ActionState.InProgress

  const passwordChangedSuccessfully = changePassword === ActionState.Completed

  const dispatch = useDispatch()

  useEffect(() => {
    // Reset form on success
    if (passwordChangedSuccessfully) {
      changePasswordFormRef?.current?.resetForm()
    }
  }, [passwordChangedSuccessfully])

  useEffect(() => {
    // Display success message on successful password change
    if (passwordChangedSuccessfully) {
      setSuccessMessage('Password changed successfully')
    }
  }, [passwordChangedSuccessfully])

  const onClearSuccessMessage = () => setSuccessMessage('')

  const onClearErrorMessage = () => dispatch(userActions.clearChangePasswordError())

  const onChangePassword = (formData) => dispatch(userOperations.changePassword(formData))

  return {
    changePasswordFormRef,
    successMessage,
    errorMessage,
    isChangingPassword,
    onClearErrorMessage,
    onClearSuccessMessage,
    onChangePassword,
  }
}
