import React from 'react'

// Reusable UI Card component which is used in the Messages section
const DashboardCard = ({
  children,
  className = '',
}) => (
  <div className={`br-2 bg-white p-3 shadow-big w-100 ${className}`}>
    {children}
  </div>
)

export default DashboardCard
