import React from 'react'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'
import { useIsFetching } from '@tanstack/react-query'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import { QueryKeys } from 'utils/constants'

const Filter = ({ onClick, displayFilters }) => {
  const isFilteringConversations = useIsFetching([QueryKeys.CONVERSATIONS])

  const loadingFiltersOrFilterIcon = isFilteringConversations && displayFilters
    ? (
      <LoadingSpinner spinnerSize={14} className="conversations__filters-button--spinner" />
    )
    : (
      <IcomoonReact
        iconSet={iconSet}
        icon="filter"
        size={15}
      />
    )

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    (
      <div
        className={`
      conversations__filters-button
       ${displayFilters ? 'conversations__filters-button--open' : 'conversations__filters-button--closed'
      }`}
        onClick={onClick}
      >
        {loadingFiltersOrFilterIcon}
        <span>
          Filters
        </span>
      </div>
    )
  )
}

export default Filter
