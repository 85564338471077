/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import useLocalVideoToggle from 'hooks/useLocalVideoToggle/useLocalVideoToggle'
import VideoControlsButton from 'components/VideoControlsButton/VideoControlsButton'
import { useDispatch } from 'react-redux'
import { updateActiveConversationTracksPermit } from 'redux/activeConversations/actions'
import useActiveConversations from 'hooks/useActiveConversations'

const ToggleVideoButton = () => {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle()
  const dispatch = useDispatch()
  const { id, settings: { workerKey, worker } } = useActiveConversations()

  const onToggleVideoButtonHandle = () => {
    dispatch(updateActiveConversationTracksPermit(id, workerKey, { isVideoEnabled: !isVideoEnabled }))
    toggleVideoEnabled()
  }

  useEffect(() => {
    if (!worker?.isVideoEnabled) {
      return toggleVideoEnabled(false)
    }
    return toggleVideoEnabled(true)
  }, [toggleVideoEnabled])

  return (
    <VideoControlsButton
      name={isVideoEnabled ? 'icon-video-call' : 'icon-video-call-end'}
      onClick={onToggleVideoButtonHandle}
      disabled={!isVideoEnabled}
    />
  )
}

export default ToggleVideoButton
