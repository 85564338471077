import constants from './constants'

const initialState = {
  chatHidden: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_HIDE_CHAT:
      return {
        ...state,
        chatHidden: !state.chatHidden,
      }
    default:
      return state
  }
}

export default reducer
