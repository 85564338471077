import React from 'react'
import IcomoonReact from 'icomoon-react'
import { isMobile } from 'utils/helpers'
import iconSet from 'assets/fonts/selection.json'

const FlipCamera = ({ onClick = () => { }, disabled }) => (
  <div className="bg-white rounded-pill m-2" style={{ padding: '10px 13px 12px 13px' }}>
    <IcomoonReact
      onClick={onClick}
      iconSet={iconSet}
      size={15}
      icon="flip_camera_android"
      color={(isMobile && disabled) ? '#d5d5d5' : 'black'}
    />
  </div>
)

export default FlipCamera
