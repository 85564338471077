import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Interweave } from 'interweave'
import { UrlMatcher } from 'interweave-autolink'
import Image from 'services/Image'
import { useChatContext } from 'providers/ChatProvider'
import useUserAvatar from 'hooks/useUserAvatar'
import EmbedChatFiles from 'components/chat/EmbedChatFiles'

const TeamMessage = ({ message }) => {
  const {
    dateCreated,
    body,
    sid,
    participantSid,
    media,
  } = message

  const displayDate = new Date(dateCreated).toLocaleTimeString()

  const { getFullNameByParticipantSid, getAvatarByParticipantSid } = useChatContext()
  const fullName = getFullNameByParticipantSid(participantSid)
  const avatar = getAvatarByParticipantSid(participantSid)

  const profilePicture = Image.get(avatar)

  const {
    renderAvatar,
  } = useUserAvatar(
    { full_name: fullName, avatar },
    { profilePicture },
    { icommonIcon: 'avatar', icommonSize: '32' },
    { avatarSize: '40', avatarRound: '20%' },
  )

  return (
    <div className="chat-message-dashboard my-2" key={sid}>
      <Row className="m-0 p-0">
        <Col md={2} xs={2} className="text-center flex-grow-1">
          <div className="avatar">
            {renderAvatar}
          </div>
        </Col>
        <Col md={10} xs={10} className="text-break flex-grow-2">
          <div className="w-100 message px-2">
            <Row>
              <Col md={6} xs={6} className="d-flex justify-content-start">
                <b>{fullName}</b>
              </Col>
              <Col md={6} xs={6} className="d-flex m-0 p-0 justify-content-end">
                <span className="time text-default">{displayDate}</span>
              </Col>
            </Row>
            {media
            && (
            <div className="my-3">
              <EmbedChatFiles media={media} />
            </div>
            ) }
            <Interweave
              content={body}
              matchers={[new UrlMatcher('url')]}
              newWindow
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default TeamMessage
