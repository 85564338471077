/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import {
  Button,
  Col,
  Row,
  Spinner,
} from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import ReactTimeAgo from 'react-time-ago'
import Chat from 'services/Chat'
import { getRequestType } from 'utils/helpers'
import usePendingChatRequests from 'features/pending-chat-requests/usePendingChatRequests'
import DeclineVideoCall from 'components/video/DeclineVideoCall'
import ArrowLeft from 'components/UI/ArrowLeft'
import chatRequestActions from 'redux/chatRequest/actions'
import { setActiveConversation } from 'redux/activeConversations/actions'
import { ConversationTypes } from 'utils/constants'
import useActiveConversations from 'hooks/useActiveConversations'
import useConversationSettings from 'hooks/useActiveConversations/useConversationSettings'
import useCustomerName from 'hooks/useCustomerName'
import useCustomerContactIdentifier from 'hooks/useCustomerContactIdentifier'
import useTimerCountdown from './queue/useTimerCountdown'

const CustomerCard = ({ chatRequest, title = 'Next Customer' }) => {
  const { addToast } = useToasts()
  const {
    isLoading: isLoadingChatRequests,
    refetch: refetchChatRequests,
    remove: stopUpdatingCustomerList,
  } = usePendingChatRequests()
  const customerIdentifier = useCustomerContactIdentifier()

  const [acceptLoading, setAcceptLoading] = useState(false)
  const [showDeclineModal, setShowDeclineModal] = useState(false)
  const isPreselect = (title === 'Priority Customer')
  const dispatch = useDispatch()
  const history = useHistory()
  const customerName = useCustomerName()
  const { isAnyVideoCallActive, isLimitReached } = useActiveConversations()
  const { workerKey, defaultSettings } = useConversationSettings()

  const { minutePassed } = useTimerCountdown(chatRequest?.created_at)
  const handleRemovePriority = () => {
    dispatch(chatRequestActions.preselectChatRequest({ id: null }))
  }

  const handleAcceptCall = async (chatRequestId) => {
    setAcceptLoading(true)

    try {
      // Because the backend will push new pending list
      // we want to halt updating it, until we are redirected to the conversation page!
      stopUpdatingCustomerList()
      await Chat.acceptChatRequest(chatRequestId)
      const activeVideoCall = await Chat.engageChatCallRequest(chatRequestId)
      dispatch(setActiveConversation(activeVideoCall, {
        consumerName: chatRequest.consumer.name,
        consumerEmail: chatRequest.consumer.email,
        consumerPhone: chatRequest.consumer.phone_number,
        id: chatRequestId,
        requestType: chatRequest.request_type,
        settings: { [workerKey]: defaultSettings[workerKey], expiry: null },
      }))
      // Reset preselected on accept action
      handleRemovePriority()
      history.push(`/conversation/${chatRequestId}`)
      setAcceptLoading(false)
    } catch (e) {
      // TODO handle errors here
      console.log('Error', e)
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      })
      setAcceptLoading(false)
    }
  }

  const handeOnDecline = async (chatRequestId) => {
    // Reset preselected on decline action
    handleRemovePriority()
    await Chat.declineChatRequest(chatRequestId)
    await refetchChatRequests()
    return setShowDeclineModal((prev) => !prev)
  }

  const handleOnLeaveMessage = async (chatRequestId) => {
    handleRemovePriority()
    await Chat.leaveMessageChatRequest(chatRequestId)
    await refetchChatRequests()
    return setShowDeclineModal((prev) => !prev)
  }
  let buttonName = 'Accept'
  if (isLimitReached) buttonName = 'Limit Reached'
  if (isAnyVideoCallActive && chatRequest?.request_type === ConversationTypes.videoText) buttonName = 'Video 🚫'
  return (
    <>
      {
        !isLoadingChatRequests
          ? (
            <Row className="m-0 p-0">
              <Col md={12} className={`${isPreselect ? 'd-flex' : 'text-center'} py-3`}>
                {isPreselect && (
                  <div className="d-flex w-25 py-2 cursor-pointer" onClick={() => handleRemovePriority()}>
                    <ArrowLeft />
                  </div>
                )}
                <span className="text-center text-uppercase card-title fw-bold">{title}</span>
              </Col>
              {
                chatRequest
                  ? (
                    <Col md={12} className="p-0 m-0">
                      <Row className="m-0 p-0 d-flex align-items-center">
                        <Col md="auto d-flex">
                          <div className="w-100 fw-bold consumer-name next-customer">
                            {customerName(chatRequest.consumer.name)}
                          </div>
                        </Col>
                        {(minutePassed && chatRequest?.created_at) ? (
                          <Col md="auto d-flex ms-4">
                            <div className="w-100 queue__timer" data-hover="Elapsed time since requested">
                              <ReactTimeAgo date={new Date(chatRequest?.created_at)} locale="en-US" />
                            </div>
                          </Col>
                        ) : null}
                        <Col md="auto flex-grow-1">
                          <div className="w-100 consumer-conversation-type">
                            {getRequestType(chatRequest?.request_type)}
                          </div>
                        </Col>
                        <Col md="auto flex-grow-1">
                          <div className="w-100 consumer-conversation-type">
                            {chatRequest?.company_service?.name}
                          </div>
                        </Col>
                        <Col md={12} className="queue queue__email">
                          {customerIdentifier(chatRequest.consumer.email, chatRequest.consumer.phone_number)}
                        </Col>
                      </Row>
                      <Row className="m-0 p-0">
                        <Col md={12} className="py-3">
                          <span className="intro-message">{chatRequest.intro_message}</span>
                        </Col>
                      </Row>
                      <DeclineVideoCall
                        show={showDeclineModal}
                        setShow={setShowDeclineModal}
                        onDecline={handeOnDecline}
                        onLeaveMessage={handleOnLeaveMessage}
                        chatRequestId={chatRequest.id}
                      />
                      <Row className="m-0 p-0 py-3">
                        <Col md={6} xs={6} className="d-flex">
                          <Button
                            variant="danger"
                            size="sm"
                            className="py-3 card-subtitle w-100 fw-bold text-white d-flex justify-content-center align-items-center"
                            id="decline-next-customer"
                            style={{ maxHeight: 35 }}
                            disabled={acceptLoading}
                            onClick={() => setShowDeclineModal((prev) => !prev)}
                          >
                            {
                              acceptLoading
                                ? (
                                  <Spinner animation="border" variant="primary" className="mx-3 text-center" />
                                ) : (
                                  'Decline'
                                )
                            }
                          </Button>
                        </Col>
                        <Col md={6} xs={6} className="d-flex">
                          <Button
                            variant="success"
                            className="py-3 card-subtitle w-100 fw-bold d-flex justify-content-center align-items-center"
                            style={{ maxHeight: 35 }}
                            id="accept-next-customer"
                            disabled={acceptLoading || (isAnyVideoCallActive && chatRequest?.request_type === ConversationTypes.videoText) || isLimitReached}
                            onClick={() => handleAcceptCall(chatRequest.id)}
                            title={(isAnyVideoCallActive && chatRequest?.request_type === ConversationTypes.videoText) ? 'Currently, you are on a video call!' : ''}
                          >
                            {
                              acceptLoading
                                ? (
                                  <Spinner animation="border" variant="primary" className="mx-3 text-center" />
                                ) : buttonName
                            }
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  ) : (
                    <Col md={12} className="py-3 text-center w-100 card-title">
                      No Customers in Queue
                    </Col>
                  )
              }
            </Row>
          ) : (
            <div className="w-100 d-flex justify-content-center">
              <Spinner animation="border" variant="primary" className="m-5 text-center" />
            </div>
          )
      }
    </>
  )
}

export default CustomerCard
