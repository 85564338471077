import React, { useState, useMemo } from 'react'
import { Col, Row } from 'react-bootstrap'
import { capitalizeFirstLetter } from 'utils/helpers'
import MembersTableHeader from 'routes/TeamMembers/components/Members/MembersTableHeader/MembersTableHeader'
import TablePagination from 'components/UI/TableData/TablePagination/TablePagination'
import MembersTableRow from 'routes/TeamMembers/components/Members/MembersTableRow/MembersTableRow'
import { useMembersContext } from 'routes/TeamMembers/context/TeamMembersContext'

const TeamMembers = () => {
  const { members, companyId } = useMembersContext()
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)

  const ITEMS_PER_PAGE = 10

  const headers = [
    { name: 'FULL NAME', field: 'name', sortable: true },
    { name: 'TEAM ROLE', field: 'role', sortable: true },
    { name: 'E-MAIL ADDRESS', field: 'email', sortable: true },
    { name: 'PHONE NUMBER', field: 'phone', sortable: true },
    { name: 'LAST ACTIVE', field: 'last_active', sortable: true },
    { name: 'EDIT', field: 'edit' },
    { name: 'DELETE', field: 'delete' },
  ]

  function getMemberRole(roles) {
    // eslint-disable-next-line
    const role = roles?.find(({ company_id }) => company_id === companyId).role
    return capitalizeFirstLetter(role)
  }

  const membersData = useMemo(() => {
    const computedmembers = members
    setTotalItems(computedmembers?.length)

    // Current Page slice
    return computedmembers?.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE,
    )
  }, [members, currentPage])

  return (
    <>
      <Row className="w-100">
        <Col md={12} className="text-center ps-5 pe-5">
          <>
            <table className="team-members__table-data table table-borderless">
              <MembersTableHeader
                headers={headers}
              />
              <tbody>
                {membersData?.map((member) => (
                  <MembersTableRow
                    key={member.id}
                    id={member.id}
                    name={member.full_name}
                    teamRole={getMemberRole(member.roles)}
                    email={member.email}
                    phone={member.phone}
                    lastActive={member.last_active}
                  />
                ))}
              </tbody>
            </table>
            <Row className="p-0 m-0">
              <Col md={12}>
                <div className="d-flex align-items-center justify-content-center">
                  <TablePagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </Col>
            </Row>
          </>
        </Col>
      </Row>
    </>
  )
}

export default TeamMembers
