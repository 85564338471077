import React from 'react'
import usePendingChatRequests from 'features/pending-chat-requests/usePendingChatRequests'
import useDialog from 'hooks/useDialog'
import CustomersQueueDialog from './CustomerQueueDialog'

const CustomersInQueue = () => {
  const {
    data: chatRequests,
  } = usePendingChatRequests((data) => data.length)

  const customersInQueue = chatRequests || 0
  const {
    displayDialog,
    onOpenDialog,
    onCloseDialog,
  } = useDialog()

  return (
    <div>
      <CustomersQueueDialog show={displayDialog} onCloseDialog={onCloseDialog} />
      <div className="conversation-header__queued-customers cursor-pointer" onClick={() => onOpenDialog()}>
        <div className="conversation-header__queued-customers__text">
          Customers in queue
        </div>
        <div
          className="conversation-header__queued-customers__text conversation-header__queued-customers__text--red"
        >
          {customersInQueue}
        </div>
      </div>
    </div>
  )
}

export default CustomersInQueue
