import { useState } from 'react'
import User from 'services/User'

/**
 * Hook for handling resend email verification logic
 */
export default function useResendVerificationCode() {
  const [resendCodeSuccessMessage, setResendCodeSuccessMessage] = useState('')
  const [resendCodeErrorMessage, setResendCodeErrorMessage] = useState('')
  const [isResendingVerificationCode, setIsResendingVerificationCode] = useState(false)

  const onResendVerificationCode = async (email) => {
    setIsResendingVerificationCode(true)
    setResendCodeErrorMessage('')
    setResendCodeSuccessMessage('')
    try {
      await User.resendVerificationCode(email)
      setResendCodeSuccessMessage('A new verification code is sent to your email.')
    } catch (e) {
      const errorMessage = `Resend Failed: ${e.message || e.error || 'Something went wrong, please try again!'}`
      setResendCodeErrorMessage(errorMessage)
    }
    setIsResendingVerificationCode(false)
  }

  const clearResendCodeErrorMessage = () => setResendCodeErrorMessage('')

  const clearResendCodeSuccessMessage = () => setResendCodeSuccessMessage('')

  return {
    isResendingVerificationCode,
    resendCodeSuccessMessage,
    resendCodeErrorMessage,
    onResendVerificationCode,
    clearResendCodeSuccessMessage,
    clearResendCodeErrorMessage,
  }
}
