import ConversationTypes from '../conversation.types'

export const setConsumerName = (name) => ({
  type: ConversationTypes.SET_CONSUMER_NAME,
  consumerName: name,
})

export const resetConsumerName = () => ({
  type: ConversationTypes.RESET_CONSUMER_NAME,
  consumerName: null,
})
