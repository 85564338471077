import React from 'react'
import usePendingChatRequests from 'features/pending-chat-requests/usePendingChatRequests'
import Dialog from 'components/WorkerDialog'
import QueueItem from 'routes/Dashboard/components/chat/queue/QueueItem'
import { getRequestType } from 'utils/helpers'
import { Col } from 'react-bootstrap'
import useCustomerName from 'hooks/useCustomerName'

const CustomersQueueDialog = ({ show, onCloseDialog }) => {
  const {
    data: chatRequests,
  } = usePendingChatRequests((data) => data)
  const customerName = useCustomerName()
  return (
    <Dialog
      show={show}
      onClose={onCloseDialog}
      title="Customers in Queue"
      footer={false}
    >
      {chatRequests?.length >= 1
        ? (
          chatRequests.map((chatRequest) => {
            const {
              id,
              consumer: {
                name: consumerName,
                email: consumerEmail,
              },
              intro_message: introMessage,
              request_type: requestType,
            } = chatRequest

            return (
              <div
                key={`${consumerName}_${consumerEmail}_${id}`}
              >
                <QueueItem
                  name={customerName(consumerName)}
                  message={introMessage}
                  id={id}
                  requestType={getRequestType(requestType)}
                  entryPointName={chatRequest.company_service?.name}
                  onClick={() => {}}
                  cursor="text"
                />
              </div>
            )
          })

        ) : (
          <Col md={12} className="text-center w-100 card-title">
            Queue is empty
          </Col>
        )}
    </Dialog>
  )
}

export default CustomersQueueDialog
