import React from 'react'
import NotFound from 'components/notFound/NotFound'
import useRedirectChatRequest from '../hooks/useRedirectChatRequest'

/**
 * Auto-detects state and knows when to redirect back to queue, text chat or call and leave rating/thank you.
 * @returns {JSX.Element}
 * @constructor
 */
const ChatRoot = () => {
  useRedirectChatRequest()

  return <NotFound />
}

export default ChatRoot
