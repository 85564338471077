import { useQuery } from '@tanstack/react-query'
import Company from 'services/Company'
import { useToasts } from 'react-toast-notifications'
import { QueryKeys } from 'utils/constants'

const companyWorkersErrorKey = 'company-workers-error'

export default function useCompanyWorkers(companyId) {
  const {
    addToast,
    removeToast,
  } = useToasts()

  const {
    isLoading,
    isIdle,
    data,
    ...others
  } = useQuery([QueryKeys.WORKERS, companyId], () => Company.getWorkers(companyId), {
    enabled: !!companyId,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      removeToast(companyWorkersErrorKey)
      addToast(`Workers: ${error.error || error.message || 'Couldn\'t get company workers'}`, {
        appearance: 'error',
        id: companyWorkersErrorKey,
      })
    },
  })

  const isLoadingWorkers = isIdle || isLoading
  const workers = data?.users

  return {
    isLoadingWorkers,
    workers,
    ...others,
  }
}
