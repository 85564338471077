import React from 'react'
import { Col, Tab, Tabs } from 'react-bootstrap'
import CustomerChat from 'routes/Dashboard/components/chat/customer/CustomerChat'
import TeamChat from 'routes/Dashboard/components/chat/team/TeamChat'

const VideoChatTabs = () => (
  <Tabs className="p-3 w-100 border-0" defaultActiveKey="messages">
    <Tab eventKey="messages" title="Messages" tabClassName="dashboard_tabs__tab text-center w-50" mountOnEnter>
      <Col md={12} xs={12} className="px-3 py-4 h-100">
        <CustomerChat />
      </Col>
    </Tab>
    <Tab eventKey="teamchat" title="Team Chat" tabClassName="dashboard_tabs__tab text-center w-50" mountOnEnter>
      <TeamChat />
    </Tab>
  </Tabs>
)
export default VideoChatTabs
