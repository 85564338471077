import React from 'react'
import { Link } from 'react-router-dom'

const MailtoButton = ({ mailto, label }) => (
  <Link
    to="#"
    onClick={(e) => {
      window.location.href = mailto
      e.preventDefault()
    }}
  >
    {label}
  </Link>
)

export default MailtoButton
