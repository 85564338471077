import ConversationTypes from '../conversation.types'

export const setToken = (token) => ({
  type: ConversationTypes.SET_TOKEN,
  conversationToken: token,
})

export const resetToken = () => ({
  type: ConversationTypes.RESET_TOKEN,
  conversationToken: null,
})
