import React, { useEffect, useState } from 'react'
import useIsInConversationScreen from 'hooks/useIsInConversationScreen'
import { Col, Container, Row } from 'react-bootstrap'
import Sidebar from 'components/sidebar'
import { isMobile, isWebViewFromMobileApp } from 'utils/helpers'
import logo from 'assets/images/swytchboard-logo-2.svg'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'
import { useLocation } from 'react-router-dom'
import useActiveConversations from 'hooks/useActiveConversations'

const MainLayout = ({
  children,
}) => {
  const showSmallSideBar = useIsInConversationScreen() || (!isMobile && window.screen.width <= 1080)
  const location = useLocation()
  const { requestType = '' } = useActiveConversations()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const toggleMenu = () => setIsMenuOpen((prev) => !prev)

  useEffect(() => setIsMenuOpen(false), [location.pathname])

  if (isMobile) {
    if ((showSmallSideBar && requestType !== 'text') || isWebViewFromMobileApp) {
      return (
        <Container fluid className="App-mobile w-100 m-0 p-0">
          <Row className="w-100 h-100 m-0 p-0 d-flex">
            {children}
          </Row>
        </Container>
      )
    }
    return (
      <Container fluid className="App-mobile w-100 m-0 p-0">
        <Row className="w-100 h-100 m-0 p-0 d-flex">
          {isMenuOpen && (
            <Col
              className="App-sidebar App-sidebar-mobile bg-primary mx-0 px-0 min-vh-100 sticky-top h-100 position-absolute"
            >
              <Sidebar />
            </Col>
          )}
          <Col className="App-mobile-header m-0 p-0">
            <div className="d-flex h-100 w-100 align-items-center justify-content-between px-4">
              <IcomoonReact
                iconSet={iconSet}
                icon="menu-icon"
                color="#0E0641"
                size={24}
                onClick={toggleMenu}
                style={{ marginLeft: isMenuOpen ? '8vh' : '0px' }}
              />
              <img src={logo} alt="logo" className="logo" />
              {!isMenuOpen && (
                <IcomoonReact
                  iconSet={iconSet}
                  icon="icon-notifications"
                  size={22}
                  color="#0E0641"
                />
              )}
            </div>
          </Col>
          <Col className="App-mobile-content mx-0 px-0 d-flex flex-column w-100" md={12} xd={12}>
            {children}
          </Col>
        </Row>
      </Container>
    )
  }
  return (
    <Container fluid>
      <Row className="App">
        <Col
          onMouseEnter={() => setIsMenuOpen(true)}
          onMouseLeave={() => setIsMenuOpen(false)}
          className={`App-sidebar ${showSmallSideBar && !isMenuOpen ? 'App-sidebar-small' : ''} bg-primary mx-0 px-0 min-vh-100 sticky-top h-100`}
        >
          <Sidebar isMenuOpen={isMenuOpen} />
        </Col>
        <Col className={`App-content 
          ${showSmallSideBar ? 'is-in-conversation-screen' : ''} bg-white mx-0 px-0 d-flex flex-column`}
        >
          {children}
        </Col>
      </Row>
    </Container>
  )
}

export default MainLayout
