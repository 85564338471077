import React from 'react'
import useParticipantNetworkQualityLevel from './useParticipantNetworkQualityLevel'

const STEP = 3
const BARS_ARRAY = [0, 1, 2, 3, 4]
/**
 * NetworkQualityLevel component
 *
 * @component
 * @description it return 5 vertical bars, the better the network the more bars are filled,
 * simulate by using network throttling on browser or device
 * @example
 * const participant = localParticipant
 * const qrflow = true'
 * return (
 *   <NetworkQualityLevel participant={Participant} qrflow={boolean} />
 * )
 */

export default function NetworkQualityLevel({ participant, qrflow }) {
  const networkQualityLevel = useParticipantNetworkQualityLevel(participant)
  return networkQualityLevel ? (
    <div
      className="outer_container"
      style={{
        position: 'absolute', top: qrflow ? '3.5rem' : '1.3rem', left: '0px', zIndex: 1,
      }}
    >
      <div className="inner_container">
        {BARS_ARRAY.map((level) => (
          <div
            key={level}
            style={{
              height: `${STEP * (level + 1)}px`,
              background: networkQualityLevel > level ? 'white' : 'rgba(255, 255, 255, 0.2)',
            }}
          />
        ))}
      </div>
    </div>
  ) : null
}
