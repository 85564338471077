import React, { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import swytchboardLogo from 'assets/images/swytchboard-Y-logo.svg'
import Company from 'services/Company'
import { fileType } from 'utils/constants'
import useUploadPhoto from 'hooks/useUploadPhoto'
import config from 'utils/config'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import { ChatBasicsConst } from 'routes/ChatSettingsEntryPoint/constants'
import StepField from 'routes/ChatSettingsEntryPoint/components/Field/StepField'
import { detectNewLineInString } from 'utils/helpers'

const Editable = ({
  color,
  setColor,
  logo1,
  logo2,
  setLogo1,
  setLogo2,
  welcomeMessage,
  setWelcomeMessage,
  setModifiedState,
}) => {
  const { id: companyServiceId } = useParams()

  const {
    loading,
    s3KeyRef,
    handleChange,
  } = useUploadPhoto(
    null,
    companyServiceId,
    '',
    fileType.COMPANY_SERVICE_LOGO,
  )

  const {
    loading: loading2,
    s3KeyRef: s3KeyRef2,
    handleChange: handleChange2,
  } = useUploadPhoto(
    null,
    companyServiceId,
    '',
    fileType.COMPANY_SERVICE_LOGO,
  )

  const companyServiceLogo1 = s3KeyRef.current === undefined
    ? logo1 : s3KeyRef.current

  const companyServiceLogo2 = s3KeyRef2.current === undefined
    ? logo2 : s3KeyRef2.current

  const renderLogo1 = `${companyServiceLogo1 !== null
    ? `${config.ASSETS_URL}/${companyServiceLogo1}` : swytchboardLogo}`

  const renderLogo2 = `${companyServiceLogo2 !== null
    ? `${config.ASSETS_URL}/${companyServiceLogo2}` : ''}`

  useEffect(() => {
    setLogo1(companyServiceLogo1)
    setLogo2(companyServiceLogo2)
  }, [companyServiceLogo1, setLogo1, companyServiceLogo2, setLogo2])

  const handleColorChange = (e) => {
    setColor(e.target.value)
    setModifiedState(true)
  }

  const handleUploadCompanyLogo1 = (e) => {
    handleChange(e)
    setModifiedState(true)
  }

  const handleDeleteLogo = async () => {
    try {
      await Company.updateCompanyEntryPoint(companyServiceId, {
        config_params: {
          config: {
            visual: {
              logo1: null,
            },
          },
        },
      })
      s3KeyRef.current = null
      setLogo1(null)
      setModifiedState(true)
    } catch (error) {
      console.log(error)
    }
  }

  const handleUploadCompanyLogo2 = (e) => {
    handleChange2(e)
    setModifiedState(true)
  }

  const handleDeleteLogo2 = async () => {
    try {
      await Company.updateCompanyEntryPoint(companyServiceId, {
        config_params: {
          config: {
            visual: {
              logo2: null,
            },
          },
        },
      })
      s3KeyRef2.current = null
      setLogo2(null)
      setModifiedState(true)
    } catch (error) {
      console.log(error)
    }
  }

  const handleWelcomeMessageChange = (e) => {
    setWelcomeMessage(detectNewLineInString(e.target.value))
    setModifiedState(true)
  }

  return (
    <>
      <Col className="chat-basics-editable">
        <StepField
          title={ChatBasicsConst.color.title}
          subTitle={ChatBasicsConst.color.subTitle}
        >
          <div className="chat-basics-editable__color">
            <div className="chat-basics-editable__color__input">
              <input
                type="color"
                value={color}
                onChange={handleColorChange}
              />
            </div>
            <div>
              <span>{color?.toUpperCase()}</span>
            </div>
          </div>
        </StepField>
        <StepField
          title={ChatBasicsConst.logo.title}
          subTitle={ChatBasicsConst.logo.subTitle}
        >
          <Row className="chat-basics-editable__logo">
            <Col>
              {loading
                ? (
                  <LoadingSpinner spinnerSize="20" />
                ) : (
                  <img src={renderLogo1} alt="logo" />
                )}
            </Col>
            <Col className="chat-basics-editable__logo__upload">
              <label htmlFor="files">
                Upload Logo
                <input
                  type="file"
                  id="files"
                  onChange={handleUploadCompanyLogo1}
                />
              </label>
            </Col>
            <Col className="chat-basics-editable__logo__delete">
              <div className="chat-settings__company-logo__button ">
                <button
                  type="button"
                  id="files"
                  onClick={handleDeleteLogo}
                  className="chat-settings__company-logo__button--danger"
                >
                  Delete Logo
                </button>
              </div>
            </Col>
          </Row>
          <Row className="chat-basics-editable__logo">
            <Col>
              {loading2
                ? (
                  <LoadingSpinner spinnerSize="20" />
                ) : (
                  <img
                    src={renderLogo2}
                    alt="logo"
                    className={`${companyServiceLogo2 === null ? 'hidden' : ''}`}
                  />
                )}
            </Col>
            <Col className="chat-basics-editable__logo__upload">
              <label htmlFor="files2">
                Upload Logo 2
                <input
                  type="file"
                  id="files2"
                  onChange={handleUploadCompanyLogo2}
                />
              </label>
            </Col>
            <Col className="chat-basics-editable__logo__delete">
              <div className="chat-settings__company-logo__button ">
                <button
                  type="button"
                  id="files2"
                  onClick={handleDeleteLogo2}
                  className="chat-settings__company-logo__button--danger"
                >
                  Delete Logo 2
                </button>
              </div>
            </Col>
          </Row>
        </StepField>
        <StepField
          title={ChatBasicsConst.welcomeMessage.title}
          subTitle={ChatBasicsConst.welcomeMessage.subTitle}
        >
          <Row className="w-100">
            <Col className="chat-editable w-100">
              <textarea
                maxLength="400"
                rows={6}
                value={welcomeMessage || ''}
                onChange={handleWelcomeMessageChange}
              />
            </Col>
          </Row>
        </StepField>
      </Col>
    </>
  )
}

export default Editable
