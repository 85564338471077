import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { chatConfigurationSteps } from 'routes/ChatSettingsEntryPoint/constants'
import { useChatConfiguration } from 'routes/ChatSettingsEntryPoint/provider/ChatConfigurationProvider'
import TopChatConfigurationButtons from 'routes/ChatSettingsEntryPoint/components/Buttons/TopChatConfigurationButtons'
import BottomChatConfigurationButtons from
  'routes/ChatSettingsEntryPoint/components/Buttons/BottomChatConfigurationButtons'
import Editable from 'routes/ChatSettingsEntryPoint/steps/ChatRatings/Editable'
import Viewable from 'routes/ChatSettingsEntryPoint/steps/ChatRatings/Viewable'

const ChatRatings = () => {
  const {
    entryPoint,
    handleStateChange,
    modifiedState,
    setModifiedState,
  } = useChatConfiguration()

  const {
    enabled: entryPointEnabledRatings,
    message: entryPointRatingsMessage,
    allow_comments: allowComments,
  } = entryPoint?.data.config.ratings

  const {
    accent_color: accentColor,
  } = entryPoint?.data.config.visual

  const [enabledRatings, setEnabledRatings] = useState(entryPointEnabledRatings === 'true')
  const [ratingsMessage, setRatingsMessage] = useState(entryPointRatingsMessage)
  const [enableDetailedComments, setEnableDetailedComments] = useState(
    allowComments === 'true',
  )

  const handleSaveButton = () => {
    handleStateChange({
      [chatConfigurationSteps.RATINGS]: {
        enabled: Boolean(enabledRatings),
        message: ratingsMessage,
        allow_comments: Boolean(enableDetailedComments),
      },
    })
    setModifiedState(false)
  }

  const handleCancelButton = () => {
    setEnabledRatings(entryPointEnabledRatings === 'true')
    setRatingsMessage(entryPointRatingsMessage)
    setEnableDetailedComments(allowComments === 'true')
    setModifiedState(false)
  }

  return (
    <>
      <Row>
        <Col className="chat-configuration__buttons top">
          <TopChatConfigurationButtons
            handleSaveButton={handleSaveButton}
            handleCancelButton={handleCancelButton}
            modifiedState={modifiedState}
          />
        </Col>
      </Row>
      <Row className="h-100">
        <Col md={8} className="pe-4 m-0">
          <Editable
            enabledRatings={enabledRatings}
            setEnabledRatings={setEnabledRatings}
            ratingsMessage={ratingsMessage}
            setRatingsMessage={setRatingsMessage}
            enableDetailedComments={enableDetailedComments}
            setEnableDetailedComments={setEnableDetailedComments}
            setModifiedState={setModifiedState}
          />
        </Col>
        <Col md={4} className="p-0 m-0">
          <Viewable
            enabledRatings={enabledRatings}
            ratingsMessage={ratingsMessage}
            enableDetailedComments={enableDetailedComments}
            accentColor={accentColor}
          />
        </Col>
      </Row>
      <Row>
        <Col className="chat-configuration__buttons">
          <BottomChatConfigurationButtons
            modifiedState={modifiedState}
            setModifiedState={setModifiedState}
          />
        </Col>
      </Row>
    </>
  )
}

export default ChatRatings
