/* eslint-disable no-nested-ternary */
import React from 'react'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'
import { useMembersContext } from 'routes/TeamMembers/context/TeamMembersContext'

const TableHeader = ({ headers }) => {
  const {
    companyId,
    sortUserTeamMembers,
    sortingField,
    setSortingField,
    sortingOrder,
    setSortingOrder,
  } = useMembersContext()

  const onSortingChange = (field) => {
    const order = field === sortingField && sortingOrder === 'ASC' ? 'DESC' : 'ASC'

    sortUserTeamMembers(companyId, field, order)
    setSortingField(field)
    setSortingOrder(order)
  }

  return (
    <thead>
      <tr className="team-members__table-header">
        {headers.map(({ name, field, sortable }) => (
          <th
            key={name}
            onClick={() => (sortable ? onSortingChange(field) : null)}
          >
            {name}
            {sortable
              ? sortingField === field && sortingOrder === 'ASC'
                ? (
                  <IcomoonReact
                    iconSet={iconSet}
                    icon="keyboard_arrow_up"
                    size={20}
                  />
                )
                : (
                  <IcomoonReact
                    iconSet={iconSet}
                    icon="keyboard_arrow_down"
                    size={20}
                  />
                )
              : null}
          </th>
        ))}
      </tr>
    </thead>
  )
}

export default TableHeader
