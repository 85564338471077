import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import chatRequestOperations from 'redux/chatRequest/operations'
import {
  Alert,
  Form,
} from 'react-bootstrap'
import { Formik, Field } from 'formik'
import QRMainLayout from 'features/qrflow/layouts/QRMainLayout'
import InputField from 'components/form/InputField'
import Checkbox from 'components/form/Checkbox'
import LoadingButton from 'components/buttons/LoadingButton'
import * as yup from 'yup'
import { onlyCharacters } from 'utils/regex'
import ErrorMessage from 'components/UI/ErrorMessage/ErrorMessage'
import { Redirect, useParams } from 'react-router-dom'
import useGetEntryPointDetails from 'features/qrflow/hooks/useGetEntryPointDetails'
import analytics from 'utils/analytics'
import InputPhoneField from 'components/form/InputPhoneField'
import { isValidPhoneNumber } from 'react-phone-number-input'
import useQuery from 'hooks/useQuery'
import { decodeBase64AsUnicode } from 'utils/helpers'

const PersonalInfoScreen = () => {
  const {
    data,
    error: errorMessage,
    isLoading,
  } = useGetEntryPointDetails()

  const { sbclid = '' } = useQuery()
  const [relayedConsumer, setRelayedConsumer] = useState({})

  const initialValues = {
    name: relayedConsumer.name || '',
    email: relayedConsumer.email || '',
    phone: relayedConsumer.phone || '',
    intro_message: relayedConsumer.intro_message || '',
    acceptTerms: false,
  }

  useEffect(() => {
    analytics.page()
  }, [])

  const {
    personal_page: personalPage,
    visual,
  } = data?.config ?? {}

  useEffect(() => {
    if (sbclid) {
      const parseForeignConsumer = (() => {
        try {
          return JSON.parse(decodeBase64AsUnicode(sbclid))
        } catch (e) {
          return Promise.resolve(e)
        }
      })()
      if (parseForeignConsumer) {
        // If set fields where not enabled, remove data from request because we cannot validate that data!
        if (personalPage?.name_enabled !== 'true') {
          delete parseForeignConsumer.name
        }
        if (personalPage?.email_enabled !== 'true') {
          delete parseForeignConsumer.email
        }
        if (personalPage?.phone_enabled !== 'true') {
          delete parseForeignConsumer.phone
        }
        if (personalPage?.request_help_enabled !== 'true') {
          delete parseForeignConsumer.intro_message
        }
        return setRelayedConsumer(parseForeignConsumer)
      }
    }
    return undefined
    // eslint-disable-next-line
  }, [data, sbclid])

  const acceptTerms = yup.boolean()
    .oneOf([true], 'You have to accept the terms & conditions in order to proceed.')
    .required()

  const validationObject = {
    acceptTerms,
  }

  const nameValidation = yup.string()
    .min(2, 'The full name should contain at least 2 characters.')
    .matches(onlyCharacters, 'The full name should contain only characters.')
    .required('The full name should contain at least 2 characters.')

  const emailValidation = yup.string()
    .email('The email address is not valid.')
    .required('The email address is not valid.')

  const phoneValidation = yup.string()
    .required('The phone number is required.')
    .test('validate-phone-number', (value = '', { createError, path }) => (!isValidPhoneNumber(value) ? createError({ path, message: 'The phone number is not valid.' }) : true))

  const introMessageValidation = yup.string().required('This field can not be empty.')

  if (personalPage?.name_enabled === 'true') {
    validationObject.name = nameValidation
  }
  if (personalPage?.email_enabled === 'true') {
    validationObject.email = emailValidation
  }
  if (personalPage?.phone_enabled === 'true') {
    validationObject.phone = phoneValidation
  }
  if (personalPage?.request_help_enabled === 'true') {
    validationObject.intro_message = introMessageValidation
  }

  const PersonalInfoSchema = yup.object()
    .shape(validationObject)

  const {
    requestType,
  } = useParams()

  const dispatch = useDispatch()

  const onSubmit = (formData, formik) => {
    formik.setSubmitting(true)
    dispatch(chatRequestOperations.createChatRequest(formData, requestType, data?.uid))
  }

  const invalidServiceUidOrRequestType = !(requestType && data?.uid)

  if (invalidServiceUidOrRequestType) {
    return <Redirect to="/" />
  }

  return (
    <QRMainLayout>
      <Formik
        initialValues={initialValues}
        validationSchema={PersonalInfoSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          handleSubmit, isSubmitting,
        }) => (
          <>
            <div className="text-start">
              <h2 className="qr-container__title">
                FILL OUT YOUR INFO
                <div
                  className="qr-container__title__line"
                  style={{
                    backgroundColor: visual?.accent_color,
                  }}
                />
              </h2>
              {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
              <Form>
                {personalPage?.name_enabled === 'true' ? (
                  <div className="mb-3">
                    <Field
                      autoFocus
                      type="text"
                      label="Enter your full name"
                      placeholder="Enter full name"
                      name="name"
                      inputClasses="qr-input"
                      component={InputField}
                    />
                  </div>
                ) : null}
                {personalPage?.email_enabled === 'true' ? (
                  <div className="mb-3">
                    <Field
                      type="email"
                      label="Enter your email address"
                      placeholder="Enter e-mail address"
                      name="email"
                      inputClasses="qr-input"
                      component={InputField}
                    />
                  </div>
                ) : null}
                {personalPage?.phone_enabled === 'true' ? (
                  <div className="mb-3">
                    <Field
                      type="tel"
                      label="Enter your phone number"
                      placeholder="Enter phone number"
                      name="phone"
                      inputClasses="qr-input"
                      component={InputPhoneField}
                    />
                  </div>
                ) : null}
                {personalPage?.request_help_enabled === 'true' ? (
                  <div className="mb-3">
                    <Field
                      rows={4}
                      name="intro_message"
                      type="text"
                      textarea
                      label="How can we assist you?"
                      placeholder="Enter your message"
                      inputClasses="qr-textarea"
                      component={InputField}
                    />
                  </div>
                ) : null}
                <Field
                  name="acceptTerms"
                  className="my-3"
                  label="I agree to the <a href='/terms-of-use'>terms of use</a> and
                  <a href='/privacy-policy'>privacy policy</a>, including having this conversation recorded for quality assurance purposes."
                  component={Checkbox}
                />
                <ErrorMessage name="acceptTerms" className="text-center my-2 mb-5" />
                {personalPage?.email_enabled === 'true' && (
                  <Checkbox
                    className="my-3"
                    label="I agree to receive marketing communication to my email address."
                  />
                )}
              </Form>
            </div>
            <LoadingButton
              className="qr-btn w-100 mb-5 mt-auto"
              disabled={isLoading || isSubmitting}
              loading={isLoading}
              onClick={handleSubmit}
              style={{
                backgroundColor: visual?.accent_color,
              }}
            >
              Start conversation
            </LoadingButton>
          </>
        )}
      </Formik>
    </QRMainLayout>
  )
}

export default PersonalInfoScreen
