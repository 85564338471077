import React, {
  createContext,
  useContext, useRef,
  useState,
} from 'react'
import User from 'services/User'
import Steps from 'components/ForgotPaswordDialog/steps'
import useSendVerificationEmail from 'components/ForgotPaswordDialog/hooks/useSendVerificationEmail'

const ForgotPasswordContext = createContext(null)

const ForgotPasswordProvider = ({
  onClose,
  children,
}) => {
  const [activeStep, setActiveStep] = useState(0)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [errorChangingPasswordMessage, setErrorChangingPasswordMessage] = useState('')
  const clearErrorChangingPasswordMessage = () => setErrorChangingPasswordMessage('')

  const {
    isResendingCode,
    sendVerificationCode,
    resendCodeErrorMessage,
    resendCodeSuccessMessage,
    resetResendCodeStates,
    clearResendCodeErrorMessage,
    clearResendCodeSuccessMessage,
  } = useSendVerificationEmail()

  const formRef = useRef()

  const resetForm = () => formRef?.current?.resetForm()

  const resetDialogOnExit = () => {
    setActiveStep(0)
    setIsSubmitting(false)
    setErrorChangingPasswordMessage('')
    resetResendCodeStates()
    resetForm()
  }

  const onNextStepHandler = () => setActiveStep(activeStep + 1)
  const onPreviousStepHandler = () => setActiveStep(activeStep - 1)

  const sendVerificationCodeToEmail = async (email) => {
    setIsSubmitting(true)
    try {
      await User.forgotPassword(email)
    } finally {
      // We allow the user to continue to the next step after entering his email
      // To prevent from malicious user to find out what users are using our app
      setIsSubmitting(false)
      onNextStepHandler()
    }
  }

  const changePassword = async (email, password, passwordConfirmation, token) => {
    setErrorChangingPasswordMessage('')
    setIsSubmitting(true)
    try {
      await User.resetPassword(email, password, passwordConfirmation, token)
      onNextStepHandler()
    } catch (e) {
      setErrorChangingPasswordMessage(e.message || e.error || 'Something went wrong, try please try again')
    }
    setIsSubmitting(false)
  }

  /**
   * Returns a different onSubmit function for each step
   */
  const onSubmit = async (values) => {
    switch (activeStep) {
      // In the first step, the user only sends his email, and makes a request to get a verification code
      case Steps.AccountInfoForm: {
        const { email } = values
        return sendVerificationCodeToEmail(email)
      }
      // In the second step, the user fills the change password form
      case Steps.ChangePasswordForm: {
        const {
          email,
          password,
          password_confirmation: passwordConfirmation,
          token,
        } = values
        return changePassword(email, password, passwordConfirmation, token)
      }
      // In the third and last step, the users "submit" functionality is to close the dialog and to go to the login page
      case Steps.ChangePasswordSuccessfully:
        return onClose()
      default:
        return null
    }
  }

  /**
   * Returns a different onBackButtonPress function for each step
   *
   */
  const onBackButtonPress = () => {
    switch (activeStep) {
      case Steps.AccountInfoForm:
        return onClose()
      // Allow the user to navigate back to the first step from the second step
      case Steps.ChangePasswordForm:
        resetForm()
        setErrorChangingPasswordMessage('')
        return onPreviousStepHandler()
      case Steps.ChangePasswordSuccessfully: {
        return onClose()
      }
      default:
        return onClose()
    }
  }

  const value = {
    activeStep,
    formRef,
    isResendingCode,
    resendCodeSuccessMessage,
    resendCodeErrorMessage,
    sendVerificationCode,
    onClose,
    isSubmitting,
    onSubmit,
    sendVerificationCodeToEmail,
    changePassword,
    onBackButtonPress,
    errorChangingPasswordMessage,
    clearErrorChangingPasswordMessage,
    clearResendCodeErrorMessage,
    clearResendCodeSuccessMessage,
    resetDialogOnExit,
  }
  return (
    <ForgotPasswordContext.Provider value={value}>
      {children}
    </ForgotPasswordContext.Provider>
  )
}

export const useForgotPassword = () => {
  const context = useContext(ForgotPasswordContext)
  if (context === undefined) {
    throw new Error('useForgotPassword must be used within a ForgotPasswordProvider')
  }
  return context
}

export default ForgotPasswordProvider
