import React from 'react'

const ToggleButtons = ({ switcher, setSwitcher }) => (
  <div className="pt-3 pb-2 d-flex">
    <button
      type="button"
      onClick={() => setSwitcher((prev) => !prev)}
      className={`${!switcher ? 'team-members__active' : ''}
        team-members__btn`}
    >
      Open
    </button>
    <button
      type="button"
      onClick={() => setSwitcher((prev) => !prev)}
      className={`${switcher ? 'team-members__active' : ''}
        team-members__btn team-members__pending-btn`}
    >
      Closed
    </button>
  </div>
)

export default ToggleButtons
