import React, { useCallback, useState } from 'react'
import { Alert, Col, Row } from 'react-bootstrap'
import { QrFlowRoutes } from 'features/qrflow/constants'
import QRDialog from 'features/qrflow/components/dialog/QRDialog'
import { useVideoContext } from 'providers/VideoProvider'
import useConsumerEndVideoConversation from 'features/qrflow/hooks/useConsumerEndVideoConversation'
import useGetEntryPointDetails from 'features/qrflow/hooks/useGetEntryPointDetails'
import { generatePath, useParams } from 'react-router-dom'
import LoadingButton from 'components/buttons/LoadingButton'
import ConsumerWebCamPreview from '../video/ConsumerWebcamPreview'

const JoinVideoCallDialog = ({
  token,
  roomName,
}) => {
  const [errorJoiningVideoCallMessage, setErrorJoiningVideoCallMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { serviceUid } = useParams()
  const defaultErrorMessage = (
    <span className="qr-dialog__message">
      Sorry, we couldn&apos;t connect you with one of the agents, please
      {' '}
      <a
        href={generatePath(QrFlowRoutes.start, { serviceUid })}
        className="qr-dialog__link"
      >
        click here
      </a>
      {' '}
      and create a new request again.
    </span>
  )

  const {
    data,
  } = useGetEntryPointDetails()

  const {
    accent_color: accentColor,
  } = data?.config.visual ?? {}

  const {
    connectToRoom,
    isConnectingToRoom,
  } = useVideoContext()

  const {
    isSubmitting: isEndingConversation,
    errorMessage: errorEndingConversation,
    onEndConversation,
    onClearErrorMessage,
  } = useConsumerEndVideoConversation()

  const displayErrorEndingConversation = errorEndingConversation === 'Request failed with status code 404' ? defaultErrorMessage : null

  // We cannot use async await here since it interferes with the user interactivity index, which results in the worker
  // video being blocked on some iOS browsers
  const onJoinVideoCall = useCallback(() => {
    setErrorJoiningVideoCallMessage('')
    setIsLoading(true)

    connectToRoom(token, {
      audio: false,
      video: false,
      name: roomName,
    })
      .catch((e) => {
        setErrorJoiningVideoCallMessage(
          e.error
          || e.message
          || 'There was an error connecting to the video chat, please try again later.',
        )
        setIsLoading(false)
      })
  }, [token, roomName, connectToRoom])

  // Join button will be disabled if token is null/undefined,
  // meaning that redux::chatRequest::operations::joinConversation failed OR
  return (
    <QRDialog
      show
      title="Ready to join?"
      disableIndirectClosing
      isJoinConversation
      displayButtons
    >
      <Row className="pb-3 py-0 px-0">
        <Col lg={6} md={12} sm={12}>
          <ConsumerWebCamPreview />
        </Col>
        <Col className="d-flex justify-content-between align-items-center m-0 p-0 row" lg={6} md={12} sm={12}>
          <Row>
            {
              errorJoiningVideoCallMessage && (
                <Alert
                  variant="danger"
                  dismissible
                  onClose={() => setErrorJoiningVideoCallMessage('')}
                  className="p-3"
                >
                  {errorJoiningVideoCallMessage}
                </Alert>
              )
            }
            {
              displayErrorEndingConversation && (
                <Alert
                  variant="danger"
                  dismissible
                  onClose={onClearErrorMessage}
                  className="p-3"
                >
                  {displayErrorEndingConversation}
                </Alert>
              )
            }

            <p className="m-0 p-0 py-3 text-center">Your conversation request has been accepted.</p>
            <p className="m-0 p-0 py-3 fw-bold text-center">Click join to continue.</p>
          </Row>
          <Row className="m-0 p-0 gap-3 align-items-center justify-content-center">
            <LoadingButton
              onClick={onEndConversation}
              className={
                `qr-dialog__button qr-dialog__button--white
             ${isEndingConversation ? 'qr-dialog__button--white--disabled' : ''}          `
              }
              disabled={isEndingConversation || isLoading || !token || !roomName}
              loading={isEndingConversation}
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              onClick={onJoinVideoCall}
              className="qr-dialog__button"
              style={{
                backgroundColor: accentColor,
              }}
              loading={isConnectingToRoom || isLoading}
              disabled={!token || isConnectingToRoom || isLoading || errorJoiningVideoCallMessage}
            >
              Join
            </LoadingButton>
          </Row>
        </Col>
      </Row>
    </QRDialog>
  )
}

export default JoinVideoCallDialog
