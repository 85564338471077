import React from 'react'
import { Form } from 'react-bootstrap'

const Checkbox = ({
  label, className, field = {},
}) => (
  <Form.Check type="checkbox" className={className}>
    <Form.Check.Input type="checkbox" {...field} />
    <Form.Check.Label dangerouslySetInnerHTML={{ __html: label }} />
  </Form.Check>
)

export default Checkbox
