import React from 'react'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'

const CircleInfo = ({
  size = 20,
  className,
  onClick,
}) => (
  <IcomoonReact
    iconSet={iconSet}
    icon="circle-info"
    size={size}
    className={className}
    onClick={onClick}
  />
)

export default CircleInfo
