import Steps from 'components/ForgotPaswordDialog/steps'

const initialValuesByStep = {
  [Steps.AccountInfoForm]: {
    email: '',
  },
  [Steps.ChangePasswordForm]: {
    token: '',
    password: '',
    password_confirmation: '',
  },
}

export default function useInitialValuesPerStep(activeStep) {
  return initialValuesByStep[activeStep]
}
