// Add Application Routes
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import SignIn from 'routes/SignIn'
import SignUp from 'routes/SignUp'
import AcceptInvite from 'routes/AcceptInvite'
import InviteSingUp from 'routes/SignUp/InviteSingUp'
import ProjectInfoConfirmation from 'routes/ProjectInfoConfirmation'
import Dashboard from 'routes/Dashboard'
import AdminDashboard from 'routes/AdminDashboard/AdminDashboard'
import WidgetScreen from 'features/qrflow/screens/WidgetScreen'
import ProtectedRoute from 'routes/ProtectedRoute'
import Conversation from 'routes/ChatConversation'
import TeamMembers from 'routes/TeamMembers/TeamMembers'
import Billing from 'routes/Billing'
import Checkout from 'routes/Checkout/index'
import NotFound from 'components/notFound/NotFound'
import MainLayout from 'layouts/MainLayout'
import CheckoutLayout from 'layouts/CheckoutLayout'
import ChatConfigurationLayout from 'layouts/ChatConfigurationLayout'
import LandingScreen from 'features/qrflow/screens/LandingScreen'
import ChatRoot from 'features/qrflow/screens/ChatRoot'
import PersonalInfoScreen from 'features/qrflow/screens/PersonalInfoScreen'
import QueueScreen from 'features/qrflow/screens/QueueScreen'
import ConversationScreen from 'features/qrflow/screens/ConversationScreen'
import RatingScreen from 'features/qrflow/screens/RatingScreen'
import { QrFlowRoutes, WorkerRoutes } from 'features/qrflow/constants'
import ThankYouScreen from 'features/qrflow/screens/ThankYouScreen'
import CallEnded from 'features/qrflow/screens/CallEnded'
import ChatSettingsEntryPoint from 'routes/ChatSettingsEntryPoint'
import ChatConfiguration from 'routes/ChatSettingsEntryPoint/components/ChatConfiguration'
import ProfileSettings from 'routes/ProfileSettings'
import Feedback from 'routes/Feedback'
import Conversations from 'routes/Conversations'
import PersonalInfoSetup from 'routes/OnBoardingInfo/PersonalInfo'
import ProjectInfoSetup from 'routes/OnBoardingInfo/ProjectInfo'
import VerifyEmail from 'routes/VerifyEmail'
import AccountSetup from 'routes/AccountSetup'
import SetupNewProject from 'routes/AccountSetup/SetupNewProject'
import JoinExistingProject from 'routes/AccountSetup/JoinExistingProject'
import TermsOfUse from 'routes/TermsAndPrivacy/TermsOfUse'
import PrivacyPolicy from 'routes/TermsAndPrivacy/PrivacyPolicy'
import MessagesLeft from 'routes/MessagesLeft'
import LeaveMessageScreen from 'features/qrflow/screens/LeaveMessageScreen'
import NewScreenShare from 'routes/NewScreenShare'
import ScreenShareScreen from 'features/screenshare'

const Role = {
  Admin: 'manager',
  User: 'worker',
}

export default function Routes() {
  return (
    <Switch>
      <Route
        path="/"
        exact
        component={SignIn}
      />
      <Route
        path="/signup"
        exact
        component={SignUp}
      />
      <Route
        path="/invite"
        exact
        component={AcceptInvite}
      />
      <Route
        path="/invite/signup"
        exact
        component={InviteSingUp}
      />
      <Route
        path="/personal-info"
        exact
        component={PersonalInfoSetup}
      />
      <Route
        path="/verify-email"
        exact
        component={VerifyEmail}
      />
      <Route
        path="/account-setup"
        exact
        component={AccountSetup}
      />
      <Route
        path="/account-setup/new"
        exact
        component={SetupNewProject}
      />
      <Route
        path="/account-setup/existing"
        exact
        component={JoinExistingProject}
      />
      <Route
        path="/project-info"
        exact
        component={ProjectInfoSetup}
      />
      <Route
        path="/setup-completed"
        exact
        component={ProjectInfoConfirmation}
      />
      <Route
        path="/widget-call/:serviceUid/:chatRequestId"
        component={WidgetScreen}
      />
      <Route
        path={`${QrFlowRoutes.start}`}
        component={LandingScreen}
      />
      <Route
        path={QrFlowRoutes.personalInfo}
        component={PersonalInfoScreen}
      />
      <Route
        path={QrFlowRoutes.queue}
        component={QueueScreen}
      />
      <Route
        path={QrFlowRoutes.videoText}
        component={ConversationScreen}
      />
      <Route
        path={QrFlowRoutes.text}
        component={ConversationScreen}
      />
      <Route
        path={QrFlowRoutes.rating}
        component={RatingScreen}
      />
      <Route
        path={QrFlowRoutes.thankYou}
        component={ThankYouScreen}
      />
      <Route
        path={QrFlowRoutes.callEnded}
        component={CallEnded}
      />
      <Route
        path={QrFlowRoutes.leaveMessage}
        component={LeaveMessageScreen}
      />
      <Route
        exact
        path="/screenshare/:meetingLink"
        component={ScreenShareScreen}
      />
      <Route
        path="/terms-of-use"
        exact
        component={TermsOfUse}
      />
      <Route
        path="/privacy-policy"
        exact
        component={PrivacyPolicy}
      />
      <ProtectedRoute
        path={WorkerRoutes.dashboard}
        layout={MainLayout}
        component={Dashboard}
      />
      <ProtectedRoute
        path={WorkerRoutes.adminDashboard}
        layout={MainLayout}
        userRole={[Role.Admin]}
        component={AdminDashboard}
      />
      <ProtectedRoute
        path={WorkerRoutes.conversation}
        layout={MainLayout}
        component={Conversation}
      />
      <ProtectedRoute
        path={WorkerRoutes.conversations}
        layout={MainLayout}
        userRole={[Role.Admin]}
        component={Conversations}
      />
      <ProtectedRoute
        path={WorkerRoutes.messagesLeft}
        layout={MainLayout}
        userRole={[Role.Admin]}
        component={MessagesLeft}
      />
      <ProtectedRoute
        path={WorkerRoutes.chatSettings}
        layout={MainLayout}
        userRole={[Role.Admin]}
        component={ChatSettingsEntryPoint}
      />
      <ProtectedRoute
        path={WorkerRoutes.chatConfiguration}
        layout={ChatConfigurationLayout}
        userRole={[Role.Admin]}
        component={ChatConfiguration}
      />
      <ProtectedRoute
        path={WorkerRoutes.profileSettings}
        layout={MainLayout}
        component={ProfileSettings}
      />
      <ProtectedRoute
        exact
        path={WorkerRoutes.screenshareLink}
        layout={MainLayout}
        component={NewScreenShare}
      />
      <ProtectedRoute
        path={WorkerRoutes.teamMembers}
        layout={MainLayout}
        userRole={[Role.Admin]}
        component={TeamMembers}
      />
      <ProtectedRoute
        path={WorkerRoutes.billing}
        layout={MainLayout}
        userRole={[Role.Admin]}
        component={Billing}
      />
      <ProtectedRoute
        path={WorkerRoutes.checkout}
        layout={CheckoutLayout}
        userRole={[Role.Admin]}
        component={Checkout}
      />
      <ProtectedRoute
        path={WorkerRoutes.feedback}
        layout={MainLayout}
        component={Feedback}
      />
      {/* <ProtectedRoute */}
      {/*   path="/faq" */}
      {/*   layout={MainLayout} */}
      {/*   component={Dashboard} */}
      {/* /> */}
      {/* <ProtectedRoute */}
      {/*   path="/shifts" */}
      {/*   layout={MainLayout} */}
      {/*   component={Dashboard} */}
      {/* /> */}
      <Route
        path={QrFlowRoutes.chatRoot}
        component={ChatRoot}
        exact
      />
      <Route
        component={NotFound}
      />
    </Switch>
  )
}
