/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/void-dom-elements-no-children */
import React, { useEffect, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import IcomoonReact from 'icomoon-react'
// import { Scrollbars } from 'react-custom-scrollbars'
import iconSet from 'assets/fonts/selection.json'
import useFormData from 'hooks/useFormData'
import FilePreview from 'components/chat/FilePreview'
import { isMobile } from 'utils/helpers'
import Message from './Message'

const MessageList = ({ messages, handleSendMessage, maxHeight }) => {
  const inputRef = useRef(null)
  const [message, setMessage] = useState('')

  const {
    fileFormData, preview, fileData, handleFileChange, setFileFormData, setPreview,
  } = useFormData()
  /**
   * Send message to the API
   * on keypress (enter)
   * @param e
   * @param from
   */
  const handleMessageChange = (e, from = null) => {
    if (message !== '' || fileFormData instanceof FormData) {
      if (from === null) {
        if (e.keyCode === 13) {
          handleSendMessage(message, fileFormData)
          setMessage('')
          setFileFormData('')
          setPreview('')
          inputRef.current.value = ''
        }
      } else {
        handleSendMessage(message, fileFormData)
        setMessage('')
        setFileFormData('')
        setPreview('')
        inputRef.current.value = ''
      }
    }
  }

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef()
    useEffect(() => elementRef.current.scrollIntoView({ behavior: 'smooth' }))
    return <div ref={elementRef} />
  }

  return (
    <Col md={12} xs={12} className="h-100" style={{ maxHeight: maxHeight || '68vh' }}>
      <Row className={`d-flex m-0 p-0 overflow-auto chat-message-dashboard__messages ${isMobile ? 'mobile' : ''}`}>
        <div className="w-100" style={{ maxHeight: '60vh' }}>
          {messages.map((value) => (
            <Message message={value} key={value.sid} />
          ))}
          <AlwaysScrollToBottom />
        </div>
      </Row>
      <Row className="m-0 p-0" id="input-messages">
        <Col md={1} xs={1} className="ms-0 p-0 m-auto">
          <label htmlFor="upload-button">
            <IcomoonReact
              iconSet={iconSet}
              color="#767676"
              size={20}
              icon="icon-add"
              role="button"
              className="d-flex align-items-center mt-3"
            />
          </label>
          <input
            type="file"
            id="upload-button"
            style={{ display: 'none' }}
            onChange={(e) => handleFileChange(e)}
          />
        </Col>
        <Col md={10} xs={10} className="m-0 p-0 ps-2">
          <div style={{
            boxShadow: '0px 2px 16px rgba(36, 54, 101, 0.1)',
            borderRadius: '10px',
          }}
          >
            <input
              type="text"
              placeholder="Enter Your Message Here"
              className="send-message br-2 bg-white w-100 shadow-default"
              ref={inputRef}
              onKeyDown={(e) => handleMessageChange(e)}
              onInput={(e) => setMessage(e.target.value)}
              style={{
                borderLeft: 'none',
                borderRight: 'none',
                borderTop: 'none',
                borderBottom: '1px solid #F5F5F5',
              }}
            />
            {preview && <FilePreview preview={preview} fileData={fileData} setPreview={setPreview} setFileFormData={setFileFormData} />}
          </div>
        </Col>
        <Col md={1} xs={1} className="m-0 p-0 text-center d-flex align-items-center justify-content-center">
          <IcomoonReact
            iconSet={iconSet}
            color="#767676"
            size={20}
            icon="icon-send"
            role="button"
            onClick={(e) => (handleMessageChange(e, 'button'))}
          />
        </Col>
      </Row>
    </Col>
  )
}

export default MessageList
