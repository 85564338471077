import { useAuth } from 'providers/AuthProvider/AuthProvider'
import { useSelector } from 'react-redux'
import { CustomerInfoOptions } from 'utils/constants'
import { getLastNameInitials } from 'utils/helpers'

const useCustomerName = () => {
  const {
    customer_full_name: customerFullName,
  } = useSelector((state) => state.company?.data?.chat_setting) ?? { customer_full_name: CustomerInfoOptions.NONE }
  const {
    userRole,
  } = useAuth()
  return (name) => {
    if (customerFullName === CustomerInfoOptions.ALL || customerFullName?.startsWith(userRole)) {
      return name
    }
    return getLastNameInitials(name)
  }
}
export default useCustomerName
