import { useDispatch, useSelector } from 'react-redux'
import userOperations from 'redux/user/operation'
import useErrorMessageFromObject from 'hooks/useErrorMessageFromObject'
import { ActionState } from 'utils/constants'
import { useAuth } from 'providers/AuthProvider/AuthProvider'

/**
 * Contains logic for user User Settings Form form in Profile Settings
 */
export default function useUserSettingsForm() {
  const {
    data: userData,
    updateUserSettings,
  } = useSelector((state) => state.user) || {}
  const { updateUser } = useAuth()

  const errorMessage = useErrorMessageFromObject(updateUserSettings)

  const isUpdatingUserSettings = updateUserSettings === ActionState.InProgress

  const {
    id,
    full_name: fullName,
    avatar,
    email,
  } = userData || {}

  const initialValues = {
    id,
    full_name: fullName,
    avatar,
    email,
  }

  const dispatch = useDispatch()

  const onUpdateUserSettings = (formData) => dispatch(userOperations.updateUserSettings(formData, updateUser))

  return {
    initialValues,
    isUpdatingUserSettings,
    onUpdateUserSettings,
    errorMessage,
  }
}
