import React from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'
import WorkerDialog from 'components/WorkerDialog'
import useCropImage from 'hooks/useCropImage'

// when upgrade to version 10 of react-image-crop
// https://github.com/DominicTobias/react-image-crop/releases/tag/10.0.0-beta.0
// https://codesandbox.io/s/react-image-crop-demo-with-react-hooks-y831o?file=/src/App.tsx:3771-3845
const EditPhoto = ({ show, onClose, handleChange }) => {
  const {
    uploadedImage,
    croppedImage,
    crop,
    setCrop,
    onSelectFile,
    onLoad,
    onCropComplete,
  } = useCropImage()

  const onSubmit = () => {
    const formData = new FormData()
    formData.append(
      'profile_pic',
      croppedImage.current,
      croppedImage.current.name,
    )
    const fileImage = formData.get('profile_pic')
    handleChange(fileImage)
    onClose()
  }

  return (
    <WorkerDialog
      show={show}
      onClose={onClose}
      title="Edit Photo"
      confirmText="Save"
      onConfirm={onSubmit}
      disabled={!uploadedImage}
    >
      <div className="react-crop">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
          type="file"
          className="react-crop__upload"
        >
          <input
            type="file"
            onChange={onSelectFile}
            src={uploadedImage}
            accept="image/*"
            id="file"
            className="react-crop__profile-photo__input"
          />
          <IcomoonReact
            iconSet={iconSet}
            icon="upload"
            size="30"
            className="react-crop__upload"
          />
          <p>Click to select a file</p>
        </label>
        {!uploadedImage || (
        <div className="react-crop__preview">
          <ReactCrop
            src={uploadedImage}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={onCropComplete}
            circularCrop
            scale={1}
          />
        </div>
        )}
      </div>
    </WorkerDialog>
  )
}

export default EditPhoto
