import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { ToastKey } from 'routes/ChatSettingsEntryPoint/constants'
import { QueryKeys } from 'utils/constants'
import Company from 'services/Company'

export default function useCreateEntryPoint() {
  const {
    addToast,
    removeToast,
  } = useToasts()

  const companyId = useSelector((state) => state.company.data?.id)
  const queryClient = useQueryClient()

  const addEntryPoint = useMutation(
    (values) => Company.createCompanyEntryPoint(values.name, String(companyId)), {
      onSuccess: (data) => {
        queryClient.setQueryData([QueryKeys.ENTRY_POINT], (oldEntryPoints) => [...oldEntryPoints, data])
      },

      onError: (error) => {
        removeToast(ToastKey.entryPointError)
        addToast(`Entry Point: ${error.message}`, {
          appearance: 'error',
          autoDismiss: 2000,
          id: ToastKey.entryPointError,
        })
      },
    },
  )
  return {
    addEntryPoint,
  }
}
