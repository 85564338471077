import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAuth } from 'providers/AuthProvider/AuthProvider'
import { useQueryClient } from '@tanstack/react-query'
import { QueryKeys } from 'utils/constants'
import { useToasts } from 'react-toast-notifications'

export default function useCompanyChannel() {
  const companyId = useSelector((state) => state.company.data?.id)
  const { cableConsumer, isAdmin } = useAuth()
  const queryClient = useQueryClient()
  const { addToast } = useToasts()

  useEffect(() => {
    async function subscribe() {
      if (companyId && cableConsumer) {
        cableConsumer.ensureActiveConnection()

        const subscription = cableConsumer.subscriptions.create(
          {
            company_id: companyId,
            channel: 'CompanyChannel',
          },
          {
            received(response) {
            // Invoked when we get an array of active users
              if (Array.isArray(response)) {
                const punchedInIDs = response.map(({ id }) => id)
                queryClient.setQueryData([QueryKeys.PUNCHED_IN_USERS], punchedInIDs)
              }

              if (response?.intent === 'notify-work-log-change') {
                const {
                  user_id: id,
                } = response.message

                if (response?.message.status === 'active') {
                  const punchedInUsers = queryClient.getQueryData([QueryKeys.PUNCHED_IN_USERS])
                  const addPunchedInUsers = punchedInUsers ? Array.from(new Set([...punchedInUsers, id])) : []
                  queryClient.setQueryData([QueryKeys.PUNCHED_IN_USERS], addPunchedInUsers)
                }

                if (response?.message.status === 'inactive') {
                  const punchedInUsers = queryClient.getQueryData([QueryKeys.PUNCHED_IN_USERS])
                  const removePunchedInUsers = punchedInUsers?.filter((userId) => userId !== id)
                  queryClient.setQueryData([QueryKeys.PUNCHED_IN_USERS], removePunchedInUsers)
                }
              }
              if (response?.intent === 'notify-composition-ready' && isAdmin) {
                const { user } = response?.message
                // eslint-disable-next-line max-len
                addToast(`The requested recording for the worker ${user} is now ready, please check the conversation details again.`,
                  {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                  })
              }

              if (response?.event && response.event.startsWith('chat:')) {
                queryClient.setQueryData([QueryKeys.PENDING_CHAT_REQUESTS], JSON.parse(response.conversations.pending))
                queryClient.setQueryData([QueryKeys.ACCEPTED_CHAT_REQUESTS], JSON.parse(response.conversations.active))
              }
            },
            connected() {
              console.log('Connected to Company Channel')
              // Called when the subscription is ready for use on the server

              subscription.perform('punched_in_users')
            },
            disconnected() {
              console.log('Disconnected from Company Channel')
            // Called when the subscription has been terminated by the server
            },
          },
        )
        return () => subscription?.unsubscribe()
      }
      return undefined
    }
    subscribe()
  }, [companyId, cableConsumer, queryClient, addToast, isAdmin])
}
