/* eslint-disable max-len */
export const ChatBasicsConst = {
  color: {
    title: 'Accent Color',
    subTitle: 'Pick a high contrast color that best suits your branding.',
  },
  logo: {
    title: 'Logo Upload',
    subTitle: `Add up to two logos that will show up in your chat.
    For best results, we recommend a logo version which looks best on white background.`,
  },
  welcomeMessage: {
    title: 'Welcome Message',
    subTitle: 'Write a introduction message which will show up on the first screen of the chat. Max. 400 characters.',
    message: `Welcome to Google Customer Support!

My name is Maria, one of our brand experts!
Feel free to type, or for a smoother conversation, click the microphone below my video and we can voice chat! What can I assist you with today?`,
  },
}

export const ChatIntroConst = {
  landingMessage: {
    title: 'Landing Message',
    subTitle: 'Write a introduction message which will show up on the first screen of the chat. Max. 400 characters.',
    message: `Welcome to our customer support!

Have a specific question?

You're in luck!

Connect with one of our friendly, live video experts for a personalised shopping experience!`,
  },
  videoChatEnabled: {
    title: 'Video Chat Enabled',
    subTitle: `This is the awesome power of Swytchboard! Don't want customers to select video right away? Turn this off.
They can still request a video feed from the Live Chat option once engaged in conversation.`,
  },
  liveChatEnabled: {
    title: 'Live Chat Enabled',
    subTitle: 'If you don\'t want messaging chat on your website, disable this option.',
  },
  awayMessage: {
    title: 'Away Message',
    subTitle: `Write an away message for customers if no worker is logged in to Swytchboard,
    only the redirect URL will appear. Max. 400 characters.`,
    message: `Hi!

Our customer support team is available 8a-4:30p central US time daily.Please come back then and see us during those hours. Thanks!`,
  },
  websiteLink: {
    title: 'Website Link',
    subTitle: 'Whether you\'re available or not, a link your customers can navigate to at any time from this landing page.',
    link: 'https://swytchboard.com',
  },
  callToActionText: {
    title: 'Website Link Label',
    subTitle: 'Add a custom text for the website link label.',
  },
}

export const ChatPersonalInfoConst = {
  requestName: {
    title: 'Request Name',
    subTitle: 'It\'s nice to know your customer\'s name before connecting with them!',
  },
  requestEmailAddress: {
    title: 'Request Email Address',
    subTitle: 'This helps you follow-up with specific customers post call, if needed, and if you\'d like to include opt-in for promotional updates.',
  },
  requestPhoneNumber: {
    title: 'Request Phone Number',
    subTitle: 'This helps you follow-up with specific customers post call, if needed.',
  },
  requestHelpInformation: {
    title: 'Request Help Information',
    subTitle: 'This helps workers better understand your customer\'s needs prior to the call. If you don\'t want this, disable this option.',
  },
}

export const ChatQueueConst = {
  queueMessage: {
    title: 'Queue Message',
    subTitle: 'Write a queue message which will show up while the customer is waiting in queue. Max. 400 characters.',
    message: `Please be patient while we connect you to your very own personal expert!

You’ll be able to type or voice chat, while our expert assists live from their camera.

We’ll automatically redirect you once your expert is available!`,
  },
  customerInactivity: {
    title: 'Customer Inactivity',
    subTitle: 'If a customer is inactive for a certain amount of time, they will be automatically removed from the queue.',
  },
  allowLeaveMessage: {
    title: 'Allow Leave Message',
    subTitle: 'If you don\'t want to allow customers to leave a message when no worker is logged in to Swytchboard, disable this option.',
  },
}

export const ChatRatingsConst = {
  enableRatings: {
    title: 'Enable Ratings',
    subTitle: 'Ask a customer to rate their experience post engagement. If disabled, it will be harder to monitor quality assurance.',
  },
  ratingsMessage: {
    title: 'Ratings Message',
    subTitle: 'Write a ratings message which will show up on the rating screen of the chat. Max. 400 characters.',
    message: `We'd love to hear your feedback in order to continue providing our customers the best experience possible.

Please take a moment to review our brand expert, Maria.`,
  },
  enableDetailedComments: {
    title: 'Enable Detailed Comments',
    subTitle: 'If you don\'t want to receive detailed comments as part of the rating process, disable this option.',
  },
}

export const ChatThanksConst = {
  enableThankYou: {
    title: 'Enable a Thanks',
    subTitle: '[copy to be added]',
  },
  thankYouLink: {
    title: 'Redirect Link',
    subTitle: 'Redirect your customers to a website you want.',
    link: 'https://swytchboard.com',
  },
  thankYouLabel: {
    title: 'Redirect Link Label',
    subTitle: 'Add a custom text for the website redirect link label.',
    defaultValue: 'Close',
  },
  thankYouMessage: {
    title: 'Thank You Message',
    subTitle: 'Write a thanks message for your customer engaging with you. Feel free to include a discount code, or just a genuine thank you! Max. 400 characters.',
    message: `Thanks for reviewing your experience!

We hope you enjoyed talking with our expert.`,
  },
  thankYouButton: {
    title: 'Redirect Button',
    subTitle: 'Add a custom text for the redirect button.',
  },
}

export const chatConfigurationSteps = Object.freeze({
  VISUAL: 'visual',
  TEXT_CHAT: 'text_chat',
  VIDEO_CHAT: 'video_chat',
  LANDING_PAGE: 'landing_page',
  PERSONAL_PAGE: 'personal_page',
  QUEUE: 'queue',
  RATINGS: 'ratings',
  THANKYOU_PAGE: 'thankyou_page',
  ROUTING: 'routing',
  THANKYOU_PAGE_CLOSE_LINK: 'thankyou_page_close_link',
})

export const COMPANY_ATTRIBUTES_NAME = 'company_attributes.name'
export const COMPANY_DETAILS_ATTRIBUTES = 'company_attributes.company_detail_attributes'
export const CHAT_SETTINGS_ATTRIBUTES = 'company_attributes.chat_setting_attributes'

// We use these keys as unique id's for the toasts to prevent having multiple toasts with the same message
export const ToastKey = {
  entryPointError: 'entryPointError',
}

export const RATE_YOUR_EXPERIENCE = 'RATE YOUR EXPERIENCE'
export const TEXT_CHAT = 'Live Text Chat Only'
export const VIDEO_CHAT = 'Live Chat with Video'
export const ALLOW_LEAVE_MESSAGE = 'Leave a Message'
