import config from 'utils/config'
import { SWYTCHBOARD_LOCALSTORAGE_KEY } from 'utils/constants'
import http from 'utils/http'

const API_URL = '/api/'

class Auth {
  login = (email, password) => (
    http
      .post(`${config.SERVER_URL}${API_URL}login`, {
        user: {
          email,
          password,
        },
      })
  )

  logout = () => {
    localStorage.removeItem('user')
    localStorage.removeItem(SWYTCHBOARD_LOCALSTORAGE_KEY)
    localStorage.removeItem('isRememberMeChecked')
    window.location.replace('/')
  }
}

export default new Auth()
