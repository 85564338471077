import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import PlanContent from './Content'

const PlanWrapper = ({ plans }) => {
  const [selected, setSelected] = useState(null)
  const [isSending, setIsSending] = useState(false)

  // Note: if needed, join monthly with yearly plans.
  const checkoutPlans = plans.monthly_plans

  const planContent = checkoutPlans.map((plan) => (
    <Col className="col-md col-sm" key={plan.id}>
      <PlanContent
        plan={plan}
        selected={selected}
        setSelected={setSelected}
        isSending={isSending}
        setIsSending={setIsSending}
      />
    </Col>
  ))

  return (
    <>
      <Row className="mb-5 justify-content-center">
        { planContent }
      </Row>
    </>
  )
}

export default PlanWrapper
