import React, { useEffect } from 'react'
import useActiveConversations from 'hooks/useActiveConversations'
import { useVideoContext } from 'providers/VideoProvider'
import ParticipantThumbnail from 'components/Room/ParticipantThumbnail'
import useRemoteParticipants from 'hooks/useRemoteParticipants/useRemoteParticipants'
import useMainParticipant from 'hooks/useMainParticipant/useMainParticipant'
import { useDispatch, useSelector } from 'react-redux'
import { isMobile } from 'utils/helpers'
import useDialog from 'hooks/useDialog'
import { setIsChatHidden } from 'redux/layout/actions'
import useFlipCameraToggle from 'hooks/useFlipCameraToggle/useFlipCameraToggle'
import VideoControls from '../VideoControls/VideoControls'
import ToggleAudioButton from '../VideoControlsButton/ToggleAudioButton/ToggleAudioButton'
import ToggleVideoButton from '../VideoControlsButton/ToggleVideoButton/ToggleVideoButton'
import Room from '../Room'
import ToggleSettingsButton from '../VideoControlsButton/ToggleSettingsButton/ToggleSettingsButton'
import MaximizeIcon from './MaximizeIcon'
import MoreOptionsIcon from './MoreOptionsIcon'
import MoreOptionsModal from './MoreOptionsModal'
import FlipCamera from './FlipCamera'
/*
 * TODO: Generalize it, wrap Twilio around this wrapper
 * @returns {JSX.Element}
 * @constructor
 */
const MobileVideoChat = () => {
  const { room } = useVideoContext()
  const dispatch = useDispatch()
  const { roomToken, roomName, id: acceptedRequest } = useActiveConversations()
  const { hasAnyParticipant, isParticipantDisconnected } = useRemoteParticipants()
  const workerName = useSelector((state) => state?.user?.data?.full_name)
  const isChatHidden = useSelector((state) => state.layout.chatHidden)
  const { flipCameraDisabled, flipCameraSupported, toggleFacingMode } = useFlipCameraToggle()

  const {
    onOpenDialog,
    onCloseDialog,
    displayDialog,
  } = useDialog()
  const localParticipant = room?.localParticipant
  const mainParticipant = useMainParticipant()

  useEffect(() => () => room?.disconnect(), [room])
  return (
    <>
      {roomToken && roomName && acceptedRequest && <Room token={roomToken} roomName={roomName} />}
      <VideoControls>
        {(isMobile && hasAnyParticipant && !isParticipantDisconnected) && (
          <ParticipantThumbnail
            participant={localParticipant}
            isTwoWayEnabled
            localParticipant={localParticipant}
            mainParticipant={mainParticipant}
            participantName={workerName}
            isWorkerMobile
          />
        )}
        <ToggleAudioButton />
        <ToggleVideoButton />
        {!isMobile && <ToggleSettingsButton />}
        {flipCameraSupported && <FlipCamera disabled={flipCameraDisabled} onClick={toggleFacingMode} />}
        {isMobile && <MaximizeIcon onClick={() => dispatch(setIsChatHidden())} disabled={isChatHidden} />}
        {isMobile && <MoreOptionsIcon onClick={onOpenDialog} />}
        <MoreOptionsModal show={displayDialog} close={onCloseDialog} />
      </VideoControls>
    </>
  )
}

export default MobileVideoChat
