import React from 'react'
import LogosPlaceholder from 'features/qrflow/components/LogosPlaceholder'
import useHeight from 'features/qrflow/hooks/useHeight'
import poweredBy from 'assets/images/mct/poweredby.svg'

const QRMainLayout = ({ children }) => {
  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight()

  return (
    <div className="qr-container" style={{ height }}>
      <div className="mb-5">
        <LogosPlaceholder />
      </div>
      <div className="flex-grow-1 d-flex flex-column">
        {children}
      </div>
      <div className="mx-auto mt-auto">
        <img src={poweredBy} alt="SwytchBoard" />
      </div>
    </div>
  )
}

export default QRMainLayout
