import ConversationTypes from '../conversation.types'

export const setConversationSID = (id) => ({
  type: ConversationTypes.SET_CONVERSATION_SID,
  conversationSID: id,
})

export const resetConversationSID = () => ({
  type: ConversationTypes.RESET_CONVERSATION_SID,
  conversationSID: null,
})
