import constants from './constants'

const setUser = (user) => ({
  type: constants.SET_USER,
  payload: user,
})

const resetUser = () => ({
  type: constants.RESET_USER,
  payload: null,
})

const updateUser = (user) => ({
  type: constants.UPDATE_USER,
  payload: user,
})

const updateUserSettings = () => ({
  type: constants.UPDATE_USER_SETTINGS,
})

const updateUserSettingsSuccess = (userSettings) => ({
  type: constants.UPDATE_USER_SETTINGS_SUCCESS,
  payload: userSettings,
})

const updateUserSettingsError = (error) => ({
  type: constants.UPDATE_USER_SETTINGS_ERROR,
  payload: error,
})

const changePassword = () => ({
  type: constants.CHANGE_PASSWORD,
})

const changePasswordSuccess = () => ({
  type: constants.CHANGE_PASSWORD_SUCCESS,
})

const changePasswordError = (error) => ({
  type: constants.CHANGE_PASSWORD_ERROR,
  payload: error,
})

const clearChangePasswordError = () => ({
  type: constants.CLEAR_CHANGE_PASSWORD_ERROR,
})

export default {
  setUser,
  updateUser,
  resetUser,
  updateUserSettings,
  updateUserSettingsSuccess,
  updateUserSettingsError,
  changePassword,
  changePasswordSuccess,
  changePasswordError,
  clearChangePasswordError,
}
