import React from 'react'
import { Formik, Field as FormikField } from 'formik'
import * as yup from 'yup'
import { Alert } from 'react-bootstrap'
import InputField from 'components/form/InputField'
import LoadingButton from 'components/buttons/LoadingButton'
import Field from 'routes/ProfileSettings/Field'
import passwordConfirmationSchema from 'schemas/passwordConfirmationSchema'
import useChangePasswordForm from 'routes/ProfileSettings/hooks/useChangePasswordForm'
import { alphaNumeric } from 'utils/regex'

const PasswordSettingsSchema = yup.object()
  .shape({
    old_password: yup.string()
      .required('Old password is required')
      .min(8, 'Password is too short - should be 8 chars minimum')
      .matches(alphaNumeric, 'Password must be alphanumeric'),
    ...passwordConfirmationSchema,
  })

const initialValues = {
  old_password: '',
  password: '',
  password_confirmation: '',
}

const ChangePasswordForm = () => {
  const {
    errorMessage,
    successMessage,
    isChangingPassword,
    onClearErrorMessage,
    onClearSuccessMessage,
    onChangePassword,
    changePasswordFormRef,
  } = useChangePasswordForm()

  return (
    <Formik
      innerRef={changePasswordFormRef}
      validationSchema={PasswordSettingsSchema}
      initialValues={initialValues}
      onSubmit={onChangePassword}
    >
      {({
        isValid,
        dirty,
        handleSubmit,
      }) => {
        const isSubmitDisabled = isChangingPassword
          || !(isValid && dirty)

        return (
          <Field
            labelTitle="Password Change"
            labelSubtitle="Choose a new password here."
            formControl={(
              <>
                <div className="profile-settings__change-password">
                  <div className="mb-3 d-flex justify-content-between">
                    <div>
                      <FormikField
                        placeholder="Enter your old password"
                        type="password"
                        inputClasses="profile-settings__field__input"
                        component={InputField}
                        name="old_password"
                      />
                    </div>
                  </div>
                  <div className="d-flex">
                    <div>
                      <FormikField
                        placeholder="Enter a new password"
                        name="password"
                        type="password"
                        inputClasses="profile-settings__field__input"
                        component={InputField}
                      />
                    </div>
                    <div className="ms-3">
                      <FormikField
                        placeholder="Confirm your new password"
                        type="password"
                        inputClasses="profile-settings__field__input"
                        component={InputField}
                        name="password_confirmation"
                      />
                    </div>
                  </div>
                  <LoadingButton
                    className="profile-settings__change-password__button align-self-start mt-2"
                    onClick={handleSubmit}
                    loading={isChangingPassword}
                    disabled={isSubmitDisabled}
                  >
                    Change Password
                  </LoadingButton>
                </div>
                {errorMessage && (
                  <Alert
                    variant="danger"
                    dismissible
                    onClose={onClearErrorMessage}
                  >
                    {errorMessage}
                  </Alert>
                )}
                {successMessage && (
                  <Alert
                    variant="success"
                    dismissible
                    onClose={onClearSuccessMessage}
                  >
                    {successMessage}
                  </Alert>
                )}
              </>
            )}
          />
        )
      }}
    </Formik>
  )
}

export default ChangePasswordForm
