import React from 'react'
import {
  Button, Col, Row,
} from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

/* eslint-disable-next-line */
export function ErrorFallback({ error, resetErrorBoundary }) {
  const history = useHistory()

  const onErrorhandler = () => {
    localStorage.clear()
    history.go('/')
  }
  return (
    <Col className="d-flex flex-column justify-content-center align-items-center vh-100 w-100">
      <Row className="f-flex flex-column justify-content-between align-items-center">
        <div className="m-3">
          <p className="h1 my-3 mx-0"><b>Oops, something went wrong.</b></p>
          <p className="mb-5 mx-1">Please either refresh the page or return home to try again.</p>
          <Button
            variant="outline-dark"
            id="end-conversation-dialog-button"
            className="my-3 me-5 px-5 py-2 card-subtitle fw-bold subsection-title"
            onClick={onErrorhandler}
          >
            Try Again
          </Button>
        </div>
      </Row>
    </Col>
  )
}
