/* eslint-disable no-return-await */
import userActions from 'redux/user/actions'
import User from 'services/User'

const fetchCurrentUser = () => async (dispatch) => User.getUser()
  .then((response) => {
    dispatch(userActions.updateUser(response?.data))
  })

const updateUserSettings = (userSettings, updateUser) => (
  async (dispatch) => {
    dispatch(userActions.updateUserSettings())
    try {
      const { full_name: fullName, avatar } = userSettings
      await User.updateUser(fullName, avatar)
      updateUser({ ...userSettings })
      dispatch(userActions.updateUserSettingsSuccess(userSettings))
    } catch (e) {
      dispatch(userActions.updateUserSettingsError(e))
    }
  }
)

const changePassword = (formData) => (
  async (dispatch) => {
    dispatch(userActions.changePassword())
    try {
      await User.changePassword(formData)
      dispatch(userActions.changePasswordSuccess())
    } catch (e) {
      dispatch(userActions.changePasswordError(e))
    }
  }
)

export default {
  fetchCurrentUser,
  updateUserSettings,
  changePassword,
}
