import React, { useEffect, useState } from 'react'
import { SECONDS_IN_MS } from 'utils/constants'
import throttle from 'lodash/throttle'
import CancelRequestDialog from './CancelRequestDialog'

const CustomerInactivityDetector = ({ timeoutPeriod }) => {
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [requestCancelled, setRequestCancelled] = useState(false)

  useEffect(() => {
    if (timeoutPeriod) {
      let timer // Timer for inactivity detection
      let innerTimer // Timer for warning modal
      const handleWorkerActivity = throttle(() => {
        clearTimeout(timer)
        clearTimeout(innerTimer)

        timer = setTimeout(() => {
          setShowWarningModal(true)
          // If no response in 30 seconds, drop the call
          // FIXME: there is a bug, if modal appears and you become active, the modal will not execute cancel
          innerTimer = setTimeout(() => {
            setRequestCancelled(true)
          }, SECONDS_IN_MS * 30)
        }, SECONDS_IN_MS * timeoutPeriod)
      }, 750)

      const handleActivityEvent = () => {
        handleWorkerActivity()
      }

      document.addEventListener('mousemove', handleActivityEvent)
      document.addEventListener('keydown', handleActivityEvent)
      document.addEventListener('click', handleActivityEvent)
      handleWorkerActivity()

      return () => {
        clearTimeout(timer)
        clearTimeout(innerTimer)
        document.removeEventListener('mousemove', handleActivityEvent)
        document.removeEventListener('keydown', handleActivityEvent)
        document.removeEventListener('click', handleActivityEvent)
      }
    }
    return () => {}
  }, [timeoutPeriod])

  return (
    <div>
      <CancelRequestDialog
        // where is the inactivity field coming from??
        inactivity
        show={showWarningModal}
        onClose={() => setShowWarningModal(false)}
        requestCancelled={requestCancelled}
      />
    </div>
  )
}

export default CustomerInactivityDetector
