import { useCallback } from 'react'
import { useVideoContext } from 'providers/VideoProvider'
import useIsTrackEnabled from 'hooks/useIsTrackEnabled/useIsTrackEnabled'

export default function useLocalVideoToggle() {
  const {
    room,
    videoTrack: localVideoTrack,
  } = useVideoContext()
  const localParticipant = room?.localParticipant
  const isEnabled = useIsTrackEnabled(localVideoTrack)
  const toggleVideoEnabled = useCallback((state) => localParticipant?.videoTracks?.forEach(({ track }) => {
    // if we pass state, must have boolean value otherwise it will fail to enable/disable the video
    if (track?.name?.includes('camera')) {
      if (state) {
        return track.enable()
      }
      if (track?.isEnabled || state === false) {
        return track?.disable()
      }
      return track?.enable()
    }
    return true
  }), [localParticipant])

  return [isEnabled, toggleVideoEnabled]
}
