import { useDispatch } from 'react-redux'
import { endActiveConversation } from 'redux/activeConversations/actions'

export default function useResetRoom() {
  const dispatch = useDispatch()

  const isTokenExpired = (token) => {
    const expire = token ? JSON.parse(window.atob(token.split('.')[1])).exp * 1000 : 0
    return new Date(expire) < new Date()
  }
  const reset = (id) => {
    dispatch(endActiveConversation(id))
  }
  return {
    resetRoom: reset,
    isTokenExpired,
  }
}
