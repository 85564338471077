import React, { useEffect } from 'react'
import { Alert } from 'react-bootstrap'
import { useParams, useHistory, generatePath } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { QrFlowRoutes } from 'features/qrflow/constants'
import chatRequestOperations from 'redux/chatRequest/operations'
import QRDialog from 'features/qrflow/components/dialog/QRDialog'
import useErrorMessageFromObject from 'hooks/useErrorMessageFromObject'
import useGetEntryPointDetails from 'features/qrflow/hooks/useGetEntryPointDetails'
import useConsumerConversations from 'features/qrflow/hooks/useConsumerConversations'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

const CancelRequestDialog = ({
  show,
  onClose,
  inactivity,
  requestCancelled,
}) => {
  const {
    data,
  } = useGetEntryPointDetails()
  const history = useHistory()

  const {
    accent_color: accentColor,
  } = data?.config.visual ?? {}

  const { serviceUid, chatRequestId } = useParams()
  const dispatch = useDispatch()
  const { state, settings: { userId } } = useConsumerConversations()
  const errorMessage = useErrorMessageFromObject(state?.cancelRequest)
  const isLoading = state?.cancelRequest === 'loading'
  const isCanceled = state?.cancelRequest === 'success'

  const onCancelRequestHandler = async () => {
    dispatch(chatRequestOperations.cancelChatRequest(userId))
  }

  useEffect(() => {
    if (isCanceled) history.push(generatePath(QrFlowRoutes.leaveMessage, { serviceUid, chatRequestId }))
  }, [chatRequestId, history, isCanceled, serviceUid])

  useEffect(() => {
    if (requestCancelled) {
      onCancelRequestHandler()
    } else {
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestCancelled])

  return (
    <QRDialog
      show={show}
      title="Cancel Conversation"
      onClose={onClose}
      onConfirm={onCancelRequestHandler}
      confirmTextColor={accentColor}
      loading={isLoading}
      disabled={isLoading}
      closeText="Close"
    >
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      <div>
        Are you sure you would like to cancel this conversation?
      </div>
      <div>
        Please note that this will remove you from the queue and you will have to wait in line
        again if you wish to start a new conversation.
      </div>
      <div>
        {inactivity
          ? (
            <div className="d-flex align-items-center">
              <span className="me-2">The conversation will be canceled in</span>
              <CountdownCircleTimer
                isPlaying
                duration={30}
                strokeWidth={4}
                colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                colorsTime={[15, 10, 5, 0]}
                size={30}
              >
                {({ remainingTime }) => remainingTime}
              </CountdownCircleTimer>
              <span className="ms-2">seconds.</span>
            </div>
          )
          : null}
      </div>
    </QRDialog>
  )
}

export default CancelRequestDialog
