import React from 'react'
import { Row } from 'react-bootstrap'
import IntroScreen from 'components/UI/IntroScreen/IntroScreen'

const OnBoardingLayout = ({ children }) => (
  <div className="text-center vw-100 vh-100">
    <Row className="m-0 p-0 w-100 h-100">
      <IntroScreen />
      {children}
    </Row>
  </div>
)

export default OnBoardingLayout
