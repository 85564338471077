/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { useSelector } from 'react-redux'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import MessagesLeftTableRow from 'routes/MessagesLeft/components/MessagesLeftTableRow/MessagesLeftTableRow'
import ArrowLeft from 'components/UI/ArrowLeft'
import ArrowRight from 'components/UI/ArrowRight'
import { useToasts } from 'react-toast-notifications'
import Company from 'services/Company'
import useCustomerName from 'hooks/useCustomerName'
import useMessagesLeft from './hooks/useMessagesLeft'

const MessagesLeft = ({ status, search }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const { addToast, removeToast } = useToasts()
  const companyId = useSelector((state) => state.company.data?.id)
  const customerName = useCustomerName()
  const onFetchMessagesError = (error) => {
    removeToast('fetch-conversations-error')
    addToast(`Conversations: ${error.error || error.message || 'Oops, something went wrong.'}`, {
      appearance: 'error',
      id: 'fetch-conversations-error',
    })
  }
  const messageStatus = status ? 'open' : 'closed'

  const {
    data,
    isLoading: isLoadingMessages,
    refetch: refetchMessagesLeft,
  } = useMessagesLeft(companyId, messageStatus, search, currentPage, onFetchMessagesError)

  const handleOnClickClose = async (id) => Company.closeMessagesLeft(companyId, id).then(() => refetchMessagesLeft())

  const messages = data?.messages_left ?? []
  const numberOfPages = data?.pagy?.pages

  if (isLoadingMessages) {
    return <LoadingSpinner spinnerSize={50} className="text-center mt-5" />
  }

  return (
    <Row className="w-100 position-relative m-0 p-0">
      <Col md={12} className="text-center p-0">
        {messages.map(({
          id, full_name, created_at: date, email, phone_number, message, company_service: companyService,
        }) => (
          <MessagesLeftTableRow
            key={id}
            name={customerName(full_name)}
            email={email}
            date={date}
            entryPointName={companyService?.name}
            phone={phone_number}
            message={message}
            id={id}
            handleOnClickClose={handleOnClickClose}
            status={status}
          />
        ))}
        <Row className="p-0 m-0">
          <Col md={12}>
            <div className="messagesLeft__pagination">
              <ReactPaginate
                previousLabel={<ArrowLeft />}
                nextLabel={<ArrowRight />}
                pageCount={numberOfPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                initialPage={currentPage}
                onPageChange={({ selected }) => setCurrentPage(selected)}
                containerClassName="messagesLeft__pagination__list"
                activeClassName="messagesLeft__pagination__active"
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default MessagesLeft
