/* eslint-disable max-len */
import React from 'react'
import useAvailableDevices from 'hooks/useAvailableDevices/useAvailableDevices'
import { MediaErrors, SELECTED_AUDIO_INPUT_KEY, SELECTED_VIDEO_INPUT_KEY } from 'providers/VideoProvider/constants'
import { Alert } from 'react-bootstrap'

function parseMediaError(hasMicrophone, hasVideo, micMediaError, videoMediaError) {
  let headline = ''
  let message = ''

  switch (true) {
    case micMediaError?.name === MediaErrors.NotAllowedError && videoMediaError?.name === MediaErrors.NotAllowedError:
      headline = 'Unable to Access Microphone and Video: '
      message = 'The user has denied permission to use audio/video. Please grant permission to the browser to access the microphone/video.'
      break

    case micMediaError?.name === MediaErrors.NotAllowedError:
      headline = 'Unable to Access Microphone: '
      message = 'The user has denied permission to use audio. Please grant permission to the browser to access the microphone.'
      break

    case videoMediaError?.name === MediaErrors.NotAllowedError:
      headline = 'Unable to Access Video: '
      message = 'The user has denied permission to use video. Please grant permission to the browser to access the video.'
      break

    case micMediaError?.name === MediaErrors.NotFoundError && videoMediaError?.name === MediaErrors.NotFoundError:
      headline = 'Cannot Find Microphone and Camera: '
      message = 'The browser cannot access the microphone/camera. Please make sure all input devices are connected and enabled.'
      break

    case micMediaError?.name === MediaErrors.NotFoundError:
      headline = 'Cannot Find Microphone: '
      message = 'The browser cannot access the microphone. Please make sure all input devices are connected and enabled.'
      break

    case videoMediaError?.name === MediaErrors.NotFoundError:
      headline = 'Cannot Find Camera: '
      message = 'The browser cannot access the camera. Please make sure all input devices are connected and enabled.'
      break

    case micMediaError?.name === MediaErrors.NotReadableError && videoMediaError?.name === MediaErrors.NotReadableError:
      headline = 'Microphone and camera is being used by other applications: '
      message = 'Please make sure that all other applications or tabs that are using your microphone/camera are closed.'
      break

    case micMediaError?.name === MediaErrors.NotReadableError:
      headline = 'Microphone is being used by other applications: '
      message = 'Please make sure that all other applications or tabs that are using your microphone are closed.'
      break

    case videoMediaError?.name === MediaErrors.NotReadableError:
      headline = 'Camera is being used by other applications: '
      message = 'Please make sure that all other applications or tabs that are using your camera are closed.'
      break

    case micMediaError?.name === MediaErrors.OverConstrainedError && videoMediaError?.name === MediaErrors.OverConstrainedError:
      localStorage.removeItem(SELECTED_AUDIO_INPUT_KEY)
      localStorage.removeItem(SELECTED_VIDEO_INPUT_KEY)
      headline = 'Requested microphone/video is not connected: '
      message = 'Try again by clicking the Toggle Microphone/Video button or by enabling your microphone/video in your browser settings.'
      break

    case micMediaError?.name === MediaErrors.OverConstrainedError:
      // Should rarely/never happen
      // Can happen only when the consumer's microphone which he has previously used on Swytchboard no longer exist (i.e got broken or replaced)
      // To be fixed in future PR when we are gonna persist the mic/camera data in state (deviceId, disabled/enabled etc.)
      localStorage.removeItem(SELECTED_AUDIO_INPUT_KEY)
      headline = 'Requested microphone is not connected: '
      message = 'Try again by clicking the Toggle Microphone button or by enabling your microphone in your browser settings.'
      break

    case videoMediaError?.name === MediaErrors.OverConstrainedError:
      localStorage.removeItem(SELECTED_VIDEO_INPUT_KEY)
      headline = 'Requested camera is not connected: '
      message = 'Try again by clicking the Toggle Camera button or by enabling your camera in your browser settings.'
      break

    case micMediaError?.name === MediaErrors.TypeError:
    case videoMediaError?.name === MediaErrors.TypeError:
      // Should never happen
      headline = 'The application is served from a non secure context: '
      message = 'Make sure this application is being served by a secure context like https or localhost.'
      break

    case !hasMicrophone && !hasVideo:
      headline = 'No Camera or Microphone Detected: '
      message = 'Other people in the room will be unable to see and hear you.'
      break

    case !hasVideo:
      headline = 'No Camera Detected: '
      message = 'Other people in the room will be unable to see you.'
      break

    case !hasMicrophone:
      headline = 'No Microphone Detected: '
      message = 'Other people wont be able to hear you, and you might have issues with hearing other people.'
      break

    default:
      headline = 'Error Acquiring Microphone: '
      message = `${micMediaError?.name} - ${micMediaError?.message}`
      break
  }

  return {
    headline,
    message,
  }
}

const DisplayMediaError = ({ micMediaError, videoMediaError }) => {
  const { hasAudioInputDevices } = useAvailableDevices()
  const { hasVideoInputDevices } = useAvailableDevices()

  const {
    headline,
    message,
  } = parseMediaError(hasAudioInputDevices, hasVideoInputDevices, micMediaError, videoMediaError)

  return (
    <Alert variant="warning" className="qr-video__media-error-message">
      <span className="fw-bold">
        {headline}
      </span>
      <span>
        {message}
      </span>
    </Alert>
  )
}

export default DisplayMediaError
