import {
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import Company from 'services/Company'
import { QueryKeys } from 'utils/constants'
import { useToasts } from 'react-toast-notifications'
import { ToastKey } from 'routes/ChatSettingsEntryPoint/constants'

export default function useDeleteEntryPoint(id) {
  const {
    addToast,
    removeToast,
  } = useToasts()

  const queryClient = useQueryClient()

  const deleteEntryPoint = useMutation(
    () => Company.removeCompanyEntryPoint(id), {
      onSuccess: () => {
        const previousEntryPoints = queryClient.getQueryData([QueryKeys.ENTRY_POINT])
        const entryPoints = previousEntryPoints.filter((d) => d.id !== id)

        queryClient.setQueryData([QueryKeys.ENTRY_POINT], entryPoints)
        queryClient.invalidateQueries([QueryKeys.ENTRY_POINT])
      },

      onError: (error, variables, rollback) => {
        removeToast(ToastKey.entryPointError)
        addToast(`Entry Point: ${error.message || error?.statusText}`, {
          appearance: 'error',
          autoDismiss: 2000,
          id: ToastKey.entryPointError,
        })
        if (rollback) rollback()
      },
    },
  )

  return {
    deleteEntryPoint,
  }
}
