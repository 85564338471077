import React from 'react'
import WorkerDialog from 'components/WorkerDialog'
import EndConversationDialog from 'components/WorkerDialog/EndConversationDialog'
import useDialog from 'hooks/useDialog'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { MessageEvent } from 'utils/constants'

const MoreOptionsModal = ({ show, close }) => {
  const history = useHistory()
  const {
    onOpenDialog,
    onCloseDialog,
    displayDialog,
  } = useDialog()

  const handleLeaveConversation = () => {
    if (window.ReactNativeWebView) {
      return window.ReactNativeWebView.postMessage(MessageEvent.LEAVE_CONVERSATION)
    }
    return history.push('/dashboard')
  }
  return (
    <WorkerDialog
      show={show}
      onClose={close}
      title="More Options"
      footer={false}
    >
      <div>
        <Button
          id="end-conversation-dialog-button"
          className="my-2 conversation-header__leave-conversation-button bg-transparent text-primary"
          onClick={handleLeaveConversation}
        >
          Leave Conversation
        </Button>
        <Button
          id="end-conversation-dialog-button"
          className="conversation-header__end-conversation-button"
          onClick={onOpenDialog}
        >
          End Conversation
        </Button>
        <EndConversationDialog
          show={displayDialog}
          onClose={onCloseDialog}
        />
      </div>
    </WorkerDialog>
  )
}
export default MoreOptionsModal
