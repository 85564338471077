import React from 'react'
import Timer from 'react-compound-timer'

const TimeElapsed = ({ initialTimeInMilliseconds }) => (
  <span className="punched-in-timer">
    <Timer
      initialTime={initialTimeInMilliseconds}
      lastUnit="h" // the last calculated unit should be hours (eg. 2 days would be 48hours)
      formatValue={(value) => `${value < 10 ? `0${value}` : value}`} // add leading zeros
    >
      <Timer.Hours />
      :
      <Timer.Minutes />
      :
      <Timer.Seconds />
    </Timer>
  </span>
)
export default TimeElapsed
