import useErrorMessageFromObject from 'hooks/useErrorMessageFromObject'
import useConsumerConversations from './useConsumerConversations'

export default function useSubmitRatings() {
  const { state: { workerName, submitRatings } } = useConsumerConversations()
  const isSubmitting = submitRatings === 'loading'
  const isDone = !([undefined, 'loading'].includes(submitRatings))

  const errorMessage = useErrorMessageFromObject(submitRatings)

  return {
    isSubmitting,
    isDone,
    errorMessage,
    workerName,
  }
}
