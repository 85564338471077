import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Interweave } from 'interweave'
import ReactTimeAgo from 'react-time-ago'
import SwytchAvatar from 'components/user/SwytchAvatar'

const ConsumerMessage = ({
  className,
  content,
  date,
  name,
}) => (
  <Row className="m-0 p-0 consumer">
    <Col xs={2} className="m-0 p-0 mt-1 text-center">
      <SwytchAvatar name={name} size={30} />
    </Col>
    <Col className="text-break">
      <div className="w-100 p-3 message br-2 shadow-default">
        <Interweave content={content} />
      </div>
      <div className={`w-100 timestamp text-end font-italic pt-2 ${className}`}>
        <ReactTimeAgo date={date} locale="en-US" />
      </div>
    </Col>
  </Row>
)

export default ConsumerMessage
