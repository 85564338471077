/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import LoadingButton from 'components/buttons/LoadingButton'
import ViewableLogoLayout from 'routes/ChatSettingsEntryPoint/components/ViewableLogoLayout'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const Viewable = ({
  requestName,
  requestEmailAddress,
  requestPhoneNumber,
  requestHelpInformation,
  accentColor,
}) => (
  <ViewableLogoLayout>
    <div className="text-start flex-fill">
      <h4 className="chat-viewable__title">
        FILL OUT YOUR INFO
        <div
          className="chat-viewable__title__line"
          style={{
            backgroundColor: accentColor,
          }}
        />
      </h4>
      {requestName && (
        <div className="mb-3">
          <label className="fw-bold">Enter your full name</label>
          <input
            type="text"
            placeholder="Enter full name"
            disabled
            className="chat-personal-info-viewable__input"
          />
        </div>
      )}
      {requestEmailAddress && (
        <div className="mb-3">
          <label className="fw-bold">Enter your email address</label>
          <input
            type="text"
            placeholder="Enter e-mail address"
            disabled
            className="chat-personal-info-viewable__input"
          />
        </div>
      )}
      {requestPhoneNumber && (
        <div className="mb-3">
          <label className="fw-bold">Enter your phone number</label>
          <PhoneInput
            placeholder="Enter your phone number"
            defaultCountry="US"
          />
        </div>
      )}
      {requestHelpInformation && (
        <div className="mb-3">
          <label className="fw-bold">How can we assist you?</label>
          <textarea
            type="text"
            rows={3}
            placeholder="Enter your message"
            disabled
            className="chat-personal-info-viewable__input"
          />
        </div>
      )}
      <div className="chat-personal-info-viewable__checkbox">
        <input
          type="checkbox"
        />
        <label>
          I agree to the
          {' '}
          <a href="/terms-of-use">terms of use</a>
          {' '}
          and
          {' '}
          <a href="/privacy-policy">privacy policy</a>
          {' '}
          including having this conversation recorded for quality assurance purposes.
        </label>
      </div>
      {requestEmailAddress && (
        <div className="chat-personal-info-viewable__checkbox">
          <input
            type="checkbox"
          />
          <label>
            I agree to receive marketing communication to my email address.
          </label>
        </div>
      )}
    </div>
    <LoadingButton
      className="qr-btn w-100 my-3"
      style={{
        backgroundColor: accentColor,
      }}
      disabled
    >
      Start conversation
    </LoadingButton>
  </ViewableLogoLayout>
)

export default Viewable
