import handleErrors from 'utils/handleErrors'
import handleResponse from 'utils/handleResponse'
import config from 'utils/config'
import http from 'utils/http'
import { ConversationTypes } from 'utils/constants'
import axios from 'axios'

const TARGET_URL = `${config.SERVER_URL}${config.API_URL}`
const PUBLIC_TARGET_URL = `${config.SERVER_URL}${config.PUBLIC_API_URL}`

class Chat {
  /**
   * Assign the project to an employee.
   * @param {string} name - The name of the consumer.
   * @param {string} email - The email of the consumer.
   * @param {string} introMessage - The intro message of the consumer.
   * @param {string} serviceUid - Company's config (entry point).
   * @param {'text'|'video_text'|'video'|undefined} requestType
   * @param {string} serviceUid
   */
  createChatRequest = (
    name, email, phone, introMessage, requestType = ConversationTypes.text, serviceUid, userAgent,
  ) => {
    const consumerAttributes = {}
    if (name !== '') {
      consumerAttributes.name = name
    }
    if (email !== '') {
      consumerAttributes.email = email
    }
    if (phone !== '') {
      consumerAttributes.phone_number = phone
    }

    return http
      .post(`${PUBLIC_TARGET_URL}/chat_requests`, {
        consumer_attributes: consumerAttributes,
        intro_message: introMessage,
        request_type: requestType,
        service_uid: serviceUid,
        user_agent: userAgent,
      })
      .then((response) => handleResponse(response))
      .catch((error) => handleErrors(error))
  }

  cancelChatRequest = (chatRequestId, consumerId) => (
    http.put(`${PUBLIC_TARGET_URL}/chat_requests/${chatRequestId}/cancel`, {
      consumer_id: consumerId,
    })
      .then((response) => handleResponse(response))
      .catch((error) => handleErrors(error))
  )

  pendingChatRequests = () => (
    http
      .get(`${TARGET_URL}/chat_requests?status=pending`)
      .then((response) => handleResponse(response))
      .catch((error) => handleErrors(error))
  )

  acceptedChatRequests = () => (
    http
      .get(`${TARGET_URL}/chat_requests?status=accepted`)
      .then((response) => handleResponse(response))
      .catch((error) => handleErrors(error))
  )

  acceptChatRequest = (chatRequestId) => (
    http.put(`${TARGET_URL}/chat_requests/${chatRequestId}/accept`)
  )

  declineChatRequest = (chatRequestId) => (
    http.put(`${TARGET_URL}/chat_requests/${chatRequestId}/decline`)
      .then((response) => handleResponse(response))
      .catch((error) => handleErrors(error))
  )

  leaveMessageChatRequest = (chatRequestId) => (
    http.put(`${TARGET_URL}/chat_requests/${chatRequestId}/leave_message`)
      .then((response) => handleResponse(response))
      .catch((error) => handleErrors(error))
  )

  completeChatRequestWorker = (id, roomName) => (
    http.put(`${TARGET_URL}/chat_requests/${id}/complete`, {
      room_name: roomName,
      broadcast: 'true',
    })
      .then((response) => handleResponse(response))
      .catch((error) => handleErrors(error))
  )

  engageChatCallRequest = (chatRequestId, requestType) => (
    http.put(`${TARGET_URL}/chat/engage`, {
      chat_request_id: chatRequestId,
      request_type: requestType,
    })
      .then((response) => handleResponse(response))
  )

  publishVideoStatus = (chatRequestId, consumerId, status) => (
    http
      .put(`${TARGET_URL}/chat/video_status?status=${status}`, {
        chat_request_id: chatRequestId,
        consumer_id: consumerId,
      })
      .then((response) => handleResponse(response))
  )

  // TODO: check if this is relevant now - used by the old UI
  engageCallConsumer = (chatID, consumerEmail) => (
    http
      .post(`${TARGET_URL}/consumers/token`, {
        chat_request_id: parseInt(chatID, 10),
        email: consumerEmail,
      })
      .then((response) => handleResponse(response))
      .catch((error) => handleErrors(error))
  )

  teamChatRequest = () => (
    http
      .get(`${TARGET_URL}/team_chat/join`)
      .then((response) => handleResponse(response))
      .catch((error) => handleErrors(error))
  )

  publicChatRequestData = (chatRequestId, consumerToken) => (
    http.get(`${PUBLIC_TARGET_URL}/chat_requests/${chatRequestId}?consumer_token=${consumerToken}`)
      .then((response) => handleResponse(response))
      .catch((error) => handleErrors(error))
  )

  joinCall = (requestType, chatRequestId, consumerId, isConsumer = false) => (isConsumer
    ? axios.put(`${TARGET_URL}/chat/join`, {
      request_type: requestType,
      chat_request_id: chatRequestId,
      consumer_id: consumerId,
    })
      .then((response) => handleResponse(response))
    : http.put(`${TARGET_URL}/chat/join`, {
      request_type: requestType,
      chat_request_id: chatRequestId,
      consumer_id: consumerId,
    })
      .then((response) => handleResponse(response))
  )

  requestVideoCall = (chatRequestId, consumerId) => (
    http.put(`${TARGET_URL}/chat/request`, {
      chat_request_id: chatRequestId,
      consumer_id: consumerId,
    })
      .then((response) => handleResponse(response))
  )

  submitRatings = (chatRequestId, consumerId, rateToken, formData) => (
    http.post(`${TARGET_URL}/ratings`, {
      chat_request_id: chatRequestId,
      consumer_id: consumerId,
      rate_token: rateToken,
      ...formData,
    })
  )

  endConversationConsumer = (chatRequestId, roomName, consumerId) => (
    http.put(`${TARGET_URL}/chat_requests/${chatRequestId}/complete`, {
      chat_request_id: chatRequestId,
      room_name: roomName,
      consumer_id: consumerId,
    })
      .then((response) => handleResponse(response))
      .catch((error) => handleErrors(error))
  )

  submitLeaveMessage = async (fullName, phoneNumber, email, message, serviceUid) => {
    http.post(`${PUBLIC_TARGET_URL}/leave_message/${serviceUid}`, {
      full_name: fullName,
      phone_number: phoneNumber,
      email,
      message,
    })
  }
}

export default new Chat()
