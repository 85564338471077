import { useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { uploadedFileMaxSize } from 'utils/constants'
import { formatSizeUnits } from 'utils/helpers'

/**
 * Upload files on FormData adaptable for twilio.
 *
 * @param event
 * @returns {formData}
 */
export default function useFormData() {
  const [fileFormData, setFileFormData] = useState('')
  const [fileData, setFileData] = useState('')
  const [preview, setPreview] = useState('')

  const { addToast } = useToasts()

  function handleFileChange(e) {
    const file = e.target === undefined ? e : e.target?.files[0]
    if (!file) {
      return
    }

    // Checking if the uploaded file is larger than 25 MB
    if (file.size > uploadedFileMaxSize) {
      const formatedSize = formatSizeUnits(file.size)
      addToast(`File is too large, file size is ${formatedSize}, maximum allowed size - 25 MB.`, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 5000,
      })
      return
    }

    const formData = new FormData()
    formData.append('file', file)
    const obj = URL.createObjectURL(file)
    setFileFormData(formData)
    setFileData(file)
    setPreview(obj)
  }

  return {
    fileFormData, preview, fileData, handleFileChange, setFileFormData, setPreview, setFileData,
  }
}
