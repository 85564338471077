import {
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import Company from 'services/Company'
import { QueryKeys } from 'utils/constants'
import { useToasts } from 'react-toast-notifications'
import { ToastKey } from 'routes/ChatSettingsEntryPoint/constants'

export default function useUpdateEntryPoint(id) {
  const {
    addToast,
    removeToast,
  } = useToasts()

  const queryClient = useQueryClient()

  const updateEntryPoint = useMutation(
    (values) => Company.updateCompanyEntryPoint(id, values), {
      onSuccess: (data) => {
        const config = data.company_service_config

        const getEntryPoint = queryClient.getQueryData([QueryKeys.ENTRY_POINT, id])
        queryClient.setQueryData([QueryKeys.ENTRY_POINT, id], {
          ...getEntryPoint,
          config,
        })

        if (queryClient.getQueryData([QueryKeys.ENTRY_POINT])) {
          queryClient.setQueryData([QueryKeys.ENTRY_POINT], (oldEntryPoints) => oldEntryPoints.map((d) => {
            if (d.id === data.id) {
              return data
            }
            return d
          }))
        } else {
          queryClient.setQueryData([QueryKeys.ENTRY_POINT], [data])
          queryClient.invalidateQueries([QueryKeys.ENTRY_POINT])
        }
      },

      onError: (error) => {
        removeToast(ToastKey.entryPointError)
        addToast(`Entry Point: ${error.message}`, {
          appearance: 'error',
          autoDismiss: 2000,
          id: ToastKey.entryPointError,
        })
      },
    },
  )

  return {
    updateEntryPoint,
  }
}
