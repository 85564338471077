import React from 'react'
import Avatar from 'react-avatar'
import { getInitials } from 'utils/helpers'

const SwytchAvatar = ({
  name,
  size = 42,
  round = '50%',
}) => (
  <Avatar
    name={name}
    initials={(e) => getInitials(e)}
    round={round}
    size={size}
    color="#E5E5E5"
    fgColor="#0E0641"
    textSizeRatio={2.2}
  />
)

export default SwytchAvatar
