import React, { useState } from 'react'
import VideoControlsButton from 'components/VideoControlsButton/VideoControlsButton'
import SettingsDialog from 'components/SettingsDialog/SettingsDialog'

const ToggleSettingsButton = () => {
  const [showSettingsDialog, setShowSettingsDialog] = useState(false)

  const onHide = () => {
    setShowSettingsDialog(false)
  }

  return (
    <>
      <VideoControlsButton
        name="settings"
        onClick={() => setShowSettingsDialog(true)}
      />
      <SettingsDialog show={showSettingsDialog} onHide={onHide} />
    </>
  )
}

export default ToggleSettingsButton
