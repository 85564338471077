import React, { useRef, useState } from 'react'
import {
  Col, Row, Overlay, Tooltip,
} from 'react-bootstrap'
import dayjs from 'dayjs'
import useDialog from 'hooks/useDialog'
import FullStar from 'components/UI/FullStar'
import CircleInfo from 'components/UI/CircleInfo'
import ConversationDetailsDialog from '../ConversationDetails'
import Messages from '../ConversationDetails/Messages/MessagesList'
import Details from '../ConversationDetails/Details/Details'

const ConversationsTableRow = ({
  customer,
  email,
  phone,
  worker,
  date,
  helpfulness,
  knowledge,
  professional,
  comment,
  headers,
  id,
  entryPointName,
}) => {
  const [show, setShow] = useState(false)
  const target = useRef(null)

  const {
    displayDialog: displayConversationDetails,
    onCloseDialog: onCloseConversationDetails,
    onOpenDialog: onOpenConversationDetails,
  } = useDialog()

  function isoDateToJsDate(dateFormater) {
    return dayjs(dateFormater).format('YYYY-MM-DD')
  }

  function commentField(string) {
    if (string?.length === 0 || string?.length === undefined) {
      return (<span>/</span>)
    }

    if (string?.length <= 30) {
      return (<span>{string}</span>)
    }

    if (string?.length >= 30) {
      return (
        <>
          <div
            ref={target}
            className="conversations__table__ellipsis"
            onMouseOver={() => setShow(!show)}
            onFocus={() => setShow(!show)}
            onBlur={() => setShow(false)}
            onMouseOut={() => setShow(false)}
          >
            {string}
          </div>
          <Overlay
            target={target.current}
            show={show}
            placement="top"
          >
            {(props) => (
              <Tooltip className="conversations__table__tooltip" {...props}>
                {string}
              </Tooltip>
            )}
          </Overlay>
        </>
      )
    }
    return string
  }

  const renderTd = (key, field, star = true) => (
    <td key={key} className={`conversations__table ${key}`}>
      {star && <FullStar size={14} color="#8BD8BD" />}
      <span>
        {field}
      </span>
    </td>
  )

  function checkField(key) {
    switch (headers[key]) {
      case headers.customer:
        return {
          field: customer,
          star: false,
        }
      case headers.email:
        return {
          field: email,
          star: false,
        }
      case headers.phone:
        return {
          field: phone,
          star: false,
        }
      case headers.worker:
        return {
          field: worker,
          star: false,
        }
      case headers.date:
        return {
          field: isoDateToJsDate(date),
          star: false,
        }
      case headers.helpfulness:
        return {
          field: helpfulness,
          star: helpfulness !== undefined,
        }
      case headers.knowledge:
        return {
          field: knowledge,
          star: knowledge !== undefined,
        }
      case headers.professional:
        return {
          field: professional,
          star: professional !== undefined,
        }
      case headers.comment:
        return {
          field: commentField(comment),
          star: false,

        }
      case headers.entryPointName:
        return {
          field: entryPointName,
          star: false,
        }
      case headers.empty:
        return {
          field: <CircleInfo
            onClick={onOpenConversationDetails}
            className="conversations__table__circle-info"
          />,
          star: false,
        }
      default:
        return {}
    }
  }

  return (
    <>
      <tr className="conversations__table">
        {Object.keys(headers).map((header) => {
          const { field, star, className } = checkField(header)
          return renderTd(header, field, star, className)
        })}
      </tr>
      <ConversationDetailsDialog
        showModal={displayConversationDetails}
        onClose={onCloseConversationDetails}
        title="Conversation Details"
      >
        <Row className="p-0 m-0">
          <Col md={7}>
            <Details
              customer={customer}
              email={email}
              phone={phone}
              worker={worker}
              date={date}
              entryPointName={entryPointName}
              helpfulness={helpfulness}
              knowledge={knowledge}
              professional={professional}
              comment={comment}
            />
          </Col>
          <Col md={5}>
            <Messages chatRequestId={id} />
          </Col>
        </Row>
      </ConversationDetailsDialog>
    </>
  )
}

export default ConversationsTableRow
