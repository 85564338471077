import React from 'react'
import { useMembersContext } from 'routes/TeamMembers/context/TeamMembersContext'
import PendingMembers from 'routes/TeamMembers/components/Pending/PendingMembers'
import Members from 'routes/TeamMembers/components/Members/Members'

const Wrapper = () => {
  const { displayPendingMembers } = useMembersContext()

  return (
    <>
      {displayPendingMembers ? (
        <Members />
      ) : (
        <PendingMembers />
      )}
    </>
  )
}

export default Wrapper
