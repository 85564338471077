import MobileVideoChat from 'components/video/MobileVideoChat'
import VideoChat from 'components/video/VideoChat'
import useActiveConversations from 'hooks/useActiveConversations'
import React, { useState } from 'react'
import {
  Col, Row, Tab, Tabs,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { setIsChatHidden } from 'redux/layout/actions'
import CustomerChat from 'routes/Dashboard/components/chat/customer/CustomerChat'
import TeamChat from 'routes/Dashboard/components/chat/team/TeamChat'
import { ConversationTypes } from 'utils/constants'
import ConversationHeaderMobile from './components/ConversationHeaderMobile'
import VideoChatTabs from './components/VideoChatTabs'
import ConversationChatWrapper from './ConversationChatWrapper'

const MobileConversation = () => {
  const { requestType } = useActiveConversations()

  const [teamTabActive, setTeamTabActive] = useState(false)
  const [chatToggle, setChatToggle] = useState('customer')
  const dispatch = useDispatch()
  const isChatHidden = useSelector((state) => state.layout.chatHidden)
  const isVideoCall = [ConversationTypes.videoText, ConversationTypes.video].includes(requestType)
  const handleChatToggle = (e) => {
    if (e.target.id === 'customer-button') {
      setChatToggle('customer')
      setTeamTabActive(false)
    } else {
      setChatToggle('team')
      setTeamTabActive(true)
    }
  }

  if (isVideoCall) {
    return (
      <Row className="w-100 h-100 p-0 m-0">
        <Col md={12} xs={12} className="w-100 p-0 m-0" style={{ height: !isChatHidden ? '60vh' : '100vh' }}>
          <MobileVideoChat />
        </Col>
        <Col
          className={`w-100 p-0 m-0 dashboard_tabs ${isChatHidden && 'd-none'}`}
          md={12}
          xs={12}
          style={{
            height: '45vh',
            backgroundColor: 'white',
            position: 'absolute',
            bottom: 0,
            borderRadius: 30,
          }}
        >
          <VideoChatTabs />
        </Col>
      </Row>
    )
  }

  return (
    <div className="h-100 dashboard_tabs px-3">
      <Tabs className="my-3 p-0 justify-content-around w-100  border-0 dashboard_tabs" defaultActiveKey="messages">
        <Tab eventKey="messages" title="Messages" tabClassName="dashboard_tabs__tab text-center w-50">
          <Col md={12} xs={12} className={`${requestType !== ConversationTypes.videoText && 'bg-lighter rounded-1'} px-3 py-4 h-100`}>
            <ConversationHeaderMobile />
            {isVideoCall ? <VideoChat /> : <CustomerChat maxHeight="55vh" />}
          </Col>
        </Tab>
        <Tab eventKey="teamchat" title="Team Chat" tabClassName="dashboard_tabs__tab text-center w-50">
          {isVideoCall ? (
            <ConversationChatWrapper
              chatToggle={chatToggle}
              handleChatToggle={handleChatToggle}
              teamTabActive={teamTabActive}
              isChatHidden={isChatHidden}
              setIsChatHidden={() => dispatch(setIsChatHidden())}
            />
          ) : (
            <TeamChat />
          )}
        </Tab>
      </Tabs>
    </div>
  )
}
export default MobileConversation
