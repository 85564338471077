import React, { useState } from 'react'
import SettingsDialog from 'components/SettingsDialog/SettingsDialog'
import VideoCallButton from './VideoCallButton'

const ToggleCustomerSettingsButton = ({ className }) => {
  const [showSettingsDialog, setShowSettingsDialog] = useState(false)

  const onHide = () => {
    setShowSettingsDialog(false)
  }

  return (
    <>
      <VideoCallButton
        className={`${className} qr-video__buttons__btn`}
        onClick={() => setShowSettingsDialog(true)}
        icon="settings"
      />
      <SettingsDialog show={showSettingsDialog} onHide={onHide} />
    </>
  )
}

export default ToggleCustomerSettingsButton
