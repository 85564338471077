/* eslint-disable no-useless-escape */
import React from 'react'
import { Button, Alert } from 'react-bootstrap'
import * as yup from 'yup'
import { Formik, Form, Field } from 'formik'
import InputField from 'components/form/InputField'
import Chat from 'services/Chat'
import { useToasts } from 'react-toast-notifications'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { QrFlowRoutes } from 'features/qrflow/constants'
import { useDispatch, useSelector } from 'react-redux'
import { updateConsumerConversation } from 'redux/consumerConversations/actions'

const LeaveMessageForm = ({ accentColor, entryPointId, entryPointError }) => {
  const { formData } = useSelector((state) => state.chatRequest) ?? {}
  const initialValues = {
    name: formData?.name || '',
    phoneNumber: '',
    emailAddress: formData?.email || '',
    message: '',
  }
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    serviceUid,
    chatRequestId,
  } = useParams()
  const { addToast } = useToasts()

  const phoneRegex = RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/)
  const nameValidation = yup.string()
    .min(2, 'The full name should contain at least 2 characters.')
    .matches(/^[a-z ,.'-]+$/i, 'The full name should contain only characters.')
    .required('The full name should contain at least 2 characters.')

  const emailValidation = yup.string()
    .email('The email address is not valid.')
    .required('The email address is not valid.')

  const messageValidation = yup.string().required('This field can not be empty.')
  const phoneNumberValidation = yup.string()
    .matches(phoneRegex, 'Phone number is Invalid')

  const handleOnCancel = () => history.push(generatePath(QrFlowRoutes.start, { serviceUid }))

  const handleOnSubmit = async (values) => {
    try {
      await Chat.submitLeaveMessage(values.name, values.phoneNumber, values.emailAddress, values.message, entryPointId)
      dispatch(updateConsumerConversation(chatRequestId, { cancelRequest: 'success', status: 'thankYou' }))
      history.push(generatePath(QrFlowRoutes.thankYou, { serviceUid: entryPointId, chatRequestId }))
    } catch (e) {
      addToast(e?.response?.data?.error, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      })
    }
  }
  const LeaveMessageSchema = yup.object()
    .shape({
      name: nameValidation,
      emailAddress: emailValidation,
      message: messageValidation,
      phoneNumber: phoneNumberValidation,
    })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={LeaveMessageSchema}
      onSubmit={handleOnSubmit}
      validateOnChange
    >
      {({
        handleSubmit,
      }) => (
        <>
          <h2 className="qr-container__title">
            LEAVE US A MESSAGE
            <div
              className="qr-container__title__line"
              style={{
                backgroundColor: accentColor,
              }}
            />
          </h2>
          {entryPointError && <Alert variant="danger">{entryPointError}</Alert>}
          <Form className="flex-grow-1">
            <div className="mb-3">
              <Field
                input="true"
                name="name"
                type="text"
                label="Full name"
                placeholder="Enter full name"
                inputClasses="qr-input"
                component={InputField}
              />
            </div>
            <div className="mb-3">
              <Field
                input="true"
                name="phoneNumber"
                type="tel"
                label="Phone Number"
                placeholder="Enter phone number"
                inputClasses="qr-input"
                component={InputField}
              />
            </div>
            <div className="mb-3">
              <Field
                input="true"
                name="emailAddress"
                type="email"
                label="Email address"
                placeholder="Enter email address"
                inputClasses="qr-input"
                component={InputField}
              />
            </div>
            <div className="mb-3">
              <Field
                rows={4}
                name="message"
                type="textarea"
                label="Message"
                placeholder="Enter your message"
                inputClasses="qr-textarea"
                component={InputField}
                textarea
              />
            </div>
          </Form>
          <div className="flex-grow-1 my-3 h-100">
            <div className="d-flex row h-100 p-0 m-0 align-items-end">
              <div className="w-100">
                <Button
                  className="qr-btn queue w-100 my-3"
                  onClick={handleSubmit}
                  style={{
                    backgroundColor: accentColor,
                  }}
                >
                  Send Message
                </Button>
                <Button
                  className="qr-btn cancel queue w-100 my-2"
                  onClick={handleOnCancel}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </Formik>
  )
}
export default LeaveMessageForm
