import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import InputField from 'components/form/InputField'
import Dialog from 'components/WorkerDialog'
import useDuplicateEntryPoint from 'routes/ChatSettingsEntryPoint/hooks/useDuplicateEntryPoint'

const DuplicateEntryPointDialog = ({
  show,
  onClose,
  item,
}) => {
  const [name, setName] = useState(item?.name)
  const { duplicateEntryPoint } = useDuplicateEntryPoint(item?.id)

  const handleConfirm = async () => {
    duplicateEntryPoint.mutate({
      name,
      company_id: item?.company.id,
    })

    onClose()
  }

  return (
    <Dialog
      show={show}
      onClose={onClose}
      title="Duplicate Entry Point"
      confirmText="Save"
      loading={duplicateEntryPoint.isLoading}
      onConfirm={handleConfirm}
    >
      <Row>
        <Col md={5}>
          <p className="fw-bold">Name</p>
        </Col>
        <Col md={7}>
          <InputField
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Set entry point name"
          />
        </Col>
      </Row>
    </Dialog>
  )
}

export default DuplicateEntryPointDialog
