import { useCallback, useState } from 'react'
import Video from 'twilio-video'
import useAvailableDevices from 'hooks/useAvailableDevices/useAvailableDevices'
import { SELECTED_VIDEO_INPUT_KEY } from 'providers/VideoProvider/constants'

export default function useLocalVideoTrack() {
  const {
    videoInputDevices,
    hasVideoInputDevices,
  } = useAvailableDevices()
  const [videoTrack, _setVideoTrack] = useState()
  const [isAcquiringVideoTrack, setIsAcquiringVideoTrack] = useState(false)
  const [videoMediaError, setVideoMediaError] = useState()

  const setVideoTrack = useCallback((deviceId, trackOptions) => {
    // We are storing all selected media devices ID in localStorage
    localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, deviceId)

    videoTrack?.restart({
      ...trackOptions,
      deviceId: { exact: deviceId },
    }).then(() => _setVideoTrack(videoTrack))
      .catch((e) => {
        setVideoMediaError(e)
      })
  }, [videoTrack])

  // Prompt browser to ask for camera permissions
  const createNewVideoTrack = useCallback(async (trackOptions) => {
    if (!hasVideoInputDevices) {
      return Promise.resolve()
    }

    if (isAcquiringVideoTrack || videoTrack) {
      return Promise.resolve()
    }

    setIsAcquiringVideoTrack(true)

    const selectedVideoDeviceId = localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)
    const hasSelectedVideoDevice = videoInputDevices
      .some((device) => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId)

    const options = {
      ...trackOptions,
      ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId } }),
      name: `camera-${Date.now()}`,
    }

    return Video.createLocalVideoTrack(options)
      .then((newTrack) => {
        _setVideoTrack(newTrack)
        localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newTrack.mediaStreamTrack.getSettings().deviceId)
        return newTrack
      }).catch((e) => {
        setVideoMediaError(e)
      })
      .finally(() => setIsAcquiringVideoTrack(false))
  }, [videoInputDevices, hasVideoInputDevices, videoTrack, isAcquiringVideoTrack])

  const removeLocalVideoTrack = useCallback(() => {
    if (videoTrack) {
      videoTrack.stop()
      // _setVideoTrack(undefined)
    }
  }, [videoTrack])

  return {
    videoTrack,
    videoMediaError,
    setVideoMediaError,
    setVideoTrack,
    createNewVideoTrack,
    isAcquiringVideoTrack,
    removeLocalVideoTrack,
  }
}
