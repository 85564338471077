import config from 'utils/config'
import http from 'utils/http'
import handleResponse from 'utils/handleResponse'
import parseAndRethrowError from 'utils/parseAndRethrowError'

const TARGET_URL = `${config.SERVER_URL}${config.API_URL}`

class Stripe {
  // returns a url to redirect to stripe checkout page
  createCheckout = (planId) => (
    http.post(`${TARGET_URL}/stripe/checkout?plan_id=${planId}`)
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )
}

export default new Stripe()
