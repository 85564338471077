import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import throttle from 'lodash/throttle'
import thunk from 'redux-thunk'
import rootReducer from './rootReducer'
import { loadState, saveState } from './localStorage'

const persistedState = loadState()
const store = createStore(
  rootReducer,
  persistedState,
  composeWithDevTools(applyMiddleware(thunk)),
)

/**
 * Add throttle of 1000ms since we are using
 * the expensive JSON.stringify function
 * and save only needed states to localStorage
 */
store.subscribe(throttle(() => {
  saveState({
    serviceIntroMessage: store.getState().serviceIntroMessage,
    acceptedRequestID: store.getState().acceptedRequestID,
    chatRequest: store.getState().chatRequest,
    company: store.getState().company,
    user: {
      data: store.getState().user.data, // This way we can persist only the stuff we want to be persisted
    },
    activeConversations: store.getState().activeConversations?.map(({
      user, consumer, participants, ...rest
    }) => rest),
    consumerConversations: store.getState().consumerConversations?.map(({
      user, consumer, participants, ...rest
    }) => rest),
    // using map prototype to exclude user and consumer activity logs from store. It prevents online/offline activity conflict
  })
}, 1000))

export default store
