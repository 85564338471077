import React from 'react'
import { useHistory } from 'react-router-dom'
import LoadingButton from 'components/buttons/LoadingButton'
import { useChatConfiguration } from 'routes/ChatSettingsEntryPoint/provider/ChatConfigurationProvider'

const BottomChatConfigurationButtons = ({
  isLoading,
  modifiedState,
  setModifiedState,
}) => {
  const history = useHistory()

  const {
    activeStep,
    setActiveStep,
    firstStep,
    lastStep,
  } = useChatConfiguration()

  const handleBackButton = () => {
    if (activeStep === firstStep) {
      history.push('/admin/chat-settings')
    }
    setActiveStep(activeStep === firstStep ? firstStep : activeStep - 1)
    setModifiedState(false)
  }

  const handleNextButton = () => {
    if (activeStep === lastStep) {
      history.push('/admin/chat-settings')
    }
    setActiveStep(activeStep === lastStep ? lastStep : activeStep + 1)
    setModifiedState(false)
  }

  return (
    <>
      <LoadingButton
        disabled={isLoading || modifiedState}
        className="btn-danger cancel"
        onClick={handleBackButton}
      >
        {activeStep === firstStep ? 'Cancel' : 'Back'}
      </LoadingButton>
      <LoadingButton
        loading={isLoading}
        disabled={modifiedState}
        spinnerSize={18}
        className="btn-success"
        onClick={handleNextButton}
      >
        {activeStep === lastStep ? 'Done' : 'Next'}
      </LoadingButton>
    </>
  )
}

export default BottomChatConfigurationButtons
