import React, { useEffect, useState } from 'react'
import {
  Route,
  Redirect,
  useHistory,
} from 'react-router-dom'
import { useAuth } from 'providers/AuthProvider/AuthProvider'
import VideoProvider from 'providers/VideoProvider'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import useNotifyChatRequests from 'hooks/useNotifyChatRequests'

const ProtectedRoute = ({
  component: Component,
  layout: Layout,
  userRole,
  ...rest
}) => {
  const {
    userRole: getUserRole,
    isLoggedIn: authIsLoggedIn,
    user,
  } = useAuth()

  const [isLoggedIn, setIsLoggedIn] = useState(true)
  const [isLoading, setIsLoading] = useState(true)

  const history = useHistory()

  useEffect(() => {
    setIsLoggedIn(authIsLoggedIn)

    setTimeout(() => {
      setIsLoading(false)
    }, 300)
  }, [authIsLoggedIn])

  useNotifyChatRequests()

  if (isLoading) {
    return <LoadingSpinner className="d-flex justify-content-center align-items-center w-100 vh-100" />
  }

  if (!isLoggedIn) {
    history.push('/')
    return null
  }

  // If the user hasn't set up his personal info, redirect to /personal-info
  if (!user?.full_name) {
    return <Redirect to="/personal-info" />
  }

  // If the user mail is not verified, redirect to /verify-email page
  if (!user?.confirmed_at) {
    return <Redirect to="/verify-email" />
  }

  const noProjectSetup = getUserRole === undefined && user?.roles?.length === 0

  // If the user is not assigned to a project (has no role), redirect to /project-info
  if (noProjectSetup) {
    return <Redirect to="/account-setup" />
  }

  // check if route is restricted by role
  if (userRole && userRole.indexOf(getUserRole) === -1) {
    return <Redirect to="/" />
  }

  return (
    (
      <Route
        {...rest}
        render={(props) => (
          /**
         * TODO: Token Validation on isLogged from backend
         */
          (Layout ? (
            <VideoProvider>
              <Layout>
                <Component {...props} />
              </Layout>
            </VideoProvider>
          ) : (
            <VideoProvider>
              <Component {...props} />
            </VideoProvider>
          ))
        )}
      />
    )
  )
}

export default ProtectedRoute
