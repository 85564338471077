import React from 'react'
import MessagingLayout from 'features/qrflow/layouts/MessagingLayout'
import ChatWindow from 'features/qrflow/components/chat/ChatWindow'
import VideoProvider from 'providers/VideoProvider'
import ChatProvider from 'providers/ChatProvider'
import withProviders from 'hoc/withProviders'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

TimeAgo.addLocale(en)

const ChatApp = ({ token, conversationSid }) => (
  <MessagingLayout>
    <ChatWindow
      token={token}
      conversationSid={conversationSid}
    />
  </MessagingLayout>
)

export default withProviders(VideoProvider, ChatProvider)(ChatApp)
