export const SWYTCHBOARD_LOCALSTORAGE_KEY = 'swytchboard'

export const ConversationTypes = {
  videoText: 'video_text',
  video: 'video',
  text: 'text',
}

export const ChatStatuses = {
  pending: 'pending',
  accepted: 'accepted',
  canceled: 'canceled',
  declined: 'declined',
  completed: 'completed',
  silentCanceled: 'silentCanceled', // silentCanceled is only used by front-end, it does not reflect as a state in db model.
}

export const CustomerInfoOptions = {
  NONE: 'none',
  MANAGER_ONLY: 'manager_only',
  ALL: 'all',
}

export const MessageEvent = {
  LEAVE_CONVERSATION: 'FE:LEAVE_CONVERSATION',
  END_CONVERSATION: 'FE:END_CONVERSATION',
}

//  Track Priority can be understood as relevance of the tracks
//  More resources are allocated for tracks with higher priorities
//  Tracks with 'high' priority are considered more important than tracks with 'standard' or 'low' priorities
export const TrackPriority = {
  HIGH: 'high',
  STANDARD: 'standard',
  LOW: 'low',
  DEFAULT: null, // When Track Priority is set to default, we revert to the original publish priority of the participant
}

export const WORKER_VIDEO_NOT_CONNECTED_MESSAGE = 'Hang on tight, we will be back soon!'
export const WORKER_VIDEO_TURNED_OFF = 'Your camera is off!'
export const CUSTOMER_VIDEO_NOT_CONNECTED_MESSAGE = 'Customer video stream is disabled.'
export const CUSTOMER_DISCONNECTED = 'Customer has been disconnected.'

/**
 * Used to define various redux action states
 * @type {{
 *    Completed: 'completed',
 *    InProgress: 'in-progress',
 *    Initial: null,
 *    Error: 'error', - The key containing the error object
 * }}
 */
export const ActionState = {
  Initial: null,
  InProgress: 'in-progress',
  Completed: 'completed',
  Error: 'error',
}

export const participantState = {
  connected: 'connected',
  disconnected: 'disconnected',
}

export const uploadedMaxSize = 1000000 // 1MB
export const uploadedFileMaxSize = 25000000 // 25MB
export const defaultTracksExpiryTime = 600000 // 6h
export const activeConversationsLimit = 5

export const fileType = Object.freeze({
  COMPANY_LOGO: 'company_logo',
  USER_AVATAR: 'avatar',
  COMPANY_SERVICE_LOGO: 'company_service_logo',
})

export const QueryKeys = {
  WORKERS: 'workers',
  CONVERSATIONS: 'conversations',
  WORK_LOGS: 'work_log',
  PENDING_CHAT_REQUESTS: 'pending_chat_requests',
  AVAILABLE_WORKERS: 'available_workers',
  ONLINE_USERS: 'online_users',
  PUNCHED_IN_USERS: 'punched_in_users',
  ENTRY_POINT: 'entry_point',
  CUSTOMER_FEEDBACK: 'customer_feedback',
  ACCEPTED_CHAT_REQUESTS: 'accepted_chat_requests',
}

export const ParticipantTypes = {
  USER: 'user',
  CONSUMER: 'consumer',
  ALL: 'participants',
}

// This is used to store the selected user stats setting in localStorage
export const SWYTCHBOARD_USER_STATS = 'swytchboard-user-stats'

// Default service intro message
export const defaultServiceIntroMessage = `Hi, we’re here to personally assist you! For a free-flowing conversation,
  please enable your microphone, and make sure sound is up.`

export const ONE_MINUTE_IN_MS = 60_000
export const ONE_DAY_IN_MS = 86_400_000
export const FIVE_MIN_IN_MS = 300_000
export const SECONDS_IN_MS = 1_000

export const PermissionStatus = {
  default: 'default',
  denied: 'denied',
  granted: 'granted',
}
