import React from 'react'

/**
 * A HoC used to wrap components with React Context Providers
 *
 * Reference: https://maximzubarev.com/wrap-providers-using-withprovider-hoc
 * Usage: withProviders([CounterProvider, { start: 5 }], DarkModeProvider)(Component)
 *
 * If we want to pass props to the provider, we use an array (e.g. CounterProvider)
 * Otherwise we just pass the provider
 */
const withProviders = (...providers) => (WrappedComponent) => (componentProps) => providers
  .reduceRight((acc, argument) => {
    const Provider = Array.isArray(argument) ? argument[0] : argument
    const providerProps = argument[1]

    return <Provider {...providerProps}>{acc}</Provider>
  }, <WrappedComponent {...componentProps} />)

export default withProviders
