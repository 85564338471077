import React from 'react'
import {
  Alert,
  Button, Form,
} from 'react-bootstrap'
import { Formik } from 'formik'
import LoadingButton from 'components/buttons/LoadingButton'
import useJoinExistingProject from 'routes/AccountSetup/JoinExistingProject/useJoinExistingProject'
import Layout from 'routes/AccountSetup/Layout'

const noPendingInvitationMessage = (
  <>
    <p>
      At this time, there is no pending invitations sent to the provided e-mail address.
    </p>
    <p>
      To continue, please ask your team manager to send you an invitation to their project.
    </p>
  </>
)

const pendingInvitationForProjectMessage = (projectName) => (
  <>
    <p>
      There is a pending invitation sent to the provided e-mail address for access to the project
      {' '}
      <strong>
        {projectName}
      </strong>
      .
    </p>
    <p>
      To continue, please accept the invitation below.
    </p>
  </>
)

const JoinExistingProject = () => {
  const {
    errorMessage,
    isAcceptingInvitation,
    isLoadingExistingInvitation,
    projectName,
    onAcceptInvitation,
    onClearErrorMessage,
    navigateToSetupNewProject,
    onBackClickHandler,
  } = useJoinExistingProject()

  // Contains information about the status of the user's active invitation
  let invitationInfoMessage = null

  const noPendingInvitation = !isLoadingExistingInvitation && !projectName
  const foundPendingInvitation = !isLoadingExistingInvitation && projectName

  if (noPendingInvitation) {
    invitationInfoMessage = noPendingInvitationMessage
  }

  if (foundPendingInvitation) {
    invitationInfoMessage = pendingInvitationForProjectMessage(projectName)
  }

  return (
    <Formik
      initialValues={{}}
      onSubmit={onAcceptInvitation}
    >
      {({
        handleSubmit,
        handleChange,
      }) => (
        <Layout displayLoader={isLoadingExistingInvitation}>
          <Form.Group>
            <Form.Check
              name="interest"
              label="Setup a new account"
              type="radio"
              className="mb-2"
              onChange={handleChange}
              onClick={navigateToSetupNewProject}
            />
            <Form.Check
              name="interest"
              label="Join an existing team"
              type="radio"
              className="mb-2"
              checked
              onChange={handleChange}
            />
          </Form.Group>
          {invitationInfoMessage}
          {errorMessage && (
            <Alert
              variant="danger"
              dismissible
              onClose={onClearErrorMessage}
            >
              {errorMessage}
            </Alert>
          )}
          <div className="d-flex justify-content-between mt-5">
            <Button
              onClick={onBackClickHandler}
              className="account-setup__button account-setup__button--back"
            >
              Back
            </Button>
            <LoadingButton
              disabled={isAcceptingInvitation || noPendingInvitation}
              loading={isAcceptingInvitation}
              onClick={handleSubmit}
              className="account-setup__button account-setup__button--submit"
            >
              Accept
            </LoadingButton>
          </div>
        </Layout>
      )}
    </Formik>
  )
}

export default JoinExistingProject
