import http from 'utils/http'
import config from 'utils/config'
import axios from 'axios'

class Image {
  /**
   * Calls the Lambda function to get a presigned url on which you can upload the file.
   * @param {object} file
   * @param {string} userId
   * @param {string} fileType
   * @param {string} contentType
   * @param {string} fileExt
   * @param {string} companyId
   * @param {string} companyServiceId
   * @returns {object}
   */
  upload = async ({
    file,
    userId,
    fileType,
    contentType,
    fileExt,
    companyId,
    companyServiceId,
  }) => {
    try {
      const { data } = await http.post(`${config.API_ASSETS_URL}/get-upload-url`,
        {
          user_id: userId,
          file_type: fileType,
          content_type: contentType,
          file_ext: fileExt,
          company_id: companyId,
          company_service_id: companyServiceId,
        })

      await axios.put(data.uploadURL, file)

      return {
        s3Key: data.s3Key,
        imageURL: `${config.ASSETS_URL}/${data.s3Key}`,
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  get = (s3Key) => `${config.ASSETS_URL}/${s3Key}`
}

export default new Image()
