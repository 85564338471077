/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import LoadingButton from 'components/buttons/LoadingButton'
import ReactTimeAgo from 'react-time-ago'
import { useDispatch } from 'react-redux'
import Chat from 'services/Chat'
import { ConversationTypes, ParticipantTypes } from 'utils/constants'
import SwytchAvatar from 'components/user/SwytchAvatar'
import useActiveConversations from 'hooks/useActiveConversations'
import { updateActiveConversation } from 'redux/activeConversations/actions'
import useConversationSettings from 'hooks/useActiveConversations/useConversationSettings'
import useCustomerName from 'hooks/useCustomerName'

const RequestVideoMessage = ({ message }) => {
  const [isAcceptingRequestVideo, setIsAcceptingRequestVideo] = useState(false)
  const { id: chatRequestId, isAnyVideoCallActive } = useActiveConversations()
  const { workerKey, defaultSettings } = useConversationSettings()
  const { consumerName } = useActiveConversations(chatRequestId)
  const customerNameFullName = useCustomerName()
  const dispatch = useDispatch()
  const onAcceptRequestVideo = async () => {
    setIsAcceptingRequestVideo(true)
    const conversationData = await Chat.engageChatCallRequest(chatRequestId, ConversationTypes.videoText)
    dispatch(
      updateActiveConversation(chatRequestId, {
        [ParticipantTypes.CONSUMER]: {},
        consumerName: conversationData.consumer_name,
        requestType: ConversationTypes.videoText,
        roomName: conversationData.room_name,
        roomToken: conversationData.token,
        conversationSID: conversationData.conversation_sid,
        serviceIntroMessage: conversationData.welcome_message,
        settings: { [workerKey]: defaultSettings[workerKey], expiry: null },
        requestedVideoChat: null,
      }),
    )
    setIsAcceptingRequestVideo(false)
  }

  return (
    <Row className="m-0 p-0 w-100">
      <Col xs={1} md={2} xl={1} className="m-0 p-0 mt-1 text-center">
        <SwytchAvatar name={consumerName} size={30} />
      </Col>
      <Col xs={11} md={6} className="px-3">
        <div className="request-video">
          <div className="request-video__header">{customerNameFullName(consumerName)} is initiating a video call</div>
          <div className="request-video__footer">
            <LoadingButton spinnerSize={12} loading={isAcceptingRequestVideo} disabled={isAcceptingRequestVideo || isAnyVideoCallActive} onClick={onAcceptRequestVideo} className="btn-success">
              Accept
            </LoadingButton>
            <Button className="btn-danger">Decline</Button>
          </div>
        </div>
        <div className="request-video__timestamp">
          <ReactTimeAgo date={message.dateCreated} locale="en-US" />
        </div>
      </Col>
    </Row>
  )
}
export default RequestVideoMessage
