/**
 * Extracts an errorMessage from an Object.
 * The object can be of type JS Error, or a custom object in format: { error: error }
 *
 * @param object
 * @returns {string}
 */
export default function useErrorMessageFromObject(object) {
  let errorMessage = ''

  if (object instanceof Error) {
    errorMessage = object.error || object.message || 'Oops, try again!'
  } else if (object?.error) {
    const { error } = object
    errorMessage = error.error || error.message || 'Oops, try again!'
  }

  return errorMessage
}
