import React from 'react'
import WorkerDialog from 'components/WorkerDialog'
import useTogglePunchInState from 'features/work-logs/useTogglePunchInState'
import useIsWorkerPunchedIn from 'features/work-logs/useIsWorkerPunchedIn'

const PuchOutWarningDialog = ({
  show,
  onClose,
  onConfirm,
}) => {
  const {
    isLoadingPunchedInState,
  } = useIsWorkerPunchedIn()

  const {
    isTogglingPunchInState,
  } = useTogglePunchInState()

  return (
    <WorkerDialog
      show={show}
      onClose={onClose}
      onConfirm={onConfirm}
      loadin={isLoadingPunchedInState || isTogglingPunchInState}
      disabled={isLoadingPunchedInState || isTogglingPunchInState}
      title="Logout"
    >
      <p>
        Are you sure that you want to logout. If Yes, then you will be punched out!
      </p>
    </WorkerDialog>
  )
}

export default PuchOutWarningDialog
