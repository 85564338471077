/* eslint-disable no-nested-ternary */
import React, {
  useEffect, useRef, useState,
} from 'react'
import { Button } from 'react-bootstrap'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import QRMainLayout from 'features/qrflow/layouts/QRMainLayout'
import CancelRequestDialog from 'features/qrflow/components/dialog/CancelRequestDialog'
import useDialog from 'hooks/useDialog'
import useRedirectChatRequest from 'features/qrflow/hooks/useRedirectChatRequest'
import { useDispatch } from 'react-redux'
import useConsumerConversationChannel from 'features/qrflow/channels/useConsumerConversationChannel'
import useGetEntryPointDetails from 'features/qrflow/hooks/useGetEntryPointDetails'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'
import { updateConsumerConversation } from 'redux/consumerConversations/actions'
import { ChatStatuses, ConversationTypes } from 'utils/constants'
import chatRequestOperations from 'redux/chatRequest/operations'
import { Intent } from '@swytchboard/client-sdk-ts'
import logo from 'assets/images/swytchboard-Y-logo.svg'
import VideoProvider from 'providers/VideoProvider'
import withProviders from 'hoc/withProviders'
import useRequestMediaPermission from 'providers/VideoProvider/useRequestMediaPermission/useRequestMediaPermission'
import useMediaPermissions from 'hooks/useMediaPermissions'
import useConsumerConversations from '../hooks/useConsumerConversations'
import { QrFlowRoutes } from '../constants'
import useDesktopNotifications from '../hooks/useDesktopNotification'
import DesktopNotification from '../components/DesktopNotification'
import RequestMediaPermissions from '../components/dialog/RequestMediaPermissions'
import CustomerInactivityDetector from '../components/dialog/CustomerInactivityDetector'
import LeaveAMessageDialog from '../components/dialog/LeaveAMessageDialog'

const QueueScreen = () => {
  const {
    data,
  } = useGetEntryPointDetails()
  const { chatRequestId, serviceUid } = useParams()
  const [queueIndex, setQueueIndex] = useState(0)
  const history = useHistory()
  const { displayDialog, onOpenDialog, onCloseDialog } = useDialog()
  const {
    queue,
    visual,
  } = data?.config ?? {}
  const { askForMicrophonePermissions, askForVideoPermissions } = useRequestMediaPermission()
  const { isCameraEnabled, isMicrophoneEnabled } = useMediaPermissions()
  const { requestType } = useConsumerConversations()
  const declineStatuses = [Intent.DECLINE_CHAT, 'leaveMessage-chat']
  const queueStats = [Intent.NOTIFY_QUEUE_STATS, Intent.NOTIFY_QUEUE_POSITION]
  const arePermissionsGranted = [isCameraEnabled, isMicrophoneEnabled].every((i) => i === true)
  const {
    isDenied, isSupported, permission, requestPermission,
  } = useDesktopNotifications()

  const permissionRef = useRef(permission)

  useEffect(() => {
    permissionRef.current = permission
  }, [permission])

  const { settings: { userId } } = useConsumerConversations()
  const {
    onOpenDialog: onOpenCancelRequestDialog,
    onCloseDialog: onCloseCancelRequestDialog,
    displayDialog: displayCancelRequestDialog,
  } = useDialog()

  const {
    onOpenDialog: onOpenLeaveMessageDialog,
    onCloseDialog: onCloseLeaveMessageDialog,
    displayDialog: displayLeaveMessageDialog,
  } = useDialog()

  const grantMediaPermissions = async () => {
    try {
      await Promise.all([askForVideoPermissions(true), askForMicrophonePermissions()])
    } catch (error) {
      console.warn('Error while granting media permissions')
    }
  }

  const dispatch = useDispatch()

  const onLeaveMessageHandler = () => {
    dispatch(chatRequestOperations.cancelChatRequest(userId, ChatStatuses.silentCanceled))
    return history.push(generatePath(QrFlowRoutes.leaveMessage, { serviceUid, chatRequestId }), { firstStep: false })
  }

  const onWorkerReceiveData = (response) => {
    // Invoked when the worker accepts the chat request
    if (response.message.intent === Intent.JOIN_CHAT) {
      if (permissionRef.current === 'granted' && isSupported && !isDenied) {
        // eslint-disable-next-line no-undef, no-new
        new Notification('Swytchboard', {
          title: `${data?.name} Request Accepted`,
          body: 'Your request has been accepted, you will be redirected to the chat room',
          silent: false,
          icon: logo,
        })
      }
      return dispatch(updateConsumerConversation(chatRequestId, { status: ChatStatuses.accepted, workerName: response?.answered_by_name }))
    }

    if (response.message.intent === Intent.NOTIFY_APPEARANCE) {
      const {
        type,
        status,
      } = response?.message
      return dispatch(updateConsumerConversation(chatRequestId, { [type]: { status } }))
    }
    // Invoked when call was accepted, but we missed the intent
    if (response.message.event === 'conversation-status') {
      if (response?.message?.data?.status === ChatStatuses.accepted) {
        return dispatch(updateConsumerConversation(chatRequestId, {
          status: ChatStatuses.accepted,
          workerName: response?.message?.data?.answered_by_name,
        }))
      }
      return dispatch(updateConsumerConversation(chatRequestId, {
        status: response?.message?.data?.status,
      }))
    }

    if (declineStatuses.includes(response.message.intent)) {
      return dispatch(updateConsumerConversation(chatRequestId, { status: ChatStatuses.declined, intent: response.message.intent }))
    }
    if (response.message.intent === 'back-to-start') {
      return dispatch(updateConsumerConversation(chatRequestId, {
        status: ChatStatuses.canceled, intent: response.message.intent,
      }))
    }
    if (queueStats.includes(response.message.intent)) {
      return setQueueIndex(response?.message?.message?.position - 1 || 0)
    }
    return []
  }

  useConsumerConversationChannel(onWorkerReceiveData)

  useRedirectChatRequest()

  return (
    <QRMainLayout>
      <h2 className="qr-container__title">
        QUEUE
        <div
          className="qr-container__title__line"
          style={{
            backgroundColor: visual?.accent_color,
          }}
        />
      </h2>
      <RequestMediaPermissions
        show={displayDialog}
        onConfirm={grantMediaPermissions}
        onClose={onCloseDialog}
        isCameraEnabled={isCameraEnabled}
        isMicrophoneEnabled={isMicrophoneEnabled}
      />
      <div className="qr-container__queue bg-light p-4 my-3 rounded">
        <IcomoonReact
          iconSet={iconSet}
          icon="icon-shifts"
          size="23"
          color="#3D4043"
        />
        <p className="bold m-0 pt-3">{queueIndex >= 1 ? `People in queue before you: ${queueIndex}` : 'You’re next!'}</p>
      </div>
      {(requestType !== ConversationTypes.text && !arePermissionsGranted) && (
        <Button
          className="qr-btn media_permissions w-100 my-2 rounded"
          onClick={onOpenDialog}
          variant="primary"
          size="sm"
          style={{ backgroundColor: visual?.accent_color }}
        >
          Enable

          {!arePermissionsGranted ? ' Camera & Mic' : !isCameraEnabled ? ' Camera' : ' Microphone'}
        </Button>
      )}

      <div className="screen-intro-text">
        <p>
          {queue?.queue_message}
        </p>
      </div>
      {!isDenied && isSupported ? (
        <DesktopNotification
          backgroundColor={visual?.accent_color}
          permission={permission}
          requestPermission={requestPermission}
        />
      ) : null}
      <div className="flex-grow-1 my-3 h-100">
        <div className="d-flex row h-100 p-0 m-0 align-items-end">
          <div className="w-100">
            {queue?.allow_leave_message === 'false' ? null : (
              <Button
                className="qr-btn  w-100 my-2"
                onClick={onOpenLeaveMessageDialog}
                style={{ backgroundColor: visual?.accent_color }}
              >
                Leave a Message
              </Button>
            )}
            <Button
              className="qr-btn cancel w-100 my-2"
              onClick={onOpenCancelRequestDialog}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
      <LeaveAMessageDialog
        show={displayLeaveMessageDialog}
        onClose={onCloseLeaveMessageDialog}
        onLeaveMessageHandler={onLeaveMessageHandler}
      />
      <CancelRequestDialog
        show={displayCancelRequestDialog}
        onClose={onCloseCancelRequestDialog}
      />
      <CustomerInactivityDetector timeoutPeriod={parseInt(queue?.timeout_period, 10)} />
    </QRMainLayout>
  )
}

export default withProviders(VideoProvider)(QueueScreen)
