/* eslint-disable no-nested-ternary */
import React from 'react'
import { Row, Col, Dropdown } from 'react-bootstrap'
import { ChatQueueConst } from 'routes/ChatSettingsEntryPoint/constants'
import StepField from 'routes/ChatSettingsEntryPoint/components/Field/StepField'
import { detectNewLineInString } from 'utils/helpers'
import ToggleButton from 'routes/ChatSettingsEntryPoint/components/Buttons/ToggleButton'

const timeouts = [{ value: 0, label: 'No Timeout' }, { value: 900, label: '15 Minutes' }, { value: 1800, label: '30 Minutes' }, { value: 3600, label: '1 Hour' }, { value: 10800, label: '3 Hours' }]

const Editable = ({
  queueMessage,
  setQueueMessage,
  setModifiedState,
  customerInactivity,
  setCustomerInactivity,
  allowLeaveMessageEnabled,
  setAllowLeaveMessageEnabled,
}) => {
  const handleQueueMessage = (e) => {
    setQueueMessage(detectNewLineInString(e.target.value))
    setModifiedState(true)
  }

  const handleWorkerInactivity = (e) => {
    setModifiedState(true)
    setCustomerInactivity(Number(e))
  }

  const handleAllowLeaveMessageEnable = () => {
    setModifiedState(true)
    setAllowLeaveMessageEnabled(!allowLeaveMessageEnabled)
  }

  return (
    <Col className="chat-queue-editable">
      <StepField
        title={ChatQueueConst.queueMessage.title}
        subTitle={ChatQueueConst.queueMessage.subTitle}
      >
        <Row className="w-100">
          <Col className="chat-editable w-100">
            <textarea
              maxLength="400"
              rows={6}
              value={queueMessage}
              onChange={handleQueueMessage}
            />
          </Col>
        </Row>
      </StepField>
      <StepField
        title={ChatQueueConst.customerInactivity.title}
        subTitle={ChatQueueConst.customerInactivity.subTitle}
      >
        <Dropdown className="d-flex" onSelect={handleWorkerInactivity}>
          <Dropdown.Toggle
            variant="light"
            className="d-flex justify-content-between align-items-center px-4 text-capitalize"
            style={{
              width: 150, height: 40, fontSize: 14,
            }}
          >
            {timeouts.find((t) => t.value === customerInactivity)?.label || 'Choose Timeout'}
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ width: 150 }}>
            {timeouts.map(({ value, label }) => (
              <Dropdown.Item
                key={label}
                active={customerInactivity === value}
                eventKey={value}
              >
                {label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </StepField>
      <StepField
        title={ChatQueueConst.allowLeaveMessage.title}
        subTitle={ChatQueueConst.allowLeaveMessage.subTitle}
        inputClassContainer="toggle-button"
        inputClassContent="toggle-button__content"
      >
        <ToggleButton
          state={allowLeaveMessageEnabled}
          onClick={handleAllowLeaveMessageEnable}
        />
      </StepField>
    </Col>
  )
}

export default Editable
