/* eslint-disable max-len */
import React from 'react'
import { Button, Col } from 'react-bootstrap'
import { parseURL } from 'utils/helpers'
import { DISPLAY_NOT_AVAILABLE_MESSAGE } from 'features/qrflow/constants'

const PunchedOut = ({
  onNextStepClickHandler, landingPageConfig, accentColor, leaveMessageAllowed,
}) => {
  const displayNotAvailableWarningMessage = landingPageConfig?.away_message || DISPLAY_NOT_AVAILABLE_MESSAGE
  const websiteLink = landingPageConfig?.website !== null && landingPageConfig?.website !== ''

  return (
    <Col className="col-12 mx-auto">
      <div className="justify-content-between text-center my-5">
        <p style={{ whiteSpace: 'pre-line' }} className="my-5">
          {displayNotAvailableWarningMessage}
        </p>
      </div>
      <Col className="justify-content-between my-5">
        {leaveMessageAllowed && (
        <Button
          onClick={() => onNextStepClickHandler()}
          className="qr-btn green w-100 mb-5"
          style={{ backgroundColor: accentColor }}
        >
          Leave a Message
        </Button>
        )}

        {websiteLink && (
          <Button
            className="qr-btn white w-100 mb-5"
            onClick={() => {
              window.open(parseURL(landingPageConfig?.website))
            }}
          >
            {landingPageConfig?.call_to_action_text || 'Go to Product Website'}
          </Button>
        )}
      </Col>
    </Col>
  )
}

export default PunchedOut
