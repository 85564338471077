// This is used only in the consumer /chat endpoint
export function parseMessage(message) {
  const individualMessage = {}
  individualMessage.body = message.state.body
  individualMessage.author = message.state.author
  individualMessage.timestamp = message.dateCreated
  individualMessage.sid = message.state.sid
  return individualMessage
}

export function parseSystemMessage(identity, body) {
  const individualMessage = {}
  individualMessage.body = identity + body
  individualMessage.author = 'system'
  individualMessage.timestamp = Date.now()
  individualMessage.sid = individualMessage.timestamp
  return individualMessage
}

export function parseRequestVideoMessage() {
  const message = {}
  message.body = ''
  message.author = 'request-video'
  message.dateCreated = Date.now()
  message.sid = message.timestamp
  return message
}
