import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Alert } from 'react-bootstrap'
import useErrorMessageFromObject from 'hooks/useErrorMessageFromObject'
import WorkerDialog from 'components/WorkerDialog'
import useActiveConversations from 'hooks/useActiveConversations'
import Chat from 'services/Chat'
import { useToasts } from 'react-toast-notifications'
import { updateActiveConversation } from 'redux/activeConversations/actions'
import { ParticipantTypes, ConversationTypes } from 'utils/constants'
import useConversationSettings from 'hooks/useActiveConversations/useConversationSettings'
import { useParams } from 'react-router-dom'

const RequestVideoCallDialog = ({
  show,
  onClose,
}) => {
  const { chatRequestId } = useParams()
  const { requestType, consumerName } = useActiveConversations(chatRequestId)
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { workerKey, defaultSettings } = useConversationSettings()

  const engageVideo = async () => {
    try {
      const conversationData = await Chat.engageChatCallRequest(chatRequestId, ConversationTypes.videoText)
      dispatch(
        updateActiveConversation(chatRequestId, {
          [ParticipantTypes.CONSUMER]: {},
          consumerName: conversationData.consumer_name,
          requestType: ConversationTypes.videoText,
          roomName: conversationData.room_name,
          roomToken: conversationData.token,
          conversationSID: conversationData.conversation_sid,
          serviceIntroMessage: conversationData.welcome_message,
          settings: { [workerKey]: defaultSettings[workerKey], expiry: null },
          requestedVideoChat: null,
        }),
      )
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      })
    }
  }

  useEffect(() => {
    if (requestType === 'video_text') {
      onClose()
    }
  }, [requestType, onClose])

  const errorMessage = useErrorMessageFromObject(requestType)

  return (
    <WorkerDialog
      show={show}
      title="Engage Video"
      onClose={onClose}
      onConfirm={engageVideo}
    >
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      <p>
        Would you like to initiate a video call with
        <b>
          &nbsp;
          {consumerName}
        </b>
        ?
      </p>
    </WorkerDialog>
  )
}

export default RequestVideoCallDialog
