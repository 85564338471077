import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'
import LoadingButton from 'components/buttons/LoadingButton'

const WorkerDialog = ({
  show = true,
  onConfirm,
  onClose,
  title,
  children,
  confirmText = 'Confirm',
  closeText = 'Cancel',
  loading = false,
  disabled = false,
  footer = true,
  onExited,
  ...others
}) => {
  // By default, the cancel button closes the dialog
  // We can override the Cancel Button functionality by passing onBackPress prop
  const onBackPress = others?.onBackPress || onClose

  return (
    <Modal
      show={show}
      onHide={onClose}
      contentClassName="worker-dialog"
      centered
      // onExited is a callback fired after the Modal finishes transitioning out (completely closes)
      onExited={onExited}
      scrollable
    >
      <Modal.Header className="worker-dialog__header-wrapper">
        <div className="worker-dialog__header-content">
          {title}
          <IcomoonReact
            className="worker-dialog__header-content__icon"
            icon="icon-cross"
            iconSet={iconSet}
            onClick={onClose}
          />
        </div>
      </Modal.Header>
      <Modal.Body className="worker-dialog__body">
        {children}
      </Modal.Body>
      {footer && (
      <Modal.Footer className="worker-dialog__footer">
        <Button
          onClick={onBackPress}
          variant="outline-danger"
        >
          {closeText}
        </Button>
        <LoadingButton
          onClick={onConfirm}
          className="btn-success"
          loading={loading}
          disabled={disabled}
        >
          {confirmText}
        </LoadingButton>
      </Modal.Footer>
      )}
    </Modal>
  )
}

export default WorkerDialog
