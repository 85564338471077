import { useRef, useState } from 'react'

export default function useCropImage() {
  const [uploadedImage, setUploadedImage] = useState()
  const imgRef = useRef(null)
  const croppedImage = useRef(null)
  const [crop, setCrop] = useState({
    unit: 'px', width: 140, height: 140, aspect: 1,
  })

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () => setUploadedImage(reader.result))
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const onLoad = (img) => {
    imgRef.current = img
  }

  const getCroppedImg = (image, fileName) => {
    if (!imgRef.current) {
      return
    }

    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    )

    // eslint-disable-next-line consistent-return
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'))
          return
        }
        // eslint-disable-next-line no-param-reassign
        blob.name = fileName
        resolve(blob)
      }, 'image/jpeg')
    })
  }

  const makeClientCrop = async () => {
    if (imgRef.current && crop.width && crop.height) {
      croppedImage.current = await getCroppedImg(
        imgRef.current,
        'newFile.jpeg',
      )
    }
  }

  const onCropComplete = () => {
    makeClientCrop()
  }

  return {
    uploadedImage,
    croppedImage,
    crop,
    setCrop,
    onSelectFile,
    onLoad,
    onCropComplete,
  }
}
