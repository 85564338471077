import React from 'react'

const TableHeader = ({ headers }) => (
  <thead>
    <tr className="table-header">
      {headers.map((name) => (
        <th key={name} className={`${name}`}>
          {name}
        </th>
      ))}
    </tr>
  </thead>
)

export default TableHeader
