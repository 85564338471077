/* eslint-disable max-len */
import React from 'react'
import { Col, Row } from 'react-bootstrap'

const PrivacyPolicy = () => (
  <Col md={12} className="bg-white h-100 d-flex align-items-center justify-content-center">
    <Col md={12}>
      <Row className="justify-content-center">
        <Col md={2} className="align-items-center justify-content-center mt-4">
          <h3 className="fw-bold mb-3">Website Privacy Policy</h3>
          <hr className="mt-4" />
        </Col>
      </Row>
      <Row className="justify-content-center mt-3">
        <Col md={12}>
          <div>
            <p>Last modified: June 14, 2022</p>
            <h2 style={{ textDecorationLine: 'underline' }}>Introduction</h2>
            <p>
              Swytchboard, Inc. (“
              <b>Company</b>
              ,” “
              <b>We</b>
              ” or “
              <b>Us</b>
              ”)
              respects your privacy and is committed to protecting it through our compliance with this policy (the “
              <b>Privacy Policy</b>
              ”).

            </p>
            <p>
              This policy describes the types of information we may collect from you or that you may provide when you visit the website
              <a href="http://www.swytchboard.com/">
                {' '}
                www.joinswytchboard.com
                {' '}
              </a>
              , its subdomains and any associated mobile applications, QR codes and scanners, widgets, or other platforms through which we provide our services (our “
              <b>Website</b>
              ”) and our practices for collecting, using, maintaining, protecting, and disclosing that information.
            </p>
            <p>This policy applies to information we collect:</p>
            <ul>
              <li><p>On this Website.</p></li>
              <li><p>In email, text, and other electronic messages between you and this Website.</p></li>
              <li><p>When you interact with our advertising and applications on third-party websites and services, if those applications or advertising include links to this policy.</p></li>
              <li><p>From our third-party business partners It does not apply to information collected by:</p></li>
              <li><p>Us offline or through any other means, including on any other website operated by Company or any third party, or</p></li>
              <p>Any third party through any application or content (including advertising) that may link to or be accessible from or on the Website. We encourage you to review the privacy policies of any such third parties, as they will control the collection and use of any such information.</p>
            </ul>
            <p>Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our Website. By accessing or using this Website, you agree to this privacy policy. This policy may change from time to time (see Changes to Our Privacy Policy). Your continued use of this Website after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.</p>
            <p>This Privacy Policy is intended for use of our Website by consumers in the United States and Canada. Local laws and regulations may apply to users in other countries, including situations where third parties contract with us to provide our services through their websites and mobile applications without directing consumers to our Website. In such situations, the terms of use and privacy policies of such third parties as well as the applicable local laws and regulations shall control the collection and use of any Personal Information you may disclose.</p>
            <h2 style={{ textDecorationLine: 'underline' }}>Children Under the Age of 16</h2>
            <p>
              Our Website is not intended for children under 16 years of age. No one under age 16 may provide any Personal Information to or on the Website. We do not knowingly collect Personal Information from children under 16. If you are under 16, do not use this Website, make any purchases through the Website, or provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or user name you may use. If we learn we have collected or received Personal Information from a child under 16 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 16, please contact us at
              <a href="mailto:support@joinswytchboard.com" target="_blank" rel="noreferrer">
                {' '}
                support@joinswytchboard.com.
              </a>
            </p>
            <p>California residents under 16 years of age may have additional rights regarding the collection and sale of their Personal Information. Please see Your California Privacy Rights for more information.</p>
            <h2 style={{ textDecorationLine: 'underline' }}>Information We Collect About You and How We Collect It</h2>
            <p>We collect several types of information from and about users of our Website, including information:</p>
            <ul>
              <li>
                <p>
                  By which you may be personally identified, such as name, postal address, e-mail address, social media handle, telephone number, or credit/payment card information, and information such as voice and likeness for users of our live chat platform (which includes storing and/or playing back recordings of live chats for purposes of providing our services) should you opt-in to such use and recordings (“
                  <b>Personal Information</b>
                  ”);
                </p>
              </li>
              <li><p>That is about you but individually does not identify you, which may include third-party products and services that you have purchased or used or may wish to purchase or use</p></li>
              <li>
                <p>About your internet connection, the equipment you use to access our Website, time spent on our Website, pages viewed on our Website, IP addresses accessing our Website, and other usage details.</p>
              </li>
            </ul>
            <p>We collect this information:</p>
            <ul>
              <li><p>Directly from you when you provide it to us.</p></li>
              <li><p>Automatically as you navigate through the site. Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies.</p></li>
              <li>
                <p>From third parties, for example, our affiliates and business partners</p>
              </li>
            </ul>
            <p style={{ fontStyle: 'italic' }}>Information You Provide to Us</p>
            <p>The information we collect on or through our Website may include:</p>
            <ul>
              <li><p>Information that you provide by filling in forms on our Website. This includes information provided at the time of registering to use our Website, purchasing goods and services on our Website, or requesting further services. We may also ask you for information when you report a problem with our Website.</p></li>
              <li><p>Records and copies of your correspondence (including email addresses), if you contact us.</p></li>
              <li><p>Details of transactions you carry out through our Website and of the fulfillment of your orders. You will be required to provide financial information before placing an order through our Website.</p></li>
              <li><p>Your responses to surveys that we might ask you to complete for research purposes.</p></li>
              <li><p>Your search queries on the Website.</p></li>
              <li><p>Records of products, content, materials, or companies viewed or searched on our Website, or linked to our live chat features via our Website, in-store displays or QR codes.</p></li>
              <li><p>Recordings of live chats with third-party representatives that you request via our Website, in-store displays or QR codes, to which you consent. IF YOU DO NOT CONSENT TO THE RECORDING OF LIVE CHATS WITH THIRD-PARTY REPRESENTATIVES, YOU MUST NOT USE THE LIVE CHAT FEATURE OR ANY OTHER INTERACTIVE SERVICES PROVIDED BY OR THROUGH THE WEBSITE.</p></li>
              <li>
                <p>During live chats with third-party representatives, you may voluntarily disclose Personal Information that is beyond the scope of the categories set forth in this Privacy Policy. The nature of live conversation is unpredictable. While we will only collect and disclose Personal Information according to the procedures set forth in this Privacy Policy, we cannot anticipate or preemptively identify all information that you may choose to disclose during any live conversation. You provide all Personal Information voluntarily and at your own risk.</p>
              </li>
            </ul>
            <p style={{ fontStyle: 'italic' }}>Information We Collect Through Automatic Data Collection Technologies</p>
            <p>As you navigate through and interact with our Website, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:</p>
            <ul>
              <li><p>Details of your visits to our Website, including traffic data, location data, source of accessing our Website, logs, and other communication data and the resources that you access and use on the Website.</p></li>
              <li>
                <p>Information about your computer and internet connection, including your IP address, operating system, and browser type.</p>
              </li>
            </ul>
            <p>We also may use these technologies to collect information about your online activities over time and across third-party websites or other online services (behavioral tracking). Please view the Choices About How We Use and Disclose Your Information section for information on how you can opt out of behavioral tracking on this website.</p>
            <p>The information we collect automatically may include Personal Information, which we may maintain or associate with Personal Information we collect in other ways or receive from third parties. It helps us to improve our Website and to deliver a better and more personalized service, including by enabling us to:</p>
            <ul>
              <li><p>Estimate our audience size and usage patterns.</p></li>
              <li><p>Store information about your preferences, allowing us to customize our Website according to your individual interests.</p></li>
              <li><p>Speed up your searches.</p></li>
              <li>
                <p>Recognize you when you return to our Website.</p>
              </li>
            </ul>
            <p>The technologies we use for this automatic data collection may include:</p>
            <ul>
              <li>
                <p>
                  <strong>Cookies (or browser cookies).</strong>
                  <span>A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our Website. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Website.</span>
                </p>
              </li>
              <li>
                <p>
                  <strong>Web Beacons.</strong>
                  <span>Pages of our the Website and our e-mails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit us, for example, to count users who have visited those pages or opened an email, clicked through, purchased from an email, or unsubscribed from an email list and for other related Website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).</span>
                </p>
              </li>
            </ul>
            <p>We may tie this information to Personal Information about you that we collect from other sources or you provide to us.</p>
            <h2 style={{ textDecorationLine: 'underline' }}>Third-Party Use of Cookies and Other Tracking Technologies</h2>
            <p>Some content or applications, including advertisements, on the Website are served by</p>
            <p>third-parties, including advertisers, ad networks and servers, content providers, and application providers. These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you when you use our website. The information they collect may be associated with your Personal Information or they may collect information, including Personal Information, about your online activities over time and across different websites and other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content.</p>
            <p>We do not control these third parties’ tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly. For information about how you can opt out of receiving targeted advertising from many providers, see Choices About How We Use and Disclose Your Information.</p>
            <h2 style={{ textDecorationLine: 'underline' }}>How We Use Your Information</h2>
            <p>We use information that we collect about you or that you provide to us, including any Personal Information:</p>

            <ul>
              <li><p>To present our Website and its contents to you.</p></li>
              <li><p>To provide you with information, products, or services that you request from us.</p></li>
              <li><p>To fulfill purchase orders, any other purpose for which you provide the information.</p></li>
              <li><p>To provide you with notices about your account on the Website, if any.</p></li>
              <li><p>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.</p></li>
              <li><p>To notify you about changes to our Website or any products or services we offer or provide though it.</p></li>
              <li><p>To contact you with more information about our products that you may be interested in purchasing, or to inquire about the status of incomplete orders left in our digital shopping cart.</p></li>
              <li><p>In any other way we may describe when you provide the information.</p></li>
              <li>
                <p>For any other purpose with your consent.</p>

              </li>
            </ul>
            <p>
              We may also use your information to contact you about our own and third-parties’ goods and services that may be of interest to you. If you do not want us to use your information in this way, please e-mail us at
              <a href="mailto:support@joinswytchboard.com" target="_blank" rel="noreferrer"> support@joinswytchboard.com.</a>
              {' '}
              For more information, see Choices About How We Use and Disclose Your Information.
            </p>
            <h2 style={{ textDecorationLine: 'underline' }}>Disclosure of Your Information</h2>
            <p>We may disclose aggregated information about our users, and information that does not identify any individual, without restriction.</p>
            <p>We may disclose Personal Information that we collect or you provide as described in this privacy policy:</p>
            <ul>
              <li><p>To our third-party partners and vendors who provide you services via our Website, as well as other contractors, service providers, and other third parties we may use from time to time to support our business.</p></li>
              <li><p>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Swytchboard, Inc.’s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Information held by Swytchboard, Inc. about our Website users is among the assets transferred.</p></li>
              <li><p>To third parties to market their products or services to you, if we choose to enter any such agreements and if you have not opted out of these disclosures. For more information, see Choices About How We Use and Disclose Your Information.</p></li>
              <li><p>To fulfill the purpose for which you provide it.</p></li>
              <li><p>For any other purpose disclosed by us when you provide the information.</p></li>
              <li>
                <p>With your consent.</p>
              </li>
            </ul>
            <p>We may also disclose your Personal Information:</p>
            <ul>
              <li><p>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</p></li>
              <li>
                <p>
                  To enforce or apply our terms of use
                  {' '}
                  <a href="https://app.joinswytchboard.com/terms-of-use" target="_blank" rel="noreferrer">https://app.joinswytchboard.com/terms-of-use </a>
                  <span>and any other applicable agreements, including for billing and collection purposes.</span>
                </p>
              </li>
              <li>
                <p>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Swytchboard, Inc., our customers, or others.</p>
              </li>
            </ul>
            <h2 style={{ textDecorationLine: 'underline' }}>Choices About How We Use and Disclose Your Information</h2>
            <p>We strive to provide you with choices regarding the Personal Information you provide to us. We have created mechanisms to provide you with the following control over your information:</p>
            <ul>
              <li>
                <p>
                  <strong>Tracking Technologies and Advertising.</strong>
                  {' '}
                  <span>You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. To learn how you can manage your browser’s cookie settings, visit your browser’s settings page. If you disable or refuse cookies, please note that some parts of this site may then be inaccessible or not function properly.</span>
                </p>
              </li>
              <li>
                <p>
                  <strong>Disclosure of Your Information for Third-Party Advertising.</strong>
                  If you do not want us to share your Personal Information with unaffiliated or non-agent third parties for promotional purposes, you can opt-out by e-mailing us at
                  {' '}
                  <a href="mailto:support@joinswytchboard.com" target="_blank" rel="noreferrer">support@joinswytchboard.com.</a>
                </p>
              </li>
              <li>
                <p>
                  <strong>Promotional Offers from the Company.</strong>
                  {' '}
                  If you do not wish for us to use your email address to promote our own or third parties’ products or services, you can opt-out by sending us an e-mailing us at
                  {' '}
                  <a href="mailto:support@joinswytchboard.com" target="_blank" rel="noreferrer">support@joinswytchboard.com. </a>
                  If we have sent you a promotional email, you may use the unsubscribe function provided in the e-mail. This opt out does not apply to information provided to the Company as a result of a product purchase, warranty registration, product service experience or other transactions.
                </p>
              </li>
              <li>
                <p>
                  <strong>Targeted Advertising.</strong>
                  {' '}
                  If you do not want us to use information that we collect or that you provide to us to deliver advertisements according to our advertisers’ target-audience preferences, you can opt-out by using the advertising blocking functions through your social media accounts or Web browser.
                </p>
              </li>
            </ul>
            <p>
              We do not control third parties’ collection or use of your information to serve interest-based advertising. However, these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative (“
              <b>NAI</b>
              ”) on the NAI’s website.
            </p>
            <p>California residents may have additional Personal Information rights and choices. Please see Your California Privacy Rights for more information.</p>
            <p>
              Nevada residents who wish to exercise their sale opt-out rights under Nevada Revised Statutes Chapter 603A may submit a request to this designated address:
              {' '}
              <a href="mailto:support@joinswytchboard.com" target="_blank" rel="noreferrer">support@joinswytchboard.com.</a>
              {' '}
              However, we do not currently sell data triggering that statute’s opt-out requirements.
            </p>
            <p>Colorado, Virginia and Utah each provide their state residents with rights to:</p>
            <ul id="l2">
              <li><p>Confirm whether we process their personal information.</p></li>
              <li><p>Access and delete certain personal information.</p></li>
              <li><p>Data portability.</p></li>
              <li>
                <p>Opt-out of personal data processing for targeted advertising and sales.</p>
              </li>
            </ul>
            <p>Colorado and Virginia also provide their state residents with rights to:</p>
            <ul>
              <li><p>Correct inaccuracies in their personal information, taking into account the information’s nature processing purpose.</p></li>
              <li><p>Opt-out of profiling in furtherance of decisions that produce legal or similarly significant effects.</p></li>
            </ul>
            <p>
              While the Company is currently exempt from the provisions of the Colorado Privacy Act, the Virginia Consumer Data Protection Act, and the Utah Consumer Privacy Act, we will endeavor to respect your preferences with regard to the use of your personal information. With questions or requests for information, please email us at
              {' '}
              <a href="mailto:support@joinswytchboard.com" target="_blank" rel="noreferrer">support@joinswytchboard.com.</a>
            </p>
            <p>
              Nevada provides its residents with a limited right to opt-out of certain personal information sales. Residents who wish to exercise this sale of opt-out rights may submit a request to
              {' '}
              <a href="mailto:support@joinswytchboard.com" target="_blank" rel="noreferrer">support@joinswytchboard.com. </a>
              However, please know we do not currently sell data triggering that statute’s opt-out requirements.
            </p>
            <h2 style={{ textDecorationLine: 'underline' }}>Accessing and Correcting Your Information</h2>
            <p>
              You may send us an email at
              {' '}
              <a href="mailto:support@joinswytchboard.com" target="_blank" rel="noreferrer">support@joinswytchboard.com </a>
              to request access to, correct or delete any Personal Information that you have provided to us. We cannot delete your Personal Information except by also deleting all records of your use of the Website that we may have. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect. Changing or deleting certain Personal Information may prevent us from providing services to you.
            </p>
            <p>California residents may have additional Personal Information rights and choices. Please see Your California Privacy Rights for more information.</p>
            <h2 style={{ textDecorationLine: 'underline' }}>Your California Privacy Rights</h2>
            <p>If you are a California resident, California law may provide you with additional rights regarding our use of your Personal Information.</p>
            <p>
              We do not meet the minimum requirements obligating us to abide by the provisions of the California Consumer Privacy Act (“
              <b>CCPA</b>
              ”) or California Privacy Rights Act (“
              <b>CPRA</b>
              ”). However, we voluntarily offer many of the protections of the CCPA and CPRA. The categories of Personal Information that we collect are name, phone number, mailing address, e-mail address, payment card information, and information such as voice and likeness for users of our live chat platform (which includes storing and offering playback of recordings of live chats for purposes of providing our services), should you opt-in to such use and recordings. We use these categories of Personal Information to fulfill orders made on our Website and to promote our own goods and services to you.
            </p>
            <p>We currently do not sell any of your Personal Information to third parties. We do disclose Personal Information as necessary to provide our Services. For example, we may share your first name or email address with the company whose streaming feed you would like to watch.</p>
            <p>
              If you wish to opt out of any such disclosure of your Personal Information, please contact us at
              {' '}
              <a href="mailto:support@joinswytchboard.com" target="_blank" rel="noreferrer">support@joinswytchboard.com. </a>
              However, please note that any such opt out may prevent us from fulfilling purchase orders or otherwise providing services to you.
            </p>
            <p>
              California’s Online Privacy Protection Act (Cal. Bus. &amp; Prof. C. §§22575-22579) (“
              <b>CalOPPA</b>
              , requires website operators to disclose how they respond to web browser “do not track” signals or other similar mechanisms that provide consumers with the ability to exercise choice regarding the collection of personally identifiable information of a consumer over time and across third party websites and apps, to the extent the operator engages in that collection. At this time, we do not respond to Do Not Track (DNT) signals. This law also requires website and app operators to disclose whether third parties may collect personally identifiable information about their users’ online activities over time and across different websites and apps when the users use the operator’s website or app. The nature of how third parties may collect or receive personally identifiable information is disclosed in this Privacy Policy. To learn more about how Do Not Track signals work, please visit”)
              <a href="http://allaboutdnt.com/" target="_blank" rel="noreferrer"> http://allaboutdnt.com.</a>
            </p>
            <p>
              California’s “Shine the Light” law (Civil Code Section § 1798.83) permits users of our Website that are California residents to request certain information regarding our disclosure of Personal Information to third parties for their direct marketing purposes. We do not currently disclose Personal Information to third parties for their direct marketing purposes. However, to make such a request, please send an email to
              {' '}
              <a href="mailto:support@joinswytchboard.com" target="_blank" rel="noreferrer">support@joinswytchboard.com. </a>
              Please allow us 30 days for a response.
            </p>
            <h2 style={{ textDecorationLine: 'underline' }}>Data Security</h2>
            <p>We have implemented measures designed to secure your Personal Information from accidental loss and from unauthorized access, use, alteration, and disclosure. We do not store Personal Information on any of our own servers. Personal Information may be stored through our AWS platform on their cloud servers. Any payment transactions will be encrypted through the Stripe platform.</p>
            <p>The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Website, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.</p>
            <p>Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your Personal Information, we cannot guarantee the security of your Personal Information transmitted to our Website. Any transmission of Personal Information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website.</p>
            <h2 style={{ textDecorationLine: 'underline' }}>Changes to Our Privacy Policy</h2>
            <p>It is our policy to post any changes we make to our privacy policy on this page. If we make material changes to how we treat our users’ Personal Information, we will notify you by email to the primary email address specified in your account. We will also update the “last updated” date on this Privacy Policy, which is identified at the top of this page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our Website and this Privacy Policy to check for any changes.</p>
            <h2 style={{ textDecorationLine: 'underline' }}>Contact Information</h2>
            <p>
              To ask questions or comment about this Privacy Policy and our privacy practices, contact us at:
              {' '}
              <a href="mailto:support@joinswytchboard.com" target="_blank" rel="noreferrer">support@joinswytchboard.com</a>
            </p>
          </div>
          <br />
        </Col>
      </Row>
    </Col>
  </Col>
)

export default PrivacyPolicy
