import TimezoneSelect from 'react-timezone-select'
import React from 'react'
import { getIn, useFormikContext } from 'formik'
import { COMPANY_DETAILS_ATTRIBUTES } from 'routes/ChatSettingsEntryPoint/constants'

const name = `${COMPANY_DETAILS_ATTRIBUTES}.timezone`

const Timezone = () => {
  const {
    setFieldValue,
    values,
  } = useFormikContext()

  const value = getIn(values, name) ?? ''

  return (
    <TimezoneSelect
      className="chat-settings__field__timezone"
      // timezone value e.g: Europe/Warsaw
      onChange={(timezone) => setFieldValue(name, timezone?.value ?? '')}
      // adds a prefix to timezone-select classes (eg. timezone-placeholder) so we can override their styles
      classNamePrefix="timezone"
      value={value}
      readOnly
      isClearable
    />
  )
}

export default Timezone
