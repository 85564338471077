/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'
import { useChatContext } from 'providers/ChatProvider'
import FilePreview from 'components/chat/FilePreview'
import useFormData from 'hooks/useFormData'
import useConsumerConversations from 'features/qrflow/hooks/useConsumerConversations'

const ChatInput = () => {
  const [message, setMessage] = useState('')
  const {
    fileFormData, preview, fileData, handleFileChange, setFileFormData, setPreview,
  } = useFormData()
  const {
    conversation,
    messages,
    loadingMessages,
  } = useChatContext()

  const isMessagePresent = message && message.length > 0

  const { introMessage } = useConsumerConversations()

  const shouldSendWelcomeMessage = conversation && introMessage && messages?.length <= 1 && !loadingMessages

  const handleSendMessage = useCallback(() => {
    const newMessage = conversation.prepareMessage()
    if (isMessagePresent) newMessage.setBody(message)
    if (fileFormData instanceof FormData) newMessage.addMedia(fileFormData)
    newMessage.build().send()
    setMessage('')
    setFileFormData('')
    setPreview('')
  }, [conversation, isMessagePresent, message, fileFormData, setFileFormData, setPreview])

  const handleReturnKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage()
    }
  }

  useEffect(() => {
    if (shouldSendWelcomeMessage) {
      conversation.sendMessage?.(introMessage)
    }
  }, [conversation, introMessage, shouldSendWelcomeMessage])

  return (
    <>
      <Row className="qr-chat__input">
        <Col md={1} xs={1} className="ms-0 p-0">
          <label htmlFor="upload-button">
            <IcomoonReact
              iconSet={iconSet}
              color="#767676"
              size={20}
              icon="icon-add"
              role="button"
              className="d-flex align-items-center mt-3"
            />
          </label>
          <input
            type="file"
            id="upload-button"
            style={{ display: 'none' }}
            onChange={(e) => handleFileChange(e)}
          />
        </Col>
        <Col md={9} xs={9} className="m-0 p-0 ps-2">
          <div style={{
            boxShadow: '0px 2px 16px rgba(36, 54, 101, 0.1)',
            borderRadius: '10px',
          }}
          >
            <input
              type="text"
              placeholder="Enter your message here"
              className="w-100"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyPress={handleReturnKeyPress}
              style={{
                borderLeft: 'none',
                borderRight: 'none',
                borderTop: 'none',
                borderBottom: '1px solid #F5F5F5',
              }}
            />
            {preview && <FilePreview preview={preview} fileData={fileData} setPreview={setPreview} setFileFormData={setFileFormData} />}
          </div>
        </Col>
        <Col md={1} xs={1} className="m-0 p-0 text-center">
          <IcomoonReact
            iconSet={iconSet}
            color="#767676"
            size={20}
            icon="icon-send"
            role="button"
            className="mt-3"
            onClick={handleSendMessage}
          />
        </Col>
      </Row>
    </>
  )
}

export default ChatInput
