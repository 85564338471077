/* eslint-disable no-mixed-operators */
import React from 'react'
import usePublications from 'hooks/usePublications/usePublications'
import Publication from 'components/Room/Publication'
/*
 *  The object model for the Room object
 *  (found here: https://www.twilio.com/docs/video/migrating-1x-2x#object-model) shows
 *  that Participant objects have TrackPublications, and TrackPublication objects have Tracks.
 *
 *  The React components in this application follow the same pattern.
 *  This ParticipantTracks component renders Publications,
 *  and the Publication component renders Tracks.
 */
const ParticipantTracks = ({
  participant,
  displayVideo,
  videoPriority,
  isLocalParticipant,
  isThumbnail,
  isScreenShareOnly,
  attachLogo = null,
  fullScreenRef,
}) => {
  const publications = usePublications(participant)

  const filteredPublications = (() => {
    if (isThumbnail) {
      // if Thumbnail is true then we exclude screen (screneshare) from publications
      return publications.filter((p) => !p.trackName.includes('screen'))
    }
    if (publications.find((p) => p.trackName.includes('screen'))) {
    // When displaying a screenshare track is allowed, and a screen share track exists,
    // remove all video tracks without the name 'screen'.
      return publications.filter((p) => !p.trackName.includes('camera'))
    }
    // Else, remove all screenshare tracks
    return publications.filter((p) => !p.trackName.includes('screen'))
  })()

  return (
    <>
      {filteredPublications.map((publication) => (
        <Publication
          key={publication.kind}
          publication={publication}
          participant={participant}
          displayVideo={displayVideo}
          videoPriority={videoPriority}
          isLocalParticipant={isLocalParticipant}
          isScreenShareOnly={isScreenShareOnly}
          attachLogo={attachLogo}
          fullScreenRef={fullScreenRef}
        />
      ))}
    </>
  )
}

export default ParticipantTracks
