import React from 'react'
import { Button } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'
import Stripe from 'services/Stripe'

const PlanContent = ({
  plan,
  selected,
  setSelected,
  isSending,
  setIsSending,
}) => {
  const { addToast } = useToasts()
  const iconColor = '#8BD8BD'

  const formatPrice = (price) => `$${price}`

  const formatInterval = (interval) => `per ${interval}`

  const formatFeature = (text) => (
    <span className="item-text ms-4">{text}</span>
  )

  const addClassOnContainerSelectedBtn = (isSelected) => {
    if (isSelected && isSelected === plan.id) return 'btn-success'
    return 'btn-lighter'
  }

  const onCheckout = async () => {
    if (isSending) return
    setIsSending(true)

    try {
      const checkout = await Stripe.createCheckout(plan.id)

      window.location.href = checkout.checkout_url
    } catch (e) {
      addToast('Error during check out', {
        appearance: 'info',
        autoDismiss: true,
        autoDismissTimeout: 2000,
      })
    }

    setIsSending(false)
  }

  return (
    <div className="checkout__content">
      <div
        className="checkout__content__plan-prices shadow"
        onMouseEnter={() => setSelected(plan.id)}
        onMouseLeave={() => setSelected(null)}
      >
        <div className="fw-bold mb-3 text-capitalize text-xl">{plan.name}</div>
        <div className="fw-bold mb-3 text-5xl">{formatPrice(plan.dollar_amount)}</div>
        <div className="fw-bold mb-3 text-xl">{formatInterval(plan.interval)}</div>
        <Button
          className={`checkout__content__btn-select ${addClassOnContainerSelectedBtn(selected)}`}
          disabled={isSending}
          onClick={onCheckout}
        >
          Select
        </Button>
      </div>
      <div className="checkout__content__plan-features">
        <div className="checkout__content__plan-features__description">
          <IcomoonReact
            iconSet={iconSet}
            color={iconColor}
            size={16}
            icon="icon-check"
          />
          {formatFeature(plan.description)}
        </div>
        <ul className="checkout__content__plan-features__ul">
          {plan.features.map((feature) => (
            <li className="checkout__content__plan-features__li" key={feature}>
              <IcomoonReact
                iconSet={iconSet}
                color={iconColor}
                size={16}
                icon="icon-check"
              />
              {formatFeature(feature)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default PlanContent
