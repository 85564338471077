import ConversationTypes from '../conversation.types'

export const setRoomName = (roomName) => ({
  type: ConversationTypes.SET_ROOM_NAME,
  roomName,
})

export const resetRoomName = () => ({
  type: ConversationTypes.RESET_ROOM_NAME,
  roomName: null,
})
