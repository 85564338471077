import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import User from 'services/User'
import { useAuth } from 'providers/AuthProvider/AuthProvider'

export default function useJoinExistingProject() {
  const { updateUser } = useAuth()
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoadingExistingInvitation, setIsLoadingExistingInvitation] = useState(true)
  const [isAcceptingInvitation, setIsAcceptingInvitation] = useState(false)
  const [projectName, setProjectName] = useState(null)
  const [invitationId, setInvitationId] = useState('')

  const history = useHistory()

  useEffect(() => {
    (async () => {
      setIsLoadingExistingInvitation(true)
      try {
        const [{
          id,
          company: {
            name,
          },
        }] = await User.getActiveInvitations()
        setInvitationId(id)
        setProjectName(name)
      } catch (e) {
        setProjectName(null)
      }
      setIsLoadingExistingInvitation(false)
    })()
  }, [])

  const onAcceptInvitation = async () => {
    setIsAcceptingInvitation(true)
    setErrorMessage('')
    try {
      const user = await User.acceptInvitation(invitationId)
      updateUser(user)
      history.push('/setup-completed')
    } catch (e) {
      setErrorMessage(e.message || e.error || 'Something went wrong, please try again.')
    }
    setIsAcceptingInvitation(false)
  }

  const onClearErrorMessage = () => setErrorMessage('')

  const navigateToSetupNewProject = () => history.push('/account-setup/new')

  const onBackClickHandler = () => history.push('/account-setup')

  return {
    isLoadingExistingInvitation,
    isAcceptingInvitation,
    projectName,
    errorMessage,
    onClearErrorMessage,
    onAcceptInvitation,
    navigateToSetupNewProject,
    onBackClickHandler,
  }
}
