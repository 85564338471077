import Analytics from 'analytics'
import googleTagManager from '@analytics/google-tag-manager'
import config from 'utils/config'

const containerId = config.GTM_CONTAINER_ID

const analytics = containerId ? Analytics({
  app: 'app.joinswytchboard.com',
  plugins: [
    googleTagManager({
      containerId,
      enabled: true,
    }),
  ],
}) : {
  page: () => {},
}

export default analytics
