/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useMemo,
  useCallback,
} from 'react'
import { useHistory, matchPath } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import userActions from 'redux/user/actions'
import AuthService from 'services/Auth'
import { createConsumer } from '@rails/actioncable'
import config from 'utils/config'
import { SWYTCHBOARD_LOCALSTORAGE_KEY } from 'utils/constants'
import companyOperations from 'redux/company/operations'
import userOperations from 'redux/user/operation'
import { QrFlowRoutes, WorkerRoutes } from 'features/qrflow/constants'

export const AuthContext = createContext()
AuthContext.displayName = 'AuthContext'

const AuthContextProvider = ({ children }) => {
  const history = useHistory()
  const pathname = history?.location?.pathname || ''
  const dispatch = useDispatch()
  const { data: currentCompany } = useSelector((state) => state.company)
  const user = useSelector((state) => state.user.data)
  const [isAdmin, setIsAdmin] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(!!(user && 'accessToken' in user))
  const [userRole, setUserRole] = useState(undefined)
  const currentRole = useMemo(() => user?.roles?.find((role) => role?.company_id === currentCompany?.id)?.role, [user, currentCompany])
  const isInWorkerRoute = Object.keys(WorkerRoutes)?.some?.((route) => matchPath(pathname, { path: WorkerRoutes?.[route] }))
  const isQRFlow = !isInWorkerRoute && Object.keys(QrFlowRoutes)?.some?.((route) => matchPath(pathname, { path: QrFlowRoutes?.[route] }))

  /**
   * Updates the user state object and stores it in localStorage
   * @param newUser
   */
  const updateUser = (newUser) => {
    dispatch(userActions.updateUser(newUser))
  }

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(companyOperations.fetchCurrentCompany())
      dispatch(userOperations.fetchCurrentUser())
    }
  }, [dispatch, isLoggedIn])

  useEffect(() => {
    if (user) {
      if (user && 'accessToken' in user) {
        setIsLoggedIn(true)
      }
      if (currentCompany !== null) {
        setUserRole(currentRole)
        setIsAdmin(currentRole === 'manager')
      } else {
        setUserRole(undefined)
        setIsAdmin(false)
      }
    }
  }, [user, currentCompany])

  const login = (email, password) => (
    AuthService.login(email, password)
      .then((response) => {
        const token = response.headers['access-token']
        const refreshToken = response.headers['refresh-token']
        response.data.accessToken = token
        response.data.refreshToken = refreshToken
        if (token) {
          dispatch(userActions.setUser(response.data))
        }
        return response.data
      })
      .catch((error) => {
        if (error.response?.data) {
          return error.response.data
        }

        return error
      })
  )

  const logout = useCallback(() => {
    localStorage.removeItem('user')
    localStorage.removeItem('isRememberMeChecked')
    localStorage.removeItem(SWYTCHBOARD_LOCALSTORAGE_KEY)
    setIsLoggedIn(false)
    setIsAdmin(false)
    setUserRole(undefined)
    history?.push('/')
  }, [history])

  const getCurrentUser = () => user

  const cableConsumer = useMemo(() => {
    if (user?.accessToken && !isQRFlow) {
      const cableUrl = `${config.WEBSOCKETS_URL}?token=${encodeURIComponent(user?.accessToken)}`
      return createConsumer(cableUrl)
    }
    return undefined
  }, [user?.accessToken])

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => cableConsumer?.disconnect?.()
  }, [cableConsumer])

  const value = {
    user,
    updateUser,
    login,
    logout,
    getCurrentUser,
    isLoggedIn,
    userRole,
    isAdmin,
    cableConsumer,
  }

  return (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  )
}

function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

export { AuthContextProvider, useAuth }
