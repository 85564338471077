import React from 'react'
import OnBoardingLayout from 'layouts/OnBoardingLayout'
import SignIn from './SignIn'

const SignInRoute = () => (
  <OnBoardingLayout>
    <SignIn />
  </OnBoardingLayout>
)

export default SignInRoute
