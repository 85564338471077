import { useCallback } from 'react'
import { useVideoContext } from 'providers/VideoProvider'
import useIsTrackEnabled from 'hooks/useIsTrackEnabled/useIsTrackEnabled'

export default function useLocalAudioToggle() {
  const { audioTrack: localAudioTrack, room } = useVideoContext()
  const isEnabled = useIsTrackEnabled(localAudioTrack)
  const localParticipant = room?.localParticipant

  const toggleAudioEnabled = useCallback((state) => localParticipant?.audioTracks?.forEach(({ track }) => {
    // if we pass state, must have boolean value otherwise it will fail to enable/disable the audio
    if (state) {
      return track.enable()
    }
    if (track?.isEnabled || state === false) {
      return track?.disable()
    }
    return track?.enable()
  }), [localParticipant?.audioTracks])

  return [isEnabled, toggleAudioEnabled]
}
