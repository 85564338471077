import React, {
  useState,
  useEffect,
} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import Billing from 'services/Billing'
import PlanWrapper from './components/Plan/Wrapper'

const Checkout = () => {
  const { addToast } = useToasts()
  const [isLoading, setLoading] = useState(false)
  const [checkoutPlan, setCheckoutPlan] = useState({
    monthly_plans: [],
  })

  useEffect(() => {
    setLoading(true)

    const fetchData = async () => {
      try {
        const plans = await Billing.getPlans()

        setCheckoutPlan({
          monthly_plans: plans.monthly_plans,
        })

        setLoading(false)
      } catch (e) {
        addToast('Error while fetching plans', {
          appearance: 'info',
          autoDismiss: true,
          autoDismissTimeout: 2000,
        })

        setLoading(false)
      }
    }

    fetchData()
  }, [addToast])

  return (
    <>
      <Row>
        <Col className="checkout__title text-center justify-content-center">
          <span className="fw-bold text-6xl">Choose a plan</span>
        </Col>
      </Row>
      <Container>
        { isLoading ? <LoadingSpinner /> : <PlanWrapper plans={checkoutPlan} /> }
      </Container>
    </>
  )
}

export default Checkout
