import { useSelector } from 'react-redux'
import {
  useIsMutating,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import User from 'services/User'
import { useToasts } from 'react-toast-notifications'
import { ToastKey } from 'features/work-logs/constants'
import isPunchedIn from 'features/work-logs/isPunchedIn'
import { QueryKeys } from 'utils/constants'

/**
 * This hook is used for toggling the punch in/out state for the worker, and displays error notifications when the API
 * call fails, and a success notification when the worker punches out
 */
export default function useTogglePunchInState() {
  const {
    addToast,
    removeToast,
  } = useToasts()

  const companyId = useSelector((state) => state.company.data?.id)

  const queryClient = useQueryClient()
  const isFetching = useIsMutating([QueryKeys.WORK_LOGS, companyId])

  const {
    isLoading,
    mutate: onTogglePunchInState,
    ...rest
  } = useMutation({
    mutationFn: () => new Promise((resolve) => {
      const workLog = queryClient
        .getQueryData([QueryKeys.WORK_LOGS, companyId])

      // We delay the execution of the API for 1.2 second to simulate a "debounce" mechanism
      setTimeout(() => {
        if (isPunchedIn(workLog)) {
          return resolve(User.punchOut(companyId))
        }
        return resolve(User.punchIn(companyId))
      }, 1200)
    }),
    onSuccess: (workLog) => {
      queryClient.setQueryData([QueryKeys.WORK_LOGS, companyId], () => workLog)

      const hasUserPunchedOut = isPunchedIn(workLog) === false
      if (hasUserPunchedOut) {
        addToast('You have successfully punched out.', {
          appearance: 'success',
          autoDismiss: true,
        })
      }
    },
    onError: (error) => {
      removeToast(ToastKey.togglePunchInError)
      addToast(error.message, {
        appearance: 'error',
        autoDismiss: false,
        id: ToastKey.togglePunchInError,
      })
    },
    retry: false,
    mutationKey: [QueryKeys.WORK_LOGS, companyId],
  })

  return {
    isTogglingPunchInState: isLoading || isFetching !== 0,
    onTogglePunchInState,
    ...rest,
  }
}
