import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import IcomoonReact from 'icomoon-react'
import { Row } from 'react-bootstrap'
import { useChatContext } from 'providers/ChatProvider'
import { setIsChatHidden } from 'redux/layout/actions'
import { ConversationTypes } from 'utils/constants'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import MessageList from 'features/qrflow/components/chat/MessageList'
import ChatInput from 'features/qrflow/components/chat/ChatInput'
import { isMobile } from 'utils/helpers'
import iconSet from 'assets/fonts/selection.json'
import ParticipantThumbnail from 'components/Room/ParticipantThumbnail'
import useRemoteParticipants from 'hooks/useRemoteParticipants/useRemoteParticipants'
import useScreenShareParticipant from 'hooks/useScreenShareParticipant/useScreenShareParticipant'
import { useVideoContext } from 'providers/VideoProvider'
import useConsumerConversations from 'features/qrflow/hooks/useConsumerConversations'

const ChatWindow = ({
  token,
  conversationSid,
  className = '',
}) => {
  const {
    connectToChat,
    conversation,
  } = useChatContext()

  const { requestType, consumerName } = useConsumerConversations()
  const { room, isTwoWayEnabled } = useVideoContext()

  const { remoteParticipants } = useRemoteParticipants()
  const screenShareParticipant = useScreenShareParticipant()
  const localParticipant = room?.localParticipant
  const bothThumbnails = isTwoWayEnabled && Boolean(screenShareParticipant)
  const isTextOnlyChat = requestType === ConversationTypes.text
  const isChatHidden = useSelector((state) => state.layout.chatHidden)
  const dispatch = useDispatch()

  const handleChatIconClick = () => dispatch(setIsChatHidden())

  const renderChatIcon = () => (
    <div className="hide-chat-icon-container" style={{ bottom: '-6vh', right: '-2vh' }}>
      <div
        className="hide-chat-icon"
        style={{ backgroundColor: `${isChatHidden ? '#0E0641' : 'white'}` }}
        onClick={handleChatIconClick}
      >
        <IcomoonReact
          iconSet={iconSet}
          size={24}
          icon={`icon-chat-bubble${isChatHidden ? '-green' : ''}`}
          type="button"
        />
      </div>
    </div>
  )

  useEffect(() => {
    if (token && conversationSid) {
      connectToChat(token, conversationSid)
    }
  }, [connectToChat, token, conversationSid])

  const renderHideShowChatButtons = () => (
    <>
      {isMobile && !isChatHidden && (
        <div
          className="hide-chat"
        >
          <span
            onClick={handleChatIconClick}
          />
        </div>
      )}
      {
          isMobile && isChatHidden && (
            <button onClick={handleChatIconClick} className="show-chat-button" type="button">
              <IcomoonReact
                iconSet={iconSet}
                size={24}
                icon="icon-chat-bubble"
                type="button"
              />
            </button>
          )
        }
    </>
  )

  return (
    <>
      {conversation
        ? (
          <>
            <div
              className={`qr-chat 
                ${className} ${isChatHidden ? 'qr-chat-hidden' : ''} 
                ${isMobile && isChatHidden ? 'qr-chat--hide-chat' : ''} `}
              style={isMobile && isChatHidden ? { height: '0vh', backgroundColor: 'transparent' } : {}}
            >
              {!isTextOnlyChat && renderHideShowChatButtons()}
              {!isMobile ? (
                <Row className={`participant-thumbnail ${bothThumbnails ? 'second-thumbnail' : ''}`}>
                  {isTwoWayEnabled && (
                  <ParticipantThumbnail
                    className={`${isChatHidden ? 'chat__hidden' : ''}`}
                    participant={localParticipant}
                    isTwoWayEnabled={isTwoWayEnabled}
                    localParticipant={localParticipant}
                    mainParticipant
                    worker
                    participantName={consumerName}
                  />
                  )}
                  {Boolean(screenShareParticipant) && remoteParticipants.map((participant) => (
                    (
                      <ParticipantThumbnail
                        key={participant}
                        className={`${isChatHidden ? 'chat__hidden' : ''}`}
                        participant={participant}
                        isTwoWayEnabled
                        worker={false}
                      />
                    )
                  ))}
                </Row>
              ) : null}
              <MessageList />
              {!isChatHidden && <ChatInput />}
              {!isTextOnlyChat && !isMobile && renderChatIcon()}
            </div>
          </>
        )
        : (
          <div className={`qr-chat qr-chat--connecting ${className}`}>
            <LoadingSpinner spinnerSize={50} />
          </div>
        )}
    </>
  )
}

export default ChatWindow
