import ConversationTypes from '../conversation.types'

const INITIAL_STATE = null

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ConversationTypes.SET_CONVERSATION_STATUS:
      return action.conversationStatus ?? INITIAL_STATE
    default:
      return state
  }
}
export default reducer
