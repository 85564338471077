/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable camelcase */
import { React, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CirclePlay from 'components/UI/CirclePlay'
import Company from 'services/Company'
import { useToasts } from 'react-toast-notifications'
import conversationDetailsOperations from 'redux/conversation/conversationDetails/operations'
import { isValidUrl } from 'utils/helpers'

const compositionInitialState = {
  compositionUrl: null,
  urlErrorMessage: null,
  message: null,
  compositionAudioId: 0,
  compositionRequested: false,
  allowRequestComposition: false,
}
const messageType = {
  AUDIO_RECORDING_NO_AVAILABLE: 'Audio recording no available',
  REQUEST_AUDIO_RECORDING: 'Click to request audio recording',
  AUDIO_RECORDING_REQUESTED: 'Audio recording has been requested',
}

const Composition = ({ conversationDetails }) => {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const companyId = useSelector((state) => state.company.data?.id)
  const [compositionState, setCompositionState] = useState(compositionInitialState)

  const onRequestComposition = async () => {
    const { id } = conversationDetails?.chat_request?.video_calls

    if (!compositionState.compositionRequested) {
      try {
        const result = await Company.createComposition(companyId, id)

        if (result === 'Video call not found') {
          addToast(`Composition request failed. ${result}`, {
            appearance: 'error',
            autoDismiss: true,
            autoDismissTimeout: 5000,
          })
          dispatch(conversationDetailsOperations.setAudioCompositionRequested(
            { conversationDetails, compositionRequested: false },
          ))
        } else {
          dispatch(conversationDetailsOperations.setAudioCompositionRequested(
            { conversationDetails, compositionRequested: true },
          ))
          addToast('Composition has been requested', {
            appearance: 'success',
            autoDismiss: true,
            autoDismissTimeout: 3000,
          })
        }
      } catch (e) {
        addToast('Composition request failed', {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        })
        dispatch(conversationDetailsOperations.setAudioCompositionRequested(
          { conversationDetails, compositionRequested: false },
        ))
      }
    }
  }

  useEffect(() => {
    (async () => {
      const composition = conversationDetails?.chat_request?.video_calls?.composition
      if (composition) {
        try {
          const data = await Company.getCompositionFile(companyId, composition.id)
          if (isValidUrl(data.url)) {
            setCompositionState((compState) => ({
              ...compState,
              compositionAudioId: composition.id,
              compositionUrl: data.url,
              urlErrorMessage: '',
              message: '',
            }))
          } else {
            setCompositionState((compState) => ({
              ...compState,
              compositionAudioId: composition.id,
              urlErrorMessage: data.status,
              compositionUrl: null,
            }))
          }
        } catch (e) {
          setCompositionState((compState) => ({
            ...compState,
            compositionAudioId: composition.id,
            compositionUrl: null,
            urlErrorMessage: e.data || e.message || e.error || 'Oops, an unexpected error occurred',
          }))
        }
      } else {
        setCompositionState((compState) => ({
          ...compState,
          urlErrorMessage: 'No composition audio available',
        }))
      }
    })()
  }, [companyId, conversationDetails])

  useEffect(() => {
    const hasVideoCalls = conversationDetails?.chat_request?.video_calls
    if (!hasVideoCalls) {
      setCompositionState((compState) => ({
        ...compState,
        message: messageType.AUDIO_RECORDING_NO_AVAILABLE,
        compositionRequested: false,
        allowRequestComposition: false,
      }))
    } if (hasVideoCalls && hasVideoCalls.compositionRequested === false) {
      setCompositionState((compState) => ({
        ...compState,
        message: messageType.REQUEST_AUDIO_RECORDING,
        compositionRequested: false,
        allowRequestComposition: true,
      }))
    } if (hasVideoCalls && hasVideoCalls.compositionRequested === true) {
      setCompositionState((compState) => ({
        ...compState,
        message: messageType.AUDIO_RECORDING_REQUESTED,
        compositionRequested: true,
        allowRequestComposition: false,
      }))
    }
  }, [conversationDetails])

  return (
    <>
      <div>
        <h5>Recorded audio</h5>
        <hr className="mt-2" />
        { compositionState.compositionRequested && isValidUrl(compositionState.compositionUrl) === true && (
          (
          <audio controls key={compositionState.compositionAudioId}>
            <source src={compositionState.compositionUrl} type="audio/mpeg" />
            <source src={compositionState.compositionUrl} type="audio/ogg" />
          </audio>
          )
        )}
        { compositionState.compositionRequested && compositionState.urlErrorMessage !== ''
      && compositionState.message === '' && (
      <span>{ compositionState.urlErrorMessage }</span>
        )}
        { compositionState.compositionRequested && compositionState.message !== '' && (
        <span>{compositionState.message}</span>
        )}
        { !compositionState.compositionRequested && (
        <>
          <div>
            { compositionState.allowRequestComposition && (
            <CirclePlay
              className="conversation-messages__footer__play2"
              onClick={onRequestComposition}
            />
            )}
            <span>{` ${compositionState.message}`}</span>
          </div>
        </>
        ) }
      </div>
    </>
  )
}

export default Composition
