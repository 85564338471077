import React from 'react'

const Field = ({
  labelTitle,
  formControl,
}) => (
  <div>
    <div className="onboarding-info__field">
      <div className="onboarding-info__field__label">
        <div className="onboarding-info__field__label__title">
          {labelTitle}
        </div>
      </div>
      <div className="onboarding-info__field__form-control">
        {formControl}
      </div>
    </div>
  </div>
)

export default Field
