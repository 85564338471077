/* eslint-disable import/no-extraneous-dependencies */
import { Chat } from '@swytchboard/client-sdk-ts'
import chatRequestActions from 'redux/chatRequest/actions'
import history from 'services/history'
import { QrFlowRoutes } from 'features/qrflow/constants'
import getActiveChatRequestId from 'features/qrflow/utils/getActiveChatRequestId'
import { generatePath } from 'react-router-dom'
import { setConsumerConversation, updateConsumerConversation } from 'redux/consumerConversations/actions'
import { ChatStatuses } from 'utils/constants'

const createChatRequest = (formData, requestType, serviceUid) => (
  async (dispatch) => {
    const {
      name,
      email,
      phone,
      intro_message: introMessage,
    } = formData
    try {
      const chatRequestData = await Chat.create({
        fullName: name, email, phoneNumber: phone, introMessage, userAgent: navigator.userAgent,
      }, requestType, serviceUid)
      if (chatRequestData.id) {
        dispatch(setConsumerConversation(chatRequestData, {
          consumerId: chatRequestData.consumer.id,
          consumerEmail: email || chatRequestData.consumer.email,
          consumerName: name || chatRequestData.consumer.name,
          consumerToken: chatRequestData.consumer_token,
          id: chatRequestData.id,
          requestType,
          status: ChatStatuses.pending,
          introMessage: chatRequestData.intro_message,
          activeConversation: 'loading',
          settings: {
            [`customer-${chatRequestData?.consumer?.id}`]: {
              isJoyrideClosed: false,
              isTwoWayVideoEnabled: false,
              isAudioEnabled: null,
            },
            expiry: null,
          },
        }))

        // dispatch(chatRequestActions.createChatRequestSuccess(chatRequestData))
        history.push(generatePath(
          QrFlowRoutes.queue, { serviceUid, chatRequestId: chatRequestData.id },
        ))
      } else {
        const error = chatRequestData.error ? chatRequestData.error : chatRequestData
        dispatch(chatRequestActions.createChatRequestError(error))
      }
    } catch (e) {
      dispatch(chatRequestActions.createChatRequestError(e))
    }
  }
)

const cancelChatRequest = (userId, type = ChatStatuses.canceled) => (
  async (dispatch) => {
    const chatRequestId = Number(getActiveChatRequestId())
    dispatch(updateConsumerConversation(chatRequestId, { cancelRequest: 'loading' }))
    try {
      await Chat.cancel(chatRequestId, userId)
      dispatch(updateConsumerConversation(chatRequestId, { cancelRequest: 'success', status: type }))
    } catch (e) {
      dispatch(updateConsumerConversation(chatRequestId, { cancelRequest: e }))
    }
  }
)

const requestVideo = () => (
  async (dispatch, getState) => {
    const chatRequestId = getActiveChatRequestId()
    dispatch(updateConsumerConversation(chatRequestId, { requestVideo: 'loading' }))
    const { consumerId } = getState().consumerConversations?.find((req) => Number(req.id) === Number(chatRequestId))
    try {
      await Chat.requestVideoCall(chatRequestId, consumerId)
      dispatch(updateConsumerConversation(chatRequestId, { requestVideo: 'requested' }))
    } catch (e) {
      dispatch(updateConsumerConversation(chatRequestId, { requestVideo: e }))
    }
  }
)

const joinConversation = (requestId) => (
  async (dispatch, getState) => {
    const chatRequestId = getActiveChatRequestId()

    const { consumerId, requestType } = await getState().consumerConversations?.find((req) => Number(req.id) === Number(chatRequestId)) ?? {}
    try {
      const conversation = await Chat.join(requestType, chatRequestId, consumerId, true)
      dispatch(updateConsumerConversation(requestId, {
        roomToken: conversation.token,
        conversationSID: conversation.conversation_sid,
        roomName: conversation.room_name,
        roomSID: conversation.room_sid,
        introMessage: conversation.intro_message,
        activeConversation: {},
      }))
    } catch (e) {
      dispatch(chatRequestActions.joinConversationError(e))
    }
  }
)

const submitRatings = (formData, rateToken) => (
  async (dispatch, getState) => {
    const chatRequestId = getActiveChatRequestId()
    const { comment = '', rate = {} } = formData

    dispatch(updateConsumerConversation(chatRequestId, { submitRatings: 'loading' }))

    const { consumerId } = getState().consumerConversations?.find((req) => Number(req.id) === Number(chatRequestId))

    try {
      await Chat.rate(chatRequestId, consumerId, rateToken, comment, rate)
      dispatch(updateConsumerConversation(chatRequestId, { submitRatings: 'success' }))
    } catch (e) {
      dispatch(updateConsumerConversation(chatRequestId, { submitRatings: e }))
    }
  }
)

export default {
  createChatRequest,
  cancelChatRequest,
  requestVideo,
  submitRatings,
  joinConversation,
}
