import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'utils/constants'
import { useToasts } from 'react-toast-notifications'
import { ToastKey } from 'routes/ChatSettingsEntryPoint/constants'
import Company from 'services/Company'

export default function useEntryPoints() {
  const {
    addToast,
    removeToast,
  } = useToasts()

  return useQuery([QueryKeys.ENTRY_POINT], () => Company.listCompanyEntryPoints(), {
    onError: (error) => {
      removeToast(ToastKey.entryPoint)
      addToast(`Entry Point: ${error.message}`, {
        appearance: 'error',
        autoDismiss: false,
        id: ToastKey.entryPoint,
      })
    },
    enabled: !!QueryKeys.ENTRY_POINT,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retryOnMount: false,
    retry: 1,
  })
}
