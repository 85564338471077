import React from 'react'
import { useSelector } from 'react-redux'
import config from 'utils/config'
import User from 'services/User'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'
import useUploadPhoto from 'hooks/useUploadPhoto'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import { fileType } from 'utils/constants'
import useDialog from 'hooks/useDialog'
import EditPhoto from 'components/EditPhoto/EditPhoto'
import { useAuth } from 'providers/AuthProvider/AuthProvider'

const UploadProfilePhoto = ({ spinnerSize = 32 }) => {
  const { data: userData } = useSelector((state) => state.user)
  const { user } = useAuth()

  const {
    onOpenDialog: onOpenEditPhotoDialog,
    onCloseDialog: onCloseEditPhotoDialog,
    displayDialog: displayEditPhotoDialog,
  } = useDialog()

  const updateUser = User.updateUserProfileImage

  const {
    loading,
    s3KeyRef,
    handleChange,
  } = useUploadPhoto(
    updateUser,
    user?.id,
    'avatar',
    fileType.USER_AVATAR,
  )

  const profilePicture = s3KeyRef.current === undefined
    ? (userData?.avatar || user?.avatar)
    : s3KeyRef.current

  const isAvatarAvaliable = !userData?.avatar && !s3KeyRef.current

  const renderProfileImage = isAvatarAvaliable ? (
    <IcomoonReact
      iconSet={iconSet}
      size={32}
      className="mt-2"
      icon="camera"
    />
  ) : (
    <img
      src={`${config.ASSETS_URL}/${profilePicture}`}
      alt="avatar"
      className="user-intro__img"
    />
  )

  return (
    <div className="upload-profile-settings__profile-photo">
      {loading
        ? (
          <div className="upload-profile-settings__profile-photo__spinner">
            <LoadingSpinner spinnerSize={spinnerSize} />
          </div>
        )
        : (
          <>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label
              className="upload-profile-settings__profile-photo__label"
              id="file"
            >
              <input
                id="file"
                type="button"
                className="upload-profile-settings__profile-photo__input"
                onClick={onOpenEditPhotoDialog}
              />
              {renderProfileImage}
            </label>
            <EditPhoto
              handleChange={handleChange}
              show={displayEditPhotoDialog}
              onClose={onCloseEditPhotoDialog}
            />
          </>
        )}
    </div>
  )
}

export default UploadProfilePhoto
