/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import VideoProvider, { useVideoContext } from 'providers/VideoProvider'
import withProviders from 'hoc/withProviders'
import useRemoteParticipants from 'hooks/useRemoteParticipants/useRemoteParticipants'
import ScreenShareParticipant from 'components/Room/ScreenShareParticipant'
import Loader from 'components/loader/Loader'
import usePublications from 'hooks/usePublications/usePublications'
import Meet from 'services/Meet'
import { Row } from 'react-bootstrap'
import config from 'utils/config'
import defaultLogo from 'assets/images/swytchboard-Y-logo.svg'
import { nanoid } from 'nanoid/non-secure'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'

const ScreenShareScreen = () => {
  const { meetingLink } = useParams()
  const uuid = nanoid()

  const { connectToRoom, room } = useVideoContext()
  const { remoteParticipants } = useRemoteParticipants()
  const publications = usePublications(remoteParticipants[0])
  const isScreenSharing = publications.find((p) => p.trackName.includes('screen'))
  const [conversation, setConversation] = useState({})
  const awsPath = `${config.ASSETS_URL}/`
  const companyLogo = conversation?.company_details?.logo ? `${awsPath}${conversation.company_details.logo}` : defaultLogo
  const totalParticipants = room?.participants?.size
  const fullScreenRef = useRef(null)

  const consumer = {
    name: `Anonymous#${uuid}`,
    email: `screenshare+customer+${uuid}@joinswytchboard.com`,
  }

  useEffect(() => {
    const asyncWrapper = async () => {
      const { meeting_id: meetId, meeting_access_key: meetingAccessKey } = await Meet.askToJoin(meetingLink)
      if (meetId) {
        const chat = await Meet.join({ id: meetId, meetingAccessKey, consumer: { name: consumer.name, email: consumer.email } })
        return setConversation(chat)
      }
      return {}
    }
    asyncWrapper()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingLink])

  useEffect(() => {
    const asyncWrapper = async () => {
      await Meet.askToJoin(meetingLink)
    }
    asyncWrapper()
  }, [meetingLink, totalParticipants])

  useEffect(() => {
    const asyncWrapper = async () => {
      if (connectToRoom && conversation.token && conversation.room_name) {
        return connectToRoom(conversation.token, {
          audio: false,
          name: conversation.room_name,
          video: false,
          bandwidthProfile: {
            video: {
              contentPreferencesMode: 'auto',
              clientTrackSwitchOffControl: 'auto',
            },
          },
          preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
        })
      }
      return []
    }
    asyncWrapper()
  }, [connectToRoom, conversation?.room_name, conversation?.token, meetingLink])

  const onRequestFullScreen = () => (fullScreenRef?.current?.requestFullscreen())

  return (
    <Row className="h-100 w-100 justify-content-center screen--share-client">
      <div className={`d-flex screen ${!isScreenSharing ? 'shadow-big rounded-xl' : '--dark'} w-100 justify-content-center align-items-center position-relative`}>
        {!(isScreenSharing)
          ? (
            <>
              {!remoteParticipants.length && <Loader />}
              <p className="p-4">
                {room ? 'Waiting for screen share to start...' : 'Connecting...'}
              </p>
            </>
          )
          : (room.localParticipant && isScreenSharing)
            ? (
              <>
                { remoteParticipants.map((participant) => (
                  <ScreenShareParticipant
                    key={participant.sid}
                    participant={participant}
                    enableScreenShare
                    isScreenShareOnly
                    attachLogo={companyLogo}
                    fullScreenRef={fullScreenRef}
                  />
                ))}
              </>
            )
            : null}
        {isScreenSharing
        && (
        <div className="d-flex w-100 controls rounded-3xl justify-content-end align-items-center px-5">
          <IcomoonReact
            onClick={onRequestFullScreen}
            iconSet={iconSet}
            size={20}
            icon="icon-maximize"
            color="white"
            className="cursor-pointer"
          />
        </div>
        )}
      </div>
    </Row>
  )
}
export default withProviders(VideoProvider)(ScreenShareScreen)
