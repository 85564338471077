import { useEffect } from 'react'
import SweetAlert from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom'

const sweetAlert = withReactContent(SweetAlert)
const SUCCESS_TITLE = 'Your subscription is successful!'
const ERROR_TITLE = 'Something went wrong with subscription!'
const SUCCESS_TEXT = 'Please wait a few minutes for your subscription to be activated, and refresh the page.'
const ERROR_TEXT = 'Please, contact us to solve the subscription issue.'

const NewSubscriptionAlert = ({ show }) => {
  const history = useHistory()

  useEffect(() => {
    const isSuccess = show === 'true'
    if (show) {
      sweetAlert.fire({
        confirmButtonColor: '#8BD8BD',
        title: isSuccess ? SUCCESS_TITLE : ERROR_TITLE,
        icon: isSuccess ? 'success' : 'error',
        text: isSuccess ? SUCCESS_TEXT : ERROR_TEXT,
      }).then(({ isConfirmed, isDismissed }) => {
        if (isConfirmed || isDismissed) {
          // clean search params and prevent going back so it will not display the alert again
          history.replace('/admin/dashboard')
        }
      }).catch((error) => {
        console.log('error displaying the modal', error)
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return undefined
}

export default NewSubscriptionAlert
