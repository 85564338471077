export default {
  SET_USER: 'SET_USER',
  UPDATE_USER: 'UPDATE_USER',
  RESET_USER: 'RESET_USER',

  UPDATE_USER_SETTINGS: 'UPDATE_USER_SETTINGS',
  UPDATE_USER_SETTINGS_SUCCESS: 'UPDATE_USER_SETTINGS_SUCCESS',
  UPDATE_USER_SETTINGS_ERROR: 'UPDATE_USER_SETTINGS_ERROR',

  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',

  CLEAR_CHANGE_PASSWORD_ERROR: 'CLEAR_CHANGE_PASSWORD_ERROR',

}
