import React, {
  useState,
  useEffect,
  useRef,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { Scrollbars } from 'react-custom-scrollbars'
import conversationDetailsOperations from 'redux/conversation/conversationDetails/operations'
import Messages from './Messages'
import Composition from './Composition'

const MessageList = ({ chatRequestId }) => {
  const [height, setHeight] = useState(0)
  const queueRef = useRef()
  const dispatch = useDispatch()
  const companyId = useSelector((state) => state.company.data?.id)
  const conversationDetails = useSelector((state) => state.conversationDetails)

  useEffect(() => {
    const handleHeight = () => {
      setHeight(queueRef?.current?.clientHeight)
    }
    if (height === 0) {
      handleHeight()
    }
    // Handle Height of scroll on resize
    window.addEventListener('resize', handleHeight)
    return () => {
      window.removeEventListener('resize', handleHeight)
    }
  }, [queueRef, height])

  useEffect(() => {
    dispatch(conversationDetailsOperations.getConversationDetails(companyId, chatRequestId))
  }, [companyId, chatRequestId, dispatch])

  return (
    <Row className="h-100">
      <div className="conversation-messages">
        <Col className="conversation-messages__header">
          <h5>Messages</h5>
          <hr className="mt-2" />
        </Col>
        <Col className="conversation-messages__content">
          <div className="h-100" ref={queueRef}>
            <Scrollbars
              autoHeight
              autoHide
              autoHeightMin={0}
              autoHeightMax={height}
            >
              <Messages conversation={conversationDetails?.conversation} />
            </Scrollbars>
          </div>
        </Col>
        <Col className="conversation-messages__footer">
          <Composition conversationDetails={conversationDetails} />
        </Col>
      </div>
    </Row>
  )
}

export default MessageList
