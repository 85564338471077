import React from 'react'
import QRMainLayout from 'features/qrflow/layouts/QRMainLayout'
import useRedirectChatRequest from 'features/qrflow/hooks/useRedirectChatRequest'
import useGetEntryPointDetails from 'features/qrflow/hooks/useGetEntryPointDetails'

const CallEnded = () => {
  const {
    data,
  } = useGetEntryPointDetails()

  const {
    visual,
  } = data?.config ?? {}

  useRedirectChatRequest()

  return (
    <QRMainLayout>
      <>
        <h2 className="qr-container__title">
          CALL ENDED!
          <div
            className="qr-container__title__line"
            style={{
              backgroundColor: visual?.accent_color,
            }}
          />
        </h2>
        <div className="screen-intro-text">
          <p>
            Your call ended.
          </p>
          <p>
            Thank you for using Swytchboard.
          </p>
        </div>
      </>
    </QRMainLayout>
  )
}

export default CallEnded
