import React from 'react'
import {
  Col,
  Row,
} from 'react-bootstrap'
import LoadingButton from 'components/buttons/LoadingButton'
import useTogglePunchInState from 'features/work-logs/useTogglePunchInState'

const PunchInPanel = () => {
  const {
    isTogglingPunchInState,
    onTogglePunchInState,
  } = useTogglePunchInState()

  return (
    <Row className="my-2 mx-0 p-0 text-center">
      <Col md={12}>
        <span className="text-center card-title fw-bold">
          You have to PUNCH IN in order to start accepting customer calls.
        </span>
      </Col>
      <hr style={{ border: '1px solid #F7F9FF' }} className="w-100 m-3" />
      <Col md={12} className="d-flex w-100">
        <LoadingButton
          loading={isTogglingPunchInState}
          disabled={isTogglingPunchInState}
          onClick={onTogglePunchInState}
          delay={1500}
          className="punch-in-out-btn punch-in-out-btn--max-width"
        >
          PUNCH IN
        </LoadingButton>
      </Col>
    </Row>
  )
}

export default PunchInPanel
