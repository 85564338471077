import React from 'react'
import { Modal } from 'react-bootstrap'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'

const ConversationDetailsDialog = ({
  showModal = true,
  onClose,
  title,
  children,
}) => (
  <Modal
    show={showModal}
    onHide={onClose}
    contentClassName="worker-dialog conversation-details"
    dialogClassName="worker-dialog__modal conversation-details__modal"
    centered
    size="lg"
  >
    <Modal.Header className="worker-dialog__header-wrapper">
      <div className="worker-dialog__header-content">
        {title}
        <IcomoonReact
          className="worker-dialog__header-content__icon"
          icon="icon-cross"
          iconSet={iconSet}
          onClick={onClose}
        />
      </div>
    </Modal.Header>
    <Modal.Body className="worker-dialog__body">
      {children}
    </Modal.Body>
  </Modal>
)

export default ConversationDetailsDialog
