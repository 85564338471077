import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useActiveConversations from 'hooks/useActiveConversations'
import BackToDashboardDialog from 'routes/ChatConversation/components/BackToDashboardDialog'

export default function ConversationRouterPrompt({ when }) {
  const history = useHistory()
  const [showPrompt, setShowPrompt] = useState(false)
  const [currentPath, setCurrentPath] = useState('')
  const unblockRef = useRef()
  const { id } = useActiveConversations()

  const handleShowModal = () => {
    setShowPrompt(true)
  }

  const onCancel = () => {
    setShowPrompt(false)
  }

  useEffect(() => {
    unblockRef.current = history.block((location) => {
      if (location.pathname === `/conversation/${id}`) {
        return false
      }
      if (when && location.state?.allowNavigation !== true && location.state?.from !== 'conversation') {
        setCurrentPath(location.pathname)
        handleShowModal()
        return false
      }
      return true
    })
    return () => unblockRef.current && unblockRef.current()
  }, [when, history, id])

  const handleConfirm = () => {
    if (unblockRef) {
      unblockRef.current()
    }
    setShowPrompt(false)
    history.push(currentPath)
  }

  return (
    <BackToDashboardDialog
      show={showPrompt}
      onClose={onCancel}
      onConfirm={handleConfirm}
    />
  )
}
