import React from 'react'
import { Button } from 'react-bootstrap'
import QRMainLayout from 'features/qrflow/layouts/QRMainLayout'
import useRedirectChatRequest from 'features/qrflow/hooks/useRedirectChatRequest'
import { parseURL } from 'utils/helpers'
import useGetEntryPointDetails from 'features/qrflow/hooks/useGetEntryPointDetails'

const ThankYouScreen = () => {
  const {
    data,
  } = useGetEntryPointDetails()

  const {
    landing_page: landingPage,
    thankyou_page: thankyouPage,
    visual,
    routing,
  } = data?.config ?? {}

  const { enabled, label, link } = routing?.thankyou_page_close_link ?? {}
  useRedirectChatRequest()

  return (
    <QRMainLayout>
      {thankyouPage?.enabled === 'true' ? (
        <>
          <h2 className="qr-container__title">
            THANK YOU!
            <div
              className="qr-container__title__line"
              style={{
                backgroundColor: visual.accent_color,
              }}
            />
          </h2>
          <div className="screen-intro-text">
            <p>
              {thankyouPage?.message}
            </p>
          </div>
          {enabled && (
            <Button
              className="qr-btn mb-5"
              onClick={() => window.location.replace(parseURL(link))}
              style={{ backgroundColor: visual?.accent_color }}
            >
              {label}
            </Button>
          )}
          {landingPage?.website !== null && landingPage?.website !== '' && (
            <Button
              className="qr-btn white w-100 mb-5 mt-auto"
              onClick={() => {
                window.open(parseURL(landingPage?.website))
              }}
            >
              {landingPage?.call_to_action_text}
            </Button>
          )}
        </>
      ) : null}
    </QRMainLayout>
  )
}

export default ThankYouScreen
