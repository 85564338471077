import constants from 'redux/conversation/conversationDetails/constants'
import { ActionState } from 'utils/constants'

const initialState = null

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_CONVERSATION_DETAILS:
      return ActionState.InProgress
    case constants.GET_CONVERSATION_DETAILS_SUCCESS:
      return {
        ...action.payload,
        chat_request: {
          ...action.payload.chat_request,
          video_calls: action.payload.chat_request?.video_calls.length > 0
            ? {
              ...action.payload.chat_request.video_calls[0],
              compositionRequested: action.payload.chat_request?.video_calls[0].composition !== null
                ? action.payload.chat_request.video_calls[0].composition.id > 0
                : false,
            } : null,
        },
      }
    case constants.GET_CONVERSATION_DETAILS_ERROR:
      return {
        error: action.payload,
      }
    case constants.SET_AUDIO_COMPOSITION_REQUESTED:
      return {
        ...action.payload.conversationDetails,
        chat_request: {
          ...action.payload.conversationDetails.chat_request,
          video_calls: {
            ...action.payload.conversationDetails.chat_request.video_calls[0],
            compositionRequested: action.payload.compositionRequested,
          },
        },
      }
    default:
      return state
  }
}

export default reducer
