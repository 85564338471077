/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Col,
  Row,
  Dropdown,
  ButtonGroup,
} from 'react-bootstrap'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'
import swytchboardLogo from 'assets/images/swytchboard-Y-logo.svg'
import { useSelector } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars'
import Image from 'services/Image'
import useFormData from 'hooks/useFormData'
import FilePreview from 'components/chat/FilePreview'
import TeamMessage from './TeamMessage'

const TeamMessageList = ({ messages, handleSendMessage }) => {
  const {
    name: companyName,
    company_detail: companyDetails,
  } = useSelector((state) => state.company?.data) ?? {}

  const {
    logo: companyLogo,
  } = companyDetails ?? {}

  const inputRef = useRef(null)

  const fileRef = useRef(null)
  const [message, setMessage] = useState('')
  const {
    fileFormData, preview, fileData, handleFileChange, setFileFormData, setPreview,
  } = useFormData()
  /**
   * Send message to the API
   * on keypress (enter)
   * @param e
   * @param from
   */
  const handleMessageChange = (e, from = null) => {
    if (message !== '' || fileFormData instanceof FormData) {
      if (from === null) {
        if (e.keyCode === 13) {
          handleSendMessage(message, fileFormData)
          setMessage('')
          setFileFormData('')
          setPreview('')
          inputRef.current.value = ''
          fileRef.current.value = ''
        }
      } else {
        handleSendMessage(message, fileFormData)
        setMessage('')
        setFileFormData('')
        setPreview('')
        inputRef.current.value = ''
        fileRef.current.value = ''
      }
    }
  }

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef()
    useEffect(() => elementRef?.current?.scrollIntoView(), [])
    return <div ref={elementRef} />
  }

  const companyLogoSource = companyLogo
    ? Image.get(companyLogo)
    : swytchboardLogo

  return (
    <div
      className="chat-wrapper bg-white position-relative d-flex h-100 w-100
      align-items-end justify-content-center flex-column team-chat-wrapper"
    >
      <Row className="m-0 p-0 bg-white text-primary border-bottom w-100 align-items-center brt-05">
        <Col md={2} xs={2} className="m-0 p-2 d-flex justify-content-center">
          <img
            src={companyLogoSource}
            alt="company-logo"
            className="team-chat-wrapper__company-logo"
          />
        </Col>
        <Col md={10} xs={10} className="m-0 p-0">
          <span className="channel-name">{companyName}</span>
        </Col>
      </Row>
      <Row className="m-0 p-0 bg-white text-primary w-100 channels border-border border-bottom">
        <Col md={12} className="m-0 p-0">
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              id="dropdown-item-button"
              title="#nike-customer-service"
              variant="white"
              className="w-100"
            >
              #team-chat
              <IcomoonReact
                iconSet={iconSet}
                size={10}
                icon="dropdown"
                className="position-absolute dropdown-icon"
              />
            </Dropdown.Toggle>
            <Dropdown.Menu className="super-colors">
              <Dropdown.Item as="button">#team-chat</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row
        className="justify-content-center  m-0 p-0  h-100 w-100"
        id="messages-wrapper"
      >
        <Row className="d-flex h-100 w-100 align-items-end justify-content-center">
          <Scrollbars universal>
            {messages?.map((value) => (
              <TeamMessage message={value} key={value.sid} />
            ))}
            <AlwaysScrollToBottom />
          </Scrollbars>
        </Row>
      </Row>
      <Row className="m-0 p-0 border-top px-4 w-100" id="input-messages">
        <Col md={1} xs={1} className="m-0 p-0 align-items-center justify-content-center d-flex">
          <label htmlFor="upload-button">
            <IcomoonReact
              iconSet={iconSet}
              color="#767676"
              size={20}
              icon="icon-attachment"
              role="button"
              className="d-flex align-items-center"
            />
          </label>
          <input
            type="file"
            ref={fileRef}
            id="upload-button"
            style={{ display: 'none' }}
            onChange={(e) => handleFileChange(e)}
          />
        </Col>
        <Col md={10} xs={10} className="m-0 py-0 px-3">
          <div style={{
            boxShadow: '0px 2px 16px rgba(36, 54, 101, 0.1)',
            borderRadius: '10px',
          }}
          >
            <input
              type="text"
              placeholder="Enter Your Message Here"
              className={`send-message br-2 bg-white w-100 shadow-default ${preview && 'border-bottom'}`}
              ref={inputRef}
              onKeyDown={(e) => handleMessageChange(e)}
              onInput={(e) => setMessage(e.target.value)}
            />
            {preview && <FilePreview preview={preview} fileData={fileData} setPreview={setPreview} setFileFormData={setFileFormData} />}
          </div>
        </Col>
        <Col md={1} xs={1} className="m-0 p-0 ps-2 d-flex align-items-center justify-content-center">
          <IcomoonReact
            iconSet={iconSet}
            color="#767676"
            size={20}
            icon="icon-send"
            role="button"
            onClick={(e) => (handleMessageChange(e, 'button'))}
          />
        </Col>
      </Row>
    </div>
  )
}

export default TeamMessageList
