/* eslint-disable max-len */
// eslint-disable-next-line no-unused-vars
import { CreateLocalTrackOptions, ConnectOptions } from 'twilio-video'
import { isMobile } from 'utils/helpers'

/**
 *
 * @type CreateLocalTrackOptions
 */
export const DEFAULT_WORKER_VIDEO_TRACK_OPTIONS = {
  width: {
    ideal: 1280,
    max: 1280,
  },
  height: {
    ideal: 720,
    max: 720,
  },
  frameRate: {
    ideal: 30,
    max: 30,
  },
}

/**
 *
 * @type ConnectOptions
 */
export const DEFAULT_VIDEO_CONNECT_OPTIONS = {
  bandwidthProfile: {
    video: {
      mode: 'presentation',
      contentPreferencesMode: 'manual', // We can manually control the dimensions of remote video tracks (when needed)
      ...(isMobile ? { maxSubscriptionBitrate: 2500000 } : {}), // Limit the maximum incoming video bitrate for mobile
    },
  },
  maxAudioBitrate: 16000,
  preferredVideoCodecs: ['H264'],
}

// These are used to store the selected media device IDs in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'swytchboard-selectedAudioInput'
export const SELECTED_AUDIO_OUTPUT_KEY = 'swytchboard-selectedAudioOutput'
export const SELECTED_VIDEO_INPUT_KEY = 'swytchboard-selectedVideoInput'

export const MediaErrors = {
  // Causes: This error is emitted when the user or the user's system has denied permissions to use the media device
  // 1. User has disabled the input device for the browser in the browser/device settings
  // 2. User's machine does not have any such input device connected to it
  // Solutions:
  // 1. User should enable the input device for the browser in the system settings
  // 2. User should have at least one input device connected
  NotFoundError: 'NotFoundError',

  // Causes: This error is emitted when the user or the user's system has denied permission to use the media devices
  // 1. User has denied permission to your app to access the input device, by clicking deny when prompted, or by going to the browser settings
  // Solutions:
  // 1. User should allow you app to access the input device in the browser settings and then reload
  // 2. User should reload your app and grant permission to access the input device
  NotAllowedError: 'NotAllowedError',

  // Cause: The browser could not start media capture with the input device even after the user gave permission
  // probably because another app or tab has reserved the input device
  // Solution: User should close all other apps and tabs that have reserved the input device and reload your app, or worst case, restart the browser
  NotReadableError: 'NotReadableError',

  // Cause: App is served from a non-secure context
  // Solution: Serve the app from a secure context (https, localhost)
  TypeError: 'TypeError',

  // Cause: The input device could not satisfy the requested media constraint
  // Solution: If this exception was raised due to your app requesting a specific device ID, then most likely the input
  // device is no longer connected to the machine, so your app should request the default input device
  OverConstrainedError: 'OverConstrainedError',
}
