import React, { useRef, useState } from 'react'
import { Overlay, Tooltip } from 'react-bootstrap'
import FullStar from 'components/UI/FullStar'

const CustomerTableRow = ({
  customer = '****',
  helpfulness,
  knowledge,
  professional,
  comment,
  entryPointName,
  headers,
}) => {
  const [show, setShow] = useState(false)
  const target = useRef(null)

  function commentField(string) {
    if (string.length === 0) {
      return (<span>/</span>)
    }

    if (string.length <= 40) {
      return (<span>{string}</span>)
    }

    if (string.length >= 40) {
      return (
        <>
          <div
            ref={target}
            className="customer-feedback__table__ellipsis"
            onMouseOver={() => setShow(!show)}
            onFocus={() => setShow(!show)}
            onBlur={() => setShow(false)}
            onMouseOut={() => setShow(false)}
          >
            {string}
          </div>
          <Overlay
            target={target.current}
            show={show}
            placement="top"
          >
            {(props) => (
              <Tooltip className="customer-feedback__table__tooltip" {...props}>
                {string}
              </Tooltip>
            )}
          </Overlay>
        </>
      )
    }
    return string
  }

  const renderTd = (key, field, star = true) => (
    <td key={key} className={`customer-feedback__table ${key}`}>
      {star && <FullStar size={14} color="#8BD8BD" />}
      <span>
        {field}
      </span>
    </td>
  )

  function checkField(key) {
    switch (headers[key]) {
      case headers.customer:
        return {
          field: customer,
          star: false,
        }
      case headers.entryPointName:
        return {
          field: entryPointName,
          star: false,
        }
      case headers.helpfulness:
        return {
          field: helpfulness,
          star: helpfulness !== undefined,
        }
      case headers.knowledge:
        return {
          field: knowledge,
          star: knowledge !== undefined,
        }
      case headers.professional:
        return {
          field: professional,
          star: professional !== undefined,
        }
      case headers.comment:
        return {
          field: commentField(comment),
          star: false,
        }
      default:
        return {}
    }
  }

  return (
    <tr className="customer-feedback__table">
      {Object.keys(headers).map((header) => {
        const { field, star, className } = checkField(header)
        return renderTd(header, field, star, className)
      })}
    </tr>
  )
}

export default CustomerTableRow
