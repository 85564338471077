import React from 'react'
import LoadingButton from 'components/buttons/LoadingButton'
import ViewableLogoLayout from 'routes/ChatSettingsEntryPoint/components/ViewableLogoLayout'
import { Button } from 'react-bootstrap'
import { ALLOW_LEAVE_MESSAGE } from 'routes/ChatSettingsEntryPoint/constants'

const Viewable = ({
  queueMessage,
  accentColor,
  allowLeaveMessageEnabled,
}) => (
  <ViewableLogoLayout>
    <div className="text-start flex-fill">
      <h4 className="chat-viewable__title">
        QUEUE
        <div
          className="chat-viewable__title__line"
          style={{
            backgroundColor: accentColor,
          }}
        />
      </h4>
      <div className="chat-queue-viewable--increase-spacing">
        <p>
          {queueMessage}
        </p>
      </div>
    </div>
    {allowLeaveMessageEnabled && (
      <Button
        className="qr-btn w-100 mb-2"
        style={{
          backgroundColor: accentColor,
        }}
      >
        {ALLOW_LEAVE_MESSAGE}
      </Button>
    )}
    <LoadingButton
      className="qr-btn w-100 my-3"
      style={{
        backgroundColor: accentColor,
      }}
      disabled
    >
      Cancel
    </LoadingButton>
  </ViewableLogoLayout>
)

export default Viewable
