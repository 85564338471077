import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import Company from 'services/Company'
import { QueryKeys } from 'utils/constants'

export default function useAvailableWorkers() {
  const { serviceUid } = useParams()

  const { data: { available: availableWorkers } } = useQuery(
    [QueryKeys.AVAILABLE_WORKERS], () => Company.getAvailableUsers(serviceUid), {
      initialData: { available: true },
      enabled: !!serviceUid,
      retryOnMount: false,
      retry: 1,
    },
  )

  return {
    availableWorkers,
  }
}
