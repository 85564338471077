import React from 'react'
import ReactTimeAgo from 'react-time-ago'
import { UrlMatcher } from 'interweave-autolink'
import { Col, Row } from 'react-bootstrap'
import SwytchAvatar from 'components/user/SwytchAvatar'
import { Interweave } from 'interweave'
import Image from 'services/Image'
import useGetEntryPointDetails from 'features/qrflow/hooks/useGetEntryPointDetails'
import useUserAvatar from 'hooks/useUserAvatar'
import { useChatContext } from 'providers/ChatProvider'
import EmbedChatFiles from 'components/chat/EmbedChatFiles'
import 'assets/styles/components/consumer/chat.scss'

const Message = ({
  message,
}) => {
  const {
    data,
  } = useGetEntryPointDetails()

  const {
    accent_color: accentColor,
  } = data?.config.visual ?? {}

  const {
    dateCreated,
    body,
    author,
    sid,
    participantSid,
    media,
  } = message

  const { getFullNameByParticipantSid, getAvatarByParticipantSid } = useChatContext()
  const fullName = getFullNameByParticipantSid(participantSid)
  const avatar = getAvatarByParticipantSid(participantSid)

  const profilePicture = Image.get(avatar)

  const isConsumer = author.includes('consumer')

  const {
    renderAvatar,
  } = useUserAvatar({ full_name: fullName, avatar }, { profilePicture }, {}, { avatarSize: 30 })

  if (isConsumer) {
    return (
      <Row
        key={sid}
        className="qr-chat__message"
      >
        <Col xs={10} sm={11} className="qr-chat__message__info">
          <div
            style={{
              backgroundColor: accentColor,
            }}
            className="qr-chat__message__info__content qr-chat__message__info__content--consumer"
          >
            {media && <EmbedChatFiles media={media} /> }
            <Interweave
              content={body}
              matchers={[new UrlMatcher('url')]}
              newWindow
            />
          </div>
          <div className="qr-chat__message__info__timestamp qr-chat__message__info__timestamp--consumer">
            <ReactTimeAgo date={dateCreated} />
          </div>
        </Col>
        <Col xs={2} sm={1} className="qr-chat__message__avatar qr-chat__message__avatar--consumer">
          <SwytchAvatar name={fullName} size={30} />
        </Col>
      </Row>
    )
  }
  return (
    <Row
      key={message.sid}
      className="qr-chat__message"
    >
      <Col xs={2} sm={1} className="qr-chat__message__avatar qr-chat__message__avatar--worker">
        {renderAvatar}
      </Col>
      <Col xs={10} sm={11} className="qr-chat__message__info">
        <div
          className="qr-chat__message__info__content qr-chat__message__info__content--worker"
        >
          {media && <EmbedChatFiles media={media} /> }
          <Interweave
            content={body}
            matchers={[new UrlMatcher('url')]}
            newWindow
          />
        </div>
        <div className="qr-chat__message__info__timestamp">
          <ReactTimeAgo date={dateCreated} />
        </div>
      </Col>
    </Row>
  )
}

export default Message
