import React from 'react'
import { Col, Row } from 'react-bootstrap'
import './userStatsCarousel.scss'
import Badge from './Badge'

const Stats = ({
  header,
  percentage,
  number,
  footer,
}) => {
  // eslint-disable-next-line no-nested-ternary
  const badgeColor = percentage > 0 ? 'badgegreen' : (percentage < 0 ? 'badgered' : 'badgegrey')
  const showBadge = false // remove this const when percentages implementation is ready
  return (
    <Row className="admin-dashboard-stats d-flex flex-column p-0">
      <Col className="admin-dashboard-stats__header">
        <span className="card-title me-3">
          {header}
        </span>
        {showBadge && <Badge percentage={percentage} badgeColor={badgeColor} />}
      </Col>
      <Col>
        <h2 className="admin-dashboard-stats__content fw-bolder text-dark p-0">{number}</h2>
      </Col>
      <Col>
        <span className="title">
          {footer}
        </span>
      </Col>
    </Row>
  )
}

export default Stats
