import React, { useState } from 'react'
import {
  Col,
  Row,
  Button,
  ProgressBar,
  Card,
} from 'react-bootstrap'
import { useBillingContext } from 'providers/BillingProvider/index'
import { useToasts } from 'react-toast-notifications'
import PlanOptionsDialog from 'routes/Billing/components/Plan/OptionsDialog'

const CurrentPlan = ({ billingPlan }) => {
  const {
    getPlans,
    checkoutPlan,
  } = useBillingContext()
  const { addToast } = useToasts()
  const [planOptionsDialog, setDisplayPlanOptionsDialog] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const onDialogOpen = async () => {
    try {
      setDisplayPlanOptionsDialog(true)
      setLoading(true)
      await getPlans()
      setLoading(false)
    } catch (e) {
      addToast('Error while fetching plans', {
        appearance: 'info',
        autoDismiss: true,
        autoDismissTimeout: 2000,
      })
      setDisplayPlanOptionsDialog(false)
      setLoading(false)
    }
  }

  const onDialogClose = () => {
    setDisplayPlanOptionsDialog(false)
  }

  const currentPlanAmount = checkoutPlan.monthly_plans.find((plan) => plan.name === billingPlan.plan)?.amount

  return (
    <>
      <PlanOptionsDialog
        setDisplayPlanOptionsDialog={setDisplayPlanOptionsDialog}
        checkoutPlans={checkoutPlan}
        isLoading={isLoading}
        show={planOptionsDialog}
        onClose={onDialogClose}
        title="Add more hours"
        currentPlanAmount={currentPlanAmount}
      />
      <Row className="mt-3 mb-5">
        <Col md={6}>
          <div className="mb-3 d-flex">
            <span className="text-xl me-3 fw-bold">Current subscription plan: </span>
            <span className="text-2xl text-capitalize fw-bold me-1">{billingPlan.plan}</span>
            <span className="text-l me-3 fw-light">(contact support for billing info)</span>
          </div>
          <div className="mb-3 d-flex">
            <span className="text-xl me-3">It may take about 15 minutes for the stats to update.</span>
          </div>
          <Card className="shadow p-3 rounded-xl">
            <Card.Body>
              <div className="mb-5 fw-bold">
                <span className="text-5xl me-2">{billingPlan.usedHours}</span>
                <span className="text-5xl">hours used</span>
              </div>
              <div className="mb-5">
                <ProgressBar variant="success" now={60} />
              </div>
              <Row>
                <Col className="d-flex" md={6}>
                  <div className="me-5">
                    <div className="me-3 mb-1 text-lg fw-bold">Billing cycle</div>
                  </div>
                  <div>
                    <div className="mb-1 text-lg">{billingPlan.cycle}</div>
                  </div>
                </Col>
                <Col className="d-flex flex-row-reverse" md={6}>
                  <Button
                    onClick={onDialogOpen}
                    className="btn-success rounded-xl text-lg text-capitalize fw-bold"
                  >
                    add more hours
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default CurrentPlan
