import React from 'react'
import QRDialog from 'features/qrflow/components/dialog/QRDialog'
import useGetEntryPointDetails from 'features/qrflow/hooks/useGetEntryPointDetails'

const LeaveAMessageDialog = ({
  show,
  onClose,
  onLeaveMessageHandler,
}) => {
  const { data } = useGetEntryPointDetails()

  const {
    accent_color: accentColor,
  } = data?.config.visual ?? {}

  return (
    <QRDialog
      show={show}
      title="Leave A Message"
      onClose={onClose}
      onConfirm={onLeaveMessageHandler}
      confirmTextColor={accentColor}
    >
      <p>
        You are about to cancel the request.
      </p>
      <p>
        After this, you&apos;ll have the option to leave a message for the company. We value your feedback and will address your message promptly.
      </p>
    </QRDialog>
  )
}
export default LeaveAMessageDialog
