import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { TeamMembersProvider } from 'routes/TeamMembers/context/TeamMembersContext'
import AddTeamMembers from 'routes/TeamMembers/components/AddTeamMembers/AddTeamMembers'
import ToggleButtons from 'routes/TeamMembers/components/ToggleButtons/ToggleButtons'
import TeamMembersWrapper from 'routes/TeamMembers/components/TeamMembersWrapper'

const TeamMembers = () => (
  <div className="bg-white ps-4 team-members">
    <Row className="m-1">
      <Col md={12}>
        <div className="team-members__header">
          <div className="team-members__header__title">
            Team
          </div>
        </div>
        <div className="team-members__header__subtitle">View and edit your company’s team.</div>
        <hr className="mb-4" />
      </Col>
    </Row>
    <TeamMembersProvider>
      <AddTeamMembers />
      <ToggleButtons />
      <TeamMembersWrapper />
    </TeamMembersProvider>
  </div>
)

export default TeamMembers
