import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'
import { isMobile } from 'utils/helpers'

const DeclineVideoCall = ({
  show,
  setShow,
  onDecline,
  onLeaveMessage,
  chatRequestId,
}) => (
  <Modal
    show={show}
    onHide={setShow}
    centered
    dialogClassName={!isMobile && 'decline-dialog__main-card'}
    contentClassName="decline-dialog"
  >
    <Modal.Header className="decline-dialog__header-wrapper">
      <div className="decline-dialog__header-content">
        Decline Customer Request
        <IcomoonReact
          className="decline-dialog__header-content__icon"
          icon="icon-cross"
          iconSet={iconSet}
          onClick={() => setShow((prev) => !prev)}
        />
      </div>
    </Modal.Header>
    <Modal.Body className="decline-dialog__body">
      <div>
        <p>If you are unable to take this customer request you can choose to:</p>
        <ul>
          <li className="inline-block">
            Ask the customer to leave a message and their contact information by clicking on&nbsp;
            <span>Leave a Message.</span>
          </li>
          <li>
            Decline and remove this user&apos;s request by clicking on&nbsp;
            <span> Decline.</span>
          </li>
        </ul>
      </div>
    </Modal.Body>
    <Modal.Footer className="decline-dialog__footer">
      <Button className="btn-danger text-white" onClick={() => onDecline(chatRequestId)}>Decline</Button>
      <Button className="btn-success" onClick={() => onLeaveMessage(chatRequestId)}>Leave a Message</Button>
    </Modal.Footer>
  </Modal>
)

export default DeclineVideoCall
