import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import MessagesLeftData from 'routes/MessagesLeft/MessagesLeft'
import InputField from 'components/form/InputField'
import ToggleButtons from './components/ToggleButtons'

const MessagesLeft = () => {
  const [switcher, setSwitcher] = useState(true)
  const [search, setSearch] = useState('')
  return (
    <div className="messagesLeft">
      <div className="messagesLeft__header">
        <div className="messagesLeft__header__title">
          Messages Left
        </div>
      </div>
      <Row>
        <Col md={7}>
          <div className="messagesLeft__header__subtitle">
            Read and follow-up messages left by your customers while you were away.
          </div>
        </Col>
        <Col>
          <InputField
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search Messages"
          />
        </Col>
      </Row>
      <Row className="m-0 p-0">
        <hr />
        <Col md={12} className="m-0 p-0">
          <ToggleButtons switcher={switcher} setSwitcher={setSwitcher} />
          <MessagesLeftData status={switcher} search={search} />
        </Col>
      </Row>
    </div>
  )
}
export default MessagesLeft
