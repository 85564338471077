import React from 'react'

const ButtonTextStepField = ({
  title,
  subTitle,
  children,
}) => (
  <div className="chat-configuration-editable mb-3">
    <div className="w-100 d-flex mb-2">
      <div className="chat-configuration-editable__title w-100">
        {title}
      </div>
      <div className="d-flex align-items-center me-3">
        {children}
      </div>
    </div>
    <div className="chat-configuration-editable__subtitle">
      {subTitle}
    </div>
  </div>
)

export default ButtonTextStepField
