import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import IcomoonReact from 'icomoon-react'
import LoadingButton from 'components/buttons/LoadingButton'
import iconSet from 'assets/fonts/selection.json'
import useRequestMediaPermission from 'providers/VideoProvider/useRequestMediaPermission/useRequestMediaPermission'

const GrantPermissions = () => {
  const isChatHidden = useSelector((state) => state.layout.chatHidden)

  const {
    askForMicrophonePermissions,
    askForVideoPermissions,
  } = useRequestMediaPermission()

  const handleRequestMediaPermissions = () => {
    askForMicrophonePermissions()
    askForVideoPermissions(true)
  }

  return (
    <div
      className={`qr-video__permissions ${isChatHidden ? 'chat-hidden' : ''}`}
      onClick={() => handleRequestMediaPermissions()}
    >
      <Row>
        <Col md={2} style={{ maxWidth: '15%' }}>
          <IcomoonReact
            iconSet={iconSet}
            size={40}
            icon="warning-icon"
            color="red"
            className="align-self-center py-2"
          />
        </Col>
        <Col md={12} style={{ maxWidth: '85%' }}>
          <p>Swytchboard needs your permission to enable Camera and Microphone.</p>
        </Col>
      </Row>
      <Row>
        <Col md={11}>
          <LoadingButton>
            Grant Permissions
          </LoadingButton>
        </Col>
      </Row>
    </div>
  )
}
export default GrantPermissions
