import React from 'react'
import {
  Col,
  Row,
} from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useBillingContext } from 'providers/BillingProvider/index'
import WebcamPreview from 'components/WebcamPreview/WebcamPreview'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import useIsWorkerPunchedIn from 'features/work-logs/useIsWorkerPunchedIn'
import DashboardCard from 'components/UI/Panel/DashboardCard'
import usePendingChatRequests from 'features/pending-chat-requests/usePendingChatRequests'
import ActiveConversations from 'components/ActiveConversations'
import PunchInPanel from 'routes/Dashboard/components/PunchInPanel/PunchInPanel'
import Queue from './components/chat/Queue'
import TeamChat from './components/chat/team/TeamChat'
import CustomerCard from './components/chat/CustomerCard'
import SubscriptionMessage from './components/chat/SubscriptionMessage'

const DashboardMain = () => {
  const preselectChatRequest = useSelector((state) => state.chatRequest.preselectChatRequest)
  const { data: chatRequests = [] } = usePendingChatRequests()
  const {
    isPunchedIn,
    isLoadingPunchedInState,
  } = useIsWorkerPunchedIn()

  const {
    subscriptionPlan,
    isLoadingSubscription,
  } = useBillingContext()

  const nextCustomer = chatRequests?.length >= 1 ? chatRequests[0] : null
  const preSelectCustomer = chatRequests?.length && chatRequests?.find((x) => x?.id === preselectChatRequest)

  if (!subscriptionPlan.status.isActive()) {
    return (
      <SubscriptionMessage
        subscriptionStatus={subscriptionPlan.status}
        isLoadingSubscription={isLoadingSubscription}
      />
    )
  }

  return (
    <Row className="m-0 p-0 h-100">
      {!subscriptionPlan.status.isActive()
        ? (
          <SubscriptionMessage
            subscriptionStatus={subscriptionPlan.status}
            isLoadingSubscription={isLoadingSubscription}
          />
        ) : (
          <Row className="p-0 m-0">
            <Col lg={8} md={8} xl={8} className="bg-lighter h-100 p-4 br-05">
              <Row className="w-100 m-0 p-0">
                <Col md={12} className="section-title pb-3 mb-0 border-b fw-bold">
                  Messages
                </Col>
              </Row>
              <Row>
                <Col md={6} className="my-4">
                  <Queue />
                </Col>
                <Col md={6} className="justify-content-between my-4">
                  <Row className="m-0 p-0 w-100" style={{ rowGap: 25 }}>
                    {
                        isLoadingPunchedInState && (
                          <DashboardCard className="text-center">
                            <LoadingSpinner />
                          </DashboardCard>
                        )
                      }
                    {
                        !isLoadingPunchedInState && !isPunchedIn && (
                          <DashboardCard>
                            <PunchInPanel />
                          </DashboardCard>
                        )
                      }
                    {
                        !isLoadingPunchedInState && isPunchedIn && (
                          <DashboardCard>
                            <CustomerCard chatRequest={nextCustomer} />
                          </DashboardCard>
                        )
                      }
                    {
                        preselectChatRequest && isPunchedIn ? (
                          <DashboardCard>
                            <CustomerCard chatRequest={preSelectCustomer} title="Priority Customer" />
                          </DashboardCard>
                        ) : <WebcamPreview />
                      }
                  </Row>
                </Col>
                <Col>
                  <ActiveConversations />
                </Col>
              </Row>
            </Col>
            <Col lg={4} md={4} xl={4} className="h-100">
              <TeamChat />
            </Col>
          </Row>
        )}
    </Row>
  )
}

export default DashboardMain
