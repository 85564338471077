import React from 'react'
import { Row, Col } from 'react-bootstrap'
import dayjs from 'dayjs'
import FullStar from 'components/UI/FullStar'

const Details = ({
  customer,
  email,
  phone,
  worker,
  date,
  entryPointName,
  helpfulness,
  knowledge,
  professional,
  comment,
}) => {
  function isoDateToJsDate(dateFormater) {
    return dayjs(dateFormater).format('MM/DD/YYYY')
  }

  function renderDetails(label, field, star, className) {
    return (
      <Row>
        <div className={`conversation-details__field ${className}`}>
          <Col md={4} className="conversation-details__field__label">
            {label}
          </Col>
          <Col md={8} className={`conversation-details__field__content ${className}`}>
            {star && <FullStar size={14} color="#8BD8BD" />}
            <span>
              {field}
            </span>
          </Col>
        </div>
      </Row>
    )
  }

  return (
    <>
      {renderDetails('Customer Name', customer, false, 'customer')}
      {renderDetails('Customer E-mail', email, false, 'email')}
      {renderDetails('Customer Phone', phone, false, 'phone')}
      {renderDetails('Date of conversation', isoDateToJsDate(date), false, 'date')}
      {renderDetails('Worker name', worker, false, 'worker')}
      {renderDetails('Entry Point name', entryPointName, false, 'entryPointName')}
      {renderDetails('Helpfulness rating', helpfulness, true, 'star')}
      {renderDetails('Knowledge rating', knowledge, true, 'star')}
      {renderDetails('Professional rating', professional, true, 'star')}
      {renderDetails('Comment', comment, false, 'comment')}
    </>
  )
}

export default Details
