import React from 'react'

// Common component used on the admin pages.
// Usually used below the page's header for identify the current page,
// with an optional button on the rigth side.
const ContentHeader = ({
  children,
  title,
  subtitle,
}) => (
  <div className="content-header">
    <div>
      <div className="content-header__title">
        {title}
      </div>
      <div className="content-header__subtitle">
        {subtitle}
      </div>
    </div>
    <div>
      { children }
    </div>
  </div>
)

export default ContentHeader
