/**
 * Parse and modify the error response to be in format
 * {message:'Error Message', status:404, ...all other axios error props}
 * @param error
 */
export default function parseAndRethrowError(error) {
  let errorObj = {}
  if (error.response && error.response.data) {
    // If the error response contains a list of errors, parse it and rethrow it in format {message:'Error message'}
    if (error.response.data.errors && error.response.data.errors.length !== 0) {
      errorObj = {
        ...error.response,
        message: error.response.data.errors[0].title,
      }
      throw errorObj
    }
    errorObj = {
      ...error.response,
      ...error.response.data,
    }
    throw errorObj
  }
  throw error
}
