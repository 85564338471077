/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { updateActiveConversations } from 'redux/activeConversations/actions'
import useGetAcceptedChatRequests from './hooks/useGetAcceptedChatRequests'
import ConversationCard from './conversationCard'

const ActiveConversations = ({ paddingTop = 0 }) => {
  const { data, isInitialLoading: isLoading } = useGetAcceptedChatRequests() // returns all active calls for company
  const stateChatRequests = useSelector((state) => state.activeConversations) // user's active chats
  const [stateChatCount, setStateChatCount] = useState(-1)
  const worker = useSelector((state) => state.user)
  const dispatch = useDispatch()

  // filter only active conversations for current user/worker
  function getCurrentUserChats(chatRequests, workerId) {
    return chatRequests?.filter?.((chat) => chat?.chat_conversation?.user?.id === workerId)
  }

  const [acceptedChatRequests, setAcceptedChatRequests] = useState([])

  useEffect(() => {
    if (stateChatCount === -1) {
      // init
      const count = stateChatRequests?.length ?? 0
      setStateChatCount(count)
      console.log('init chat request count', count)
    } else {
      // after init
      const count = stateChatRequests?.length ?? 0
      if (count > stateChatCount) {
        console.log('new chat request is accepted')
      } else {
        console.log('chat request is ended')
      }
      setStateChatCount(count)
      console.log('chat count', count)
    }
  }, [stateChatRequests])

  useEffect(() => {
    if (!isLoading) {
      const currentChats = getCurrentUserChats(data, worker?.data?.id)
      setAcceptedChatRequests(currentChats ?? [])
      // remove remotely ended conversations
      const acceptedChatCount = currentChats?.length ?? 0
      if (acceptedChatCount < stateChatCount) {
        const acceptedChatIds = currentChats.map((chat) => Number(chat.id))
        // check if state chat is part of the accepted chats for current user/worker
        const filtered = stateChatRequests?.filter((stateChat) => acceptedChatIds.includes(Number(stateChat.id)))
        // cleanup user's state
        dispatch(updateActiveConversations(filtered))
      }
    }
  }, [data])

  return (
    <Row className={`w-100 pt-${paddingTop}`}>
      <Col md={12} className="section-title py-3 ms-3 border-top border-black fw-bold">
        Active Conversations (
        {acceptedChatRequests?.length}
        /5)
      </Col>
      <Row className="w-100 ps-4 ms-1">
        {acceptedChatRequests?.map((chat) => (
          <ConversationCard
            key={`${chat?.consumer?.name}_${chat?.id}`}
            name={chat?.consumer?.name}
            id={chat?.id}
            requestType={chat?.request_type}
          />
        ))}
      </Row>
    </Row>
  )
}
export default ActiveConversations
