import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import PunchedOut from '../punchedOut'
import LeaveMessageForm from './LeaveMessageForm'

const LeaveMessage = ({
  landingPageConfig,
  visualConfig,
  queueConfig,
  entryPointId,
  entryPointError,
}) => {
  const { firstStep = true, intent } = useLocation().state ?? { state: { firstStep: true } }
  // 'leaveMessage-chat' is broadcasted by backend when worker explicitly requests leave message
  const leaveMessageAllowed = (queueConfig?.allow_leave_message === 'true' || queueConfig?.allow_leave_message === undefined) || intent === 'leaveMessage-chat'
  const [isFirstStep, setIsFirstStep] = useState(firstStep)
  const handleOnNextStep = () => setIsFirstStep((prev) => !prev)

  return (
    isFirstStep || !leaveMessageAllowed
      ? (
        <PunchedOut
          onNextStepClickHandler={handleOnNextStep}
          landingPageConfig={landingPageConfig}
          accentColor={visualConfig?.accent_color}
          leaveMessageAllowed={leaveMessageAllowed}
        />
      ) : (
        <LeaveMessageForm
          accentColor={visualConfig?.accent_color}
          entryPointId={entryPointId}
          entryPointError={entryPointError}
        />
      )
  )
}
export default LeaveMessage
