/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useVideoContext } from 'providers/VideoProvider'
import { DEFAULT_WORKER_VIDEO_TRACK_OPTIONS } from 'providers/VideoProvider/constants'
import {
  ParticipantTypes, TrackPriority,
} from 'utils/constants'
import { RoomStateType } from 'providers/VideoProvider/useRoomState'
import useWorkerConversationChannel from 'channels/useWorkerConversationChannel'
import { useAuth } from 'providers/AuthProvider/AuthProvider'
import useRemoteParticipants from 'hooks/useRemoteParticipants/useRemoteParticipants'
import useMainParticipant from 'hooks/useMainParticipant/useMainParticipant'
import useScreenShareParticipant from 'hooks/useScreenShareParticipant/useScreenShareParticipant'
import useResetRoom from 'hooks/useResetRoom'
import useConversationSettings from 'hooks/useActiveConversations/useConversationSettings'
import useActiveConversations from 'hooks/useActiveConversations'
import Chat from 'services/Chat'
import { updateActiveConversation } from 'redux/activeConversations/actions'
import NetworkQualityLevel from 'components/NetworkQualityLevel/NetworkQualityLevel'
import { isMobile } from 'utils/helpers'
import DisplayMediaError from 'features/qrflow/components/video/DisplayMediaError'
import RemoteParticipant from './RemoteParticipant'
import LocalParticipant from './LocalParticipant'
import VideoInfoContainer from './VideoInfoContainer'

const hasAcquiredLocalTracks = (localTracks) => localTracks.length === 2

const videoInfoContainerClassName = `connecting ${isMobile ? 'mobile' : ''}`

const RenderConversationRoom = ({
  room, isParticipantDisconnected, hasAnyParticipant, localParticipant, screenShareParticipant, remoteParticipants,
}) => {
  const {
    isSharingScreen,
    isTwoWayEnabled,
    micMediaError,
    videoMediaError,
  } = useVideoContext()
  const mainParticipant = useMainParticipant()

  const videoPriority = mainParticipant === screenShareParticipant && mainParticipant !== localParticipant
    ? 'high'
    : null

  if (micMediaError || videoMediaError) {
    return (
      <DisplayMediaError
        micMediaError={micMediaError}
        videoMediaError={videoMediaError}
      />
    )
  }
  if (!room) {
    return (
      <div className="conversation-screen__video__video-player-content">
        <div className="participant-info">
          <VideoInfoContainer
            className={videoInfoContainerClassName}
          />
        </div>
      </div>
    )
  }
  if (isParticipantDisconnected || !hasAnyParticipant) {
    return (
      <LocalParticipant
        participant={localParticipant}
        videoOnly
        enableScreenShare={mainParticipant !== localParticipant}
        videoPriority={videoPriority}
        isLocalParticipant={mainParticipant === localParticipant}
      />
    )
  }
  if (Boolean(screenShareParticipant) || isSharingScreen) {
    return (
      <LocalParticipant
        participant={isSharingScreen ? localParticipant : remoteParticipants?.[0]}
        videoOnly
        enableScreenShare={mainParticipant !== localParticipant}
        videoPriority={videoPriority}
        isLocalParticipant={mainParticipant === localParticipant}
      />
    )
  }
  return remoteParticipants.map((remoteParticipant) => (
    <Fragment key={remoteParticipant}>
      <NetworkQualityLevel participant={remoteParticipant} />
      <RemoteParticipant
        key={remoteParticipant}
        participant={remoteParticipant}
        videoConnectionInfoClassName={videoInfoContainerClassName}
        isTwoWayEnabled={isTwoWayEnabled}
        worker
      />
    </Fragment>
  ))
}

const Room = ({ roomName, token }) => {
  const { cableConsumer } = useAuth()
  const { worker } = useConversationSettings()
  const dispatch = useDispatch()
  const { id: chatRequestId } = useActiveConversations()
  const { isTokenExpired, resetRoom } = useResetRoom()

  const {
    room,
    isConnectingToRoom,
    connectToRoom,
    createNewAudioTrack,
    createNewVideoTrack,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    localTracks,
    setIsTwoWayEnabled,
    videoTrack,
    audioTrack,
    setMicMediaError,
    setVideoMediaError,
  } = useVideoContext()
  const { remoteParticipants, hasAnyParticipant, isParticipantDisconnected } = useRemoteParticipants()
  const screenShareParticipant = useScreenShareParticipant()
  const localParticipant = room?.localParticipant
  const canConnectToRoom = room?.state !== RoomStateType.CONNECTED
  && !isConnectingToRoom
  && roomName && token
  && hasAcquiredLocalTracks(localTracks)

  useEffect(() => {
    const handleAudioTrack = async () => {
      try {
        if (!hasAcquiredLocalTracks(localTracks)) {
          await createNewAudioTrack()
        }
        if (audioTrack?.isStopped) {
          await audioTrack?.restart()
        }
      } catch (error) {
        setMicMediaError(error)
      }
    }
    handleAudioTrack()
  }, [createNewAudioTrack, localTracks])

  useEffect(() => {
    const handleVideoTrack = async () => {
      try {
        if (!hasAcquiredLocalTracks(localTracks)) {
          await createNewVideoTrack(DEFAULT_WORKER_VIDEO_TRACK_OPTIONS)
        }
        if (videoTrack?.isStopped) {
          await videoTrack?.restart()
        }
      } catch (error) {
        setVideoMediaError(error)
      }
    }
    handleVideoTrack()
  }, [createNewVideoTrack, localTracks, worker?.isVideoEnabled])

  useEffect(() => () => removeLocalAudioTrack(), [removeLocalAudioTrack])
  useEffect(() => () => removeLocalVideoTrack(), [removeLocalVideoTrack])

  useEffect(() => {
    const handleRoom = async () => {
      if (isTokenExpired(token)) {
        room?.disconnect()
        Chat.completeChatRequestWorker(chatRequestId, roomName)
        return resetRoom(chatRequestId)
      }
      if (canConnectToRoom) {
        return connectToRoom(
          token,
          {
            name: roomName,
          },
          localTracks,
          TrackPriority.STANDARD,
        )
      }
      return () => []
    }
    handleRoom()
  }, [room, canConnectToRoom, token])

  useEffect(() => {
    dispatch(updateActiveConversation(chatRequestId, { [ParticipantTypes.ALL]: { } }))
    // first reset the reducer for all participant types, then recreate the state
    remoteParticipants?.forEach((p) => (
      dispatch(updateActiveConversation(chatRequestId, { [ParticipantTypes.ALL]: { [p?.identity]: { status: 'on', type: ParticipantTypes.CONSUMER } } }))
    ))
  }, [remoteParticipants, dispatch])

  const onReceiveConversationEvent = (response) => {
    // Invoked when the consumer enables the two-way video
    if (response?.intent === 'update-two-way-video') {
      if (response?.data.status === 'on') {
        setIsTwoWayEnabled(true)
      }
      if (response?.data.status === 'off') {
        setIsTwoWayEnabled(false)
      }
    }
  }

  useWorkerConversationChannel(chatRequestId, cableConsumer, onReceiveConversationEvent)

  return (
    <div className={`conversation-screen__video__video-player-content ${isMobile ? 'mobile' : ''}`}>
      <div className={`participant-info ${screenShareParticipant ? 'screen-share' : ''} ${isMobile ? 'mobile' : ''} ${isParticipantDisconnected || !hasAnyParticipant ? 'self-camera' : ''}`}>
        <RenderConversationRoom
          room={room}
          isParticipantDisconnected={isParticipantDisconnected}
          hasAnyParticipant={hasAnyParticipant}
          localParticipant={localParticipant}
          screenShareParticipant={screenShareParticipant}
          remoteParticipants={remoteParticipants}
        />
      </div>
    </div>
  )
}

export default Room
