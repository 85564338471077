import React from 'react'
import { useMembersContext } from 'routes/TeamMembers/context/TeamMembersContext'

const ToggleButtons = () => {
  const {
    displayPendingMembers, setDisplayPendingMembers, userTeamMembers, pendingTeamMembers,
  } = useMembersContext()

  const handlePendingTeamMembers = () => {
    pendingTeamMembers()
    setDisplayPendingMembers(false)
  }

  const handleUserTeamMembers = () => {
    userTeamMembers()
    setDisplayPendingMembers(true)
  }

  return (
    <div className="ps-3 pb-2 d-flex">
      <button
        type="button"
        onClick={handleUserTeamMembers}
        className={`${!displayPendingMembers ? 'team-members__active' : ''}
        team-members__btn`}
      >
        Members
      </button>
      <button
        type="button"
        onClick={handlePendingTeamMembers}
        className={`${displayPendingMembers ? 'team-members__active' : ''}
        team-members__btn team-members__pending-btn`}
      >
        Pending
      </button>
    </div>
  )
}

export default ToggleButtons
