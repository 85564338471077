import React from 'react'
import { Button, Alert } from 'react-bootstrap'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import TextFormField from 'components/UI/TextFormField/TextFormField'
import LoadingButton from 'components/buttons/LoadingButton'
import Layout from 'routes/OnBoardingInfo/Layout'
import useSetupPerojectInfo from 'routes/OnBoardingInfo/ProjectInfo/useProjectInfoSetup'
import { validUrl } from 'utils/regex'

const ProjectInfoSchema = Yup.object({
  name: Yup.string()
    .required('Enter a project name!')
    .min(3, 'Name must be at least 3 characters'),
  websiteURL: Yup.string()
    .required('Enter a website!')
    .matches(validUrl, 'Enter a correct website!'),
})

const ProjectInfoSetup = () => {
  const {
    isSubmitting,
    errorMessage,
    onClearErrorMessage,
    onPersonalInfoHandler,
    onBackClickHandler,
  } = useSetupPerojectInfo()

  const initialValues = {
    name: '',
    websiteURL: '',
  }

  return (
    <Layout>
      <Formik
        initialValues={initialValues}
        validationSchema={ProjectInfoSchema}
        onSubmit={onPersonalInfoHandler}
      >
        {({
          handleSubmit,
          isValid,
        }) => (
          <div>
            {errorMessage && (
            <Alert
              className="my-3"
              variant="danger"
              dismissible
              onClose={onClearErrorMessage}
            >
              {errorMessage}
            </Alert>
            )}

            <Field
              label="Project Name"
              name="name"
              placeholder="Enter a name for your project (required)"
              component={TextFormField}
            />
            <Field
              label="Website URL"
              name="websiteURL"
              placeholder="Enter your website’s URL (required)"
              component={TextFormField}
            />

            <div className="d-flex justify-content-between mt-4">
              <Button
                onClick={onBackClickHandler}
                className="verify-email__button account-setup__button--back"
              >
                Back
              </Button>
              <LoadingButton
                loading={isSubmitting}
                disabled={!(isValid)}
                onClick={handleSubmit}
                className="account-setup__button account-setup__button--submit"
              >
                Continue
              </LoadingButton>
            </div>
          </div>
        )}
      </Formik>
    </Layout>
  )
}

export default ProjectInfoSetup
