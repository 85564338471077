import React from 'react'
import { isMobile } from 'utils/helpers'

const VideoControls = ({
  children,
}) => (
  <div className={`video-buttons ${isMobile ? 'mobile' : ''}`}>
    {children}
  </div>
)

export default VideoControls
