import { useRef, useLayoutEffect, useEffect } from 'react'

export default function useInterval(callback, delay) {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.
  useLayoutEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if ((!delay && delay !== 0)) {
      return
    }
    const id = setInterval(() => savedCallback.current(), delay)
    // eslint-disable-next-line consistent-return
    return () => clearInterval(id)
  }, [delay])
}
