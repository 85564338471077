import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import userActions from 'redux/user/actions'
import { useAuth } from 'providers/AuthProvider/AuthProvider'
import User from 'services/User'

/**
 * The return value useVerifyEmail hook
 * @typedef {Object} UseVerifyEmailReturnValue
 * @property {Function} onLogout
 * @property {Function} onVerifyEmail
 * @property {string | null} userEmail
 * @property {Function} onNavigateToPersonalInfo
 * @property {boolean} isVerifyingEmail
 * @property {string} verifyEmailErrorMessage
 * @property {Function} clearVerifyEmailErrorMessage
 */

/**
 * Contains business logic used in the VerifyEmail component
 * @return {UseVerifyEmailReturnValue}
 */
export default function useVerifyEmail() {
  const [isVerifyingEmail, setIsVerifyingEmail] = useState(false)
  const [verifyEmailErrorMessage, setVerifyEmailErrorMessage] = useState('')

  const {
    updateUser,
    user,
    logout,
  } = useAuth()

  const userEmail = user?.email

  const dispatch = useDispatch()

  const onLogout = () => {
    dispatch(userActions.resetUser())
    logout()
  }

  const history = useHistory()

  const onNavigateToPersonalInfo = () => history.push('/personal-info')

  const onVerifyEmail = async (token) => {
    setIsVerifyingEmail(true)
    setVerifyEmailErrorMessage('')
    try {
      const updatedUser = await User.verifyEmail(token)
      updateUser(updatedUser)
      history.push('/account-setup')
      return
    } catch (e) {
      let errorMessage = 'Verification Failed: '
      if (e.errors) {
        errorMessage += e.errors[0].title
        setVerifyEmailErrorMessage(e.errors[0].title)
      } else {
        errorMessage += e.message || e.error || 'Something went wrong, please try again!'
      }
      setVerifyEmailErrorMessage(errorMessage)
    }
    setIsVerifyingEmail(false)
  }

  useEffect(() => {
    // If the user confirmed his email, navigate to /account-setup
    if (user?.confirmed_at) {
      history.push('/account-setup')
    }
  }, [user, history])

  const clearVerifyEmailErrorMessage = () => setVerifyEmailErrorMessage('')

  return {
    isVerifyingEmail,
    verifyEmailErrorMessage,
    onLogout,
    onVerifyEmail,
    onNavigateToPersonalInfo,
    userEmail,
    clearVerifyEmailErrorMessage,
  }
}
