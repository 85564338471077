import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import Company from 'services/Company'
import InputField from 'components/form/InputField'
import SelectField from 'components/UI/SelectFormField/SelectFormField'
import { useMembersContext } from 'routes/TeamMembers/context/TeamMembersContext'
import { useToasts } from 'react-toast-notifications'

const options = [
  { label: 'Manager', value: 'manager' },
  { label: 'Worker', value: 'worker' },
]

const AddTeamMemberSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required(),
  selectRole: Yup.string()
    .required(),
})

const AddTeamMembers = () => {
  const {
    companyId, pendingMembers, setPendingMembers, setDisplayPendingMembers,
  } = useMembersContext()
  const { addToast } = useToasts()

  const onAddTeamMember = async ({ email, selectRole }, resetForm) => {
    try {
      const { email: inviteEmail, id, role } = await Company.createInviteMember(companyId, email, selectRole)
      addToast('Invitation is created!', {
        appearance: 'success',
        autoDismiss: true,
        autoDismissTimeout: 2000,
      })
      resetForm()
      const pendingUser = {
        email: inviteEmail,
        id,
        status: 'Pending',
        roles: [
          {
            company_id: companyId,
            role,
          },
        ],
      }
      setPendingMembers([
        pendingUser,
        ...pendingMembers,
      ])
      setDisplayPendingMembers(false)
    } catch (e) {
      addToast(e.data.errors[0].title, {
        appearance: 'info',
        autoDismiss: true,
        autoDismissTimeout: 2000,
      })
    }
  }

  return (
    <div className="add-team-members d-flex">
      <Row className="m-0 pb-4 justify-content-center align-items-center w-100">
        <Col md={3}>
          <h4 className="fw-bolder ">Add Teammates</h4>
          <span>Enter an e-mail address to invite a new team member.</span>
        </Col>
        <Col className="m-0 p-0">
          <Formik
            validationSchema={AddTeamMemberSchema}
            initialValues={{
              email: '',
              selectRole: '',
            }}
            onSubmit={(values, { resetForm }) => onAddTeamMember(values, resetForm)}
          >
            {({
              handleBlur, isValid, dirty,
            }) => (
              <Form>
                <Row className="p-0 m-0">
                  <Col md={4}>
                    <Field
                      type="email"
                      placeholder="Enter e-mail address"
                      name="email"
                      inputClasses="qr-input rounded"
                      component={InputField}
                      hasErrorMessage={false}
                    />
                  </Col>
                  <Col md={3}>
                    <Field
                      name="selectRole"
                      placeholder="Select a team role"
                      classNamePrefix="select"
                      onBlur={handleBlur}
                      component={SelectField}
                      options={options}
                      customStyles
                    />
                  </Col>
                  <Col md={2}>
                    <button
                      type="submit"
                      id="send-button"
                      className="add-team-members__button btn btn-success d-block w-100 btn-auth fw-bold"
                      disabled={!(isValid && dirty)}
                    >
                      Send
                    </button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  )
}

export default AddTeamMembers
