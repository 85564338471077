import React, { useEffect } from 'react'
import {
  Col,
  Tab,
  Tabs,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useBillingContext } from 'providers/BillingProvider/index'
import companyOperations from 'redux/company/operations'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import useIsWorkerPunchedIn from 'features/work-logs/useIsWorkerPunchedIn'
import DashboardCard from 'components/UI/Panel/DashboardCard'
import usePendingChatRequests from 'features/pending-chat-requests/usePendingChatRequests'
import PunchInPanel from 'routes/Dashboard/components/PunchInPanel/PunchInPanel'
import { useErrorHandler } from 'react-error-boundary'
import Queue from './chat/Queue'
import TeamChat from './chat/team/TeamChat'
import CustomerCard from './chat/CustomerCard'
import SubscriptionMessage from './chat/SubscriptionMessage'

const MobileDashboardMain = () => {
  const dispatch = useDispatch()
  const preselectChatRequest = useSelector((state) => state.chatRequest.preselectChatRequest)
  const errorOnCompany = useSelector((state) => state.company.error)
  const errorHandler = useErrorHandler()

  const { data: chatRequests = [] } = usePendingChatRequests()
  const {
    isPunchedIn,
    isLoadingPunchedInState,
  } = useIsWorkerPunchedIn()

  const {
    subscriptionPlan,
    isLoadingSubscription,
  } = useBillingContext()

  useEffect(() => {
    dispatch(companyOperations.fetchCurrentCompany())
  }, [dispatch])

  useEffect(() => {
    if (errorOnCompany) {
      errorHandler(errorOnCompany)
    }
  }, [errorHandler, errorOnCompany])

  const nextCustomer = chatRequests?.length >= 1 ? chatRequests[0] : null
  const preSelectCustomer = chatRequests?.length && chatRequests?.find((x) => x?.id === preselectChatRequest)

  if (!subscriptionPlan.status.isActive()) {
    return (
      <SubscriptionMessage
        subscriptionStatus={subscriptionPlan.status}
        isLoadingSubscription={isLoadingSubscription}
      />
    )
  }

  return (
    <div className="align-stretch h-100 dashboard_tabs">
      <Tabs className="p-3 justify-content-around w-100 border-0 dashboard_tabs" defaultActiveKey="dashboard">
        <Tab eventKey="dashboard" title="Dashboard" tabClassName="dashboard_tabs__tab">
          <Col md={12} xs={12} className="mb-3">
            {
              isLoadingPunchedInState && (
                <DashboardCard className="my-3 text-center">
                  <LoadingSpinner />
                </DashboardCard>
              )
            }
            {
              !isLoadingPunchedInState && !isPunchedIn && (
                <DashboardCard className="my-3">
                  <PunchInPanel />
                </DashboardCard>
              )
            }
            {
              !isLoadingPunchedInState && isPunchedIn && (
                <DashboardCard className="my-3">
                  <CustomerCard chatRequest={nextCustomer} />
                </DashboardCard>
              )
            }
            {
              preselectChatRequest && isPunchedIn && (
                <DashboardCard className="my-3">
                  <CustomerCard chatRequest={preSelectCustomer} title="Priority Customer" />
                </DashboardCard>
              )
            }
          </Col>
          <Col md={12} xs={12} className="my-3">
            <Queue />
          </Col>
        </Tab>
        <Tab eventKey="teamchat" title="Team Chat" tabClassName="dashboard_tabs__tab">
          <TeamChat />
        </Tab>
      </Tabs>
    </div>
  )
}

export default MobileDashboardMain
