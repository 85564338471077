import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ConversationTypes, activeConversationsLimit } from 'utils/constants'
import { findLastItemInListById } from 'utils/helpers'
import useConversationSettings from './useConversationSettings'

const useActiveConversations = (reqId) => {
  const { chatRequestId: chatReqId } = useParams() ?? reqId
  const chatRequestId = reqId ?? chatReqId
  const chatRequests = useSelector((state) => state.activeConversations)

  const videoTypes = [ConversationTypes.video, ConversationTypes.videoText]
  const {
    id,
    roomName,
    roomToken,
    conversationSID,
    requestType,
    consumerName,
    consumerEmail,
    consumerPhone,
    ...other
  } = findLastItemInListById(chatRequests, chatRequestId) ?? {}

  const isLimitReached = chatRequests.length >= activeConversationsLimit
  const isAnyVideoCallActive = !!(chatRequests?.find((x) => videoTypes.includes(x.requestType)))

  const settings = useConversationSettings(id)

  return {
    id,
    roomName,
    roomToken,
    conversationSID,
    requestType,
    consumerName,
    consumerEmail,
    consumerPhone,
    isAnyVideoCallActive,
    state: other,
    settings,
    isLimitReached,
  }
}

export default useActiveConversations
