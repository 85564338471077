import React from 'react'
import { Col, Row } from 'react-bootstrap'
import logo from 'assets/images/swytchboard-logo-2.svg'
import loginImage from 'assets/images/landing_login.png'

const IntroScreen = () => (
  <Col md={6} className="bg-lighter align-items-center align-self-center h-100">
    <Row className="m-0 p-0 h-100 justify-content-center align-items-center">
      <Col md={8}>
        <Row className="justify-content-center">
          <Col md={4}>
            <img src={logo} className="App-logo mb-3 w-100" alt="logo" />
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <Col md={12}>
            <img src={loginImage} className="App-logo w-75 mb-3 w-100" alt="logo" />
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <Col md={12}>
            <h4 className="pb-4 fw-bold">Start building a better customer experience today.</h4>
            <div>
              Nothing sells your brand and products better than your best people.
              <br />
              Let your customers see and hear their passion!
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  </Col>
)

export default IntroScreen
