/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query'
import Company from 'services/Company'
import { QueryKeys } from 'utils/constants'
import { useParams, useHistory } from 'react-router-dom'

export default function useGetEntryPointDetails() {
  const { serviceUid } = useParams()
  const history = useHistory()

  const {
    data,
    isSuccess,
    isError,
    error,
    isInitialLoading: isLoading,
  } = useQuery([QueryKeys.ENTRY_POINT, serviceUid],
    () => Company.getPublicCompanyEntryPoint(serviceUid), {
      onError: (errorMessage) => {
        if (errorMessage?.status === 404 || errorMessage?.code === 'ERR_BAD_REQUEST') {
          history.push('/404')
        }
      },
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retryOnMount: false,
      retry: 1,
    })

  return {
    data,
    isSuccess,
    isError,
    error,
    isLoading,
  }
}
