import React, { useState } from 'react'
import { Modal, Tab, Tabs } from 'react-bootstrap'
import AudioInputList from 'components/AudioInputList/AudioInputList'
import AudioOutputList from 'components/AudioOutputList/AudioOutputList'
import VideoInputList from 'components/VideoInputList/VideoInputList'
import './settings-dialog.scss'

const TabContent = ({ children }) => <div style={{ padding: 10 }}>{children}</div>

const SettingsDialog = ({
  show,
  onHide,
}) => {
  const [currentTab, setCurrentTab] = useState('audio') // audio or video tabs

  return (
    <Modal show={show} onHide={onHide} id="settings-dialog">
      <Tabs
        activeKey={currentTab}
        onSelect={(k) => setCurrentTab(k)}
      >
        <Tab eventKey="audio" title="Audio Settings">
          <TabContent>
            <AudioInputList />
            <AudioOutputList />
          </TabContent>
        </Tab>
        <Tab eventKey="video" title="Video Settings">
          <TabContent>
            <VideoInputList />
          </TabContent>
        </Tab>
      </Tabs>
    </Modal>
  )
}

export default SettingsDialog
