import React from 'react'
import {
  Row,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import SubscriptionStatus from 'providers/BillingProvider/SubscriptionStatus'
import MailtoButton from '../../../../components/buttons/MailToButton'

const SubscriptionMessage = ({ subscriptionStatus, isLoadingSubscription }) => {
  const selectMessages = (status) => {
    switch (status.name) {
      case SubscriptionStatus.EndedFreeTrial.name:
        return {
          isDefault: false,
          title: 'Your free trial period has ended',
          body1: 'Your customers like this experience!',
          body2: 'To continue, please select one of our plans.',
          button: 'Choose Plan',
          footer1: 'Got any questions?',
          footer2: "Don't hesitate to",
        }
      case SubscriptionStatus.ReachedTalkTimeLimit.name:
        return {
          isDefault: false,
          title: 'You have reached the time limit',
          body1: 'Your customers like this experience!',
          body2: 'To continue, upgrade your plan or buy additional hours.',
          button: 'Upgrade',
          footer1: 'Got any questions?',
          footer2: "Don't hesitate to",
        }
      case SubscriptionStatus.ExpiredPlan.name:
        return {
          isDefault: false,
          title: 'Your payment was unsuccessful',
          body1: 'Your customers like this experience!',
          body2: 'To continue, please renew or upgrade your plan.',
          button: 'Update Payment',
          footer1: 'Got any questions?',
          footer2: "Don't hesitate to",
        }
      case SubscriptionStatus.NoPlan.name:
        return {
          isDefault: false,
          title: 'Currently, there is no plan.',
          body1: 'Recently, you signed up on Swytchboard!',
          body2: 'To continue, please select one of our plans.',
          button: 'Choose Plan',
          footer1: 'Got any questions?',
          footer2: "Don't hesitate to",
        }
      default:
        return {
          isDefault: true,
          title: 'Problem with the subscription',
          body1: 'Something wrong happened while checking your subscription',
          body2: '',
          button: '',
          footer1: '',
          footer2: 'Please, ',
        }
    }
  }

  const messages = selectMessages(subscriptionStatus)

  if (isLoadingSubscription) {
    return <LoadingSpinner className="d-flex justify-content-center align-items-center w-100 h-100" />
  }

  return (
    <Row className="h-100 w-100 m-0 p-5 dashboard__chat__subscription-message shadow">
      <div className="mb-5">
        <span className="dashboard__chat__subscription-message__title">{ messages.title }</span>
      </div>
      <div className="mb-5 dashboard__chat__subscription-message__body">
        <span className="d-block">{ messages.body1 }</span>
        <span>{ messages.body2 }</span>
      </div>
      {!messages.isDefault && (
      <div className="mb-5 dashboard__chat__subscription-message__button">
        <Link
          className="billing__plan-new__btn-choose-plan btn btn-link"
          to="/admin/checkout"
        >
          {messages.button}
        </Link>
      </div>
      )}
      <div className="dashboard__chat__subscription-message__footer">
        <span className="d-block">{ messages.footer1 }</span>
        <span>{ `${messages.footer2} ` }</span>
        <span>
          <MailtoButton label="contact us." mailto="mailto:hello@joinswytchboard.com" />
        </span>
      </div>
    </Row>
  )
}

export default SubscriptionMessage
