export const chatRequestIdUrlParam = ':chatRequestId'
export const serviceUidUrlParam = ':serviceUid'
export const requestTypeUrlParam = ':requestType'

export const QrFlowRoutes = {
  start: `/start/${serviceUidUrlParam}`,
  personalInfo: `/${serviceUidUrlParam}/${requestTypeUrlParam}/request`,
  queue: `/${serviceUidUrlParam}/${chatRequestIdUrlParam}/queue`,
  chatRoot: `/${serviceUidUrlParam}/${chatRequestIdUrlParam}`,
  videoText: `/${serviceUidUrlParam}/${chatRequestIdUrlParam}/call`,
  text: `/${serviceUidUrlParam}/${chatRequestIdUrlParam}/messaging`,
  rating: `/${serviceUidUrlParam}/${chatRequestIdUrlParam}/rating`,
  thankYou: `/${serviceUidUrlParam}/${chatRequestIdUrlParam}/thank-you`,
  callEnded: `/${serviceUidUrlParam}/${chatRequestIdUrlParam}/call-ended`,
  leaveMessage: `/${serviceUidUrlParam}/${chatRequestIdUrlParam}/leave-message`,
}

export const WorkerRoutes = {
  dashboard: '/dashboard',
  adminDashboard: '/admin/dashboard',
  conversation: '/conversation/:chatRequestId',
  conversations: '/conversations',
  messagesLeft: '/admin/messages-left',
  chatSettings: '/admin/chat-settings',
  chatConfiguration: '/admin/chat-configuration/:id',
  profileSettings: '/profile-settings',
  screenshareLink: '/screenshare/current/:meetingLink',
  teamMembers: '/admin/team-members',
  billing: '/admin/billing',
  checkout: '/admin/checkout',
  feedback: '/feedback',
}

export const QueryKeys = {
  ENTRY_POINT_ID: 'entry_point_id',
}

export const DISPLAY_NOT_AVAILABLE_MESSAGE = 'We\'re sorry, our service is not available right now. Click on the button below if you want to leave a message.'
