import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { chatConfigurationSteps } from 'routes/ChatSettingsEntryPoint/constants'
import { useChatConfiguration } from 'routes/ChatSettingsEntryPoint/provider/ChatConfigurationProvider'
import TopChatConfigurationButtons from 'routes/ChatSettingsEntryPoint/components/Buttons/TopChatConfigurationButtons'
import BottomChatConfigurationButtons from
  'routes/ChatSettingsEntryPoint/components/Buttons/BottomChatConfigurationButtons'
import Editable from 'routes/ChatSettingsEntryPoint/steps/ChatQueue/Editable'
import Viewable from 'routes/ChatSettingsEntryPoint/steps/ChatQueue/Viewable'

const ChatQueue = () => {
  const {
    entryPoint,
    handleStateChange,
    modifiedState,
    setModifiedState,
  } = useChatConfiguration()

  const {
    queue_message: entryPointQueueMessage,
    timeout_period: entryPointCustomerInactivity,
    allow_leave_message: allowLeaveMessage,
  } = entryPoint?.data.config.queue

  const {
    accent_color: accentColor,
  } = entryPoint?.data.config.visual

  const [queueMessage, setQueueMessage] = useState(entryPointQueueMessage)
  const [customerInactivity, setCustomerInactivity] = useState(Number(entryPointCustomerInactivity))
  const [allowLeaveMessageEnabled, setAllowLeaveMessageEnabled] = useState(allowLeaveMessage === 'true' || allowLeaveMessage === undefined)

  const handleSaveButton = () => {
    handleStateChange({
      [chatConfigurationSteps.QUEUE]: {
        queue_message: queueMessage,
        timeout_period: parseInt(customerInactivity, 10),
        allow_leave_message: allowLeaveMessageEnabled,
      },
    })
    setModifiedState(false)
  }

  const handleCancelButton = () => {
    setQueueMessage(entryPointQueueMessage)
    setModifiedState(false)
  }

  return (
    <>
      <Row>
        <Col className="chat-configuration__buttons top">
          <TopChatConfigurationButtons
            handleSaveButton={handleSaveButton}
            handleCancelButton={handleCancelButton}
            modifiedState={modifiedState}
          />
        </Col>
      </Row>
      <Row className="h-100">
        <Col md={8} className="pe-4 m-0">
          <Editable
            queueMessage={queueMessage || ''}
            setQueueMessage={setQueueMessage}
            setModifiedState={setModifiedState}
            customerInactivity={customerInactivity}
            setCustomerInactivity={setCustomerInactivity}
            allowLeaveMessageEnabled={allowLeaveMessageEnabled}
            setAllowLeaveMessageEnabled={setAllowLeaveMessageEnabled}
          />
        </Col>
        <Col md={4} className="p-0 m-0">
          <Viewable
            queueMessage={queueMessage}
            accentColor={accentColor}
            allowLeaveMessageEnabled={allowLeaveMessageEnabled}
          />
        </Col>
      </Row>
      <Row>
        <Col className="chat-configuration__buttons">
          <BottomChatConfigurationButtons
            modifiedState={modifiedState}
            setModifiedState={setModifiedState}
          />
        </Col>
      </Row>
    </>
  )
}

export default ChatQueue
