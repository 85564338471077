/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import iconSet from 'assets/fonts/selection.json'
import IcomoonReact from 'icomoon-react'
import closeButton from 'assets/images/close-x.svg'
import './_file-preview.scss'

function FilePreview({
  preview, fileData, setPreview, setFileFormData,
}) {
  const removeFile = () => {
    setPreview('')
    setFileFormData('')
  }

  return preview && fileData?.type?.includes('image')
    ? (
      <div style={{
        overflow: 'visible',
        position: 'relative',
      }}
      >
        <img
          src={preview}
          alt="preview"
          width={50}
          height={50}
          style={{
            marginLeft: '10px',
            marginTop: '5px',
            marginBottom: '5px',
          }}
        />
        <img
          src={closeButton}
          alt="close"
          onClick={() => removeFile()}
          style={{
            position: 'absolute',
            cursor: 'pointer',
          }}
        />
      </div>
    )
    : (
      <div
        style={{
          border: '1px solid #F5F5F5',
          borderRadius: '5px',
          overflow: 'visible',
          position: 'relative',
          display: 'flex',
          alignContent: 'center',
          margin: '10px 10px 10px 10px',
          paddingBottom: '5px',
        }}
      >
        <IcomoonReact
          iconSet={iconSet}
          color="#0E0641"
          size={40}
          icon="file-empty"
        />
        <div className="text__file__preview">
          <span className="text__file__preview__name">{fileData?.name?.substring(0, 40)}</span>
          <span className="text__file__preview__type">{fileData?.type?.split('/')[1]?.toUpperCase()}</span>
        </div>
        <img
          src={closeButton}
          alt="close"
          onClick={() => removeFile()}
          style={{
            position: 'absolute',
            top: -10,
            right: -10,
            cursor: 'pointer',
          }}
        />
      </div>
    )
}

export default FilePreview
