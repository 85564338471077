import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import userActions from 'redux/user/actions'
import { useHistory } from 'react-router-dom'
import User from 'services/User'

export default function useSetupPerojectInfo() {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const onClearErrorMessage = () => setErrorMessage('')

  const dispatch = useDispatch()

  const history = useHistory()

  const { data } = useSelector((state) => state.user)

  const onPersonalInfoHandler = async ({ full_name: fullName, avatar }) => {
    setIsSubmitting(true)
    setErrorMessage('')
    try {
      await User.updateUser(fullName)
      dispatch(userActions.updateUserSettingsSuccess({
        ...data,
        full_name: fullName,
        avatar,
      }))
      history.push('/verify-email')
    } catch (e) {
      if (e.errors) {
        setErrorMessage(e.errors[0].title)
      } else {
        setErrorMessage(e.message || e.error || 'Something went wrong, please try again.')
      }
    }
    setIsSubmitting(false)
  }

  return {
    data,
    isSubmitting,
    errorMessage,
    onClearErrorMessage,
    onPersonalInfoHandler,
  }
}
