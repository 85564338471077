import passwordConfirmationSchema from 'schemas/passwordConfirmationSchema'
import * as yup from 'yup'
import Steps from 'components/ForgotPaswordDialog/steps'

const validationSchemasPerStep = {
  [Steps.AccountInfoForm]: yup.object()
    .shape({
      email: yup.string()
        .email('The email is not valid')
        .required('The email is required'),
    }),
  [Steps.ChangePasswordForm]: yup.object()
    .shape({
      token: yup.string()
        .required('The code is required'),
      ...passwordConfirmationSchema,
    }),
}

export default function useValidationSchemaPerStep(activeStep) {
  return validationSchemasPerStep[activeStep]
}
