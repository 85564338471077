import React from 'react'
import InputField from 'components/form/InputField'

const TextFormField = ({ field, ...props }) => (
  <div className="field text-start">
    <InputField inputClasses="text-from-field mt-2" {...field} {...props} />
  </div>
)

export default TextFormField
