import React from 'react'
import RemoteParticipant from 'components/Room/RemoteParticipant'
import LocalParticipant from 'components/Room/LocalParticipant'
import NetworkQualityLevel from 'components/NetworkQualityLevel/NetworkQualityLevel'

/**
 * @param participant
 * @param worker
 * @returns {JSX.Element}
 * @constructor
 */

const videoInfoContainerClassName = 'connecting'

const ParticipantThumbnail = ({
  className,
  participant,
  isTwoWayEnabled,
  localParticipant,
  mainParticipant,
  videoPriority,
  worker,
  videoOnly,
  participantName,
  isMobile,
  isWorkerMobile,
}) => (
  <div
    className={`m-0 p-0 ${isMobile ? 'video-thumbnail-mobile' : 'video-thumbnail'} ${className} ${isWorkerMobile ? 'worker-mobile' : ''}`}
  >
    <NetworkQualityLevel participant={localParticipant || participant} />
    {localParticipant ? (
      <>
        <LocalParticipant
          participant={localParticipant}
          videoOnly={videoOnly}
          enableScreenShare={mainParticipant !== localParticipant}
          videoPriority={videoPriority}
          isTwoWayEnabled={isTwoWayEnabled}
          isLocalParticipant={mainParticipant === localParticipant}
          worker={worker}
          participantName={participantName}
          isThumbnail
        />

      </>
    ) : (
      <RemoteParticipant
        participant={participant}
        videoConnectionInfoClassName={videoInfoContainerClassName}
        isTwoWayEnabled={isTwoWayEnabled}
        worker={worker}
        isThumbnail
      />
    )}
  </div>
)

export default ParticipantThumbnail
