import { SWYTCHBOARD_LOCALSTORAGE_KEY } from 'utils/constants'

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(SWYTCHBOARD_LOCALSTORAGE_KEY)
    // If state is null return undefined so the states initialize in Redux
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (error) {
    // Return undefined so states initialize in Redux
    return undefined
  }
}

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(SWYTCHBOARD_LOCALSTORAGE_KEY, serializedState)
  } catch (error) {
    // ignore write errors
  }
}
