import React from 'react'
import { Form } from 'react-bootstrap'
import useMediaStreamTrack from 'hooks/useMediaStreamTrack/useMediaStreamTrack'
import useAvailableDevices from 'hooks/useAvailableDevices/useAvailableDevices'
import { useVideoContext } from 'providers/VideoProvider'

const AudioInputList = () => {
  const { audioInputDevices } = useAvailableDevices()
  const {
    audioTrack,
    setAudioTrack,
  } = useVideoContext()
  const mediaStreamTrack = useMediaStreamTrack(audioTrack)
  const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId

  return (
    <Form.Group>
      <Form.Label>Microphone</Form.Label>
      <Form.Control
        as="select"
        value={localAudioInputDeviceId}
        onChange={(e) => setAudioTrack(e.target.value)}
      >
        {audioInputDevices.map(({
          deviceId,
          label,
        }) => <option key={deviceId} value={deviceId}>{label}</option>)}
      </Form.Control>
    </Form.Group>
  )
}

export default AudioInputList
