import { useDispatch, useSelector } from 'react-redux'
import companyOperations from 'redux/company/operations'
import companyActions from 'redux/company/actions'
import useErrorMessageFromObject from 'hooks/useErrorMessageFromObject'
import { ActionState } from 'utils/constants'
import { useEffect } from 'react'
import { useToasts } from 'react-toast-notifications'

/**
 * Contains the business logic that is used in the Chat Settings page
 */
export default function useChatSettings() {
  const {
    addToast,
    removeToast,
  } = useToasts()

  const {
    name: companyName,
    chat_setting: chatSettings,
    company_detail: companyDetails,
  } = useSelector((state) => state.company.data)

  // Since all properties are undefined for new projects, we set custom initial values,
  // to allow proper form validation to take place
  const initialValues = {
    company_attributes: {
      name: companyName,
      chat_setting_attributes: {
        chat_availability: false,
        video_availability: false,
        rating_award: false,
        ...chatSettings,
      },
      company_detail_attributes: {
        website: '',
        timezone: '',
        end_office_hour: null,
        start_office_hour: null,
        ...companyDetails,
      },
    },
  }

  const dispatch = useDispatch()

  const updateSettings = (settings) => dispatch(companyOperations.updateSettings(settings))

  const {
    error,
    updateSettings: updateSettingsState,
  } = useSelector((state) => state.company)

  const errorMessage = useErrorMessageFromObject(error)

  const isUpdatingSettings = updateSettingsState === ActionState.InProgress

  const isSuccessful = updateSettingsState === ActionState.Completed

  useEffect(() => {
    if (isSuccessful) {
      addToast('Successfully updated company settings', {
        appearance: 'success',
        autoDismiss: true,
        autoDismissTimeout: 2000,
      })
      dispatch(companyActions.resetUpdateSettingsStates())
    }
  }, [isSuccessful, addToast, dispatch])

  useEffect(() => {
    const errorMessageToastKey = 'update-settings-error'

    if (errorMessage) {
      removeToast(errorMessageToastKey)
      // The timeout fixes a bug where consecutive API calls result in the error toast not being displayed
      setTimeout(() => {
        addToast(`Save settings error: ${errorMessage}`, {
          appearance: 'error',
          id: errorMessageToastKey,
        })
      })
      dispatch(companyActions.resetUpdateSettingsStates())
    }
    // Remove the error message Toast when we leave the page
    return () => removeToast(errorMessageToastKey)
  }, [errorMessage, addToast, removeToast, dispatch])

  return {
    initialValues,
    isUpdatingSettings,
    updateSettings,
  }
}
