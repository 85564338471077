import React, { useEffect } from 'react'
import QRMainLayout from 'features/qrflow/layouts/QRMainLayout'
import InputField from 'components/form/InputField'
import Rating from 'components/form/Rating'
import 'features/qrflow/styles/_ratings-screen.scss'
import * as yup from 'yup'
import { Formik, Field, Form } from 'formik'
import useRedirectChatRequest from 'features/qrflow/hooks/useRedirectChatRequest'
import chatRequestOperations from 'redux/chatRequest/operations'
import { useDispatch } from 'react-redux'
import { Alert } from 'react-bootstrap'
import LoadingButton from 'components/buttons/LoadingButton'
import useSubmitRatings from 'features/qrflow/hooks/useSubmitRatings'
import useGetEntryPointDetails from 'features/qrflow/hooks/useGetEntryPointDetails'
import { updateConsumerConversation } from 'redux/consumerConversations/actions'
import { RATE_YOUR_EXPERIENCE } from 'routes/ChatSettingsEntryPoint/constants'
import useConsumerConversations from '../hooks/useConsumerConversations'

const RatingsFormSchema = yup.object()
  .shape({
    rate: yup.object()
      .shape({
        helpfulness: yup.number()
          .min(1)
          .max(5)
          .required(),
        knowledge: yup.number()
          .min(1)
          .max(5)
          .required(),
        professional: yup.number()
          .min(1)
          .max(5)
          .required(),
      }),
    comment: yup.string(),
  })

const initialValues = {
  comment: '',
  rate: {
    knowledge: 0,
    helpfulness: 0,
    professional: 0,
  },
}

const RatingScreen = () => {
  const {
    data,
    error: errorMessage,
  } = useGetEntryPointDetails()

  const {
    id: chatRequestId,
    state: { rateToken },
  } = useConsumerConversations()

  const {
    ratings,
    visual,
  } = data?.config ?? {}

  useRedirectChatRequest()

  const dispatch = useDispatch()

  const onSubmit = (formData) => {
    dispatch(chatRequestOperations.submitRatings(formData, rateToken))
  }

  const {
    isSubmitting,
    isDone,
  } = useSubmitRatings()

  useEffect(() => {
    if (isDone) {
      dispatch(updateConsumerConversation(chatRequestId, { rateToken: null }))
    }
  }, [isDone, chatRequestId, dispatch])

  return (
    <QRMainLayout>
      {ratings?.enabled === 'true' ? (
        <div className="screen-intro-text screen-intro-text--stretch">
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          <h2 className="qr-container__title">
            {RATE_YOUR_EXPERIENCE}
            <div
              className="qr-container__title__line"
              style={{
                backgroundColor: visual?.accent_color,
              }}
            />
          </h2>
          <p>
            {ratings?.message}
          </p>
          <Formik
            validationSchema={RatingsFormSchema}
            initialValues={initialValues}
            onSubmit={(values) => onSubmit(values)}
          >
            {({
              isValid,
              dirty,
            }) => (
              <Form className="ratings-form">
                <>
                  <Field
                    name="rate.helpfulness"
                    label="Helpfulness"
                    component={Rating}
                  />
                  <Field
                    name="rate.knowledge"
                    label="Knowledge"
                    component={Rating}
                  />
                  <Field
                    name="rate.professional"
                    label="Professional"
                    component={Rating}
                  />
                </>

                {ratings?.allow_comments === 'true' ? (
                  <Field
                    name="comment"
                    type="text"
                    label="Comment (optional)"
                    textarea
                    placeholder="Add your comment here"
                    labelClasses="fw-bold"
                    inputClasses="qr-textarea"
                    groupClasses="mb-5"
                    rows={5}
                    component={InputField}
                  />
                ) : null}
                <LoadingButton
                  className="qr-btn w-100 mt-auto mb-5"
                  style={{
                    backgroundColor: visual.accent_color,
                  }}
                  type="submit"
                  loading={isSubmitting}
                  disabled={!(isValid && dirty) || isSubmitting}
                >
                  Submit
                </LoadingButton>
              </Form>
            )}
          </Formik>
        </div>
      ) : null}
    </QRMainLayout>
  )
}

export default RatingScreen
