import ConversationTypes from '../conversation.types'

const INITIAL_STATE = null

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ConversationTypes.SET_SERVICE_INTRO_MESSAGE:
      return action.serviceIntroMessage ?? INITIAL_STATE
    case ConversationTypes.RESET_SERVICE_INTRO_MESSAGE:
      return null
    default:
      return state
  }
}
export default reducer
