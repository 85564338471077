import React from 'react'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'
import SwytchAvatar from 'components/user/SwytchAvatar'

export default function useUserAvatar(userData, profilePictureProps, icomonProps, avatarProps) {
  const renderProfileImage = userData?.avatar === null || userData === null ? (
    <IcomoonReact
      iconSet={iconSet}
      size={icomonProps?.icommonSize}
      icon={icomonProps?.icommonIcon}
      color="white"
    />
  ) : (
    <img
      src={profilePictureProps?.profilePicture}
      alt="user-logo"
      className="upload-profile-settings__profile-photo__img"
    />
  )

  const renderAvatar = (
    userData?.avatar ? (
      <img
        src={profilePictureProps?.profilePicture}
        alt=""
        className={`br-2 ${profilePictureProps?.rounded}`}
        width={30}
      />
    ) : (
      <SwytchAvatar name={userData?.full_name} size={avatarProps?.avatarSize} round={avatarProps?.avatarRound} />
    )
  )

  return {
    renderProfileImage,
    renderAvatar,
  }
}
