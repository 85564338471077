import React from 'react'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'

const CheckValidation = ({ text, isValid }) => (
  <div className="pt-3">
    <IcomoonReact
      iconSet={iconSet}
      className={`circle ${isValid ? 'circle--active' : ''}`}
      size={22}
      icon="checkmark"
    />
    <span>{text}</span>
  </div>
)

export default CheckValidation
