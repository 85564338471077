import React from 'react'

const Header = ({
  title,
  subTitle,
  actionButton,
}) => (
  <div className="profile-settings__header">
    <div>
      <div className="profile-settings__header__title">
        {title}
      </div>
      <div className="profile-settings__header__subtitle">
        {subTitle}
      </div>
    </div>
    <div>
      {actionButton}
    </div>
  </div>
)

export default Header
