/* eslint-disable max-len */
import React from 'react'
import IcomoonReact from 'icomoon-react'
import useDialog from 'hooks/useDialog'
import iconSet from 'assets/fonts/selection.json'
import DeletePendingTeamMember from 'routes/TeamMembers/components/Pending/DeletePendingTeamMemberDialog/DeletePendingTeamMember'
import ResendPendingInvite from 'routes/TeamMembers/components/Pending/ResendPendingTeamMemberDialog/ResendPendingTeamMember'

const PendingMembersTableRow = ({
  id,
  name,
  teamRole,
  email,
  status,
  phone,
}) => {
  const {
    displayDialog: displayDeleteTeamMember,
    onCloseDialog: onCloseDeleteTeamMember,
    onOpenDialog: onOpenDeleteTeamMember,
  } = useDialog()

  const {
    displayDialog: displayResendInvitation,
    onCloseDialog: onCloseResendInvite,
    onOpenDialog: onOpenResendInvite,
  } = useDialog()

  const deleteIcon = (
    <IcomoonReact
      className="worker-dialog__header-content__icon"
      icon="bin"
      iconSet={iconSet}
      onClick={onOpenDeleteTeamMember}
    />
  )
  const resendIcon = (
    <IcomoonReact
      className="worker-dialog__header-content__icon"
      icon="icon-send"
      iconSet={iconSet}
      onClick={onOpenResendInvite}
    />
  )

  return (
    <>
      <tr className="team-members__table-row">
        <th className="team-members__table-row__header" scope="row" id={id}>
          {name}
        </th>
        <td>{teamRole}</td>
        <td>{email}</td>
        <td>{status}</td>
        <td>{phone}</td>
        <td>{deleteIcon}</td>
        <td>{resendIcon}</td>
      </tr>
      <DeletePendingTeamMember
        id={id}
        email={email}
        show={displayDeleteTeamMember}
        onClose={onCloseDeleteTeamMember}
      />
      <ResendPendingInvite
        id={id}
        email={email}
        selectRole={teamRole}
        show={displayResendInvitation}
        onClose={onCloseResendInvite}
      />
    </>
  )
}

export default PendingMembersTableRow
