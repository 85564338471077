import React, { useEffect } from 'react'
import { Alert, Button } from 'react-bootstrap'
import { useHistory, generatePath } from 'react-router-dom'
import Lottie from 'react-lottie'
import QRMainLayout from 'features/qrflow/layouts/QRMainLayout'
import { ConversationTypes } from 'utils/constants'
import useAvailableWorkers from 'hooks/useAvailableWorkers'
import isClientSupported from 'utils/isClientSupported'
import swytchboardLogoAnimation from 'assets/animations/swytchboard-logo-animation.json'
import { parseURL, decodeBase64AsUnicode } from 'utils/helpers'
import { useDispatch } from 'react-redux'
import chatRequestActions from 'redux/chatRequest/actions'
import { QrFlowRoutes } from 'features/qrflow/constants'
import useGetEntryPointDetails from 'features/qrflow/hooks/useGetEntryPointDetails'
import analytics from 'utils/analytics'
import '../styles/_landingScreen.scss'
import { resetConsumerConversations } from 'redux/consumerConversations/actions'
import LeaveMessage from 'features/qrflow/components/leaveMessage'
import config from 'utils/config'
import { TEXT_CHAT, VIDEO_CHAT } from 'routes/ChatSettingsEntryPoint/constants'
import useQuery from 'hooks/useQuery'

const LandingScreen = () => {
  const {
    data,
    error: errorMessage,
    isLoading,
  } = useGetEntryPointDetails()

  const { sbclid = '' } = useQuery()

  const {
    landing_page: landingPage,
    text_chat: textChat,
    video_chat: videoChat,
    queue,
    visual,
  } = data?.config ?? {}

  const videoAvailability = Boolean(videoChat?.enabled)
  const chatAvailability = Boolean(textChat?.enabled)

  const {
    isSupported,
    message,
    deviceInfo,
    debugInfo,
  } = isClientSupported()

  const history = useHistory()
  const dispatch = useDispatch()
  const {
    availableWorkers,
  } = useAvailableWorkers()

  useEffect(() => {
    if (data && sbclid) {
      const clickData = (() => {
        try {
          return JSON.parse(decodeBase64AsUnicode(sbclid))
        } catch (e) {
          return Promise.reject(e)
        }
      })()
      if (Object.values(ConversationTypes).includes(clickData?.request_type)) {
        return history.push(generatePath(`${QrFlowRoutes.personalInfo}?sbclid=${sbclid}`, { serviceUid: data?.uid, requestType: clickData?.request_type }))
      }
    }
    return undefined
  }, [data, history, sbclid])

  const onNextStepHandler = (requestType) => {
    history.push(generatePath(QrFlowRoutes.personalInfo, { serviceUid: data?.uid, requestType }))
  }

  useEffect(() => {
    analytics.page()
  }, [])

  useEffect(() => {
    dispatch(chatRequestActions.resetChatRequests())
    dispatch(resetConsumerConversations())
  }, [dispatch])

  if (isLoading) {
    return (
      <div className="qr-container qr-mobile-screen justify-content-center p-0 w-100">
        <Lottie
          options={{
            animationData: swytchboardLogoAnimation,
          }}
          width={150}
          isClickToPauseDisabled
        />
      </div>
    )
  }

  const websiteLink = landingPage?.website !== null && landingPage?.website !== ''

  // When chat and video are not available, show a warning message and disable
  const displayNotAvailableWarningMessage = (
    isSupported
    && !videoAvailability
    && !chatAvailability
    && landingPage?.away_message
  )

  const displayDefaultAwayMessage = `At this point, there is no one available to help you, 
  ${websiteLink === false ? 'please try again later' : 'please check the product website, or try again later'}`

  if (!availableWorkers) {
    return (
      <QRMainLayout>
        <LeaveMessage
          landingPageConfig={landingPage}
          visualConfig={visual}
          queueConfig={queue}
          entryPointId={data.uid}
          entryPointError={errorMessage}
        />
      </QRMainLayout>
    )
  }
  return (
    <QRMainLayout>
      {config.DEBUG && (
        <Alert variant="info" className="p-3 qr-container__alert">
          {'Browser: '}
          {deviceInfo.browserName}
          {' '}
          {deviceInfo.browserVersion}
          {', '}
          {deviceInfo.operatingSystemName}
          <br />
          {'Webview: '}
          {deviceInfo.webviewBrowser ? 'Yes' : 'No'}
          <br />
          {'Debug info: '}
          {debugInfo}
        </Alert>
      )}
      {!isSupported && (
        <Alert variant="warning" className="p-3 qr-container__alert">
          {message}
        </Alert>
      )}
      {
        displayNotAvailableWarningMessage && landingPage?.away_message && (
          <Alert variant="warning" className="p-3 text-center qr-container__alert">
            {landingPage?.away_message === ''
              ? displayDefaultAwayMessage
              : landingPage?.away_message}
          </Alert>
        )
      }
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      { !displayNotAvailableWarningMessage
      && (
      <div className="screen-intro-text screen-intro-text--center screen-intro-text--increase-spacing">
        <p className="first-line">
          { landingPage?.introduction }
        </p>
      </div>
      )}
      {
        videoChat?.enabled === 'true' ? (
          <Button
            disabled={!availableWorkers || !isSupported || !videoAvailability}
            className="qr-btn w-100 mb-5"
            style={{
              backgroundColor: visual?.accent_color,
            }}
            onClick={() => onNextStepHandler(ConversationTypes.videoText)}
          >
            {VIDEO_CHAT}
          </Button>
        ) : null
      }
      {
        textChat?.enabled === 'true' ? (
          <Button
            disabled={!availableWorkers || !isSupported || !chatAvailability}
            className="qr-btn w-100 mb-5"
            style={{
              backgroundColor: visual?.accent_color,
            }}
            onClick={() => onNextStepHandler(ConversationTypes.text)}
          >
            {TEXT_CHAT}
          </Button>
        ) : null
      }
      {websiteLink && (
        <Button
          className="qr-btn white w-100 mb-5"
          onClick={() => {
            window.open(parseURL(landingPage?.website))
          }}
        >
          {landingPage?.call_to_action_text}
        </Button>
      )}
    </QRMainLayout>
  )
}
export default LandingScreen
