import React from 'react'
import { Formik } from 'formik'
import WorkerDialog from 'components/WorkerDialog'
import { useForgotPassword } from 'components/ForgotPaswordDialog/providers/ForgotPasswordProvider'
import useInitialValuesPerStep from 'components/ForgotPaswordDialog/hooks/useInitialValuesPerStep'
import useValidationSchemaPerStep from 'components/ForgotPaswordDialog/hooks/useValidationSchemaPerStep'
import useDialogTextPropsPerStep from 'components/ForgotPaswordDialog/hooks/useDialogTextPropsPerStep'
import ChangePasswordForm from 'components/ForgotPaswordDialog/steps/ChangePasswordForm'
import AccountInfoForm from 'components/ForgotPaswordDialog/steps/AccountInfoForm'
import ChangePasswordSuccessfully from 'components/ForgotPaswordDialog/steps/ChangePasswordSuccessfully'
import Steps from 'components/ForgotPaswordDialog/steps'

function renderStep(step) {
  switch (step) {
    case Steps.AccountInfoForm:
      return <AccountInfoForm />
    case Steps.ChangePasswordForm:
      return <ChangePasswordForm />
    case Steps.ChangePasswordSuccessfully:
      return <ChangePasswordSuccessfully />
    default:
      return <AccountInfoForm />
  }
}

const ForgotPasswordDialog = ({
  show,
}) => {
  const {
    activeStep,
    isSubmitting,
    resetDialogOnExit,
    onSubmit,
    onBackButtonPress,
    formRef,
    onClose,
  } = useForgotPassword()

  const initialValues = useInitialValuesPerStep(activeStep)
  const validationSchema = useValidationSchemaPerStep(activeStep)

  const {
    closeText,
    confirmText,
    title,
  } = useDialogTextPropsPerStep(activeStep)

  return (
    <Formik
      validateOnMount
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        dirty,
        isValid,
      }) => (
        <WorkerDialog
          className="position-relative"
          onExited={resetDialogOnExit}
          loading={isSubmitting}
          disabled={(isSubmitting || !(dirty && isValid))}
          show={show}
          onClose={onClose}
          onBackPress={onBackButtonPress}
          title={title}
          onConfirm={handleSubmit}
          closeText={closeText}
          confirmText={confirmText}
        >
          {renderStep(activeStep)}
        </WorkerDialog>
      )}
    </Formik>
  )
}

export default ForgotPasswordDialog
