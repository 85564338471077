import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import chatRequestOperations from 'redux/chatRequest/operations'
import QRDialog from 'features/qrflow/components/dialog/QRDialog'
import { Alert } from 'react-bootstrap'
import useErrorMessageFromObject from 'hooks/useErrorMessageFromObject'
import useGetEntryPointDetails from 'features/qrflow/hooks/useGetEntryPointDetails'
import useConsumerConversations from 'features/qrflow/hooks/useConsumerConversations'

const RequestVideoDialog = ({
  show,
  onClose,
}) => {
  const { state: { workerName, requestVideo } } = useConsumerConversations()
  const {
    data,
  } = useGetEntryPointDetails()

  const {
    accent_color: accentColor,
  } = data?.config?.visual ?? {}

  const dispatch = useDispatch()

  const onRequestVideo = () => {
    dispatch(chatRequestOperations.requestVideo())
  }

  useEffect(() => {
    if (requestVideo === 'requested') {
      onClose()
    }
  }, [requestVideo, onClose])

  const errorMessage = useErrorMessageFromObject(requestVideo)

  return (
    <QRDialog
      show={show}
      title="Request Video Call"
      onClose={onClose}
      onConfirm={onRequestVideo}
      confirmTextColor={accentColor}
      loading={requestVideo === 'loading'}
      disabled={requestVideo === 'loading'}
    >
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      <p>
        Would you like to initiate a video call with our expert
        <b>
          &nbsp;
          {workerName}
        </b>
        ?
      </p>
    </QRDialog>
  )
}

export default RequestVideoDialog
