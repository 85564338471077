import { useCallback, useEffect, useState } from 'react'
import useAvailableDevices from 'hooks/useAvailableDevices/useAvailableDevices'
import { SELECTED_AUDIO_OUTPUT_KEY } from 'providers/VideoProvider/constants'

// For audio output device, we store and manipulate only the ID (there are no tracks like the other media devices)
export default function useLocalAudioOutput() {
  const {
    audioOutputDevices,
  } = useAvailableDevices()
  const [audioOutputId, _setAudioOutputId] = useState('default')

  const setAudioOutputId = useCallback((deviceId) => {
    localStorage.setItem(SELECTED_AUDIO_OUTPUT_KEY, deviceId)
    _setAudioOutputId(deviceId)
  }, [])

  useEffect(() => {
    const selectedDeviceId = localStorage.getItem(SELECTED_AUDIO_OUTPUT_KEY)
    const hasSelectedAudioOutputDevice = audioOutputDevices.some(
      (device) => selectedDeviceId && device.deviceId === selectedDeviceId,
    )
    if (hasSelectedAudioOutputDevice) {
      _setAudioOutputId(selectedDeviceId)
    }
  }, [audioOutputDevices])

  return {
    audioOutputId,
    setAudioOutputId,
  }
}
