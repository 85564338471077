/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import useLocalAudioToggle from 'hooks/useLocalAudioToggle/useLocalAudioToggle'
import { useDispatch } from 'react-redux'
import { updateActiveConversationTracksPermit } from 'redux/activeConversations/actions'
import useActiveConversations from 'hooks/useActiveConversations'
import VideoControlsButton from '../VideoControlsButton'

const ToggleAudioButton = () => {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle()
  const dispatch = useDispatch()
  const { id, settings: { workerKey, worker } } = useActiveConversations()

  useEffect(() => {
    if (!worker?.isAudioEnabled) {
      return toggleAudioEnabled(false)
    }
    return toggleAudioEnabled(true)
  }, [toggleAudioEnabled])
  // useEffect(() => () => () => toggleAudioEnabled(false), [toggleAudioEnabled])

  const onToggleAudioButtonHandle = () => {
    dispatch(updateActiveConversationTracksPermit(id, workerKey, { isAudioEnabled: !isAudioEnabled }))
    toggleAudioEnabled()
  }
  return <VideoControlsButton name={isAudioEnabled ? 'icon-voice' : 'icon-mute'} disabled={!isAudioEnabled} onClick={onToggleAudioButtonHandle} />
}

export default ToggleAudioButton
