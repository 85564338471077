import Steps from 'components/ForgotPaswordDialog/steps'

const dialogTextProps = {
  [Steps.AccountInfoForm]: {
    title: 'Forgot your password?',
    closeText: 'Cancel',
    confirmText: 'Continue',
  },
  [Steps.ChangePasswordForm]: {
    title: 'Change Your Password',
    closeText: 'Back',
    confirmText: 'Change Password',
  },
  [Steps.ChangePasswordSuccessfully]: {
    title: 'Congratulations!',
    closeText: 'Close',
    confirmText: 'Go to Login',
  },
}

/**
 * Returns the text for the title and buttons per step of the Forgot Password Dialog
 * @param activeStep
 * @returns {{closeText: (string), confirmText: (string), title: string}}
 */
export default function useDialogTextPropsPerStep(activeStep) {
  return dialogTextProps[activeStep]
}
