import React from 'react'
import { Col, Row, Button } from 'react-bootstrap'

const MessagesLeftTableRow = ({
  name, date, id, email, entryPointName, phone, message, handleOnClickClose, status,
}) => (
  <div className="mt-5 messagesLeft__body">
    <Row className="p-0 m-0">
      <Col md={5} className="d-flex">
        <Row className="w-50">
          <span className="m-50">Name</span>
        </Row>
        <Row className="w-50">
          <p className="m-50">{name}</p>
        </Row>
      </Col>
      <Col md={6} className="d-flex">
        <Row className="w-50">
          <span className="m-50">Date</span>
        </Row>
        <Row className="w-50">
          <p className="m-25">{date}</p>
        </Row>
      </Col>
      <Col md={1} className="d-flex justify-content-end">
        <Button key={id} className="messagesLeft__body__button" onClick={() => handleOnClickClose(id)} disabled={!status}>{status ? 'Close' : 'Closed'}</Button>
      </Col>
    </Row>
    <Row className="p-10 m-0">
      <Col md={5} className="d-flex">
        <Row className="w-50">
          <span className="m-25">Email</span>
        </Row>
        <Row className="w-50">
          <p className="m-25">{email}</p>
        </Row>
      </Col>
      <Col md={6} className="d-flex">
        <Row className="w-50">
          <span className="m-25">Entry Point</span>
        </Row>
        <Row className="w-50">
          <p className="m-25">{entryPointName}</p>
        </Row>
      </Col>
    </Row>
    <Row className="p-10 m-0">
      <Col md={5} className="d-flex">
        <Row className="w-50">
          <span className="m-25">Cell Phone</span>
        </Row>
        <Row className="w-50">
          <p className="m-25">{phone}</p>
        </Row>
      </Col>
    </Row>
    <Row className="p-10 m-0">
      <Col md={2} className="d-flex">
        <Row>
          <span className="m-25">Messages</span>
        </Row>
      </Col>
      <Col>
        <Row>
          <p className="text-start">{message}</p>
        </Row>
      </Col>
    </Row>
  </div>
)
export default MessagesLeftTableRow
