/* eslint-disable no-return-assign */
import constants from './constants'

const setActiveConversation = (conversation, { ...other }) => ({
  type: constants.SET_ACTIVE_CONVERSATION,
  conversation: {
    ...other,
    roomName: conversation.room_name,
    roomToken: conversation.token,
    conversationSID: conversation.conversation_sid,
    serviceIntroMessage: conversation.welcome_message,
  },
})

const setActiveScreenShare = (payload) => ({
  type: constants.SET_ACTIVE_SCREEN_SHARE,
  conversation: { ...payload },
})

// fixme: unused
const updateActiveScreenShare = (payload) => ({
  type: constants.UPDATE_ACTIVE_SCREEN_SHARE,
  conversation: { ...payload },
})

// fixme: unused
const endActiveScreenShare = (payload) => ({
  type: constants.END_ACTIVE_SCREEN_SHARE,
  conversation: { ...payload },
})

const updateActiveConversation = (id, data) => ({
  type: constants.UPDATE_ACTIVE_CONVERSATION,
  id,
  data,
})

const updateActiveConversationTracksPermit = (id, key, { ...settings }) => ({
  type: constants.UPDATE_ACTIVE_CONVERSATION_TRACKS,
  id,
  key,
  settings,
})

const endActiveConversation = (id) => ({
  type: constants.END_ACTIVE_CONVERSATION,
  id,
})

const resetActiveConversations = () => ({
  type: constants.RESET_ACTIVE_CONVERSATIONS,
})

const updateActiveConversations = (data) => ({
  type: constants.UPDATE_ACTIVE_CONVERSTATIONS,
  data,
})

export {
  setActiveConversation,
  endActiveConversation,
  updateActiveConversationTracksPermit,
  updateActiveConversation,
  resetActiveConversations,
  updateActiveConversations,

  setActiveScreenShare,
  updateActiveScreenShare,
  endActiveScreenShare,
}
