import React from 'react'
import { Col, Row } from 'react-bootstrap'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'
import InputField from 'components/form/InputField'
import './entry-point-search-bar.scss'

const EntryPointSearchBar = ({
  searchHandler,
}) => {
  const handleChange = (e) => {
    searchHandler(e.target.value)
  }

  return (
    <Row className="my-2">
      <Col className="entry-point-search-bar__title">
        <h5>Entry Point</h5>
      </Col>
      <Col className="entry-point-search-bar__search-bar my-3">
        <IcomoonReact
          iconSet={iconSet}
          size={16}
          icon="search-icon"
          className="entry-point-search-bar__search-bar__icon"
        />
        <InputField
          onChange={handleChange}
          placeholder="Search entry points"
          className="entry-point-search-bar__input"
        />
      </Col>
    </Row>
  )
}

export default EntryPointSearchBar
