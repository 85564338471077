import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { findLastItemInListById } from 'utils/helpers'
import useConversationSettings from './useConversationSettings'

const useConsumerConversations = (reqId) => {
  const { chatRequestId: chatReqId } = useParams() ?? reqId
  const chatRequestId = reqId ?? chatReqId
  const chatRequests = useSelector((state) => state.consumerConversations)

  const {
    id,
    roomName,
    roomToken,
    conversationSID,
    requestType,
    introMessage,
    activeConversation,
    consumerEmail,
    consumerName,
    ...other
  } = findLastItemInListById(chatRequests, chatRequestId) ?? {}

  const settings = useConversationSettings(id)

  return {
    id,
    roomName,
    roomToken,
    conversationSID,
    requestType,
    introMessage,
    activeConversation,
    consumerEmail,
    consumerName,
    state: other,
    settings,
  }
}

export default useConsumerConversations
