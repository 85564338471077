// import { ActionState } from 'utils/constants'
import { defaultTracksExpiryTime } from 'utils/constants'
import constants from './constants'

const initialState = [
  //     {
  //         id: String,
  //         roomName: String,
  //         roomToken: String,
  //         conversationSID: String,
  //         consumerName: String,
  //         consumerRequestType: String,
  //         rateToken: String,
  //         status: String,
  //         conversationSettings: {
  //             [`worker`]: {
  //               isAudioEnabled: Boolean,
  //               isVideoEnabled: Boolean,
  //             },
  //             [`customer`]: {
  //               isJoyrideClosed: Boolean,
  //               isTwoWayVideoEnabled: Boolean,
  //               isAudioEnabled: Boolean,
  //             },
  //             expiry: Date,
  //           },
  //     },
]
const now = new Date()

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_CONSUMER_CONVERSATION:
      return [...state, action.conversation]

    case constants.END_CONSUMER_CONVERSATION:
      return [...state?.filter((item) => Number(item.id) !== Number(action.id))]

    case constants.UPDATE_CONSUMER_CONVERSATION: {
      const updateConversation = state?.map((chat) => {
        if (Number(chat.id) === Number(action.id)) {
          return { ...chat, ...action.data }
        }
        return chat
      })
      return updateConversation
    }

    case constants.UPDATE_CONSUMER_CONVERSATION_TRACKS: {
      const updateConversation = state?.map((chat) => {
        if (chat.id === action.id) {
          return {
            ...chat,
            settings: {
              [action.key]: {
                ...chat.settings[action.key],
                ...action.settings,
              },
              expiry: now.getTime() + defaultTracksExpiryTime,
            },
          }
        }
        return chat
      })
      return updateConversation
    }

    case constants.UPDATE_CONSUMER_CONVERSTATIONS:
      return action.data

    case constants.RESET_CONSUMER_CONVERSATIONS:
      return initialState

    default:
      return state
  }
}

export default reducer
