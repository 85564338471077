import React from 'react'
import { Col } from 'react-bootstrap'
import usePendingChatRequests from 'features/pending-chat-requests/usePendingChatRequests'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import ChatRequestsInQueue from 'routes/Dashboard/components/chat/queue/ChatRequestsInQueue'

const QueueList = () => {
  const {
    isLoading: isLoadingChatRequests,
    data: chatRequestsInQueue,
  } = usePendingChatRequests((chatRequests) => chatRequests || [])

  if (isLoadingChatRequests) {
    return (
      <div
        className="d-flex justify-content-center align-items-center my-3 br-2 bg-white p-3 shadow-big mx-0 px-0 h-100"
      >
        <LoadingSpinner spinnerSize={50} />
      </div>
    )
  }

  const numberOfChatRequestsInQueue = chatRequestsInQueue?.length

  return (
    <div
      className="br-2 bg-white p-3 shadow-big h-100 d-flex flex-column"
    >
      <div className="text-center py-3">
        <span className="text-center text-uppercase card-title fw-bold">
          QUEUED CUSTOMERS (
          {numberOfChatRequestsInQueue}
          )
        </span>
      </div>
      <div className="pt-3 mx-0 px-0 queue-container">
        {numberOfChatRequestsInQueue
          ? <ChatRequestsInQueue chatRequestsInQueue={chatRequestsInQueue} />
          : (
            <Col md={12} className="text-center w-100 card-title">
              Queue is empty
            </Col>
          )}
      </div>
    </div>
  )
}

export default QueueList
