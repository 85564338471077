import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import ConversationsData from 'routes/Conversations/Conversations'
import Filter from 'components/UI/Filter'
import Filters from './components/Filters'

const initialValues = {
  worker: null,
  consumer_name_or_email: '',
  start_date: '',
  end_date: '',
  helpfulness: '',
  knowledge: '',
  professional: '',
}

const Conversations = () => {
  const [displayFilters, setDisplayFilters] = useState(false)
  const [filters, setFilters] = useState(initialValues)

  const onToggleFilters = () => setDisplayFilters((prevDisplayFiltersValue) => !prevDisplayFiltersValue)

  return (
    <div className="conversations">
      <div className="conversations__header">
        <div>
          <div className="conversations__header__title">
            Conversations
          </div>
          <div className="conversations__header__subtitle">
            View all past conversations.
          </div>
        </div>
        <div>
          <Filter onClick={onToggleFilters} displayFilters={displayFilters} />
        </div>
      </div>
      <Row className="m-0 p-0">
        {
          displayFilters && (
            <Filters
              setFilters={setFilters}
              initialValues={filters}
            />
          )
        }
        <hr />
        <Col md={12} className="m-0 p-0">
          <ConversationsData filters={filters} />
        </Col>
      </Row>
    </div>
  )
}
export default Conversations
