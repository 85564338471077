import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import DashboardMain from 'routes/Dashboard/DashboardMain'
import UserStats from 'routes/Dashboard/components/UserStats/UserStats'
import { useBillingContext } from 'providers/BillingProvider/index'
import { useToasts } from 'react-toast-notifications'
import { isMobile } from 'utils/helpers'
import MobileDashboardMain from './components/MobileDashboardMain'

const Dashboard = () => {
  const { addToast } = useToasts()
  const {
    hasErrorSubscription,
  } = useBillingContext()

  useEffect(() => {
    if (hasErrorSubscription) {
      addToast("We couldn't initialize your account. Invalid subscription data.", {
        appearance: 'info',
        autoDismiss: true,
        autoDismissTimeout: 2000,
      })
    }
  }, [hasErrorSubscription, addToast])

  return (
    <>
      <UserStats />
      <Row className="d-flex flex-grow-1 m-0 p-0 w-100">
        <Col md={12} className={isMobile && 'm-0 p-0'}>
          {!isMobile ? <DashboardMain /> : <MobileDashboardMain />}
        </Col>
      </Row>
    </>
  )
}

export default Dashboard
