import React from 'react'
import {
  Alert,
  Button,
  Form,
} from 'react-bootstrap'
import { Formik, Field } from 'formik'
import LoadingButton from 'components/buttons/LoadingButton'
import TextFormField from 'components/UI/TextFormField/TextFormField'
import useSetupNewProject from 'routes/AccountSetup/SetupNewProject/useSetupNewProject'
import Layout from 'routes/AccountSetup/Layout'

const initialValues = {
  name: '',
}

const SetupNewProject = () => {
  const {
    errorMessage,
    onClearErrorMessage,
    isSubmitting,
    onCreateNewProject,
    navigateToJoinExistingProject,
    onBackClickHandler,
  } = useSetupNewProject()

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onCreateNewProject}
    >
      {({
        handleSubmit,
        handleChange,
      }) => (
        <Layout>
          <Form.Group>
            <Form.Check
              name="interest"
              label="Setup a new account"
              type="radio"
              className="mb-2"
              checked
              onChange={handleChange}
            />
            <Form.Check
              name="interest"
              label="Join an existing team"
              type="radio"
              className="mb-2"
              onChange={handleChange}
              onClick={navigateToJoinExistingProject}
            />
          </Form.Group>
          <Field
            label="Name of your new project"
            name="name"
            placeholder="Enter a name for your project"
            component={TextFormField}
          />
          {errorMessage && (
            <Alert
              className="my-3"
              variant="danger"
              dismissible
              onClose={onClearErrorMessage}
            >
              {errorMessage}
            </Alert>
          )}
          <div className="d-flex justify-content-between mt-4">
            <Button
              onClick={onBackClickHandler}
              className="verify-email__button account-setup__button--back"
            >
              Back
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={handleSubmit}
              className="account-setup__button account-setup__button--submit"
            >
              Continue
            </LoadingButton>
          </div>
        </Layout>
      )}
    </Formik>
  )
}

export default SetupNewProject
