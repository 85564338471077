import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Chat from 'services/Chat'
import { useVideoContext } from 'providers/VideoProvider'
import WorkerDialog from 'components/WorkerDialog'
import { ParticipantTypes, MessageEvent } from 'utils/constants'
import useResetRoom from 'hooks/useResetRoom'
import useActiveConversations from 'hooks/useActiveConversations'
import { updateActiveConversation } from 'redux/activeConversations/actions'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import alertTriangle from 'assets/images/alert-triangle.svg'

const EndConversationDialog = ({
  show,
  onClose,
  inactivity,
  callDropped,
  inactivityTriggered,
}) => {
  const [isEndingConversation, setIsEndingConversation] = useState(false)

  const { id: acceptedRequestId, roomName } = useActiveConversations()
  const { resetRoom } = useResetRoom()
  const {
    room,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    isSharingScreen,
    toggleScreenShare,
  } = useVideoContext()

  const dispatch = useDispatch()

  const handleEndConversion = async () => {
    setIsEndingConversation(true)

    try {
      await Chat.completeChatRequestWorker(acceptedRequestId, roomName)
      resetRoom(acceptedRequestId)
      dispatch(updateActiveConversation(acceptedRequestId, { [ParticipantTypes.CONSUMER]: {} }))
      if (isSharingScreen) {
        toggleScreenShare()
      }
      removeLocalAudioTrack()
      removeLocalVideoTrack()
      room?.disconnect()
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(MessageEvent.END_CONVERSATION)
      }
    } catch (e) {
      console.log('Error ending conversation, TODO handle errors when needed', e)
      setIsEndingConversation(false)
    }
  }

  useEffect(() => {
    if (callDropped) {
      handleEndConversion()
    }
    if (!callDropped && !inactivityTriggered) {
      onClose()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callDropped, inactivityTriggered])

  return (
    <WorkerDialog
      show={show}
      onClose={onClose}
      onConfirm={handleEndConversion}
      title={inactivity ? (
        <span>
          <img src={alertTriangle} alt="alert" />
          {' '}
          Warning
        </span>
      ) : 'End Conversation'}
      loading={isEndingConversation}
    >
      <p>
        {inactivity
          ? (
            <div className="d-flex align-items-center">
              <span className="me-2">The call will be dropped in</span>
              <CountdownCircleTimer
                isPlaying
                duration={30}
                strokeWidth={4}
                colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                colorsTime={[15, 10, 5, 0]}
                size={30}
              >
                {({ remainingTime }) => remainingTime}
              </CountdownCircleTimer>
              <span className="ms-2">seconds.</span>
            </div>
          )
          : 'Are you sure you would like to end this conversation?'}
      </p>
      <p>
        This action is not reversible and you would not be able to connect with the customer again.
      </p>
    </WorkerDialog>
  )
}

export default EndConversationDialog
