import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

const useScreenShare = (meetLink) => {
  const { meetingLink } = useParams() ?? meetLink

  return useSelector((state) => state.activeConversations)?.find((request) => request.meetingLink === meetingLink) ?? {
    room: {},
  }
}
export default useScreenShare
