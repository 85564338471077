import React from 'react'
import usePublications from 'hooks/usePublications/usePublications'
import { WORKER_VIDEO_NOT_CONNECTED_MESSAGE, CUSTOMER_VIDEO_NOT_CONNECTED_MESSAGE } from 'utils/constants'
import VideoInfoContainer from 'components/Room/VideoInfoContainer'
import ParticipantTracks from 'components/Room/ParticipantTracks'
/**
 * Used by both the consumer (QR flow), and the worker (conversation)
 * In the conversation, this object is the customer.
 * In the qr-flow, this object is the user/worker.
 *
 * @param participant
 * @param videoConnectionInfoClassName
 * @param isTwoWayEnabled
 * @param worker
 * @returns {JSX.Element}
 * @constructor
 */
const ScreenShareParticipant = ({
  participant,
  videoConnectionInfoClassName,
  isTwoWayEnabled,
  worker = true,
  isScreenShareOnly,
  attachLogo = null,
  fullScreenRef,
}) => {
  const publications = usePublications(participant)
  const isScreenSharing = publications.find((p) => p.trackName.includes('screen'))

  const remoteVideoDisconnected = !isScreenSharing

  const shouldDisplayRemoteVideo = !remoteVideoDisconnected

  if (isScreenShareOnly) {
    return (
      <ParticipantTracks
        participant={participant}
        displayVideo={shouldDisplayRemoteVideo && isTwoWayEnabled}
        enableScreenShare={isScreenSharing}
        isWorker={worker}
        isScreenShareOnly={isScreenShareOnly}
        attachLogo={attachLogo}
        fullScreenRef={fullScreenRef}
      />
    )
  }

  return (
    <>
      {
        isTwoWayEnabled && <VideoInfoContainer className={videoConnectionInfoClassName} />
      }
      {
        remoteVideoDisconnected && (
          <VideoInfoContainer
            message={!worker ? CUSTOMER_VIDEO_NOT_CONNECTED_MESSAGE : WORKER_VIDEO_NOT_CONNECTED_MESSAGE}
            displaySpinner={false}
            className={videoConnectionInfoClassName}
          />
        )
      }
      <ParticipantTracks
        participant={participant}
        displayVideo={shouldDisplayRemoteVideo && isTwoWayEnabled}
        enableScreenShare={isScreenSharing}
        isWorker={worker}
        isScreenShareOnly={isScreenShareOnly}
        attachLogo={attachLogo}
      />
    </>
  )
}

export default ScreenShareParticipant
