import React from 'react'
import { Nav } from 'react-bootstrap'
import IcomoonReact from 'icomoon-react'
import { useAuth } from 'providers/AuthProvider/AuthProvider'
import { NavLink, withRouter } from 'react-router-dom'
import useIsInConversationScreen from 'hooks/useIsInConversationScreen'
import iconSet from 'assets/fonts/selection.json'
import logo from 'assets/images/swytchboard-logo.svg'
import swytchboardYLogo from 'assets/images/swytchboard-Y-logo.svg'
import Header from 'components/header'
import useMessagesLeft from 'routes/MessagesLeft/hooks/useMessagesLeft'
import { useSelector } from 'react-redux'
import ConversationRouterPrompt from 'components/RouterPrompt/RouterPrompt'
import './sidebar.scss'
import useQueryString from 'hooks/useQueryString'
import { isMobile } from 'utils/helpers'
import NewSubscriptionAlert from 'components/SweetAlert/NewSubscriptionAlert'
import useActiveConversations from 'hooks/useActiveConversations'
import { ConversationTypes } from 'utils/constants'

const Side = ({ isMenuOpen = false }) => {
  const { isAdmin } = useAuth()
  const showSmallSideBar = (useIsInConversationScreen() && !isMenuOpen) || isMobile || (!isMobile && window.screen.width <= 1080 && !isMenuOpen)
  const iconColor = '#F7F9FF'
  const queryString = useQueryString()
  const subscriptionActivated = queryString.get('sub_activated')
  const { requestType } = useActiveConversations()
  const companyId = useSelector((state) => state.company.data?.id)
  const { data } = useMessagesLeft(companyId)
  const openMessages = data?.pagy?.count ?? 0

  const renderLogo = () => (
    !showSmallSideBar
      ? <img src={logo} className="App-logo w-75 m-3" alt="logo" />
      : <img src={swytchboardYLogo} className="App-logo h-44 m-3" alt="logo" />
  )
  const sideBarTextElement = (text) => (
    <div className="item-text d-inline-block ms-4 text-white">{text}</div>
  )

  return (
    <>
      <Nav
        className={!isMobile ? 'col-md-12 d-none d-md-block sidebar m-0 p-0' : 'flex-column'}
      >
        <ConversationRouterPrompt when={useIsInConversationScreen() && requestType === ConversationTypes.videoText} />
        <div className="logo-container w-100 text-center mt-3">
          {renderLogo()}
        </div>
        {subscriptionActivated && <NewSubscriptionAlert show={subscriptionActivated} />}
        <Nav.Item>
          <Nav.Link
            as={NavLink}
            to={isAdmin ? '/admin/dashboard' : '/dashboard'}
            eventKey={isAdmin ? '/admin/dashboard' : '/dashboard'}
            isActive={(_, { pathname }) => ['/admin/dashboard', '/dashboard'].includes(pathname)}
            className="text-start d-flex align-items-center p-4"
          >
            <IcomoonReact iconSet={iconSet} color={iconColor} size={24} icon="icon-dashboard" />
            {!showSmallSideBar && sideBarTextElement('Dashboard')}
          </Nav.Link>
        </Nav.Item>
        {
          isAdmin && (
            <Nav.Item>
              <Nav.Link
                as={NavLink}
                to="/conversations"
                eventKey="/conversations"
                className="text-start d-flex align-items-center p-4"
              >
                <IcomoonReact iconSet={iconSet} size={24} icon="convo-icon" />
                {!showSmallSideBar && sideBarTextElement('Conversations')}
              </Nav.Link>
            </Nav.Item>
          )
        }
        {
          isAdmin && (
            <Nav.Item>
              <Nav.Link
                as={NavLink}
                to="/admin/messages-left"
                eventKey="/admin/messages-left"
                className="text-start d-flex align-items-center p-4"
              >
                <IcomoonReact iconSet={iconSet} size={24} icon="mail-icon" color="white" />
                {!showSmallSideBar && sideBarTextElement('Messages Left')}
                {!showSmallSideBar && openMessages ? <span className="number-circle">{Number(openMessages) > 99 ? '99+' : openMessages}</span> : null}
              </Nav.Link>
            </Nav.Item>
          )
        }
        {
          isAdmin && (
            <Nav.Item>
              <Nav.Link
                as={NavLink}
                to="/admin/chat-settings"
                eventKey="/admin/chat-settings"
                className="text-start d-flex align-items-center p-4"
              >
                <IcomoonReact iconSet={iconSet} color={iconColor} size={24} icon="settings" />
                {!showSmallSideBar && sideBarTextElement('Chat Settings')}
              </Nav.Link>
            </Nav.Item>
          )
        }
        {isAdmin && (
          <Nav.Item>
            <Nav.Link
              as={NavLink}
              to="/admin/team-members"
              eventKey="/admin/team-members"
              className="item text-start d-flex align-items-center p-4"
            >
              <IcomoonReact iconSet={iconSet} color={iconColor} size={24} icon="team-icon" />
              {!showSmallSideBar && sideBarTextElement('Team')}
            </Nav.Link>
          </Nav.Item>
        )}
        {isAdmin && (
          <Nav.Item>
            <Nav.Link
              as={NavLink}
              to="/admin/billing"
              eventKey="/admin/billing"
              className="item text-start d-flex align-items-center p-4"
            >
              <IcomoonReact iconSet={iconSet} color={iconColor} size={24} icon="credit-card" />
              {!showSmallSideBar && sideBarTextElement('Billing')}
            </Nav.Link>
          </Nav.Item>
        )}
        <Nav.Item>
          <Nav.Link
            as={NavLink}
            to="/feedback"
            eventKey="/feedback"
            className="text-start d-flex align-items-center p-4"
          >
            <IcomoonReact iconSet={iconSet} color={iconColor} size={24} icon="icon-feedback" />
            {!showSmallSideBar && sideBarTextElement('Feedback')}
          </Nav.Link>
        </Nav.Item>
      </Nav>
      {!showSmallSideBar && <Header />}
    </>
  )
}
const Sidebar = withRouter(Side)
export default Sidebar
