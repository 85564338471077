import React from 'react'
import ReactRating from 'react-rating'
import FullStar from 'components/UI/FullStar'
import EmptyStar from 'components/UI/EmptyStar'

const Rating = ({
  label,
  field: {
    name,
    value,
  },
  form: {
    setFieldValue,
  },
}) => (
  <div className="rating-field">
    <p className="fw-bold">
      {label}
    </p>
    <ReactRating
      onChange={(newValue) => setFieldValue(name, newValue)}
      fullSymbol={<FullStar />}
      emptySymbol={<EmptyStar />}
      placeholderSymbol={<FullStar />}
      className="rating-field__stars"
      initialRating={value}
    />
  </div>
)

export default Rating
