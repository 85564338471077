import React from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'

const TeamActionDialog = ({
  show = true,
  onConfirm,
  onClose,
  title,
  children,
  confirmText = 'Confirm',
  closeText = 'Cancel',
  loading = false,
}) => (
  <Modal
    show={show}
    onHide={onClose}
    contentClassName="worker-dialog"
    centered
  >
    <Modal.Header className="worker-dialog__header-wrapper">
      <div className="worker-dialog__header-content">
        {title}
        <IcomoonReact
          className="worker-dialog__header-content__icon"
          icon="icon-cross"
          iconSet={iconSet}
          onClick={onClose}
        />
      </div>
    </Modal.Header>
    <Modal.Body className="worker-dialog__body">
      {children}
    </Modal.Body>
    <Modal.Footer className="worker-dialog__footer">
      <Button
        onClick={onClose}
        className="btn-danger"
      >
        {closeText}
      </Button>
      <Button
        onClick={onConfirm}
        className="btn-success"
      >
        {
          loading
            ? <Spinner animation="border" />
            : confirmText
        }
      </Button>
    </Modal.Footer>
  </Modal>
)

export default TeamActionDialog
