import {
  useCallback,
  useEffect,
  useState,
} from 'react'
import Video, { Logger } from 'twilio-video'
import { TrackPriority } from 'utils/constants'
import { DEFAULT_VIDEO_CONNECT_OPTIONS } from 'providers/VideoProvider/constants'

const logger = Logger.getLogger('twilio-video')
logger.setLevel('error')

export default function useRoom() {
  const [room, setRoom] = useState(null)
  const [isConnectingToRoom, setIsConnectingToRoom] = useState(false)
  const connectToRoom = useCallback(async (token, options, localTracks, trackPriority = TrackPriority.STANDARD) => {
    setIsConnectingToRoom(true)
    try {
      const newRoom = await Video.connect(token, {
        ...DEFAULT_VIDEO_CONNECT_OPTIONS,
        ...options,
        tracks: localTracks,
        // Network Quality is set to "detailed", which will report network quality logging level and networkQualityStats for the participant
        networkQuality: { local: 3, remote: 3 },
      })
      setRoom(newRoom)

      newRoom.localParticipant.videoTracks
        .forEach((publication) => publication.setPriority(trackPriority))

      const disconnect = () => newRoom.disconnect()
      newRoom.once('disconnected', () => {
        // Reset the room only after all other `disconnected` listeners have been called.
        setTimeout(() => setRoom(null))
        window.removeEventListener('beforeunload', disconnect)
        window.removeEventListener('pagehide', disconnect)
      })

      window.addEventListener('beforeunload', disconnect)
      window.addEventListener('pagehide', disconnect)

      setIsConnectingToRoom(false)
    } catch (error) {
      console.log('Error connecting to room', error)
      setIsConnectingToRoom(false)
    }
  }, [])

  useEffect(() => () => {
    if (room) {
      room.disconnect()
    }
  }, [room])

  return {
    room,
    isConnectingToRoom,
    connectToRoom,
  }
}
