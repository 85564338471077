import React from 'react'
import 'features/qrflow/styles/chatHeader.scss'
import useGetEntryPointDetails from 'features/qrflow/hooks/useGetEntryPointDetails'
import config from 'utils/config'
import defaultLogo from 'assets/images/swytchboard-Y-logo.svg'
import useConsumerConversations from 'features/qrflow/hooks/useConsumerConversations'

const VideoCallHeader = () => {
  const { state: { user, workerName } } = useConsumerConversations()
  const {
    data,
  } = useGetEntryPointDetails()

  const {
    visual,
  } = data?.config ?? {}

  const awsPath = `${config.ASSETS_URL}/`

  const isWorkerOnline = user?.status === 'online'

  const companyLogo = visual?.logo1 === null ? defaultLogo : `${awsPath}${visual?.logo1}`

  return (
    <div className="qr-chat-header">
      <div className="qr-chat-header--person">
        <div
          className={`activity-indicator 
            ${isWorkerOnline
            ? 'activity-indicator__connected'
            : 'activity-indicator__disconnected'
          }`}
        />
        Hi, I&apos;m
        &nbsp;
        {workerName}
      </div>
      <div className="qr-chat-header--logo">
        <img src={companyLogo} alt="company-logo" />
      </div>
    </div>
  )
}

export default VideoCallHeader
