import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Col, Row,
} from 'react-bootstrap'
import { Redirect, useHistory } from 'react-router-dom'
import VideoChat from 'components/video/VideoChat'
import CustomerChat from 'routes/Dashboard/components/chat/customer/CustomerChat'
import TeamChat from 'routes/Dashboard/components/chat/team/TeamChat'
import {
  ChatStatuses, ConversationTypes, MessageEvent, ParticipantTypes,
} from 'utils/constants'
import ConversationChatWrapper from 'routes/ChatConversation/ConversationChatWrapper'
import { setIsChatHidden } from 'redux/layout/actions'
import VideoProvider, { useVideoContext } from 'providers/VideoProvider'
import { useAuth } from 'providers/AuthProvider/AuthProvider'
import useWorkerConversationChannel from 'channels/useWorkerConversationChannel'
import ConversationHeader from 'routes/ChatConversation/components/ConversationHeader'
import useResetRoom from 'hooks/useResetRoom'
import useActiveConversations from 'hooks/useActiveConversations'
import ActiveConversations from 'components/ActiveConversations'
import { updateActiveConversation } from 'redux/activeConversations/actions'
import ArrowLeft from 'components/UI/ArrowLeft'
import { isMobile } from 'utils/helpers'
import withProviders from 'hoc/withProviders'
import ChatProvider from 'providers/ChatProvider'
import '../../assets/styles/components/mobile/dashboard.scss'
import iconSet from 'assets/fonts/selection.json'
import IcomoonReact from 'icomoon-react'
import MobileConversation from './MobileConversation'
import InactivityDetectorDialog from './components/InactivityDetectorDialog'

const Conversation = () => {
  const { cableConsumer } = useAuth()
  const dispatch = useDispatch()
  const { room } = useVideoContext()
  const { id: acceptedRequestId, requestType, state: { unReadMessages, consumer, participants } } = useActiveConversations()
  const isConsumerOnline = consumer?.status === 'online'
  const isConsumerConnected = participants && Object.values(participants).find((p) => p.type === ParticipantTypes.CONSUMER)

  const { resetRoom } = useResetRoom()
  const onReceiveConversationEvent = (response) => {
    try {
      // Invoked when the consumer requests a video call
      if (response?.intent === 'engage-video-chat') {
        dispatch(updateActiveConversation(acceptedRequestId, { requestedVideoChat: true }))
      }
      // Invoked when the consumer ends the call
      if (response.status === ChatStatuses.completed) {
        room?.disconnect()
        resetRoom(acceptedRequestId)
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(MessageEvent.END_CONVERSATION)
        }
      }
      // Invoked when the appearance of a user changes
      if (response.intent === 'notify-appearance') {
        const {
          type,
          status,
        } = response.message
        dispatch(updateActiveConversation(acceptedRequestId, { [type]: { status } }))
      }

      // Invoked when we get an array of online users
      if (Array.isArray(response)) {
        response.forEach(({ type }) => (
          dispatch(updateActiveConversation(acceptedRequestId, { [type]: { status: 'online' } }))))
      }
    } catch (error) {
      console.log('onReceiveConversationEvent error', error)
    }
  }

  useWorkerConversationChannel(acceptedRequestId, cableConsumer, onReceiveConversationEvent)

  const [chatToggle, setChatToggle] = useState('customer')
  const isChatHidden = useSelector((state) => state.layout.chatHidden)

  const [teamTabActive, setTeamTabActive] = useState(false)

  const isVideoCall = [ConversationTypes.videoText, ConversationTypes.video].includes(requestType)

  const history = useHistory()

  useEffect(() => {
    if (!acceptedRequestId) {
      history.push({ pathname: '/dashboard', state: { from: 'conversation', allowNavigation: true } })
    }
  }, [acceptedRequestId, history])

  useEffect(() => {
    if (unReadMessages) {
      dispatch(updateActiveConversation(acceptedRequestId, { unReadMessages: null }))
    }
  }, [acceptedRequestId, history, dispatch, unReadMessages])

  const handleChatToggle = (e) => {
    if (e.target.id === 'customer-button') {
      setChatToggle('customer')
      setTeamTabActive(false)
    } else {
      setChatToggle('team')
      setTeamTabActive(true)
    }
  }

  if (!acceptedRequestId) {
    return <Redirect to={{ pathname: '/dashboard', state: { from: 'conversation', allowNavigation: true } }} />
  }

  if (isMobile) {
    return <MobileConversation />
  }

  return (
    <Col md={12} xs={12}>
      <Row className="m-3">
        <Col md={12} xs={12} className="m-0 p-0">
          <div className="conversation-header__go-back__wrapper" onClick={() => history.push('/dashboard')}>
            <ArrowLeft />
            <p className="px-0 ps-2 m-0 fw-bold">Back to Dashboard</p>
          </div>
        </Col>
      </Row>
      <Row className={`m-3 ${requestType === ConversationTypes.videoText && 'bg-lighter rounded-1'}`} style={{ height: '91%' }}>
        <Col md={isChatHidden ? 12 : 8} className={`${requestType !== ConversationTypes.videoText && 'bg-lighter rounded-1'} px-3 py-4 h-100`}>
          <ConversationHeader />
          <InactivityDetectorDialog inactivityTriggered={!isConsumerOnline && !isConsumerConnected} />
          {isVideoCall ? <VideoChat /> : <CustomerChat />}
          {!isMobile && <ActiveConversations paddingTop={3} />}
        </Col>
        {!isChatHidden
          && (
            <Col md={4} className="h-100">
              {requestType === ConversationTypes.videoText ? (
                <ConversationChatWrapper
                  chatToggle={chatToggle}
                  handleChatToggle={handleChatToggle}
                  teamTabActive={teamTabActive}
                  isChatHidden={isChatHidden}
                  setIsChatHidden={() => dispatch(setIsChatHidden())}
                />
              ) : (
                <Col md={12} className={`h-100 team-chat ${chatToggle === 'customer' ? 'd-flex flex-grow-1' : 'd-none'}`}>
                  <TeamChat changed={teamTabActive} />
                </Col>
              )}
            </Col>
          )}
      </Row>
      <div className="hide-chat-icon-container">
        <div
          className="hide-chat-icon"
          style={{ backgroundColor: `${isChatHidden ? '#0E0641' : 'white'}` }}
          onClick={() => dispatch(setIsChatHidden())}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={24}
            icon={`icon-chat-bubble${isChatHidden ? '-green' : ''}`}
            type="button"
          />
        </div>
      </div>
    </Col>
  )
}

export default withProviders(VideoProvider, ChatProvider)(Conversation)
