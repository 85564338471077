import {
  useMutation, useQueryClient,
} from '@tanstack/react-query'
import Company from 'services/Company'
import { useToasts } from 'react-toast-notifications'
import { ToastKey } from 'routes/ChatSettingsEntryPoint/constants'
import { QueryKeys } from 'utils/constants'

export default function useUpdateEntryPointName(companyServiceId) {
  const {
    addToast,
    removeToast,
  } = useToasts()

  const queryClient = useQueryClient()

  const updateEntryPointName = useMutation(
    ({ name, company_id: companyId }) => Company.updateCompanyEntryPointName(
      String(companyServiceId),
      name,
      String(companyId),
    ), {
      onMutate: (data) => {
        queryClient.setQueryData([QueryKeys.ENTRY_POINT, companyServiceId], {
          data,
        })
      },

      onSuccess: (data) => {
        const getEntryPoint = queryClient.getQueryData([QueryKeys.ENTRY_POINT, companyServiceId])
        queryClient.setQueryData([QueryKeys.ENTRY_POINT, companyServiceId], {
          ...getEntryPoint,
          data,
        })

        if (queryClient.getQueryData([QueryKeys.ENTRY_POINT])) {
          queryClient.setQueryData([QueryKeys.ENTRY_POINT], (oldEntryPoints) => oldEntryPoints.map((d) => {
            if (d.id === data.id) {
              return data
            }
            return d
          }))
        } else {
          queryClient.invalidateQueries([QueryKeys.ENTRY_POINT])
        }
      },

      onError: (error) => {
        removeToast(ToastKey.entryPointError)
        addToast(`Entry Point: ${error.message}`, {
          appearance: 'error',
          autoDismiss: 2000,
          id: ToastKey.entryPointError,
        })
      },
    },
  )

  return {
    updateEntryPointName,
  }
}
