import React from 'react'
import { Spinner } from 'react-bootstrap'

const LoadingSpinner = ({
  spinnerSize = '75px',
  className = 'd-flex justify-content-center align-items-center w-100',
}) => (
  <div className={className}>
    <Spinner
      animation="border"
      variant="primary"
      style={{
        width: spinnerSize,
        height: spinnerSize,
      }}
    />
  </div>
)

export default LoadingSpinner
