import Company from 'services/Company'
import _ from 'lodash'
import {
  CHAT_SETTINGS_ATTRIBUTES,
  COMPANY_DETAILS_ATTRIBUTES,
  COMPANY_ATTRIBUTES_NAME,
} from 'routes/ChatSettingsEntryPoint/constants'
import actions from './actions'

const fetchCurrentCompany = () => async (dispatch) => {
  dispatch(actions.getCurrentCompanyRequest())
  try {
    const currentCompany = await Company.current()
    dispatch(actions.getCurrentCompanySuccess(currentCompany))
  } catch (e) {
    dispatch(actions.getCurrentCompanyError(e))
  }
}

const updateSettings = (settings) => async (dispatch, getState) => {
  dispatch(actions.updateCompanySettings())
  const { data } = getState().company

  const {
    id: companyId,
    company_detail: oldCompanyDetails,
    chat_setting: oldChatSettings,
  } = data
  const { data: allCompanyData } = getState().company
  try {
    await Company.updateSettings(companyId, settings)

    const updatedCompanyName = _.get(settings, COMPANY_ATTRIBUTES_NAME)
    const updatedCompanyDetails = _.get(settings, COMPANY_DETAILS_ATTRIBUTES)
    const updatedChatSettings = _.get(settings, CHAT_SETTINGS_ATTRIBUTES)

    dispatch(
      actions.updateCompanySettingsSuccess({
        ...allCompanyData,
        name: updatedCompanyName,
        company_detail: {
          ...oldCompanyDetails,
          ...updatedCompanyDetails,
        },
        chat_setting: {
          ...oldChatSettings,
          ...updatedChatSettings,
        },
      }),
    )
  } catch (e) {
    console.log({ e })
    dispatch(actions.updateCompanySettingsError(e))
  }
  dispatch(actions.resetUpdateSettingsStates())
}

export default {
  fetchCurrentCompany,
  updateSettings,
}
