import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row, Alert } from 'react-bootstrap'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { useAuth } from 'providers/AuthProvider/AuthProvider'
import UserService from 'services/User'
import TextFormField from 'components/UI/TextFormField/TextFormField'
import Loader from 'components/loader/Loader'
import Checkbox from 'components/form/Checkbox'
import passwordConfirmationSchema from 'schemas/passwordConfirmationSchema'
import analytics from 'utils/analytics'
import SignUpCheckboxes from './SignUpCheckboxes'

const SignUpSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('The email is required'),
  acceptTerms: Yup.boolean()
    .oneOf([true], 'You have to accept the terms & conditions in order to proceed.')
    .required(),
  ...passwordConfirmationSchema,
})

const SignUp = () => {
  const { updateUser } = useAuth()
  const [displayErrorMessage, setDisplayErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    analytics.page()
  }, [])

  const onSignUpHandler = async ({ email, password }) => {
    setLoading(true)
    try {
      // The user gets redirected after successful sign up on the SignUpRoute component
      await UserService.userSignup(email, password, updateUser)
    } catch (e) {
      if (e.errors) {
        setDisplayErrorMessage(e.errors[0].title)
      } else {
        setDisplayErrorMessage(e.message || e.error || 'Something went wrong, please try again.')
      }
    }
    setLoading(false)
  }

  return (
    <Col md={6} className="bg-white">
      <Row className="m-0 p-0 h-100 justify-content-center align-items-center">
        <Col md={8}>
          <div className="login-form p-5 position-relative">
            { loading === true
            && (
              <div className="loader-spinner">
                <Loader />
              </div>
            ) }
            <div className="text-start mb-4">
              <h3 className="login-title fw-bold mb-3">Create your account</h3>
              <span>Already have an account?</span>
            &nbsp;
              <Link to="/">Log in.</Link>
              <hr className="mt-3" />
            </div>

            <Formik
              validationSchema={SignUpSchema}
              initialValues={{
                email: '',
                password: '',
                password_confirmation: '',
              }}
              onSubmit={(values) => onSignUpHandler(values)}
            >
              {({
                values, handleBlur, isValid, dirty,
              }) => (
                <Form>
                  { displayErrorMessage !== ''
                  && (
                  <Alert variant="danger" onClose={() => setDisplayErrorMessage('')} dismissible>
                    {displayErrorMessage}
                  </Alert>
                  )}
                  <div className="mb-3">
                    <Field
                      label="E-mail address"
                      name="email"
                      type="email"
                      placeholder="Enter a valid e-mail address"
                      onBlur={handleBlur}
                      component={TextFormField}
                    />
                  </div>
                  <div className="mb-3">
                    <Field
                      label="Choose a password"
                      name="password"
                      type="password"
                      placeholder="Enter a password"
                      onBlur={handleBlur}
                      component={TextFormField}
                    />
                  </div>
                  <div className="mb-3">
                    <Field
                      label="Confirm password"
                      type="password"
                      name="password_confirmation"
                      placeholder="Confirm your password"
                      onBlur={handleBlur}
                      component={TextFormField}
                    />
                  </div>
                  <div className="mb-3 ms-4">
                    <Field
                      name="acceptTerms"
                      className="mb-1"
                      label="I agree to the <a href='/terms-of-use'>terms of use</a> and <a href='/privacy-policy'>privacy policy</a>."
                      component={Checkbox}
                    />
                  </div>
                  <SignUpCheckboxes value={values.password} />
                  <div className="mb-3 mt-3">
                    <button
                      type="submit"
                      id="sign-up-button"
                      className="btn btn-success d-block w-100 btn-auth"
                      disabled={!(isValid && dirty) || loading}
                    >
                      Continue
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </Col>
  )
}

export default SignUp
