import config from 'utils/config'
import http from 'utils/http'
import handleResponse from 'utils/handleResponse'
import parseAndRethrowError from 'utils/parseAndRethrowError'
import handleErrors from '../utils/handleErrors'

const TARGET_URL = `${config.SERVER_URL}${config.API_URL}`

class Billing {
  getPlans = () => (
    http.get(`${TARGET_URL}/plans`)
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  getCustomerPortal = () => (
    http.get(`${TARGET_URL}/stripe/billing_portal`)
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  getCustomerData = () => (
    http.get(`${TARGET_URL}/stripe/customer`)
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  updatePlan = (planId) => (
    http.put(`${TARGET_URL}/stripe/update_subscription`, {
      plan_id: planId,
    })
      .then((response) => handleResponse(response))
      .catch((error) => handleErrors(error))
  )
}

export default new Billing()
