/* eslint-disable no-return-assign */
import constants from './constants'

const setConsumerConversation = (conversation, { ...other }) => ({
  type: constants.SET_CONSUMER_CONVERSATION,
  conversation: {
    ...other,
    roomName: conversation.room_name,
    roomToken: conversation.token,
    conversationSID: conversation.conversation_sid,
    serviceIntroMessage: conversation.welcome_message,
  },
})

const updateConsumerConversation = (id, data) => ({
  type: constants.UPDATE_CONSUMER_CONVERSATION,
  id,
  data,
})

const updateConsumerConversationTracksPermit = (id, key, { ...settings }) => ({
  type: constants.UPDATE_CONSUMER_CONVERSATION_TRACKS,
  id,
  key,
  settings,
})

const endConsumerConversation = (id) => ({
  type: constants.END_CONSUMER_CONVERSATION,
  id,
})

const resetConsumerConversations = () => ({
  type: constants.RESET_CONSUMER_CONVERSATIONS,
})

const updateConsumerConversations = (data) => ({
  type: constants.UPDATE_CONSUMER_CONVERSTATIONS,
  data,
})

export {
  setConsumerConversation,
  updateConsumerConversation,
  updateConsumerConversationTracksPermit,
  endConsumerConversation,
  resetConsumerConversations,
  updateConsumerConversations,
}
