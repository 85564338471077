import React from 'react'
import * as yup from 'yup'
import { Formik, Field as FormikField } from 'formik'
import Header from 'routes/ChatSettingsEntryPoint/components/Header'
import Field from 'routes/ChatSettingsEntryPoint/components/Field'
import EditCompanyLogo from 'routes/ChatSettingsEntryPoint/components/EditCompanyLogo'
import Timezone from 'routes/ChatSettingsEntryPoint/components/Timezone'
import useChatSettings from 'routes/ChatSettingsEntryPoint/useChatSettings'
import AddEntryPoint from 'routes/ChatSettingsEntryPoint/components/AddEntryPoint'
import ListEntryPoints from 'routes/ChatSettingsEntryPoint/components/ListEntryPoints'
import { COMPANY_DETAILS_ATTRIBUTES, COMPANY_ATTRIBUTES_NAME } from 'routes/ChatSettingsEntryPoint/constants'
import _ from 'lodash'
import { validUrl } from 'utils/regex'
import InputField from 'components/form/InputField'
import Line from 'components/UI/Line/Line'
import { Dropdown } from 'react-bootstrap'
import { CustomerInfoOptions } from 'utils/constants'
import { CHAT_SETTINGS_ATTRIBUTES } from './constants'

const ChatSettingsFormSchema = yup.object()
  .shape({
    company_attributes: yup.object()
      .shape({
        name: yup.string(),
        chat_setting_attributes: yup.object()
          .shape({
            customer_full_name: yup.string(),
            customer_contact_identifier: yup.string(),
          }),
        company_detail_attributes: yup.object()
          .shape({
            website: yup.string()
              .matches(
                validUrl,
                'Invalid Product Website',
              ),
            timezone: yup.string(),
            // If start_office_hour is set, then end_office_hour must be set as well, and vice-versa
            start_office_hour: yup.string().nullable()
              .when('end_office_hour', {
                is: null,
                then: yup.string().nullable(),
                otherwise: yup.string().required(),
              }),
            end_office_hour: yup.string().nullable()
              .when('start_office_hour', {
                is: null,
                then: yup.string().nullable(),
                otherwise: yup.string().required(),
              }),
          }, ['start_office_hour', 'end_office_hour']),
      }),
  })

const ChatSettings = () => {
  const {
    initialValues,
    isUpdatingSettings,
    updateSettings,
  } = useChatSettings()

  const customerFullName = (values) => CustomerInfoOptions[_.get(values, CHAT_SETTINGS_ATTRIBUTES)?.customer_full_name?.toUpperCase()] ?? CustomerInfoOptions.NONE
  const customerContactIdentifier = (values) => CustomerInfoOptions[_.get(values, CHAT_SETTINGS_ATTRIBUTES)?.customer_contact_identifier?.toUpperCase()] ?? CustomerInfoOptions.NONE
  return (
    <div className="chat-settings">
      <Formik
        initialValues={initialValues}
        validationSchema={ChatSettingsFormSchema}
        onSubmit={(values) => updateSettings(values)}
        enableReinitialize
      >
        {({
          values,
          setFieldValue,
        }) => (
          <>
            <Header
              isButtonDisabled={isUpdatingSettings || _.isEqual(initialValues, values)}
              isSubmitting={isUpdatingSettings}
            />
            <Field
              labelTitle="Company Logo"
              labelSubtitle="Add/edit your company logo"
              formControl={(
                <>
                  <FormikField
                    name={`${COMPANY_DETAILS_ATTRIBUTES}.logo`}
                    value={_.get(values, COMPANY_DETAILS_ATTRIBUTES).logo}
                    component={EditCompanyLogo}
                  />
                </>
              )}
            />
            <Field
              labelTitle="Timezone"
              labelSubtitle="Choose the main timezone of your support"
              formControl={<Timezone />}
            />
            <Field
              labelTitle="Change company name"
              labelSubtitle="Edit your company name"
              formControl={(
                <>
                  <FormikField
                    name={`${COMPANY_ATTRIBUTES_NAME}`}
                    value={_.get(values, COMPANY_ATTRIBUTES_NAME)}
                    inputClasses="chat-settings__field__input"
                    component={InputField}
                    placeholder="Company name"
                  />
                </>
              )}
            />
            <Field
              labelTitle="Customer full name"
              labelSubtitle="Show customer full name"
              formControl={(
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    className="d-flex justify-content-between align-items-center px-4 text-capitalize"
                    style={{
                      width: 275, height: 40, fontSize: 14,
                    }}
                  >
                    {customerFullName(values)?.replace('_', ' ')}
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ width: 275 }}>
                    {Object.values(CustomerInfoOptions)?.map(
                      (x) => (
                        <Dropdown.Item
                          key={x}
                          active={customerFullName(values) === x}
                          onSelect={() => setFieldValue(
                            `${CHAT_SETTINGS_ATTRIBUTES}.customer_full_name`,
                            Object.keys(CustomerInfoOptions).find((key) => CustomerInfoOptions[key] === x).toLowerCase(),
                          )}
                          className="text-capitalize"
                        >
                          {x.replace('_', ' ')}
                        </Dropdown.Item>
                      ),
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            />
            <Field
              labelTitle="Customer contact identifier"
              labelSubtitle="Show customer phone number or email address provided"
              formControl={(
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    className="d-flex justify-content-between align-items-center px-4 text-capitalize"
                    style={{
                      width: 275, height: 40, fontSize: 14,
                    }}
                  >
                    {customerContactIdentifier(values)?.replace('_', ' ')}
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ width: 275 }}>
                    {Object.values(CustomerInfoOptions)?.map(
                      (x) => (
                        <Dropdown.Item
                          key={x}
                          active={customerContactIdentifier(values) === x}
                          onSelect={() => setFieldValue(
                            `${CHAT_SETTINGS_ATTRIBUTES}.customer_contact_identifier`,
                            Object.keys(CustomerInfoOptions).find((key) => CustomerInfoOptions[key] === x).toLowerCase(),
                          )}
                          className="text-capitalize"
                        >
                          {x.replace('_', ' ')}
                        </Dropdown.Item>
                      ),
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            />
            <Line />
            <Field
              labelTitle="Chat Configuration"
              labelSubtitle="Set up and configure one or more entry points."
              formControl={<AddEntryPoint />}
            />
            <Line />
            <ListEntryPoints />
          </>
        )}
      </Formik>
    </div>
  )
}

export default ChatSettings
