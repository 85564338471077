import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { useAuth } from 'providers/AuthProvider/AuthProvider'
import './projectInfoConfirmation.scss'

const ProjectInfoConfirmation = () => {
  const { isAdmin } = useAuth()
  const history = useHistory()

  const onSignUpDoneClickHandler = () => {
    if (isAdmin) {
      history.push('/admin/dashboard')
    } else {
      history.push('/dashboard')
    }
  }

  return (
    <Col md={6} className="card-subtitle bg-white h-100 d-flex align-items-center justify-content-center">
      <Col md={6} className="registred-confirmation">
        <Row className="justify-content-center">
          <Col md={12}>
            <h3 className="fw-bold mb-3">You are all set up!</h3>
            <hr className="mt-4" />
          </Col>
        </Row>
        <Row className="registred-confirmation--section justify-content-center mt-3">
          <Col md={12}>
            <div>
              Thank you for setting up your profile! You are ready to start using Swytchboard.
            </div>
            <br />
            <div>
              Need tips on how to get started?
              <br />
              Head over to our
              <Link to="/dashboard"> Getting Started Guide.</Link>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <Col md={12}>
            <button
              type="submit"
              id="sign-in-button"
              className="btn btn-success d-block w-100 btn-auth fw-bold"
              onClick={onSignUpDoneClickHandler}
            >
              Take me to my Dashboard
            </button>
          </Col>
        </Row>
      </Col>
    </Col>
  )
}

export default ProjectInfoConfirmation
