import React from 'react'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'
import './video-controls-button.scss'
import { isMobile } from 'utils/helpers'

const VideoControlsButton = ({
  name,
  onClick,
  size = isMobile ? 42 : 50,
  style,
  disabled,
}) => (
  <IcomoonReact
    className={`video-controls-button ${isMobile ? 'mobile m-2' : 'mx-3'} ${disabled ? 'disabled' : ''}`}
    onClick={onClick}
    iconSet={iconSet}
    icon={name}
    color={(isMobile && disabled) ? 'white' : 'black'}
    style={{
      padding: 12,
      width: size,
      height: size,
      lineHeight: size,
      ...style,
    }}
  />
)

export default VideoControlsButton
