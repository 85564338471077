import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { ChatThanksConst } from 'routes/ChatSettingsEntryPoint/constants'
import StepField from 'routes/ChatSettingsEntryPoint/components/Field/StepField'
import ToggleButton from 'routes/ChatSettingsEntryPoint/components/Buttons/ToggleButton'
import { detectNewLineInString } from 'utils/helpers'
import { validUrlNotStrict } from 'utils/regex'
import ButtonTextStepField from 'routes/ChatSettingsEntryPoint/components/Field/ButtonTextStepField'

const Editable = ({
  enableThankYou,
  setEnableThankYou,
  thankYouMessage,
  setThankYouMessage,
  enabledThankYouButton,
  setEnableThankYouButton,
  thankYouLabel,
  setThankYouLabel,
  thankYouLink,
  setThankYouLink,
  setModifiedState,
  websiteLinkError,
  setWebsiteLinkError,
  modifiedState,
}) => {
  const handleEnableThanks = () => {
    setEnableThankYou(!enableThankYou)
    setModifiedState(true)
  }

  const handleThankYouMessage = (e) => {
    setThankYouMessage(detectNewLineInString(e.target.value))
    setModifiedState(true)
  }

  const handleThankYouLabel = (e) => {
    setThankYouLabel(e.target.value)
    setModifiedState(true)
  }

  const handleThankYouLink = (e) => {
    setThankYouLink(e.target.value)
    setWebsiteLinkError(e.target.value.match(validUrlNotStrict))
    setModifiedState(true)
  }

  const handleThankYouEnabledChange = () => {
    setModifiedState(true)
    setEnableThankYouButton(!enabledThankYouButton)
  }

  return (
    <>
      <Col className="chat-thanks-editable">
        <StepField
          title={ChatThanksConst.enableThankYou.title}
          subTitle={ChatThanksConst.enableThankYou.subTitle}
          inputClassContainer="toggle-button"
          inputClassContent="toggle-button__content"
        >
          <ToggleButton
            state={enableThankYou}
            onClick={handleEnableThanks}
          />
        </StepField>
        <StepField
          title={ChatThanksConst.thankYouMessage.title}
          subTitle={ChatThanksConst.thankYouMessage.subTitle}
        >
          <Row className="w-100">
            <Col className="chat-editable w-100">
              <textarea
                maxLength="400"
                rows={6}
                value={thankYouMessage || ''}
                onChange={handleThankYouMessage}
              />
            </Col>
          </Row>
        </StepField>
        <ButtonTextStepField
          title={ChatThanksConst.thankYouButton.title}
          subTitle={ChatThanksConst.thankYouButton.subTitle}
        >
          <ToggleButton
            state={enabledThankYouButton}
            onClick={handleThankYouEnabledChange}
            buttonTitleEnabled
          />
        </ButtonTextStepField>
        {enabledThankYouButton ? (
          <>
            <StepField
              title={ChatThanksConst.thankYouLink.title}
              subTitle={ChatThanksConst.thankYouLink.subTitle}
            >
              <input
                type="text"
                placeholder="Enter Website Link"
                className="toggle-buttons__website-link w-100"
                value={thankYouLink}
                onChange={handleThankYouLink}
              />
              {!websiteLinkError && modifiedState && (
              <p className="chat-intro-editable__error">
                Enter a correct website!
              </p>
              )}
            </StepField>
            <StepField
              title={ChatThanksConst.thankYouLabel.title}
              subTitle={ChatThanksConst.thankYouLabel.subTitle}
            >
              <input
                type="text"
                placeholder="Enter Website Name"
                className="chat-editable w-100"
                value={thankYouLabel}
                onChange={handleThankYouLabel}
              />
            </StepField>
          </>
        ) : null}
      </Col>
    </>
  )
}

export default Editable
