import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAuth } from 'providers/AuthProvider/AuthProvider'
import companyActions from 'redux/company/actions'
import Company from 'services/Company'
import { useHistory } from 'react-router-dom'

export default function useSetupPersonalInfo() {
  const { updateUser } = useAuth()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const onClearErrorMessage = () => setErrorMessage('')

  const dispatch = useDispatch()

  const history = useHistory()

  const onPersonalInfoHandler = async ({ name, websiteURL }) => {
    setIsSubmitting(true)
    setErrorMessage('')
    try {
      const {
        user,
        company,
      } = await Company.createCompany(name, websiteURL)
      updateUser(user)
      dispatch(companyActions.getCurrentCompanySuccess(company))
      history.push('/account-setup')
    } catch (e) {
      if (e.errors) {
        setErrorMessage(e.errors[0].title)
      } else {
        setErrorMessage(e.message || e.error || 'Something went wrong, please try again.')
      }
    }
    setIsSubmitting(false)
  }

  const onBackClickHandler = () => history.push('/personal-info')

  return {
    isSubmitting,
    errorMessage,
    onClearErrorMessage,
    onPersonalInfoHandler,
    onBackClickHandler,
  }
}
