import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'
import config from 'utils/config'
import swytchboardLogo from 'assets/images/swytchboard-Y-logo.svg'
import SwytchAvatar from 'components/user/SwytchAvatar'

const Viewable = ({
  color,
  logo1,
  logo2,
  welcomeMessage,
}) => {
  const renderLogo1 = `${logo1 !== null ? `${config.ASSETS_URL}/${logo1}` : swytchboardLogo}`
  const renderLogo2 = `${logo2 !== null ? `${config.ASSETS_URL}/${logo2}` : swytchboardLogo}`

  return (
    <div className="chat-basics-viewable">
      <div className="chat-basics-viewable__header">
        <div className="chat-basics-viewable__header__company-info">
          <div className="d-flex align-items-center">
            <div className="chat-basics-viewable__header__company-info__worker-name">
              Maria
            </div>
          </div>
          <div>
            <img
              className="chat-basics-viewable__header__company-info__logo me-2"
              src={renderLogo1}
              alt="company-logo"
            />
            {logo2 !== null ? (
              <img
                className="chat-basics-viewable__header__company-info__logo me-1"
                src={renderLogo2}
                alt="company-logo2"
              />
            ) : null }
          </div>
        </div>
        <div className="chat-basics-viewable__btn-container">
          <Button
            className="chat-basics-viewable__btn-container__btn chat-basics-viewable__button"
            style={{ backgroundColor: color }}
          >
            Request Video Call
          </Button>
          <Button className="chat-basics-viewable__btn-container__btn chat-basics-viewable__btn-container__btn--danger">
            End Conversation
          </Button>
        </div>
      </div>
      <div className="chat-basics-viewable-chat__messages-container">
        <div className="chat-basics-viewable-chat__message-list">

          {/* Chat message for the worker side */}
          <div className="chat-basics-viewable-chat__message-list__worker">
            <div className="chat-basics-viewable-chat__message-list__worker__avatar">
              <SwytchAvatar name="Maria H" size={30} />
            </div>
            <div className="chat-basics-viewable-chat__message-list__worker-content">
              <div className="chat-basics-viewable-chat__message-list__worker-content__message">
                <span>
                  {welcomeMessage}
                </span>
              </div>
              <div className="chat-basics-viewable-chat__message-list__worker-content__timesnap ">
                <span>
                  1 minute ago
                </span>
              </div>
            </div>
          </div>

          {/* Chat message for the customer side */}
          <div className="chat-basics-viewable-chat__message-list__customer">
            <div className="chat-basics-viewable-chat__message-list__customer-content">
              <div
                className="chat-basics-viewable-chat__message-list__customer-content__message"
                style={{ backgroundColor: color }}
              >
                <span>
                  This is a preview of the incoming message bubble!
                  Feel free to choose a different color from our color picker.
                </span>
              </div>
              <div className="chat-basics-viewable-chat__message-list__customer-content__timesnap ">
                <span>
                  1 minute ago
                </span>
              </div>
            </div>
            <div className="chat-basics-viewable-chat__message-list__customer__avatar">
              <SwytchAvatar name="G C" size={30} />
            </div>
          </div>

        </div>
        <Row className="chat-basics-viewable-chat__input">
          <Col md={12} className="position-relative m-0 p-0">
            <input
              type="text"
              placeholder="Enter your message here"
              className="w-100"
              disabled
            />
            <IcomoonReact
              iconSet={iconSet}
              color="#767676"
              size={16}
              icon="icon-send"
              role="button"
              className="position-absolute ms-n5 mt-3"
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Viewable
