import React from 'react'
import usePublications from 'hooks/usePublications/usePublications'
import usePublicationIsTrackEnabled from 'hooks/usePublicationTrackIsEnabled'
import VideoInfoContainer from 'components/Room/VideoInfoContainer'
import { WORKER_VIDEO_TURNED_OFF } from 'utils/constants'
import { isMobile } from 'utils/helpers'
import ParticipantTracks from './ParticipantTracks'

/**
 * Used by both the consumer (QR flow), and the worker (conversation)
 * In the conversation, this object is the user/worker.
 * In the qr-flow, this object is the customer.
 *
 * @param participant
 * @param videoPriority
 * @param enableScreenShare
 * @param isLocalParticipant
 * @param hideParticipant
 * @returns {JSX.Element}
 * @constructor
 */
const LocalParticipant = ({
  participant,
  videoPriority,
  enableScreenShare,
  isLocalParticipant,
  isThumbnail,
  participantName,
  isNewScreenShare,
  fullScreenRef,
}) => {
  const videoConnectionInfoClassName = `connecting ${isMobile ? 'mobile' : ''}`
  const publications = usePublications(participant)
  const videoPublication = publications.find((p) => p.trackName.includes('camera'))
  const isScreenSharing = publications.find((p) => p.trackName.includes('screen'))
  const isVideoEnabled = usePublicationIsTrackEnabled(videoPublication)
  const remoteVideoDisconnected = (videoPublication && !isVideoEnabled)
  return (
    <>
      {
        (!participantName && !isScreenSharing) && remoteVideoDisconnected && (
        <VideoInfoContainer
          message={WORKER_VIDEO_TURNED_OFF}
          className={videoConnectionInfoClassName}
          displaySpinner={false}
        />
        )
      }
      {
        Boolean(participantName) && remoteVideoDisconnected && (
          <VideoInfoContainer
            message={participantName?.charAt(0)?.toUpperCase()}
            displaySpinner={false}
          />
        )
      }
      {((isThumbnail && isVideoEnabled) || (!isThumbnail && isScreenSharing) || (!isThumbnail && !isScreenSharing && isVideoEnabled))
      && (
      <ParticipantTracks
        participant={participant}
        videoPriority={videoPriority}
        enableScreenShare={enableScreenShare}
        isLocalParticipant={isLocalParticipant}
        isThumbnail={isThumbnail}
        isScreenShareOnly={isNewScreenShare}
        fullScreenRef={fullScreenRef}
      />
      )}
    </>
  )
}

export default LocalParticipant
