import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { ChatIntroConst } from 'routes/ChatSettingsEntryPoint/constants'
import StepField from 'routes/ChatSettingsEntryPoint/components/Field/StepField'
import ToggleButton from 'routes/ChatSettingsEntryPoint/components/Buttons/ToggleButton'
import { detectNewLineInString } from 'utils/helpers'
import ButtonTextStepField from 'routes/ChatSettingsEntryPoint/components/Field/ButtonTextStepField'
import { validUrlNotStrict } from 'utils/regex'

const Editable = ({
  landingMessage,
  setLandingMessage,
  messagingChatEnabled,
  setMessagingChatEnabled,
  videoChatEnabled,
  setVideoChatEnabled,
  websiteLink,
  websiteLinkError,
  setWebsiteLinkError,
  setWebsiteLink,
  callToActionText,
  setCallToActionText,
  awayMessage,
  setAwayMessage,
  awayMessagePreviewEnabled,
  setAwayMessagePreviewEnabled,
  modifiedState,
  setModifiedState,
}) => {
  const handleLandingMessageChange = (e) => {
    setLandingMessage(detectNewLineInString(e.target.value))
    setModifiedState(true)
  }

  const handleVideoChatEnable = () => {
    setVideoChatEnabled(!videoChatEnabled)
    setModifiedState(true)
  }

  const handleMessagingChatEnabled = () => {
    setModifiedState(true)
    setMessagingChatEnabled(!messagingChatEnabled)
  }

  const handleWebsiteLink = (e) => {
    setModifiedState(true)
    setWebsiteLink(e.target.value)
    setWebsiteLinkError(e.target.value.match(validUrlNotStrict))
  }

  const handleCallToActionText = (e) => {
    setModifiedState(true)
    setCallToActionText(e.target.value)
  }

  const handleAwayMessageChange = (e) => {
    setModifiedState(true)
    setAwayMessage(detectNewLineInString(e.target.value))
  }

  const handleAwayMessageEnabledChange = () => {
    setAwayMessagePreviewEnabled(!awayMessagePreviewEnabled)
  }

  return (
    <Col className="chat-intro-editable">
      <StepField
        title={ChatIntroConst.landingMessage.title}
        subTitle={ChatIntroConst.landingMessage.subTitle}
      >
        <Row className="w-100">
          <Col className="chat-editable w-100">
            <textarea
              maxLength="400"
              rows={5}
              value={landingMessage || ''}
              onChange={handleLandingMessageChange}
            />
          </Col>
        </Row>
      </StepField>
      <StepField
        title={ChatIntroConst.videoChatEnabled.title}
        subTitle={ChatIntroConst.videoChatEnabled.subTitle}
        inputClassContainer="toggle-button"
        inputClassContent="toggle-button__content"
      >
        <ToggleButton
          state={videoChatEnabled}
          onClick={handleVideoChatEnable}
        />
      </StepField>
      <StepField
        title={ChatIntroConst.liveChatEnabled.title}
        subTitle={ChatIntroConst.liveChatEnabled.subTitle}
        inputClassContainer="toggle-button"
        inputClassContent="toggle-button__content"
      >
        <ToggleButton
          state={messagingChatEnabled}
          onClick={handleMessagingChatEnabled}
        />
      </StepField>
      <ButtonTextStepField
        title={ChatIntroConst.awayMessage.title}
        subTitle={ChatIntroConst.awayMessage.subTitle}
      >
        <ToggleButton
          state={awayMessagePreviewEnabled}
          onClick={handleAwayMessageEnabledChange}
          buttonTitle="Preview"
          buttonTitleEnabled
        />
      </ButtonTextStepField>
      <Row className="w-100 mb-1">
        <Col className="chat-editable w-100">
          <textarea
            maxLength="400"
            rows={6}
            value={awayMessage || ''}
            onChange={handleAwayMessageChange}
          />
        </Col>
      </Row>
      <StepField
        title={ChatIntroConst.websiteLink.title}
        subTitle={ChatIntroConst.websiteLink.subTitle}
      >
        <input
          type="text"
          placeholder="Enter Website Link"
          className="toggle-buttons__website-link w-100"
          value={websiteLink}
          onChange={handleWebsiteLink}
        />
        {!websiteLinkError && modifiedState && (
        <p className="chat-intro-editable__error">
          Enter a correct website!
        </p>
        )}
      </StepField>
      <StepField
        title={ChatIntroConst.callToActionText.title}
        subTitle={ChatIntroConst.callToActionText.subTitle}
      >
        <input
          type="text"
          placeholder="Enter Website Name"
          className="chat-editable w-100"
          value={callToActionText}
          onChange={handleCallToActionText}
        />
      </StepField>
    </Col>
  )
}

export default Editable
