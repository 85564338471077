import React from 'react'
import usePublications from 'hooks/usePublications/usePublications'
import usePublicationIsTrackEnabled from 'hooks/usePublicationTrackIsEnabled'
import { WORKER_VIDEO_NOT_CONNECTED_MESSAGE, CUSTOMER_VIDEO_NOT_CONNECTED_MESSAGE } from 'utils/constants'
import VideoInfoContainer from 'components/Room/VideoInfoContainer'
import ParticipantTracks from 'components/Room/ParticipantTracks'
import { isMobile } from 'utils/helpers'

/**
 * Used by both the consumer (QR flow), and the worker (conversation)
 * In the conversation, this object is the customer.
 * In the qr-flow, this object is the user/worker.
 *
 * @param participant
 * @param videoConnectionInfoClassName
 * @param isTwoWayEnabled
 * @param worker
 * @returns {JSX.Element}
 * @constructor
 */
const RemoteParticipant = ({
  participant,
  videoConnectionInfoClassName,
  worker = false,
  isThumbnail = false,
}) => {
  const publications = usePublications(participant)
  const videoPublication = publications.find((p) => p.trackName.includes('camera'))
  const isScreenSharing = publications.find((p) => p.trackName.includes('screen'))
  const isVideoEnabled = usePublicationIsTrackEnabled(videoPublication)
  return (
    <>
      {!isVideoEnabled && (
      <VideoInfoContainer
        message={worker ? CUSTOMER_VIDEO_NOT_CONNECTED_MESSAGE : WORKER_VIDEO_NOT_CONNECTED_MESSAGE}
        displaySpinner={false}
        className={`${videoConnectionInfoClassName} ${isMobile ? 'mobile' : ''}`}
      />
      )}
      <ParticipantTracks
        participant={participant}
        displayVideo={isVideoEnabled}
        enableScreenShare={isScreenSharing}
        isWorker={worker}
        isThumbnail={isThumbnail}
      />
    </>
  )
}

export default RemoteParticipant
