import React from 'react'
import { Formik, Field as FormikField } from 'formik'
import _ from 'lodash'
import { Alert } from 'react-bootstrap'
import * as yup from 'yup'
import Header from 'routes/ProfileSettings/Header'
import LoadingButton from 'components/buttons/LoadingButton'
import Field from 'routes/ProfileSettings/Field'
import EditProfilePhoto from 'routes/ProfileSettings/EditProfilePhoto'
import InputField from 'components/form/InputField'
import useUserSettingsForm from 'routes/ProfileSettings/hooks/useUserSettingsForm'
import { onlyCharacters } from 'utils/regex'

const UserSettingsSchema = yup.object()
  .shape({
    id: yup.number(),
    full_name: yup.string()
      .required('Full name cannot be empty')
      .min(3, 'Full name must be at least 3 characters')
      .matches(onlyCharacters, 'Full name must contain only characters'),
  })

const UserSettingsForm = () => {
  const {
    initialValues,
    isUpdatingUserSettings,
    onUpdateUserSettings,
    errorMessage,
  } = useUserSettingsForm()

  return (
    <Formik
      enableReinitialize
      validationSchema={UserSettingsSchema}
      initialValues={initialValues}
      onSubmit={onUpdateUserSettings}
    >
      {({
        values,
        handleSubmit,
        isValid,
        dirty,
      }) => {
        const isSubmitDisabled = isUpdatingUserSettings
          || !(isValid && dirty)
          || _.isEqual(values, initialValues)

        return (
          <>
            <Header
              title="Profile Settings"
              subTitle="Update and edit your profile settings."
              actionButton={(
                <LoadingButton
                  disabled={isSubmitDisabled}
                  loading={isUpdatingUserSettings}
                  onClick={handleSubmit}
                  className="profile-settings__header__button"
                >
                  Save Settings
                </LoadingButton>
              )}
            />
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <Field
              labelTitle="Profile Photo"
              labelSubtitle="Add/edit your profile photo."
              formControl={(
                <>
                  <FormikField
                    name="avatar"
                    value={values.avatar}
                    component={EditProfilePhoto}
                  />
                </>
              )}
            />
            <Field
              labelTitle="Display Name"
              labelSubtitle="The name appears in the chat."
              formControl={(
                <FormikField
                  name="full_name"
                  value={values.full_name}
                  inputClasses="profile-settings__field__input"
                  component={InputField}
                />
              )}
            />
            <Field
              labelTitle="E-Mail"
              labelSubtitle="Your e-mail linked to this account."
              formControl={(
                <InputField
                  inputClasses="profile-settings__field__input"
                  value={values.email}
                  disabled
                />
              )}
            />
          </>
        )
      }}
    </Formik>
  )
}

export default UserSettingsForm
