/* eslint-disable no-plusplus */
import _ from 'lodash'
import { ConversationTypes } from 'utils/constants'
import dayjs from 'dayjs'
import { matchPath } from 'react-router-dom'
import { validUrl } from './regex'

export default function validateEmail(email) {
  // eslint-disable-next-line
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase())
}

export function getRequestType(requestType) {
  if (requestType === ConversationTypes.videoText || requestType === ConversationTypes.video) {
    return 'Video Call'
  } if (requestType === ConversationTypes.text) {
    return 'Messaging'
  }
  return ''
}

export function isLong(value) {
  return /(.{8,})/.test(value)
}

export function isUpperCase(value) {
  return /(?=.*?[A-Z])/.test(value)
}

export function isLowercase(value) {
  return /(?=.*?[a-z])/.test(value)
}

export function isNumber(value) {
  return /(?=(.*[\d]){1,})/.test(value)
}

export function capitalizeFirstLetter(string) {
  return `${string[0].toUpperCase()}${string.slice(1).toLowerCase()}`
}

export function roundToTwo(num) {
  return _.round(num, 1)
}

/**
 * Formats the queue waiting time in formats: 3h 2min or 2min if there are no hours present
 * @param averageTime - Average waiting time in format DD HH:MM:SS.MSS
 * @returns {string} - Returns string in format: {3h 2min} or {3min} - Depending on the hours, minutes and seconds
 */
export function formatQueueWaitingTime(averageTime = '00 03:00:00.024') {
  const hours = Number(averageTime?.substr(3, 2) ?? 0) // Returns 0 if no average time is found
  let minutes = Number(averageTime?.substr(6, 2) ?? 0) // Returns 0 if no average time is found
  const seconds = Number(averageTime?.substr(9, 2) ?? 0) // Returns 0 if no average time is found

  // We display 1 minute average time if there are 0 hours and 0 minutes average time (only seconds)
  if (hours === 0 && minutes === 0) {
    minutes = 1
  } else if (seconds >= 30) {
    minutes += 1
  }

  return `${hours !== 0 ? `${hours}h ` : ''}${minutes !== 0 ? `${minutes} min` : ''}`
}

export const getBase64 = async (file) => new Promise((resolve) => {
  let baseURL = ''
  // Make new FileReader
  const reader = new FileReader()

  // Convert the file to base64 text
  reader.readAsDataURL(file)

  // on reader load somthing...
  reader.onload = () => {
    // Make a fileInfo Object
    baseURL = reader.result
    resolve(baseURL)
  }
})

export function formatSizeUnits(formatedFile) {
  let bytes = formatedFile
  if (bytes >= 1048576) {
    bytes = `${(bytes / 1048576).toFixed(2)} MB`
  } else if (bytes >= 1024) {
    bytes = `${(bytes / 1024).toFixed(2)} KB`
  } else if (bytes > 1) {
    bytes += ' bytes'
  } else if (bytes === 1) {
    bytes += ' byte'
  } else {
    bytes = '0 bytes'
  }
  return bytes
}

export const isMobile = (() => {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
    return false
  }
  return /Mobile/.test(navigator.userAgent) || /SwytchboardApp/.test(navigator.userAgent)
})()

export const isWebViewFromMobileApp = (() => {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
    return false
  }
  return /SwytchboardApp/.test(navigator.userAgent)
})()

// given string 'John Cage', returns initials 'JC'
export function getInitials(fullName = '') {
  const arrayOfStrings = fullName?.trim().split(' ')
  const firstName = arrayOfStrings[0]?.charAt(0)
  const lenArrayOfStrings = arrayOfStrings.length
  const lastName = arrayOfStrings[lenArrayOfStrings - 1 === 0 ? 1 : 0]
    ? arrayOfStrings[lenArrayOfStrings - 1]?.charAt(0) : ''
  return `${firstName}${lastName}`
}

// given string 'John Cage', returns 'John C'
export function getLastNameInitials(fullName = '') {
  const arrayOfStrings = fullName?.trim().split(' ')
  const firstName = arrayOfStrings[0]
  const lenArrayOfStrings = arrayOfStrings.length
  const lastInit = arrayOfStrings[lenArrayOfStrings - 1 === 0 ? 1 : 0]
    ? arrayOfStrings[lenArrayOfStrings - 1]?.charAt(0) : ''
  return `${firstName} ${lastInit}`
}

export function parseURL(url) {
  if (!url.match(/^https?:\/\//i)) {
    // eslint-disable-next-line no-param-reassign
    url = `http://${url}`
  }
  return url
}

// This helper function is used to convert the format
export function dateToLocalFormatedDate(date) {
  return dayjs(date).isValid() ? dayjs(date).local().format('YYYY-MM-DD hh:mm') : ''
}

export function isValidUrl(url) {
  const regex = new RegExp(validUrl)
  return regex.test(url)
}

// This helper is to detect if is there a new line in a string and replace it
export function detectNewLineInString(string) {
  return string.replace(/\\n/g, String.fromCharCode(13, 10))
}

// This helper function is used to convert the format
export function isoDateToJsDate(date) {
  return dayjs(date).format('YYYY-MM-DD')
}

export function playSound(audioLink) {
  const audio = new Audio(audioLink)
  audio?.play().catch((error) => {
    // browsers don't allow autoplay without user interaction like click to play audio, so we catch the error and ignore it
    console.log('Error playing sound', error)
  })
}

export function getConversationId() {
  const id = matchPath(window.location.pathname, {
    path: '/conversation/:chatRequestId',
  })?.params?.chatRequestId

  return id || undefined
}

export function findLastItemInListById(list, itemId) {
  if (!list || list.length === 0) return null
  return list.reverse().find((item) => parseInt(item.id, 10) === parseInt(itemId, 10))
}

export const millisElapsedFromProvidedDate = (date) => dayjs(new Date())
  .utc()
  .diff(dayjs(date).utc(), 'milliseconds')

// Supports Unicode data
export function decodeBase64AsUnicode(base64str) {
  const binString = atob(base64str)
  const uintArray = Uint8Array.from(binString, (m) => m.codePointAt(0))
  return new TextDecoder().decode(uintArray)
}
