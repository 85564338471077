import React, { useEffect, useState } from 'react'
import VideoCallLayout from 'features/qrflow/layouts/VideoCallLayout'
import withProviders from 'hoc/withProviders'
import VideoProvider from 'providers/VideoProvider'
import ChatProvider from 'providers/ChatProvider'
import VideoCall from 'features/qrflow/components/video/VideoCall'
import ChatWindow from 'features/qrflow/components/chat/ChatWindow'
import useRoomState, { RoomStateType } from 'providers/VideoProvider/useRoomState'
import JoinVideoCallDialog from 'features/qrflow/components/dialog/JoinVideoCallDialog'
import useConsumerConversations from 'features/qrflow/hooks/useConsumerConversations'
import { ChatStatuses } from 'utils/constants'

const VideoChatApp = ({
  token,
  roomName,
  conversationSid,
}) => {
  const [hasJoinedCall, setHasJoinedCall] = useState(false)
  const roomState = useRoomState()
  const { state: { status } } = useConsumerConversations()

  useEffect(() => {
    if (roomState === RoomStateType.CONNECTED) {
      setHasJoinedCall(true)
    }
  }, [roomState])

  if (roomState === RoomStateType.DISCONNECTED && status === ChatStatuses.accepted && !hasJoinedCall) {
    return <JoinVideoCallDialog token={token} roomName={roomName} />
  }

  return (
    <VideoCallLayout>
      <VideoCall
        token={token}
        roomName={roomName}
      />
      <ChatWindow
        token={token}
        conversationSid={conversationSid}
        className="qr-chat--half-screen"
      />
    </VideoCallLayout>
  )
}

export default withProviders(VideoProvider, ChatProvider)(VideoChatApp)
