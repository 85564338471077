import React from 'react'
import MessagingLayoutHeader from 'features/qrflow/components/MessagingLayoutHeader'
import useGetEntryPointDetails from 'features/qrflow/hooks/useGetEntryPointDetails'
import { isMobile } from 'utils/helpers'
import poweredBy from 'assets/images/mct/poweredby.svg'

const MessagingLayout = ({ children }) => {
  const {
    data,
  } = useGetEntryPointDetails()

  return (
    <div className={`${isMobile ? 'messaging-layout-container' : 'video-call-desktop-layout'}`}>
      {!isMobile && (
      <div className="video-call-desktop-layout__title">
        <h4>{`Welcome to ${data?.name} live expert chat`}</h4>
      </div>
      )}
      <div className={`${isMobile ? 'messaging-layout' : 'desktop-messaging-layout'}`}>
        <MessagingLayoutHeader />
        {children}
      </div>
      {!isMobile && (
      <div className="video-call-desktop-layout__footer">
        <img src={poweredBy} alt="SwytchBoard" />
      </div>
      )}
    </div>
  )
}

export default MessagingLayout
