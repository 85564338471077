import React from 'react'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'

const VideoInfoContainer = ({
  message = 'Connecting...',
  displayMessage = true,
  className,
  displaySpinner = true,
  spinnerSize = 30,
}) => (
  <div className={className}>
    <div>
      {displaySpinner && <LoadingSpinner spinnerSize={spinnerSize} />}
      {displayMessage && <p className={`${message?.length === 1 ? 'first-letter' : 'ps-3'} mt-2 text-white`}>{message}</p>}
    </div>
  </div>
)
export default VideoInfoContainer
