import React from 'react'
import './signUp.scss'
import CheckValidation from 'components/UI/CheckValidation/CheckValidation'
import {
  isLong, isUpperCase, isLowercase, isNumber,
} from 'utils/helpers'

const SignUpCheckboxes = ({ value }) => (
  <>
    <div className="check-validation">
      <CheckValidation text="At least 8 characters long." isValid={isLong(value)} />
      <CheckValidation text="Contains uppercase letter." isValid={isUpperCase(value)} />
      <CheckValidation text="Contains lowercase letter." isValid={isLowercase(value)} />
      <CheckValidation text="Contains number." isValid={isNumber(value)} />
    </div>
  </>
)

export default SignUpCheckboxes
