/* eslint-disable no-nested-ternary */
import React from 'react'
import { Alert, Button } from 'react-bootstrap'
import { PermissionStatus } from 'utils/constants'

const DesktopNotification = ({
  permission, requestPermission,
}) => {
  const handleRequestPermission = async () => {
    await requestPermission()
  }

  return permission !== PermissionStatus.granted ? (
    <Alert variant="info" className="p-0 mb-3">
      <span className="text-muted fw-light">
        Want to look at something else? We&apos;ll send you a browser notification when we&apos;re ready!
      </span>
      <div className="d-flex align-items-center justify-content-center">
        <Button className="pt-2 pb-2 mt-3" variant="light" type="submit" onClick={handleRequestPermission}>
          Turn on browser notification
        </Button>
      </div>
    </Alert>
  ) : null
}

export default DesktopNotification
