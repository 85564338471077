import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { ChatRatingsConst } from 'routes/ChatSettingsEntryPoint/constants'
import StepField from 'routes/ChatSettingsEntryPoint/components/Field/StepField'
import ToggleButton from 'routes/ChatSettingsEntryPoint/components/Buttons/ToggleButton'
import { detectNewLineInString } from 'utils/helpers'

const Editable = ({
  enabledRatings,
  setEnabledRatings,
  ratingsMessage,
  setRatingsMessage,
  enableDetailedComments,
  setEnableDetailedComments,
  setModifiedState,
}) => {
  const handleEnabledRatings = () => {
    setEnabledRatings(!enabledRatings)
    setModifiedState(true)
  }

  const handleRatingsMessage = (e) => {
    setRatingsMessage(detectNewLineInString(e.target.value))
    setModifiedState(true)
  }

  const handleEnableDetailedComments = () => {
    setEnableDetailedComments(!enableDetailedComments)
    setModifiedState(true)
  }

  return (
    <>
      <Col className="chat-editable">
        <StepField
          title={ChatRatingsConst.enableRatings.title}
          subTitle={ChatRatingsConst.enableRatings.subTitle}
          inputClassContainer="toggle-button"
          inputClassContent="toggle-button__content"
        >
          <ToggleButton
            state={enabledRatings}
            onClick={handleEnabledRatings}
          />
        </StepField>
        <StepField
          title={ChatRatingsConst.ratingsMessage.title}
          subTitle={ChatRatingsConst.ratingsMessage.subTitle}
        >
          <Row className="w-100">
            <Col className="chat-editable w-100">
              <textarea
                maxLength="400"
                value={ratingsMessage}
                onChange={handleRatingsMessage}
                rows={5}
              />
            </Col>
          </Row>
        </StepField>
        <StepField
          title={ChatRatingsConst.enableDetailedComments.title}
          subTitle={ChatRatingsConst.enableDetailedComments.subTitle}
          inputClassContainer="toggle-button"
          inputClassContent="toggle-button__content"
        >
          <ToggleButton
            state={enableDetailedComments}
            onClick={handleEnableDetailedComments}
          />
        </StepField>
      </Col>
    </>
  )
}

export default Editable
