import React from 'react'
import Company from 'services/Company'
import { useToasts } from 'react-toast-notifications'
import TeamActionDialog from 'routes/TeamMembers/components/TeamActionDialog'
import { useMembersContext } from 'routes/TeamMembers/context/TeamMembersContext'

const ResendPendingInvite = ({
  id,
  show,
  onClose,
  email,
}) => {
  const { addToast } = useToasts()
  const { companyId } = useMembersContext()

  const onResendInvitation = async (e) => {
    e.preventDefault()
    try {
      await Company.reInviteMember(companyId, email)

      addToast('Invitation is created!', {
        appearance: 'success',
        autoDismiss: true,
        autoDismissTimeout: 2000,
      })
    } catch (ex) {
      addToast(ex.data.errors[0].title, {
        appearance: 'info',
        autoDismiss: true,
        autoDismissTimeout: 2000,
      })
    }
    onClose()
  }

  return (
    <TeamActionDialog
      show={show}
      onClose={onClose}
      onConfirm={(e) => onResendInvitation(e, id)}
      title="Resend Invitation"
    >
      <p>
        Are you sure you want to resend an invite to
        <strong>
          {' '}
          {email}
          {' '}
        </strong>
        ?
      </p>
    </TeamActionDialog>
  )
}

export default ResendPendingInvite
