import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'
import OptionCard from 'routes/Billing/components/Plan/OptionCard'
import ContactUsModal from './ContactUsModal'

const PlanOptionsDialog = ({
  setDisplayPlanOptionsDialog,
  checkoutPlans,
  isLoading,
  show = true,
  onClose,
  title,
  currentPlanAmount,
}) => {
  const [isSending, setIsSending] = useState(false)

  const formatTitle = (plan) => `Upgrade to ${plan}`
  const formatDescription = (description) => {
    const hours = description.match(/\d+ hours/)
    if (hours) return hours[0]

    return description
  }
  const formatPrice = (price) => `$${price}`
  const formatConfirmText = () => 'Upgrade'

  const filterPlanContent = checkoutPlans.monthly_plans.filter((plan) => plan.amount > currentPlanAmount)
  const planContent = filterPlanContent.length ? filterPlanContent.map((plan) => (
    <OptionCard
      key={plan.id}
      planId={plan.id}
      planName={plan.name}
      title={formatTitle(plan.name)}
      detail={formatDescription(plan.description)}
      price={formatPrice(plan.dollar_amount)}
      confirmText={formatConfirmText()}
      isSending={isSending}
      setIsSending={setIsSending}
      setDisplayPlanOptionsDialog={setDisplayPlanOptionsDialog}
      closeDialog={onClose}
    />
  )) : (
    <ContactUsModal
      title={formatTitle('Enterprise')}
      detail={formatDescription('Custom solutions')}
    />
  )

  return (
    <Modal
      show={show}
      onHide={onClose}
      dialogClassName="billing__plan-options-dialog__main-card"
      contentClassName="worker-dialog"
      centered
    >
      <Modal.Header className="worker-dialog__header-wrapper">
        <div className="worker-dialog__header-content">
          {title}
          <IcomoonReact
            className="worker-dialog__header-content__icon"
            icon="icon-cross"
            iconSet={iconSet}
            onClick={onClose}
          />
        </div>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-around">
        { isLoading
          ? (
            <LoadingSpinner
              spinnerSize={30}
              className="d-flex justify-content-center align-items-center w-100 my-5"
            />
          ) : planContent }
      </Modal.Body>
    </Modal>
  )
}

export default PlanOptionsDialog
