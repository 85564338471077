import React from 'react'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'

const EntryPointButton = ({
  onClick,
  color,
  icon,
  title,
}) => (
  <button
    type="button"
    onClick={onClick}
    className="entry-point__buttons__link"
  >
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={16}
      icon={icon}
      role="button"
      className="me-3"
    />
    <h5>{title}</h5>
  </button>
)

export default EntryPointButton
