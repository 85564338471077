/**
 * @deprecated Since version 4.0.0 Use useConsumerConversation for QRFlow or useActiveConversation for Workers instead.
 */
export default {
  CREATE_CHAT_REQUEST: 'CREATE_CHAT_REQUEST',
  CREATE_CHAT_REQUEST_SUCCESS: 'CREATE_CHAT_REQUEST_SUCCESS',
  CREATE_CHAT_REQUEST_ERROR: 'CREATE_CHAT_REQUEST_ERROR',

  CANCEL_CHAT_REQUEST: 'CANCEL_CHAT_REQUEST',
  CANCEL_CHAT_REQUEST_SUCCESS: 'CANCEL_CHAT_REQUEST_SUCCESS',
  CANCEL_CHAT_REQUEST_ERROR: 'CANCEL_CHAT_REQUEST_ERROR',

  PRESELECT_CHAT_REQUEST: 'PRESELECT_CHAT_REQUEST',

  JOIN_CONVERSATION: 'JOIN_CONVERSATION',
  JOIN_CONVERSATION_SUCCESS: 'JOIN_CONVERSATION_SUCCESS',
  JOIN_CONVERSATION_ERROR: 'JOIN_CONVERSATION_ERROR',

  JOIN_WIDGET_CONVERSATION_SUCCESS: 'JOIN_WIDGET_CONVERSATION_SUCCESS',
  JOIN_WIDGET_CONVERSATION_ERROR: 'JOIN_WIDGET_CONVERSATION_ERROR',

  END_CONVERSATION: 'END_CONVERSATION',
  END_CONVERSATION_SUCCESS: 'END_CONVERSATION_SUCCESS',
  END_CONVERSATION_ERROR: 'END_CONVERSATION_ERROR',

  REQUEST_VIDEO: 'REQUEST_VIDEO',
  REQUEST_VIDEO_SUCCESS: 'REQUEST_VIDEO_SUCCESS',
  REQUEST_VIDEO_ERROR: 'REQUEST_VIDEO_ERROR',

  JOIN_CHAT: 'JOIN_CHAT',

  SUBMIT_RATINGS: 'SUBMIT_RATINGS',
  SUBMIT_RATINGS_SUCCESS: 'SUBMIT_RATINGS_SUCCESS',
  SUBMIT_RATINGS_ERROR: 'SUBMIT_RATINGS_ERROR',

  SET_CONSUMER_TOKEN: 'SET_CONSUMER_TOKEN',

  RESET_CHAT_REQUESTS: 'RESET_CHAT_REQUESTS',
}
