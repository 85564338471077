/* eslint-disable jsx-a11y/media-has-caption */
import React, { useCallback, useEffect, useRef } from 'react'
import { useVideoContext } from 'providers/VideoProvider'
import useMediaPermissions from 'hooks/useMediaPermissions'
import useIsTrackEnabled from 'hooks/useIsTrackEnabled/useIsTrackEnabled'
import { useDispatch } from 'react-redux'
import { updateConsumerConversationTracksPermit } from 'redux/consumerConversations/actions'
import useConsumerConversations from 'features/qrflow/hooks/useConsumerConversations'
import { Col } from 'react-bootstrap'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'
import useDialog from 'hooks/useDialog'
import MediaButton from '../VideoCallButton'
import RequestMediaPermissions from '../dialog/RequestMediaPermissions'
import ToggleCustomerSettingsButton from '../ToggleCustomerSettingsButton'

const ConsumerWebCamPreview = () => {
  const {
    videoTrack,
    audioTrack,
    createNewVideoTrack,
    createNewAudioTrack,
  } = useVideoContext()
  const { isCameraEnabled, isMicrophoneEnabled, arePermissionBlocked } = useMediaPermissions()
  const { displayDialog, onCloseDialog } = useDialog()

  const trackRef = useRef(null)
  const isVideoTrackEnabled = useIsTrackEnabled(videoTrack)
  const isAudioTrackEnabled = useIsTrackEnabled(audioTrack)
  const dispatch = useDispatch()

  const {
    id,
    consumerName,
    settings: {
      customerKey,
      isAudioEnabled,
      isTwoWayVideoEnabled,
    },
  } = useConsumerConversations()

  const requestMediaPermissions = useCallback(() => Promise.all(
    [createNewVideoTrack()
      .then((track) => {
        if (!isTwoWayVideoEnabled) {
          track?.disable?.()
        }
      }).catch((e) => console.warn(e)),
    createNewAudioTrack().then((track) => {
      if (!isAudioEnabled) {
        track?.disable?.()
      }
    }).catch((e) => console.warn(e))],
  ), [createNewAudioTrack, createNewVideoTrack, isAudioEnabled, isTwoWayVideoEnabled])

  const handleToggleVideo = useCallback(() => {
    dispatch(updateConsumerConversationTracksPermit(id, customerKey, { isTwoWayVideoEnabled: !isVideoTrackEnabled }))

    if (videoTrack.isEnabled) {
      return videoTrack.disable()
    }
    return videoTrack.enable()
  }, [customerKey, dispatch, id, isVideoTrackEnabled, videoTrack])

  const handleToggleAudio = useCallback(() => {
    dispatch(updateConsumerConversationTracksPermit(id, customerKey, { isAudioEnabled: !isAudioTrackEnabled }))

    if (audioTrack.isEnabled) {
      return audioTrack.disable()
    }
    return audioTrack.enable()
  }, [audioTrack, customerKey, dispatch, id, isAudioTrackEnabled])

  useEffect(() => {
    const asyncWrapper = async () => {
      if (![isMicrophoneEnabled, isCameraEnabled].includes(false)) {
        return requestMediaPermissions()
      }
      if ([videoTrack?.isStopped, audioTrack?.isStopped].includes(true)) {
        return Promise.all([audioTrack?.restart(), videoTrack?.restart()])
      }
      return undefined
    }
    asyncWrapper()
  }, [audioTrack, isCameraEnabled, isMicrophoneEnabled, requestMediaPermissions, videoTrack])

  useEffect(() => {
    if (videoTrack) {
      videoTrack.attach(trackRef.current)
    }
  }, [videoTrack, isVideoTrackEnabled])

  if (!(isCameraEnabled && isMicrophoneEnabled)) {
    return (
      <div className="video">
        <div className="d-flex row bg-light h-100 w-100 p-1 rounded-sm justify-content-center camera__disabled">
          <IcomoonReact
            iconSet={iconSet}
            size={40}
            icon="warning-icon"
            color="orange"
            className="align-self-center py-2"
          />
          <p className="text-center fs-6 py-2 fw-bold py-2 m-0 p-0 ">
            {arePermissionBlocked ? 'Media permissions are blocked, please unblock them from browser settings' : 'Swytchboard needs your permission to enable Camera and Microphone.'}
          </p>
          <p className="text-center fs-6 py-2 fw-lighter p-0 m-0">
            <a className="primary cursor-pointer" onClick={requestMediaPermissions}>Click here</a>
          &nbsp;or go to browser&apos;s settings and enable them.
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className="video h-100 w-100">
      <RequestMediaPermissions
        show={displayDialog}
        onClose={onCloseDialog}
        isCameraEnabled={isCameraEnabled}
        isMicrophoneEnabled={isMicrophoneEnabled}
        showSettings
      />
      {isVideoTrackEnabled
        ? (<video playsInline ref={trackRef} />)
        : (
          <div className="d-flex bg-black h-100 w-100 rounded-sm justify-content-center align-items-center camera__disabled">
            <h1 className="m-0 p-0 text-white display-2">{consumerName?.charAt?.(0)}</h1>
          </div>
        )}
      <div className="position-absolute h-100 w-100 bottom-0">
        <div className="d-flex h-50 justify-content-end align-items-start px-3">
          <ToggleCustomerSettingsButton
            className="qr-video__buttons__btn webcam_preview cursor-pointer"
          />
        </div>
        <Col className="d-flex h-50 justify-content-center align-items-end gap-3 bottom-0 p-3">
          <MediaButton
            className={`qr-video__buttons__btn toggle-camera-button webcam_preview ${isVideoTrackEnabled ? '' : 'inactive'}`}
            icon={isVideoTrackEnabled ? 'icon-video-call' : 'icon-video-call-end'}
            color={!isVideoTrackEnabled && 'white'}
            onClick={handleToggleVideo}
          />
          <MediaButton
            className={`qr-video__buttons__btn webcam_preview ${isAudioTrackEnabled ? '' : 'inactive'}`}
            icon={isAudioTrackEnabled ? 'icon-voice' : 'icon-mute'}
            color={!isAudioTrackEnabled && 'white'}
            onClick={handleToggleAudio}
          />
        </Col>
      </div>
    </div>
  )
}
export default ConsumerWebCamPreview
