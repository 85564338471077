import React, { useEffect, useRef } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Field, Formik, useFormikContext } from 'formik'
import * as yup from 'yup'
import Select from 'react-select'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import useDebounce from 'hooks/useDebounce'
import InputField from 'components/form/InputField'
import DateRangePicker from 'components/form/DateRangePicker'
import ConversationFilter from 'routes/Conversations/components/ConversationFilter'
import useCompanyWorkers from 'routes/Conversations/hooks/useCompanyWorkers'
import RatingsFilter from 'routes/Conversations/components/RatingsFilter'
import { useSelector } from 'react-redux'

dayjs.extend(utc)

const ConversationFilterValidationSchema = yup.object()
  .shape({
    worker: yup.object().shape({
      id: yup.number(),
      full_name: yup.string(),
    }),
    consumer_email: yup.string(),
    consumer_name: yup.string(),
    start_date: yup.date(),
    end_date: yup.date(),
  })

const Filters = ({
  setFilters,
  initialValues,
}) => {
  const companyId = useSelector((state) => state.company?.data?.id)
  const formRef = useRef()

  const {
    workers,
    isLoading: isLoadingWorkers,
  } = useCompanyWorkers(companyId)

  const onDateRangeChange = ([startDate, endDate]) => {
    const setFieldValue = formRef.current?.setFieldValue

    // We avoid passing null values to dayjs library to avoid it from crashing

    let isoStringOrEmptyString = startDate
      ? dayjs(startDate)
        .utc(true)
        .toISOString()
      : ''

    setFieldValue('start_date', isoStringOrEmptyString)

    isoStringOrEmptyString = endDate
      ? dayjs(endDate)
        .utc(true)
        .toISOString()
      : ''

    setFieldValue('end_date', isoStringOrEmptyString)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ConversationFilterValidationSchema}
      enableReinitialize
      innerRef={formRef}
    >
      {({
        values,
        setFieldValue,
      }) => (
        <DebounceFilters setFilters={setFilters}>
          <Col md={12} className="m-0 mb-4 p-0">
            <Row className="m-0 p-0">
              <Col md={3} className="p-0">
                <ConversationFilter
                  label="Worker"
                  field={(
                    <Field
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={workers}
                      isLoading={isLoadingWorkers}
                      placeholder="Select a worker"
                      name="user"
                      component={Select}
                      isClearable
                      value={values.worker ?? initialValues.worker}
                      getOptionLabel={(worker) => worker.full_name}
                      getOptionValue={(worker) => worker.id}
                      onChange={(worker) => setFieldValue('worker', worker)}
                    />
                  )}
                />
              </Col>
              <Col md={1} />
              <Col md={3} className="p-0">
                <ConversationFilter
                  label="Date range"
                  field={(
                    <Field
                      component={DateRangePicker}
                      autoComplete="off"
                      onChange={onDateRangeChange}
                      placeholder="Select a date range"
                      maxDate={new Date()}
                    />
                  )}
                />
              </Col>
              <Col md={1} />
              <Col md={3} className="p-0">
                <ConversationFilter
                  label="Customer Name/E-mail"
                  field={(
                    <Field
                      name="consumer_name_or_email"
                      placeholder="Enter customer name or e-mail"
                      component={InputField}
                    />
                  )}
                />
              </Col>
              <Col md={1} />
            </Row>
            <Row className="m-0 p-0">
              <Col md={3} className="p-0">
                <ConversationFilter
                  label="Helpfulness"
                  field={(
                    <Field
                      name="helpfulness"
                      component={RatingsFilter}
                    />
                  )}
                />
              </Col>
              <Col md={1} />
              <Col md={3} className="p-0">
                <ConversationFilter
                  label="Knowledge"
                  field={(
                    <Field
                      name="knowledge"
                      component={RatingsFilter}
                    />
                  )}
                />
              </Col>
              <Col md={1} />
              <Col md={3} className="p-0">
                <ConversationFilter
                  label="Professional"
                  field={(
                    <Field
                      name="professional"
                      component={RatingsFilter}
                    />
                  )}
                />
              </Col>
            </Row>
          </Col>
        </DebounceFilters>
      )}
    </Formik>
  )
}

const DebounceFilters = ({ children, setFilters }) => {
  const { values: filters } = useFormikContext()

  const debouncedFilters = useDebounce(filters, 750)

  // We change the filter values in the parent component to re-fetch the conversations based on the new filters
  useEffect(() => {
    if (debouncedFilters) {
      setFilters(debouncedFilters)
    }
  }, [debouncedFilters, setFilters])

  return children
}

export default Filters
