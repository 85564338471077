import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import OnBoardingLayout from 'layouts/OnBoardingLayout'
import Loader from 'components/loader/Loader'
import { useAuth } from 'providers/AuthProvider/AuthProvider'

const Layout = ({
  children,
  displayLoader = false,
}) => {
  const { logout } = useAuth()

  return (
    <OnBoardingLayout>
      <Col md={6} className="account-setup bg-white text-justify">
        <Row className="m-0 p-0 h-100 justify-content-center align-items-center">
          <Col md={8} className="position-relative m-0 p-0">
            {displayLoader && (
              <div className="loader-spinner m-0">
                <Loader />
              </div>
            )}
            <div className="account-setup__header">
              <h3 className="account-setup__header__title">
                Account setup
              </h3>
              <Link
                className="account-setup__header__log-out"
                onClick={logout}
                to="/"
              >
                Log Out
              </Link>
            </div>
            <hr className="my-4" />
            <div>
              <p className="account-setup__title">
                Are you interested in?
              </p>
              {children}
            </div>
          </Col>
        </Row>
      </Col>
    </OnBoardingLayout>
  )
}

export default Layout
