import { ActionState } from 'utils/constants'
import constants from './constants'

const initialState = {
  data: null,
  updateUserSettings: ActionState.Initial,
  changePassword: ActionState.Initial,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_USER:
      return {
        ...state,
        data: action.payload,
      }
    case constants.UPDATE_USER:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
      }
    case constants.RESET_USER:
      return initialState

    case constants.UPDATE_USER_SETTINGS:
      return {
        ...state,
        updateUserSettings: ActionState.InProgress,
      }
    case constants.UPDATE_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        updateUserSettings: ActionState.Completed,
      }
    case constants.UPDATE_USER_SETTINGS_ERROR:
      return {
        ...state,
        updateUserSettings: {
          [ActionState.Error]: action.payload,
        },
      }

    case constants.CHANGE_PASSWORD:
      return {
        ...state,
        changePassword: ActionState.InProgress,
      }
    case constants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: ActionState.Completed,
      }
    case constants.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePassword: {
          [ActionState.Error]: action.payload,
        },
      }

    case constants.CLEAR_CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePassword: ActionState.Initial,
      }

    default:
      return state
  }
}

export default reducer
