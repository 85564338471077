import React from 'react'
import useGetEntryPointDetails from 'features/qrflow/hooks/useGetEntryPointDetails'
import { isMobile } from 'utils/helpers'
import poweredBy from 'assets/images/mct/poweredby.svg'

const VideoCallLayout = ({ children }) => {
  const {
    data,
  } = useGetEntryPointDetails()

  return (
    <div className={`${isMobile ? 'video-call-layout' : 'video-call-desktop-layout'}`}>
      {!isMobile && (
        <div className="video-call-desktop-layout__title">
          <h4>{`Welcome to ${data?.name} live expert chat`}</h4>
        </div>
      )}
      <div className={`${isMobile ? 'video-call-layout' : 'video-call-desktop-layout__content'}`}>
        {children}
      </div>
      {!isMobile && (
      <div className="video-call-desktop-layout__footer">
        <img src={poweredBy} alt="SwytchBoard" />
      </div>
      )}
    </div>
  )
}

export default VideoCallLayout
