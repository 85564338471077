import React from 'react'
import { connect, getIn } from 'formik'

const ErrorMessage = ({
  className = '',
  ...props
}) => {
  // All FormikProps available on props.formik!
  const error = getIn(props.formik.errors, props.name)
  const touch = getIn(props.formik.touched, props.name)
  const errorMessage = touch && error ? error : null

  if (errorMessage) {
    return <div className={`errorMsg ${className}`}>{touch && error ? error : null}</div>
  }
  return undefined
}

export default connect(ErrorMessage)
