import React from 'react'
import { Button } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'
import { useHistory } from 'react-router-dom'
import useDialog from 'hooks/useDialog'
import Billing from 'services/Billing'
import ConfirmUpgradeDialog from './ConfirmUpgradeDialog'

const OptionCard = ({
  title = 'Add Title',
  detail = 'Add detail',
  price = 'Add Cost',
  confirmText = 'Confirm',
  planId,
  planName,
  isSending,
  setIsSending,
  closeDialog,
}) => {
  const { addToast } = useToasts()

  const {
    onOpenDialog: openConfirmUpgradeDialog,
    onCloseDialog: closeConfirmUpgradeDialog,
    displayDialog: displayConfirmUpgradeDialog,
  } = useDialog()

  const history = useHistory()

  const updateSubscriptionPlan = async () => {
    if (isSending) return
    setIsSending(true)

    try {
      const res = await Billing.updatePlan(planId.toString())
      if (res.error || res?.status >= 400) {
        throw new Error(JSON.stringify({ message: res.message, error: res.error, status: res.status }))
      }
      setIsSending(false)
      addToast('Plan successfully changed', {
        appearance: 'success',
        autoDismiss: true,
        autoDismissTimeout: 2000,
      })
      setTimeout(() => {
        history.go(0)
      }, 3000)
    } catch (e) {
      setIsSending(false)
      const errorMessage = `Update failed: ${e.message || e.error || 'Something went wrong, please try again!'}`
      addToast(errorMessage, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 4000,
      })
    }
    closeConfirmUpgradeDialog()
    closeDialog()
  }

  return (
    <div className="billing__plan-options-dialog__option shadow rounded-1 p-4 border text-center">
      <div className="mb-5 text-2xl fw-bold">{title}</div>
      <div className="mb-5 text-xl">{detail}</div>
      <div className="mb-5 text-3xl fw-bold">{price}</div>
      <Button
        className="btn-success d-block w-100 rounded-xl text-xl fw-bold"
        disabled={isSending}
        onClick={openConfirmUpgradeDialog}
      >
        { confirmText }
      </Button>
      <ConfirmUpgradeDialog
        show={displayConfirmUpgradeDialog}
        onClose={closeConfirmUpgradeDialog}
        onConfirm={updateSubscriptionPlan}
        plan={planName}
        disabled={isSending}
        loading={isSending}
      />
    </div>
  )
}

export default OptionCard
