import { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

const useQuery = () => {
  const [queries, setQueries] = useState({})
  const { search } = useLocation()

  useMemo(() => new URLSearchParams(search)
    ?.forEach((value, key) => setQueries((prev) => ({ ...prev, [key]: value }))), [search])

  return queries
}

export default useQuery
