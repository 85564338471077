import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Client } from '@twilio/conversations'
import ErrorMessages from './error-messages'

const ChatContext = createContext(null)

const ChatProvider = ({ children, onError }) => {
  const [loadingMessages, setLoadingMessages] = useState(true)
  const [conversation, setConversation] = useState(null)
  const [chatClient, setChatClient] = useState(null)
  const [messages, setMessages] = useState([])
  // Map containing all participants in a conversation. Map<Key, Value>: Map<participantSid, participant>
  // Every participant has an attributes object which contains the full_name property
  const [participants, setParticipants] = useState(new Map())

  const getFullNameByParticipantSid = useCallback((sid) => participants.get(sid)?.attributes?.full_name,
    [participants])

  const getAvatarByParticipantSid = useCallback((sid) => participants.get(sid)?.attributes?.avatar,
    [participants])

  const connectToChat = useCallback(async (token, conversationSid) => {
    try {
      // https://sdk.twilio.com/js/conversations/releases/2.2.0/docs/#instantiating-and-using
      const newChatClient = new Client(token)
      newChatClient.on('initialized', async () => {
        setChatClient(newChatClient)
        const newConversation = await newChatClient.getConversationBySid(conversationSid)
          .catch(() => {
            throw new Error(ErrorMessages.CONVERSATION_NOT_FOUND)
          })
        await newConversation.setAllMessagesRead()
        setConversation(newConversation)
      })
      newChatClient.on('initFailed', ({ error }) => {
        onError(error)
      })
    } catch (e) {
      onError(e)
    }
  }, [onError])

  useEffect(() => {
    if (conversation) {
      (async () => {
        const remoteParticipants = await conversation.getParticipants()
        // Convert remoteParticipants array to a Map
        setParticipants(new Map((remoteParticipants).map((participant) => [participant.sid, participant])))
      })()
    }
  }, [conversation])

  useEffect(() => {
    if (conversation && participants.size > 0) {
      const onMessageAdded = (message) => {
        setMessages((prevMessages) => [...prevMessages, message])
      }

      (async () => {
        setLoadingMessages(true)
        const { items: remoteMessages } = await conversation.getMessages()

        setMessages(remoteMessages)
        setLoadingMessages(false)
      })()

      conversation.on('messageAdded', onMessageAdded)
      return () => {
        conversation.off('messageAdded', onMessageAdded)
      }
    }
    return undefined
  }, [conversation, participants])

  return (
    <ChatContext.Provider
      value={{
        connectToChat,
        messages,
        conversation,
        chatClient,
        loadingMessages,
        setMessages,
        participants,
        getFullNameByParticipantSid,
        getAvatarByParticipantSid,
      }}
    >
      {children}
    </ChatContext.Provider>
  )
}

export const useChatContext = () => {
  const chatContext = useContext(ChatContext)
  if (!chatContext) {
    throw new Error('useChatContext must be used within a ChatProvider')
  }
  return chatContext
}

export default ChatProvider
