import constants from 'redux/chatRequest/constants'
import getActiveChatRequestId from 'features/qrflow/utils/getActiveChatRequestId'
import { ChatStatuses } from 'utils/constants'

const initialState = {
  preselectedRequest: null,
  formData: {},
  // We store the active chat request id, and all its data under the chat request id key
  // e.g. For an active chat request with an id: 123
  // - All the routes for the chat-requests are: /123/queue, /123/messaging, /123/rating, etc.
  // - All the data for the active conversation is stored in redux under that key: chatRequest[123]: {
  //    createRequest: null, // null | loading | {error}
  //    cancelRequest: null, // null | loading | {error}
  //    requestVideo: null, // null | 'requested' | 'loading' | {error}
  //    endConversation: null, // null | 'loading' | {error}
  //    activeConversation: null, // null | 'loading' | {error}
  //    submitRatings: null, // null | loading | 'success' | {error}
  //    workerName: null,
  //    data: { id, intro_message, request_type, status, created_at, consumer_token, consumer,
  //    company, company_service, chat_conversation }
  // }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_CHAT_REQUEST: {
      const formData = action.payload
      return {
        ...state,
        createRequest: 'loading',
        formData,
      }
    }
    case constants.CREATE_CHAT_REQUEST_SUCCESS: {
      const chatRequestData = action.payload

      return {
        ...state,
        [chatRequestData.id]: {
          data: chatRequestData,
          createRequest: null,
        },
        createRequest: null,
      }
    }
    case constants.CREATE_CHAT_REQUEST_ERROR:
      return {
        ...state,
        createRequest: {
          error: action.payload,
        },
      }
    case constants.CANCEL_CHAT_REQUEST: {
      const activeChatRequestId = getActiveChatRequestId()

      return {
        ...state,
        [activeChatRequestId]: {
          ...state[activeChatRequestId],
          cancelRequest: 'loading',
        },
      }
    }
    case constants.CANCEL_CHAT_REQUEST_SUCCESS: {
      const activeChatRequestId = getActiveChatRequestId()

      return {
        ...state,
        [activeChatRequestId]: {
          ...state[activeChatRequestId],
          data: action.payload,
          cancelRequest: null,
        },
      }
    }

    case constants.CANCEL_CHAT_REQUEST_ERROR: {
      const activeChatRequestId = getActiveChatRequestId()

      return {
        ...state,
        [activeChatRequestId]: {
          ...state[activeChatRequestId],
          cancelRequest: {
            error: action.payload,
          },
        },
      }
    }
    case constants.PRESELECT_CHAT_REQUEST: {
      const chatRequest = action.payload
      return {
        ...state,
        preselectChatRequest: chatRequest?.id,
      }
    }

    case constants.REQUEST_VIDEO: {
      const activeChatRequestId = getActiveChatRequestId()

      return {
        ...state,
        [activeChatRequestId]: {
          ...state[activeChatRequestId],
          requestVideo: 'loading',
        },
      }
    }

    case constants.REQUEST_VIDEO_SUCCESS: {
      const activeChatRequestId = getActiveChatRequestId()

      return {
        ...state,
        [activeChatRequestId]: {
          ...state[activeChatRequestId],
          requestVideo: 'requested',
        },
      }
    }

    case constants.REQUEST_VIDEO_ERROR: {
      const activeChatRequestId = getActiveChatRequestId()

      return {
        ...state,
        [activeChatRequestId]: {
          ...state[activeChatRequestId],
          requestVideo: {
            error: action.payload,
          },
        },
      }
    }

    case constants.JOIN_CHAT: {
      const activeChatRequestId = getActiveChatRequestId()

      return {
        ...state,
        [activeChatRequestId]: {
          ...state[activeChatRequestId],
          data: {
            ...state[activeChatRequestId].data,
            request_type: action.payload.request_type,
            status: ChatStatuses.accepted,
          },
          workerName: action.payload.data.answered_by_name,
        },
      }
    }

    case constants.JOIN_CONVERSATION: {
      const activeChatRequestId = getActiveChatRequestId()

      return {
        ...state,
        [activeChatRequestId]: {
          ...state[activeChatRequestId],
          activeConversation: 'loading',
        },
      }
    }

    case constants.JOIN_CONVERSATION_SUCCESS: {
      const activeChatRequestId = getActiveChatRequestId()

      return {
        ...state,
        [activeChatRequestId]: {
          ...state[activeChatRequestId],
          activeConversation: action.payload,
        },
      }
    }

    case constants.JOIN_CONVERSATION_ERROR: {
      const activeChatRequestId = getActiveChatRequestId()

      return {
        ...state,
        [activeChatRequestId]: {
          ...state[activeChatRequestId],
          activeConversation: {
            error: action.payload,
          },
        },
      }
    }

    case constants.JOIN_WIDGET_CONVERSATION_SUCCESS: {
      return {
        ...state,
        [action.payload.activeChatRequestId]: {
          ...state[action.payload.activeChatRequestId],
          activeConversation: action.payload.conversation,
        },
      }
    }

    case constants.JOIN_WIDGET_CONVERSATION_ERROR: {
      return {
        ...state,
        [action.payload.activeChatRequestId]: {
          ...state[action.payload.activeChatRequestId],
          activeConversation: {
            error: action.payload,
          },
        },
      }
    }

    case constants.END_CONVERSATION: {
      const activeChatRequestId = getActiveChatRequestId()

      return {
        ...state,
        [activeChatRequestId]: {
          ...state[activeChatRequestId],
          endConversation: 'loading',
        },
      }
    }

    // FIXME: it's never used!!!
    case constants.END_CONVERSATION_SUCCESS: {
      const activeChatRequestId = getActiveChatRequestId()

      return {
        ...state,
        [activeChatRequestId]: {
          ...state[activeChatRequestId],
          data: {
            ...state[activeChatRequestId].data,
            status: ChatStatuses.completed,
            rate_token: action.payload,
          },
          endConversation: null,
        },
      }
    }

    case constants.END_CONVERSATION_ERROR: {
      const activeChatRequestId = getActiveChatRequestId()

      return {
        ...state,
        [activeChatRequestId]: {
          ...state[activeChatRequestId],
          endConversation: {
            error: action.payload,
          },
        },
      }
    }

    case constants.SUBMIT_RATINGS: {
      const activeChatRequestId = getActiveChatRequestId()

      return {
        ...state,
        [activeChatRequestId]: {
          ...state[activeChatRequestId],
          submitRatings: 'loading',
        },
      }
    }

    case constants.SUBMIT_RATINGS_SUCCESS: {
      const activeChatRequestId = getActiveChatRequestId()

      return {
        ...state,
        [activeChatRequestId]: {
          ...state[activeChatRequestId],
          submitRatings: 'success',
        },
      }
    }

    case constants.SUBMIT_RATINGS_ERROR: {
      const activeChatRequestId = getActiveChatRequestId()

      return {
        ...state,
        [activeChatRequestId]: {
          ...state[activeChatRequestId],
          submitRatings: {
            error: action.payload,
          },
        },
      }
    }

    case constants.SET_CONSUMER_TOKEN: {
      return {
        ...state,
        [action.payload.chatRequestId]: {
          ...state[action.payload.chatRequestId],
          data: {
            ...state[action.payload.chatRequestId].data,
            consumer_token: action.payload.token,
          },
        },
      }
    }

    case constants.RESET_CHAT_REQUESTS:
      return initialState

    default:
      return state
  }
}
/**
 * @deprecated Since version 4.0.0 Use useConsumerConversation for QRFlow or useActiveConversation for Workers instead.
 */
export default reducer
