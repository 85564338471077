/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'
import Image from 'services/Image'
import UserService from 'services/User'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import utc from 'dayjs/plugin/utc'
import LoadingButton from 'components/buttons/LoadingButton'
import useTogglePunchInState from 'features/work-logs/useTogglePunchInState'
import useIsWorkerPunchedIn from 'features/work-logs/useIsWorkerPunchedIn'
import { SWYTCHBOARD_USER_STATS } from 'utils/constants'
import useLocalStorage from 'hooks/useLocalStorage'
import TimeElapsed from 'features/work-logs/TimeElapsed'
import DateRangePicker from 'components/form/DateRangePicker'
import { useBillingContext } from 'providers/BillingProvider/index'
import { millisElapsedFromProvidedDate, isMobile } from 'utils/helpers'
import Meet from 'services/Meet'
import { useHistory, generatePath } from 'react-router-dom'
import { setActiveScreenShare } from 'redux/activeConversations/actions'
import { useToasts } from 'react-toast-notifications'
import UserStatsCarousel from '../UserStatsCarousel/UserStatsCarousel'

dayjs.extend(weekday)
dayjs.extend(utc)

const UserStats = () => {
  const {
    subscriptionPlan,
  } = useBillingContext()
  const [userStats, setUserStats] = useState(null)
  const [hideDatePicker, setHideDatePicker] = useState(false)
  const { full_name: fullName, avatar } = useSelector((state) => state.user.data) || {}
  const { product_capability: productCapability } = useSelector((state) => state.company.data) || {}
  const enableScreenShare = productCapability?.enable_direct_screen_share
  const [minimize, setMinimize] = useLocalStorage(SWYTCHBOARD_USER_STATS, false)
  const profilePicture = Image.get(avatar)
  const history = useHistory()
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const dispatch = useDispatch()
  const [isScreenShareLoading, setIsScreenShareLoading] = useState(false)
  const { addToast } = useToasts()

  // This helper function is used to convert the format
  function isoDateToJsDate(date) {
    return dayjs(date).format('YYYY-MM-DD')
  }

  const getUserStats = async (start, end) => {
    try {
      const userStatsData = await UserService.userStats([start], [end])
      setUserStats(userStatsData)
    } catch (error) {
      // ignore error
    }
  }

  useEffect(() => {
    const firstMondayOfWeek = dayjs(new Date()).weekday(1).toDate()
    const todayDate = dayjs(new Date()).toDate()
    getUserStats(firstMondayOfWeek, todayDate)
  }, [])

  const onDateRangeChange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }

  const onRequestScreenSharing = async () => {
    setIsScreenShareLoading(true)
    const {
      id: meetId,
      meeting_access_key: meetingAccessKey,
      meeting_password: meetingPassword,
      meeting_link: meetingLink,
      error,
    } = await Meet.newScreenShare()

    if (error) {
      addToast(error ?? 'Sorry we cannot start new screen-share', {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 5000,
      })
      setIsScreenShareLoading(false)
      return
    }

    const { token, room_name: roomName, room_sid: roomSid } = await Meet.join({ id: meetId, meetingAccessKey })

    dispatch(setActiveScreenShare({
      id: meetId,
      meetingPassword,
      meetingAccessKey,
      meetingLink,
      room: {
        token,
        name: roomName,
        sid: roomSid,
      },
    }))
    setIsScreenShareLoading(false)
    history.push(generatePath('/screenshare/current/:meetingLink', { meetingLink }))
  }

  const onHandleClick = () => {
    setMinimize(false)
    const isoStartDate = isoDateToJsDate(startDate)
    const isoEndDate = isoDateToJsDate(endDate)
    setHideDatePicker(true)

    if (endDate !== null) {
      getUserStats(isoStartDate, isoEndDate)
    }
  }

  const renderProfileImage = !avatar ? (
    <IcomoonReact
      iconSet={iconSet}
      size={80}
      className="mt-2"
      icon="avatar"
      color="#0F0641"
    />
  ) : (
    <img
      src={profilePicture}
      alt="avatar"
    />
  )

  const {
    onTogglePunchInState,
    isTogglingPunchInState,
  } = useTogglePunchInState()

  const {
    punchInStartDate,
    isLoadingPunchedInState,
    isPunchedIn,
  } = useIsWorkerPunchedIn()

  const millisElapsedFromPunchInDate = millisElapsedFromProvidedDate(punchInStartDate)

  return (
    <div className="user-stats p-2">
      <Row className="mx-3">
        {!isMobile && (
          <Col md={1} className="user-stats__avatar">
            {renderProfileImage}
          </Col>
        )}
        <Col>
          <Row className="m-0 p-0 align-items-start w-100">
            <Col md={3} sm="auto" className="align-items-center my-1">
              <h2 className="fw-bolder">
                {
                      isMobile && isPunchedIn && (
                      <TimeElapsed
                            // the calculated milliseconds sometimes result in a negative value which result in --:--:-- timer
                        initialTimeInMilliseconds={
                              millisElapsedFromPunchInDate < 0
                                ? 0
                                : millisElapsedFromPunchInDate
                            }
                      />
                      )
                    }
                { isMobile && isPunchedIn ? '' : `Welcome ${!isMobile ? fullName : 'back!'}`}
              </h2>
            </Col>
            <Col className="d-flex align-items-center my-1">
              <LoadingButton
                onClick={onTogglePunchInState}
                className={`punch-in-out-btn punch-in-out-btn ${!isMobile ? 'me-4' : 'm-0 p-0'}`}
                loading={isLoadingPunchedInState || isTogglingPunchInState}
                disabled={!subscriptionPlan.status.isActive() || isLoadingPunchedInState || isTogglingPunchInState}
              >
                {
                    isPunchedIn
                      ? 'PUNCH OUT'
                      : 'PUNCH IN'
                  }
              </LoadingButton>
              {
                 !isMobile && isPunchedIn && (
                 <TimeElapsed
                      // the calculated milliseconds sometimes result in a negative value which result in --:--:-- timer
                   initialTimeInMilliseconds={
                        millisElapsedFromPunchInDate < 0
                          ? 0
                          : millisElapsedFromPunchInDate
                      }
                 />
                 )
                }
            </Col>
            {!isMobile && enableScreenShare && (
            <Col className="d-flex justify-content-end my-1" sm={12} md="auto">
              <LoadingButton
                onClick={onRequestScreenSharing}
                className="punch-in-out-btn align-item-center justify-content-center"
                loading={isLoadingPunchedInState || isTogglingPunchInState || isScreenShareLoading}
                disabled={!subscriptionPlan.status.isActive() || isLoadingPunchedInState || isTogglingPunchInState}
                style={{ width: '100%', top: 10 }}
              >
                <IcomoonReact iconSet={iconSet} size={17} icon="icon-share-screen" />
                &nbsp;&nbsp;&nbsp;New Screen Share
              </LoadingButton>
            </Col>
            )}
            {!isMobile
              && (
              <Col md={12} className="d-flex pt-4">
                <p className="card-title">Let&apos;s see how you&apos;ve been doing this week.&nbsp;</p>
                <div className={`${hideDatePicker === true
                  ? 'user-stats__datepicker-popper' : ''} user-stats__input-container`}
                >
                  <DateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    onChange={onDateRangeChange}
                    shouldCloseOnSelect={false}
                    maxDate={new Date()}
                  >
                    <button
                      type="button"
                      className="user-stats__button"
                      onClick={() => onHandleClick(startDate, endDate)}
                    >
                      Apply
                    </button>
                  </DateRangePicker>
                </div>
                {// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <label
                    onClick={() => setHideDatePicker(false)}
                    onKeyDown={() => setHideDatePicker(false)}
                    className="user-stats__label"
                    htmlFor="date-picker"
                  >
                    Change stats period
                  </label>
                }
              </Col>
              )}
          </Row>
        </Col>
      </Row>
      {!isMobile
        && (
        <>
          <Col md={12} className="user-stats__minimize__button d-flex justify-content-end align-items-center">
            <p>
              {`${minimize ? 'Hide' : 'Show'}`}
              {' '}
              stats
            </p>
            <IcomoonReact
              iconSet={iconSet}
              color="#767676"
              size={38}
              icon={`${minimize ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}`}
              role="button"
              className="d-flex flex-align-end pe-3"
              onClick={() => setMinimize(!minimize)}
            />
          </Col>
          <Col md={12}>
            {!minimize && userStats && <UserStatsCarousel userStats={userStats} />}
          </Col>

        </>
        )}
    </div>
  )
}

export default UserStats
