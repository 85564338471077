import { useState } from 'react'
import useInterval from 'hooks/useInterval'
import { ONE_MINUTE_IN_MS } from 'utils/constants'
import { millisElapsedFromProvidedDate } from 'utils/helpers'

const useTimerCountdown = (createdAt) => {
  const milliseconds = millisElapsedFromProvidedDate(createdAt)
  const [, setTimePassed] = useState(milliseconds)
  const checkInterval = 10000 // every 10 seconds is good enough

  useInterval(() => {
    setTimePassed((prevTime) => (prevTime + checkInterval))
  }, milliseconds > ONE_MINUTE_IN_MS * 2 ? null : checkInterval)

  return {
    minutePassed: milliseconds > ONE_MINUTE_IN_MS * 2,
  }
}

export default useTimerCountdown
