import React, { useEffect } from 'react'
import OnBoardingLayout from 'layouts/OnBoardingLayout'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'providers/AuthProvider/AuthProvider'
import SignUp from './SignUp'

const SignUpRoute = () => {
  const { isLoggedIn, user, userRole } = useAuth()

  const history = useHistory()

  useEffect(() => {
    if (isLoggedIn) {
      if (!user?.full_name) {
        history.push('/personal-info')
        return
      }

      if (!user?.confirmed_at) {
        history.push('/verify-email')
        return
      }

      const noProjectSetup = userRole === undefined && user?.roles?.length === 0
      // If the user is not assigned to a project (has no role), redirect to /account-setup
      if (noProjectSetup) {
        history.push('/account-setup')
      }
    }
  }, [isLoggedIn, user, history, userRole])
  return (
    <OnBoardingLayout>
      <SignUp />
    </OnBoardingLayout>
  )
}

export default SignUpRoute
