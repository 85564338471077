import React, { useEffect, useCallback } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import logo from 'assets/images/swytchboard-logo-2.svg'
import Company from 'services/Company'
import './acceptInvite.scss'

const AcceptInvite = () => {
  const { addToast } = useToasts()
  const location = useLocation()
  const tokenParam = new URLSearchParams(location.search).get('token')
  const history = useHistory()

  const inviteMember = useCallback(async () => {
    try {
      const { invite } = await Company.acceptInviteMember(tokenParam)

      history.push({
        pathname: '/invite/signup',
        state: {
          inviteToken: tokenParam,
          email: invite.email,
          company_name: invite.company.name,
        },
      })
    } catch (e) {
      addToast(e.error, {
        appearance: 'error',
      })
    }
  }, [history, addToast, tokenParam])

  useEffect(() => {
    inviteMember()
  }, [inviteMember])

  return (
    <Row className="accept-invite bg-lighter justify-content-center align-items-center h-100 w-100 m-0 p-0">
      <Col>
        <Row className="justify-content-center mb-4">
          <Col md={3}>
            <img src={logo} className="App-logo mb-3 w-100" alt="logo" />
          </Col>
        </Row>
        <Row className="accept-invite--card">
          <Col className="accept-invite--card__body bg-white">
            <div className="accept-invite--card__body-title">
              <h4 className="fw-bold">We’re redirecting you to Swytchboard!</h4>
              <hr className="mt-3" />
            </div>
            <div className="accept-invite--card__body-content">
              <p>
                Thank you for accepting your Swytchboard invite.
              </p>
              <p>
                You’ll be automatically redirected to your Dashboard.
                This might take a few seconds.
              </p>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default AcceptInvite
