import { useEffect, useRef } from 'react'
import { useVideoContext } from 'providers/VideoProvider'

const AudioTrack = ({ track }) => {
  const audioEl = useRef()
  const { audioOutputId } = useVideoContext()

  useEffect(() => {
    if (track) {
      audioEl.current = track.attach()
      document.body.appendChild(audioEl.current)

      return () => track.detach()
        .forEach((el) => el.remove())
    }
    return undefined
  }, [track])

  useEffect(() => {
    // sinkId represents the ID of the audio output device
    // setSinkId is not supported on all browsers
    if (audioEl?.current?.setSinkId !== undefined) {
      audioEl?.current?.setSinkId(audioOutputId)
    }
    // Handle non supported browser audio output device selections in future
  }, [audioOutputId])

  return undefined
}

export default AudioTrack
