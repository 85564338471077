/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react'
import { useVideoContext } from 'providers/VideoProvider'
import useConversationSettings from 'features/qrflow/hooks/useConsumerConversations/useConversationSettings'

const useRequestMediaPermission = () => {
  const { isAudioEnabled, isTwoWayVideoEnabled } = useConversationSettings()
  const {
    createNewVideoTrack,
    room,
    createNewAudioTrack,
    isTwoWayEnabled,
    setIsTwoWayEnabled,
    setIsVideoTrackPublished,
    setIsAudioTrackPublished,
    setMicMediaError,
    setVideoMediaError,
  } = useVideoContext()

  const localParticipant = room?.localParticipant

  const askForMicrophonePermissions = useCallback(async () => {
    createNewAudioTrack()
      .then((track) => {
        if (track) {
          localParticipant?.publishTrack(track)
          setIsAudioTrackPublished(true)
          setMicMediaError(null)
        }
      })
      .catch((e) => setMicMediaError(e))
  }, [createNewAudioTrack, localParticipant, setIsAudioTrackPublished, setMicMediaError])

  const askForVideoPermissions = useCallback(async (isFirstTime = false) => {
    createNewVideoTrack()
      .then((track) => {
        if (track) {
          if (isFirstTime) {
            track?.disable()
          }
          localParticipant?.publishTrack(track)
          setIsVideoTrackPublished(true)
          setIsTwoWayEnabled(true)
        }
      })
      .catch((e) => setVideoMediaError(e))
  }, [createNewVideoTrack, isTwoWayEnabled, localParticipant, setIsVideoTrackPublished])

  return {
    askForMicrophonePermissions,
    askForVideoPermissions,
    isTwoWayVideoEnabled,
    isAudioEnabled,
  }
}
export default useRequestMediaPermission
