import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import Chat from 'services/Chat'
import { ChatStatuses, ParticipantTypes } from 'utils/constants'
import { updateConsumerConversation } from 'redux/consumerConversations/actions'
import useConsumerConversations from './useConsumerConversations'

export default function useConsumerEndChatConversation() {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const dispatch = useDispatch()
  const { settings: { userId }, id, roomName } = useConsumerConversations()
  const onEndConversation = useCallback(async () => {
    setIsSubmitting(true)
    setErrorMessage('')
    try {
      const { rate_token: rateToken } = await Chat.endConversationConsumer(id, roomName, userId)
      dispatch(updateConsumerConversation(id, {
        rateToken, [ParticipantTypes.USER]: {}, status: ChatStatuses.completed, submitRating: null,
      }))
    } catch (e) {
      setErrorMessage(e.message || e.error || 'Something went wrong, please try again.')
    }
    setIsSubmitting(false)
  }, [id, roomName, userId, dispatch])

  return {
    isSubmitting,
    errorMessage,
    onEndConversation,
  }
}
