/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import LoadingButton from 'components/buttons/LoadingButton'
import EmptyStar from 'components/UI/EmptyStar'
import ViewableLogoLayout from 'routes/ChatSettingsEntryPoint/components/ViewableLogoLayout'
import { RATE_YOUR_EXPERIENCE } from 'routes/ChatSettingsEntryPoint/constants'

function ratings(title) {
  return (
    <Row>
      <Col className="chat-ratings-viewable__ratings">
        <Col>
          <p><strong>{title}</strong></p>
        </Col>
        <Col className="chat-ratings-viewable__ratings__stars">
          <EmptyStar />
          <EmptyStar />
          <EmptyStar />
          <EmptyStar />
          <EmptyStar />
        </Col>
      </Col>
    </Row>
  )
}

const Viewable = ({
  enabledRatings,
  ratingsMessage,
  enableDetailedComments,
  accentColor,
}) => (
  <ViewableLogoLayout>
    {enabledRatings && (
      <>
        <div className="text-start flex-fill">
          <h4 className="chat-viewable__title">
            {RATE_YOUR_EXPERIENCE}
            <div
              className="chat-viewable__title__line"
              style={{
                backgroundColor: accentColor,
              }}
            />
          </h4>
          <div className="chat-ratings-viewable--increase-spacing">
            <p>
              {ratingsMessage}
            </p>
          </div>
          {ratings('Helpfulness')}
          {ratings('Knowledge')}
          {ratings('Professional')}
          {enableDetailedComments && (
          <div className="mb-3">
            <label>
              <strong>
                Comment (optional)
              </strong>
            </label>
            <textarea
              type="text"
              rows={3}
              placeholder="Add your comment here"
              disabled
              className="chat-ratings-viewable__input"
            />
          </div>
          )}
        </div>
        <LoadingButton
          className="qr-btn w-100 my-3"
          style={{
            backgroundColor: accentColor,
          }}
          disabled
        >
          Submit
        </LoadingButton>
      </>
    )}
  </ViewableLogoLayout>
)

export default Viewable
