import { useEffect } from 'react'
import { ChatStatuses, ConversationTypes } from 'utils/constants'
import { useHistory, useParams, generatePath } from 'react-router-dom'
import { QrFlowRoutes } from 'features/qrflow/constants'
import useGetEntryPointDetails from './useGetEntryPointDetails'
import useConsumerConversations from './useConsumerConversations'

export default function useRedirectChatRequest() {
  const {
    data,
  } = useGetEntryPointDetails()

  const {
    ratings,
    thankyou_page: thankyouPage,
  } = data?.config ?? {}

  const history = useHistory()

  const {
    chatRequestId: requestId,
    serviceUid,
  } = useParams()

  const { requestType, state: { status, rateToken, intent } } = useConsumerConversations()
  const chatRequestId = requestId || 0

  useEffect(() => {
    const currentRoute = history.location.pathname

    const isThankyouPageEnabled = thankyouPage?.enabled === 'true' ? QrFlowRoutes.thankYou : QrFlowRoutes.callEnded
    const isRatingsEnabled = ratings?.enabled === 'true' && rateToken

    let navigateToRoute = ''

    switch (status) {
      case ChatStatuses.pending:
        history.push(generatePath(QrFlowRoutes.queue, { serviceUid, chatRequestId }))
        break

      case ChatStatuses.accepted:
        navigateToRoute = requestType === ConversationTypes.videoText
          ? QrFlowRoutes.videoText
          : QrFlowRoutes.text
        history.push(generatePath(navigateToRoute, { serviceUid, chatRequestId }))
        break

      case ChatStatuses.canceled:
        navigateToRoute = generatePath(QrFlowRoutes.start, { serviceUid, chatRequestId })
        history.push(navigateToRoute)
        break
      case ChatStatuses.silentCanceled:
        navigateToRoute = generatePath(QrFlowRoutes.leaveMessage, { serviceUid, chatRequestId })
        history.push(navigateToRoute, { firstStep: false })
        break
      case ChatStatuses.declined:
        navigateToRoute = generatePath(QrFlowRoutes.leaveMessage, { serviceUid, chatRequestId })
        history.push(navigateToRoute, { intent })
        break

      case ChatStatuses.completed:
        navigateToRoute = isRatingsEnabled
          ? QrFlowRoutes.rating
          : isThankyouPageEnabled
        history.push(generatePath(navigateToRoute, { serviceUid, chatRequestId }))
        break

      default: {
        const noActiveConversation = !chatRequestId
          && (
            currentRoute.startsWith(generatePath(QrFlowRoutes.text, { serviceUid, chatRequestId }))
            || currentRoute.startsWith(generatePath(
              QrFlowRoutes.videoText, { serviceUid, chatRequestId },
            ))
            || currentRoute.startsWith(generatePath(
              QrFlowRoutes.rating, { serviceUid, chatRequestId },
            ))
            || currentRoute.startsWith(generatePath(
              QrFlowRoutes.thankYou, { serviceUid, chatRequestId },
            ))
            || currentRoute.startsWith(generatePath(
              QrFlowRoutes.callEnded, { serviceUid, chatRequestId },
            ))
            || currentRoute.startsWith(generatePath(
              QrFlowRoutes.queue, { serviceUid, chatRequestId },
            ))
          )
        if (noActiveConversation) {
          history.push('/')
        }
      }
    }
  }, [history, chatRequestId, ratings?.enabled, thankyouPage?.enabled, serviceUid, status, requestType, rateToken, intent])
}
