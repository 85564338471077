import { useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { playSound } from 'utils/helpers'
import usePendingChatRequests from 'features/pending-chat-requests/usePendingChatRequests'
import beepNotification from 'assets/audio/beep-notification.mp3'

export default function useNotifyChatRequests() {
  const {
    data: chatRequests,
  } = usePendingChatRequests()

  const [pendingChatRequest, setPendingChatRequest] = useState(0)

  const { addToast } = useToasts()

  useEffect(() => {
    if (pendingChatRequest < chatRequests?.length) {
      addToast('We just received a new chat request.',
        {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 2000,
        })
      playSound(beepNotification)
    }
    setPendingChatRequest(chatRequests?.length)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRequests])
}
