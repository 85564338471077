import React from 'react'

const StepField = ({
  title,
  subTitle,
  children,
  inputClassContainer,
  inputClassContent,
}) => (
  <div className={`chat-configuration-editable ${inputClassContainer}`}>
    <div className="w-100">
      <div className="chat-configuration-editable__title">
        {title}
      </div>
      <div className="chat-configuration-editable__subtitle">
        {subTitle}
      </div>
    </div>
    <div className={`chat-configuration-editable ${inputClassContent}`}>
      <span>
        {children}
      </span>
    </div>
  </div>
)

export default StepField
