import { useAuth } from 'providers/AuthProvider/AuthProvider'
import { useSelector } from 'react-redux'
import { CustomerInfoOptions } from 'utils/constants'
import { formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'

const useCustomerContactIdentifier = () => {
  const {
    customer_contact_identifier: customerContactIdentifier,
  } = useSelector((state) => state.company?.data?.chat_setting) ?? { customer_contact_identifier: CustomerInfoOptions.NONE }
  const { userRole } = useAuth()

  return (email, phone = '') => {
    if ((email || phone) && (customerContactIdentifier === CustomerInfoOptions.ALL || customerContactIdentifier?.startsWith(userRole))) {
      if (email && !email.includes('@joinsywtchboard.com')) return `<${email}>`
      if (isValidPhoneNumber(phone)) return `<${formatPhoneNumber(phone)}>`
      return email?.split('@')[0] ? `<${email?.split('@')[0]}>` : null
    }
    return null
  }
}
export default useCustomerContactIdentifier
