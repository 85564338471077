import { useEffect, useState } from 'react'
import { useVideoContext } from 'providers/VideoProvider'
import { useDispatch } from 'react-redux'
import { participantState } from 'utils/constants'

export default function useRemoteParticipants() {
  const { room } = useVideoContext()
  const [participants, setParticipants] = useState(Array.from(room?.participants?.values() ?? []))
  const [participantStatus, setParticipantStatus] = useState('')
  const hasAnyParticipant = participants?.length >= 1
  const isParticipantDisconnected = (participantStatus === participantState.disconnected)
  const dispatch = useDispatch()

  useEffect(() => {
    setParticipants(Array.from(room?.participants?.values() ?? []))
  }, [room])

  useEffect(() => {
    if (room) {
      const participantConnected = (participant) => {
        setParticipantStatus(participantState.connected)
        setParticipants((prevParticipants) => [...prevParticipants, participant])
      }
      const participantDisconnected = (participant) => {
        setParticipantStatus(participantState.disconnected)
        setParticipants((prevParticipants) => prevParticipants.filter((p) => p !== participant))
      }

      room.on('participantConnected', participantConnected)
      room.on('participantDisconnected', participantDisconnected)
      return () => {
        room.off('participantConnected', participantConnected)
        room.off('participantDisconnected', participantDisconnected)
      }
    }
    return undefined
  }, [room, dispatch])

  return { remoteParticipants: participants, hasAnyParticipant, isParticipantDisconnected }
}
