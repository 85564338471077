import React from 'react'
import WorkerDialog from 'components/WorkerDialog'

const BackToDashboardDialog = ({
  show,
  onClose,
  onConfirm,
  loading,
}) => (
  <WorkerDialog
    show={show}
    onClose={onClose}
    onConfirm={onConfirm}
    title="Back To Dashboard"
    confirmText="Yes"
    closeText="No"
    loading={loading}
  >
    <p>
      You are in a video call, if you go back to the dashboard it will temporarily disconnect you.
      Do you want to continue?
    </p>
  </WorkerDialog>
)

export default BackToDashboardDialog
