import React from 'react'
import { Alert } from 'react-bootstrap'
import { Formik, Field as FormikField } from 'formik'
import * as Yup from 'yup'
import TextFormField from 'components/UI/TextFormField/TextFormField'
import Field from 'routes/OnBoardingInfo/Field'
import LoadingButton from 'components/buttons/LoadingButton'
import Layout from 'routes/OnBoardingInfo/Layout'
import useSetupPersonalInfo from 'routes/OnBoardingInfo/PersonalInfo/usePersonalInfoSetup'
import { onlyCharacters } from 'utils/regex'
import UploadProfilePhoto from './UploadProfilePhoto/UploadProfilePhoto'

const PersonalInfoSchema = Yup.object({
  full_name: Yup.string()
    .required('Enter a full name')
    .min(3, 'Full name must be at least 3 characters')
    .matches(onlyCharacters, 'Full name must contain only characters')
    .nullable(),
})

const PersonalInfoSetup = () => {
  const {
    data,
    isSubmitting,
    errorMessage,
    onClearErrorMessage,
    onPersonalInfoHandler,
  } = useSetupPersonalInfo()

  const initialValues = {
    full_name: data?.full_name ?? '',
    avatar: data?.avatar ?? '',
  }

  return (
    <Layout>
      <Formik
        initialValues={initialValues}
        initialTouched={{
          full_name: data?.full_name ?? '',
          avatar: data?.avatar ?? '',
        }}
        enableReinitialize
        validationSchema={PersonalInfoSchema}
        onSubmit={onPersonalInfoHandler}
      >
        {({
          values,
          handleSubmit,
          isValid,
        }) => (
          <>
            {errorMessage && (
            <Alert
              className="my-3"
              variant="danger"
              dismissible
              onClose={onClearErrorMessage}
            >
              {errorMessage}
            </Alert>
            )}
            <Field
              labelTitle="Full Name"
              formControl={(
                <>
                  <FormikField
                    name="full_name"
                    value={values.full_name}
                    placeholder="Enter your full name (required)"
                    component={TextFormField}
                  />
                </>
              )}
            />
            <Field
              labelTitle="Choose a Profile Picture"
              formControl={(
                <>
                  <FormikField
                    name="avatar"
                    value={values.avatar}
                    component={UploadProfilePhoto}
                  />
                </>
              )}
            />

            <div className="d-flex justify-content-between mt-4">
              <LoadingButton
                loading={isSubmitting}
                onClick={handleSubmit}
                disabled={!(isValid)}
                className="personal-info__button personal-info__button--submit"
              >
                Continue
              </LoadingButton>
            </div>
          </>
        )}
      </Formik>
    </Layout>
  )
}

export default PersonalInfoSetup
