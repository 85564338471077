import React, { createContext, useContext, useState } from 'react'
import useLocalVideoTrack from 'providers/VideoProvider/useLocalVideoTrack/useLocalVideoTrack'
import useLocalAudioTrack from 'providers/VideoProvider/useLocalAudioTrack/useLocalAudioTrack'
import useLocalAudioOutput from 'providers/VideoProvider/useLocalAudioOutput/useLocalAudioOutput'
import useScreenShareToggle from 'providers/VideoProvider/useLocalScreenShare/useLocalScreenShare'
import useRoom from 'providers/VideoProvider/useRoom/useRoom'

const VideoContext = createContext(null)

const VideoProvider = ({ children }) => {
  const {
    videoTrack,
    videoMediaError,
    setVideoMediaError,
    setVideoTrack,
    createNewVideoTrack,
    removeLocalVideoTrack,
  } = useLocalVideoTrack()

  const {
    audioTrack,
    micMediaError,
    setMicMediaError,
    setAudioTrack,
    createNewAudioTrack,
    removeLocalAudioTrack,
  } = useLocalAudioTrack()

  const {
    audioOutputId,
    setAudioOutputId,
  } = useLocalAudioOutput()

  const localTracks = [audioTrack, videoTrack].filter((track) => track !== undefined)

  const {
    room,
    isConnectingToRoom,
    connectToRoom,
  } = useRoom()

  const [isTwoWayEnabled, setIsTwoWayEnabled] = useState(false)
  const [isSharingScreen, toggleScreenShare] = useScreenShareToggle(room)
  const [isVideoTrackPublished, setIsVideoTrackPublished] = useState(false)
  const [isAudioTrackPublished, setIsAudioTrackPublished] = useState(false)

  const value = {
    videoTrack,
    setVideoTrack,
    createNewVideoTrack,
    removeLocalVideoTrack,
    audioTrack,
    setAudioTrack,
    createNewAudioTrack,
    removeLocalAudioTrack,
    audioOutputId,
    setAudioOutputId,
    room,
    isConnectingToRoom,
    connectToRoom,
    localTracks,
    micMediaError,
    setMicMediaError,
    videoMediaError,
    setVideoMediaError,
    isTwoWayEnabled,
    setIsTwoWayEnabled,
    isSharingScreen,
    toggleScreenShare,
    isVideoTrackPublished,
    setIsVideoTrackPublished,
    isAudioTrackPublished,
    setIsAudioTrackPublished,
  }

  return (
    <VideoContext.Provider value={value}>
      {children}
    </VideoContext.Provider>
  )
}

export const useVideoContext = () => {
  const videoContext = useContext(VideoContext)
  if (!videoContext) {
    throw new Error('VideoContext is not provided')
  }
  return videoContext
}

export default VideoProvider
