import config from 'utils/config'
import http from 'utils/http'
import handleResponse from 'utils/handleResponse'
import parseAndRethrowError from 'utils/parseAndRethrowError'

const API_URL = '/api/'

class User {
  userSignup = (email, password, updateUser) => (
    http
      .post(`${config.SERVER_URL}${API_URL}signup`, {
        user: {
          email,
          password,
        },
      })
      .then((response) => {
        const token = response.headers['access-token']
        response.data.accessToken = token
        if (token) {
          localStorage.setItem('user', JSON.stringify(response.data))
          updateUser(response.data)
        }
        return response.data
      })
      .catch((error) => parseAndRethrowError(error))
  )

  getUser = () => (
    http
      .get(`${config.SERVER_URL}${API_URL}v1/users/me`)
      .then((response) => response)
      .catch((error) => {
        if (error.response && error.response.data) {
          return error.response.data
        }

        return error
      })
  )

  userUpdate = (fullName, phone = '0000000000000') => (
    http
      .patch(`${config.SERVER_URL}${API_URL}v1/users/me`, {
        full_name: fullName,
        phone,
      })
      .then((response) => response)
      .catch((error) => {
        if (error.response && error.response.data) {
          return error.response.data
        }

        return error
      })
  )

  updateUserBillingInfo = (formData) => (
    http
      .patch(`${config.SERVER_URL}${API_URL}v1/users/me`, {
        ...formData,
      })
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  updateUser = (fullName) => (
    http
      .patch(`${config.SERVER_URL}${API_URL}v1/users/me`, {
        full_name: fullName,
      })
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  updateUserProfileImage = (id, avatar) => (
    http
      .patch(`${config.SERVER_URL}${API_URL}v1/users/me`, {
        id,
        avatar,
      })
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  changePassword = (formData) => (
    http
      .put(`${config.SERVER_URL}${API_URL}v1/users/change-password`, {
        ...formData,
      })
      .then((response) => handleResponse(response))
      .catch((error) => {
        // Modify and rethrow error
        if (error.response && error.response.data) {
          throw error.response.data
        }
        throw error
      })
  )

  deleteMe = (password) => (
    http
      .delete(`${config.SERVER_URL}${API_URL}v1/users/me`, {
        data: {
          password,
        },
      })
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  verifyEmail = (token) => (
    http.post(`${config.SERVER_URL}${API_URL}confirmation?token=${token}`)
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  resendVerificationCode = (email) => (
    http.put(`${config.SERVER_URL}${API_URL}v1/users/resend_confirm_email`, {
      email,
    })
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  forgotPassword = (email) => (
    http.get(`${config.SERVER_URL}${API_URL}v1/users/forgot-password?email=${encodeURIComponent(email)}`)
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  resetPassword = (email, password, passwordConfirmation, token) => (
    http.put(`${config.SERVER_URL}${API_URL}v1/users/password/${token}`, {
      email,
      password,
      password_confirmation: passwordConfirmation,
    })
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  getActiveInvitations = () => (
    http.get(`${config.SERVER_URL}${API_URL}v1/invitations/me`)
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  acceptInvitation = (invitationId) => (
    http.post(`${config.SERVER_URL}${API_URL}v1/invitations/${invitationId}/accept-and-complete`, {
      invitation_id: invitationId,
    })
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  userStats = (from, to) => (
    http
      .get(`${config.SERVER_URL}${API_URL}v1/users/stats?from=${from}&to=${to}`)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response && error.response.data) {
          return error.response.data
        }

        return error
      })
  )

  getActiveWorkLog = (companyId) => (
    http.get(`${config.SERVER_URL}${config.API_URL}/work_logs/${companyId}/active`)
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  punchIn = (companyId) => (
    http.post(`${config.SERVER_URL}${API_URL}v1/work_logs/${companyId}/in`)
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )

  punchOut = (companyId) => (
    http.put(`${config.SERVER_URL}${API_URL}v1/work_logs/${companyId}/out`)
      .then((response) => handleResponse(response))
      .catch((error) => parseAndRethrowError(error))
  )
}

export default new User()
