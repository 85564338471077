import { useEffect, useState } from 'react'

export default function useAvailableDevices() {
  const [audioInputDevices, setAudioInputDevices] = useState([])
  const [audioOutputDevices, setAudioOutputDevices] = useState([])
  const [videoInputDevices, setVideoInputDevices] = useState([])

  useEffect(() => {
    const getAvailableDevices = () => navigator.mediaDevices.enumerateDevices()
      .then((newDevices) => {
        setAudioInputDevices(newDevices.filter((device) => device.kind === 'audioinput'))
        setAudioOutputDevices(newDevices.filter((device) => device.kind === 'audiooutput'))
        setVideoInputDevices(newDevices.filter((device) => device.kind === 'videoinput'))
      })

    navigator.mediaDevices.addEventListener('devicechange', getAvailableDevices)

    getAvailableDevices()

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', getAvailableDevices)
    }
  }, [])

  return {
    audioInputDevices,
    audioOutputDevices,
    videoInputDevices,
    hasAudioInputDevices: audioInputDevices.length > 0,
    hasVideoInputDevices: videoInputDevices.length > 0,
    hasAudioOutputDevices: audioOutputDevices.length > 0,
  }
}
