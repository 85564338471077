import React from 'react'

// TODO: refactor admin's pages to use this component.
// Note: some pages already use these paddings, but are duplicated.
//
// Sets a default padding for the page's main content.
const ContentContainer = ({ children }) => (
  <div className="content-container">
    {children}
  </div>
)

export default ContentContainer
