import React from 'react'

const ChangePasswordSuccessfully = () => (
  <>
    <p>
      You have successfully changed your password.
    </p>
    <p>
      You can now use your new password to login.
    </p>
  </>
)

export default ChangePasswordSuccessfully
