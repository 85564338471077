/**
 * Handle Errors from backend
 */

export default function handleErrors(error) {
  if (error.response) {
    if (error.response.data) {
      return error.response.data
    }
  }
  return error
}
