import useRemoteParticipants from 'hooks/useRemoteParticipants/useRemoteParticipants'
import { useVideoContext } from 'providers/VideoProvider'

export default function useMainParticipant() {
  const { remoteParticipants } = useRemoteParticipants()
  const { room } = useVideoContext()
  const localParticipant = room?.localParticipant

  // The participant that is returned is displayed in the main video area.
  return remoteParticipants[0] || localParticipant
}
