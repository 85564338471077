import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Col, Row, Alert } from 'react-bootstrap'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { useAuth } from 'providers/AuthProvider/AuthProvider'
import { useToasts } from 'react-toast-notifications'
import TextFormField from 'components/UI/TextFormField/TextFormField'
import Loader from 'components/loader/Loader'
import passwordConfirmationSchema from 'schemas/passwordConfirmationSchema'
import Company from 'services/Company'
import SignUpCheckboxes from '../SignUpCheckboxes'

const InviteSignUpSchema = Yup.object().shape({
  fullName: Yup.string()
    .required('The full name is required'),
  email: Yup.string()
    .email()
    .required('The email is required'),
  ...passwordConfirmationSchema,
})

const InviteSignUp = () => {
  const { login } = useAuth()
  const { addToast } = useToasts()
  const [displayErrorMessage, setDisplayErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const history = useHistory()
  const location = useLocation()

  const token = location.state.inviteToken
  const userEmail = location.state.email
  const companyName = location.state.company_name

  const onInviteSignUpHandler = async ({ fullName, email, password }) => {
    setLoading(true)
    try {
      await Company.completeInviteMember(token, fullName, email, password)
      await login(email, password)
      setLoading(false)
      addToast('User invite is completed!', {
        appearance: 'success',
        autoDismiss: true,
        autoDismissTimeout: 2000,
      })
      history.push('/dashboard')
    } catch (e) {
      setDisplayErrorMessage(e.error)
      setLoading(false)
    }
  }

  return (
    <Col md={6} className="bg-white">
      <Row className="m-0 p-0 h-100 justify-content-center align-items-center">
        <Col md={8}>
          <div className="login-form p-5 position-relative">
            { loading === true
            && (
              <div className="loader-spinner">
                <Loader />
              </div>
            ) }
            <div className="text-start mb-4">
              <h3 className="login-title fw-bold mb-3">{`Welcome to ${companyName}!`}</h3>
              <h3 className="login-title fw-bold mb-3">Let’s get you started.</h3>
              <hr className="mt-3" />
            </div>

            <Formik
              validationSchema={InviteSignUpSchema}
              validateOnMount
              enableReinitialize
              initialValues={{
                fullName: '',
                email: userEmail,
                password: '',
                password_confirmation: '',
              }}
              initialTouched={{
                email: userEmail,
              }}
              onSubmit={(values) => onInviteSignUpHandler(values)}
            >
              {({
                values, handleBlur, isValid, dirty,
              }) => (
                <Form>
                  { displayErrorMessage !== ''
                  && (
                  <Alert variant="danger" onClose={() => setDisplayErrorMessage('')} dismissible>
                    {displayErrorMessage}
                  </Alert>
                  )}
                  <Field
                    label="Full Name"
                    name="fullName"
                    type="fullName"
                    placeholder="Enter full name"
                    onBlur={handleBlur}
                    component={TextFormField}
                  />
                  <Field
                    label="E-mail address"
                    name="email"
                    type="email"
                    placeholder="Enter a valid e-mail address"
                    onBlur={handleBlur}
                    component={TextFormField}
                  />
                  <Field
                    label="Choose a password"
                    name="password"
                    type="password"
                    placeholder="Enter a password"
                    onBlur={handleBlur}
                    component={TextFormField}
                  />
                  <Field
                    label="Confirm password"
                    type="password"
                    name="password_confirmation"
                    placeholder="Confirm your password"
                    onBlur={handleBlur}
                    component={TextFormField}
                  />
                  <SignUpCheckboxes value={values.password} />
                  <div className="mb-3 mt-3">
                    <button
                      type="submit"
                      id="invite-sign-up-button"
                      className="btn btn-success d-block w-100 btn-auth"
                      disabled={!(isValid && dirty) || loading}
                    >
                      Complete Invitation
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </Col>
  )
}

export default InviteSignUp
