import { useQuery } from '@tanstack/react-query'
import Company from 'services/Company'
import { QueryKeys } from 'utils/constants'

// The backend throws an error when we pass empty values for the filters, so we apply the filter only if it exists
// The backend throws an error when we pass only one date, so we make sure that the user selects both start and end date
function createFiltersParams({
  start_date: startDate,
  end_date: endDate,
  consumer_name_or_email: consumerNameOrEmail,
  helpfulness,
  knowledge,
  professional,
  worker,
}) {
  // Conditionally add every filter if it is applied
  return {
    ...(startDate && endDate && { start_date: startDate, end_date: endDate }),
    ...(consumerNameOrEmail && { consumer_name: consumerNameOrEmail, consumer_email: consumerNameOrEmail }),
    ...(helpfulness && { helpfulness }),
    ...(knowledge && { knowledge }),
    ...(professional && { professional }),
    ...(worker && worker.id && { user_id: worker.id }),
  }
}

export default function useConversations(companyId, zeroIndexedPage, filters, onError) {
  // This query will re-execute whenever the page or the filters change
  return useQuery([QueryKeys.CONVERSATIONS, companyId, zeroIndexedPage, filters],
    // The react-paginate library accepts a zero-indexed page number, while backend pagination accepts one-indexed page
    () => Company.getConversations(companyId, zeroIndexedPage + 1, createFiltersParams(filters)),
    {
      refetchOnWindowFocus: false,
      enabled: !!companyId,
      keepPreviousData: true,
      retry: false,
      onError,
    })
}
