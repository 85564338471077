import React from 'react'
import { UrlMatcher } from 'interweave-autolink'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Image from 'services/Image'
import { Interweave } from 'interweave'
import SwytchAvatar from 'components/user/SwytchAvatar'
import ReactTimeAgo from 'react-time-ago'
import useUserAvatar from 'hooks/useUserAvatar'
import RequestVideoMessage from 'components/messages/RequestVideoMessage'
import EmbedChatFiles from 'components/chat/EmbedChatFiles'
import useActiveConversations from 'hooks/useActiveConversations'
import { isMobile } from 'utils/helpers'

const Message = ({ message }) => {
  const { data: userData } = useSelector((state) => state.user)
  const profilePicture = Image.get(userData?.avatar)
  const { consumerName } = useActiveConversations()
  const isWorker = message.author.includes('worker_') || message.author === 'system'

  const {
    renderAvatar,
  } = useUserAvatar(userData, { profilePicture }, {}, { avatarSize: 30 })

  return (
    <Col md={12} className={`d-flex ${isWorker ? 'justify-content-end' : 'justify-content-start'}`}>
      {
        message.author.includes('worker_') && (
          <Row className={`chat-message ${!isMobile && 'w-100'}`} key={message.sid}>
            <Col xs={10} md={10} xl={11} className="chat-message__info">
              <div className="chat-message__info__content chat-message__info__content--worker">
                {message.media && <EmbedChatFiles media={message.media} />}
                <Interweave
                  content={message.body}
                  matchers={[new UrlMatcher('url')]}
                  newWindow
                />
              </div>
              <div className="chat-message__info__timestamp">
                <ReactTimeAgo date={message.dateCreated} locale="en-US" />
              </div>
            </Col>
            <Col xs={2} md={2} xl={1} className="chat-message__avatar chat-message__avatar--worker">
              {renderAvatar}
            </Col>
          </Row>
        )
      }
      {
        message.author.includes('consumer_') && (
          <Row className={`chat-message ${!isMobile && 'w-100'}`} key={message.sid}>
            <Col xs={2} md={2} xl={1} className="chat-message__avatar chat-message__avatar--consumer">
              <SwytchAvatar name={consumerName} size={30} />
            </Col>
            <Col xs={10} md={10} xl={11} className="chat-message__info">
              <div className="chat-message__info__content chat-message__info__content--consumer shadow">
                {message.media && <EmbedChatFiles media={message.media} />}
                <Interweave
                  content={message.body}
                  matchers={[new UrlMatcher('url')]}
                  newWindow
                />
              </div>
              <div className="chat-message__info__timestamp chat-message__info__timestamp--consumer">
                <ReactTimeAgo date={message.dateCreated} locale="en-US" />
              </div>
            </Col>
          </Row>
        )
      }
      {
        message.author === 'system' && (
          <Row className={`m-0 p-0 system ${!isMobile && 'w-75'}`}>
            <Col md={12} className="text-break text-end">
              <div className="w-100 p-3 message text-light text-default">
                {message.body}
              </div>
            </Col>
          </Row>
        )
      }
      {
        message.author === 'request-video' && (
          <RequestVideoMessage message={message} />
        )
      }
    </Col>
  )
}

export default Message
