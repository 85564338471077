import React from 'react'
import IcomoonReact from 'icomoon-react'
import { isMobile } from 'utils/helpers'
import iconSet from 'assets/fonts/selection.json'

const MoreOptionsIcon = React.forwardRef(({ onClick = () => { }, disabled, children }, ref) => (
  <div className="bg-white rounded-pill m-2 shadow" style={{ padding: '10px 11px 10px 11px', maxWidth: 45, maxHeight: 45 }} href="" ref={ref}>
    <IcomoonReact
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
      iconSet={iconSet}
      size={20}
      icon="more-icon"
      color={(isMobile && disabled) ? 'white' : 'black'}
    >
      {children}
    </IcomoonReact>
  </div>
))

export default MoreOptionsIcon
