import { useQuery } from '@tanstack/react-query'
import Company from 'services/Company'
import { QueryKeys } from 'utils/constants'

export default function useMessagesLeft(companyId, status = 'open', search = '', zeroIndexedPage = 0, onError) {
  // This query will re-execute whenever the page or the filters change
  return useQuery([QueryKeys.CONVERSATIONS, companyId, status, search, zeroIndexedPage],
    // The react-paginate library accepts a zero-indexed page number, while backend pagination accepts one-indexed page
    () => Company.getMessagesLeft(companyId, status, search, zeroIndexedPage + 1),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      retry: false,
      onError,
    })
}
