import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react'
import { useSelector } from 'react-redux'
import { capitalizeFirstLetter } from 'utils/helpers'
import Company from 'services/Company'
import { useErrorHandler } from 'react-error-boundary'

const TeamMembersContext = createContext()
TeamMembersContext.displayName = 'TeamMembersContext'

const TeamMembersProvider = ({ children }) => {
  const [members, setMembers] = useState([])
  const [pendingMembers, setPendingMembers] = useState([])
  const [displayPendingMembers, setDisplayPendingMembers] = useState(false)
  const [loading, setLoading] = useState(false)
  const [sortingField, setSortingField] = useState('')
  const [sortingOrder, setSortingOrder] = useState('ASC')
  const errorHandler = useErrorHandler()

  const companyId = useSelector((state) => state.company.data.id)

  useEffect(() => {
    setLoading(true)
    const getUser = async () => {
      setDisplayPendingMembers(true)
      const { users } = await Company.members(companyId)
      setLoading(false)
      setMembers([...users])
    }
    getUser()
  }, [companyId])

  const userTeamMembers = async () => {
    setLoading(true)
    try {
      const { users } = await Company.members(companyId)
      setMembers(users)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
      errorHandler(error)
    }
  }

  const pendingInvites = useCallback(
    (invites) => invites?.map((invite) => (
      {
        email: invite.email,
        id: invite.id,
        status: 'Pending',
        roles: [
          {
            company_id: companyId,
            role: invite.role,
          },
        ],
      }
    )),
    [companyId],
  )

  const pendingTeamMembers = async () => {
    setLoading(true)
    try {
      const { invites } = await Company.members(companyId)
      const invitedMembers = pendingInvites(invites)
      setPendingMembers(invitedMembers)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      // ignore errors
      errorHandler(error)
    }
  }

  const sortUserTeamMembers = async (id, field, order) => {
    try {
      const { users: sortMembers } = await Company.sortMembers(id, field, order)
      setMembers([...sortMembers])
    } catch (error) {
      errorHandler(error)
    }
  }

  const removeTeamMember = async (id) => {
    try {
      await Company.removeMember(companyId, id)
      const newMembers = members.filter((member) => member.id !== id)
      setMembers(newMembers)
    } catch (error) {
      // ignore errors
      errorHandler(error)
    }
  }

  const removePendingTeamMember = async (invitationId) => {
    try {
      await Company.removePendingMember(companyId, invitationId)
      const newMembers = pendingMembers.filter((member) => member.id !== invitationId)
      setPendingMembers(newMembers)
    } catch (error) {
      // ignore errors
    }
  }

  const editTeamMember = async (id, role) => {
    try {
      await Company.editMember(companyId, id, role)
      const updatedMembers = members.map((member) => {
        if (member.id === id) {
          // eslint-disable-next-line no-param-reassign
          member.roles[0].role = capitalizeFirstLetter(role)
        }
        return member
      })
      setMembers(updatedMembers)
    } catch (error) {
      // ignore errors
      errorHandler(error)
    }
  }

  return (
    <TeamMembersContext.Provider
      value={{
        members,
        setMembers,
        pendingMembers,
        setPendingMembers,
        displayPendingMembers,
        setDisplayPendingMembers,
        loading,
        companyId,
        removeTeamMember,
        removePendingTeamMember,
        editTeamMember,
        pendingTeamMembers,
        userTeamMembers,
        sortUserTeamMembers,
        sortingField,
        setSortingField,
        sortingOrder,
        setSortingOrder,
      }}
    >
      {children}
    </TeamMembersContext.Provider>
  )
}

function useMembersContext() {
  const context = useContext(TeamMembersContext)
  if (context === undefined) {
    throw new Error('useMembersContext must be used within a TeamMembersProvider')
  }
  return context
}

export { TeamMembersProvider, useMembersContext }
