import React from 'react'

const Field = ({
  labelTitle,
  labelSubtitle,
  formControl,
}) => (
  <div>
    <div className="chat-settings__field">
      <div className="chat-settings__field__label">
        <div className="chat-settings__field__label__title">
          {labelTitle}
        </div>
        <div
          className="chat-settings__field__label__subtitle"
        >
          {labelSubtitle}
        </div>
      </div>
      <div className="chat-settings__field__form-control">
        {formControl}
      </div>
    </div>
  </div>
)

export default Field
