import React from 'react'
import { Col } from 'react-bootstrap'
import LoadingButton from 'components/buttons/LoadingButton'

const TopChatConfigurationButtons = ({
  isLoading,
  handleSaveButton,
  handleCancelButton,
  modifiedState,
}) => (
  <>
    <Col className="chat-configuration__header">
      <Col md={3} className="chat-configuration__header__buttons">
        <LoadingButton
          disabled={!modifiedState}
          className={`btn-success btn btn-primary w-100 ${!modifiedState ? 'disabled' : 'enabled'}`}
          onClick={modifiedState ? handleCancelButton : null}
        >
          Undo
        </LoadingButton>
      </Col>
      <Col md={6} className="chat-configuration__header__content">
        <div className="chat-configuration__header__content__title">
          Chat Configuration
        </div>
        <div className="chat-configuration__header__content__subtitle">
          Set up and configure all chat screen details.
        </div>
      </Col>
      <Col md={3} className="chat-configuration__header__buttons">
        <LoadingButton
          loading={isLoading}
          disabled={!modifiedState}
          spinnerSize={18}
          className={`btn-success btn btn-primary w-100 ${!modifiedState ? 'disabled' : 'enabled'}`}
          onClick={modifiedState ? handleSaveButton : null}
        >
          Save Changes
        </LoadingButton>
      </Col>
    </Col>
  </>
)

export default TopChatConfigurationButtons
