import React from 'react'
import { Provider } from 'react-redux'
import { ToastProvider } from 'react-toast-notifications'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from 'components/ErrorBoundary/ErrorFallback'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import withProviders from 'hoc/withProviders'
import { AuthContextProvider } from 'providers/AuthProvider/AuthProvider'
import { BillingProvider } from 'providers/BillingProvider/index'
import store from 'redux/store'
import useCompanyChannel from 'channels/useCompanyChannel'
import Routes from 'routes'
import { Router } from 'react-router-dom'
import history from 'services/history'
import * as Sentry from '@sentry/react'

const queryClient = new QueryClient()

function App() {
  // The connection will be made only for the worker
  useCompanyChannel()
  window.onunhandledrejection = (e) => {
    Sentry.captureException(e?.reason)
  }
  return (
    <div className="App">
      <Routes />
    </div>
  )
}
// To add props to a Provider, we use an array, where the second element is an object containing the props
export default withProviders(
  // To access the history object outside of React components
  // (e.g redux thunk, we need to use Router instead of Browser Router, and pass the browserHistory property
  [Router, { history }],
  [ErrorBoundary, { FallbackComponent: ErrorFallback }],
  [QueryClientProvider, { client: queryClient }],
  [Provider, { store }],
  ToastProvider,
  AuthContextProvider,
  BillingProvider,
)(Sentry.withProfiler(App))
