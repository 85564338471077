import React, {
  useEffect, useState, useCallback,
} from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Alert, Col, Form, FormLabel, Row,
} from 'react-bootstrap'
import { useAuth } from 'providers/AuthProvider/AuthProvider'
import userActions from 'redux/user/actions'
import Loader from 'components/loader/Loader'
import { useDispatch } from 'react-redux'
import useDialog from 'hooks/useDialog'
import ForgotPasswordProvider from 'components/ForgotPaswordDialog/providers/ForgotPasswordProvider'
import ForgotPasswordDialog from 'components/ForgotPaswordDialog'
import './signin.scss'
import useUserRedirection from 'hooks/useUserRedirection'

const SignIn = () => {
  const {
    displayDialog: displayForgotPasswordDialog,
    onOpenDialog: onOpenForgotPasswordDialog,
    onCloseDialog: onCloseForgotPasswordDialog,
  } = useDialog()

  const { login: authLogin } = useAuth()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [show, setShow] = useState(false)
  const [filled, setFilled] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  useUserRedirection()

  const handleRememberMeChange = (e) => {
    if (e.target.checked) {
      localStorage.setItem('isRememberMeChecked', true)
    } else {
      localStorage.removeItem('isRememberMeChecked')
    }
  }

  const handleLogin = async (event) => {
    event.preventDefault()
    setLoading(true)
    const user = await authLogin(email, password)
    if ('email' in user) {
      // Redirect to personal-info if the user hasn't
      if (!user?.full_name) {
        history.push('/personal-info')
        return
      }

      if (!user?.confirmed_at) {
        history.push('/verify-email')
        return
      }

      const noProjectSetup = user?.roles?.length === 0
      // If the user is not assigned to a project (has no role), redirect to /account-setup
      if (noProjectSetup) {
        history.push('/account-setup')
        return
      }

      dispatch(userActions.setUser(user))
    } else {
      if (!('error' in user)) {
        setShow(true)
        setError('Something went wrong please try again!')
      } else {
        setError(user.error === 'User is not authorized' ? 'Invalid login credentials.' : user.error)
        setShow(true)
      }
      setLoading(false)
    }
  }

  const checkValues = useCallback(() => {
    if (email !== '' && password !== '') {
      setFilled(true)
    } else {
      setFilled(false)
    }
  }, [email, password])

  useEffect(() => {
    checkValues()
  }, [checkValues])

  return (
    <Col md={6} className="bg-white">
      <Row className="m-0 p-0 h-100 justify-content-center align-items-center">
        <Col md={8}>
          <div className="login-form p-5 position-relative">
            {loading === true && (
            <div className="loader-spinner">
              <Loader />
            </div>
            )}
            <div className="text-start mb-5">
              <h3 className="login-title fw-bold mb-3">Log in to your account</h3>
              <span>Don&apos;t have an account</span>
                &nbsp;
              <Link to="/signup">Sign up</Link>
            </div>
            <form>
              {show === true && (
              <Alert key="error" variant="danger" onClose={() => setShow(false)} dismissible>
                {error}
              </Alert>
              )}
              <div className="mb-3 text-start card-title">
                <FormLabel className="mb-3">E-mail address</FormLabel>
                <input
                  type="text"
                  name="email"
                  id="email"
                  className="form-control"
                  placeholder="Enter your e-mail address"
                  onChange={(event) => {
                    setEmail(event.target.value)
                    checkValues()
                  }}
                  autoComplete="off"
                />
              </div>
              <div className="mb-3 text-start card-title my-4">
                <FormLabel className="mb-3">Password</FormLabel>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  id="password"
                  placeholder="Enter your password"
                  onChange={(event) => {
                    setPassword(event.target.value)
                    checkValues()
                  }}
                />
              </div>
              <Form.Group className="text-start card-title my-4 text-default remember-me" controlId="rememberMe">
                <Form.Check type="checkbox" label="Keep me logged in" onChange={handleRememberMeChange} />
              </Form.Group>
              <div className="mb-3 mt-5">
                <button
                  type="submit"
                  id="sign-in-button"
                  className={`btn ${filled ? 'btn-success ' : 'btn-light disabled '}d-block w-100 btn-auth`}
                  onClick={handleLogin}
                  disabled={!filled || false}
                >
                  Log in
                </button>
              </div>
              <div className="forgot-password text-start" aria-hidden="true" onClick={onOpenForgotPasswordDialog}>
                Forgot your password?
              </div>
            </form>
          </div>
        </Col>
      </Row>
      <ForgotPasswordProvider onClose={onCloseForgotPasswordDialog}>
        <ForgotPasswordDialog show={displayForgotPasswordDialog} />
      </ForgotPasswordProvider>
    </Col>
  )
}

export default SignIn
