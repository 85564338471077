import React from 'react'
import QRDialog from './QRDialog'

const RequestMediaPermissions = ({
  show, onClose, onConfirm, isCameraEnabled, isMicrophoneEnabled,
}) => {
  const arePermissionsGranted = [isCameraEnabled, isMicrophoneEnabled].every(Boolean)
  return (
    <QRDialog
      show={show}
      title={arePermissionsGranted ? 'Edit Default Audio/Video Settings' : 'Camera and Microphone Permissions'}
      onClose={onClose}
      onConfirm={arePermissionsGranted ? onClose : onConfirm}
      confirmText={arePermissionsGranted ? 'Save' : 'Allow'}
    >
      <div>
        <div className="px-3">
          <p className="m-0 p-0 fw-bold">Camera</p>
          <p>
            Enable camera permissions for authentic face-to-face
            interactions in Swytchboard&apos;s web live chats.
          </p>
        </div>
        <div className="px-3">
          <p className="m-0 p-0 fw-bold">Microphone</p>
          <p>Enable microphone permissions for clear and effortless participation in dynamic discussions.</p>
        </div>
      </div>
    </QRDialog>
  )
}
export default RequestMediaPermissions
