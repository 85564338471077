import React, { useCallback, useEffect, useRef } from 'react'
import { useChatContext } from 'providers/ChatProvider'
import Message from 'features/qrflow/components/chat/Message'

const MessageList = () => {
  const { messages } = useChatContext()

  const messagesListRef = useRef()

  const scrollToBottom = useCallback(() => {
    const messagesListEl = messagesListRef.current

    messagesListEl.scrollTop = messagesListEl.scrollHeight
  }, [messagesListRef])

  // Scroll to bottom everytime there is a new message
  useEffect(() => {
    scrollToBottom()
  }, [messages, scrollToBottom])

  return (
    <div className="qr-chat__messages-container">
      <div className="qr-chat__message-list" ref={messagesListRef}>
        {messages.map((message) => (<Message key={message.sid} message={message} />))}
      </div>
    </div>
  )
}

export default MessageList
