import React from 'react'
import VideoCallButton from 'features/qrflow/components/VideoCallButton'
import useFlipCameraToggle from 'hooks/useFlipCameraToggle/useFlipCameraToggle'

const ToggleFlipCameraButton = () => {
  const { flipCameraDisabled, toggleFacingMode, flipCameraSupported } = useFlipCameraToggle()

  return flipCameraSupported ? (
    <VideoCallButton
      className="qr-video__buttons__btn"
      onClick={toggleFacingMode}
      icon="flip_camera_android"
      disabled={flipCameraDisabled}
    />
  ) : null
}

export default ToggleFlipCameraButton
