import { useEffect, useRef } from 'react'

export default function useWorkerConversationChannel(chatRequestId, cableConsumer, onReceived) {
  const onReceivedRef = useRef(onReceived)

  useEffect(() => {
    if (chatRequestId && cableConsumer) {
      cableConsumer.ensureActiveConnection()

      const subscription = cableConsumer.subscriptions.create(
        {
          id: chatRequestId,
          channel: 'ConversationChannel',
        },
        {
          received: onReceivedRef.current,
          connected() {
            console.log('Connected to Conversation Channel')

            subscription.perform('online_users')
          },
          disconnected() {
            console.log('Disconnected from Conversation Channel')
          },
        },
      )

      return () => subscription?.unsubscribe()
    }
    return undefined
  }, [chatRequestId, cableConsumer])
}
