import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Field } from 'formik'
import InputField from 'components/form/InputField'
import SelectField from 'components/form/SelectField'

const UserInfoInputs = ({ values, getCustomerPortal }) => {
  const displayErrorMessage = false

  const options = [
    { label: 'United States', value: 'US', isDisabled: true },
    { label: 'Canada', value: 'CA', isDisabled: true },
  ]

  const selectedCountry = options.filter((option) => option.value === values.initialValues.billing_country)

  return (
    <>
      <Row className="mb-3">
        <Col md={12}>
          <span className="text-2xl text-capitalize fw-bold">Billing Info</span>
          <button
            type="submit"
            id="edit-button"
            className="billing__btn-edit-billing btn btn-success fw-bold float-end"
            onClick={(e) => getCustomerPortal(e)}
          >
            Edit Billing Info
          </button>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col md={3}>
          <Field
            type="email"
            label="Billing E-mail"
            labelClasses="fw-bold ms-1"
            placeholder="Enter your billing e-mail"
            name="billing_email"
            inputClasses="qr-input rounded"
            component={InputField}
            hasErrorMessage={displayErrorMessage}
            disabled
          />
        </Col>
        <Col md={3}>
          <Field
            type="text"
            label="Billing Address"
            labelClasses="fw-bold ms-1"
            placeholder="Enter billing address"
            name="billing_address"
            inputClasses="qr-input rounded"
            component={InputField}
            hasErrorMessage={displayErrorMessage}
            disabled
          />
        </Col>
      </Row>
      <Row className="mb-5">
        <Col md={3}>
          <Field
            type="text"
            label="Zip Code"
            labelClasses="fw-bold ms-1"
            placeholder="Enter zip code"
            name="billing_zip_code"
            inputClasses="qr-input rounded"
            component={InputField}
            hasErrorMessage={displayErrorMessage}
            disabled
          />
        </Col>
        <Col md={3}>
          <Field
            name="billing_country"
            label="Country"
            labelClasses="fw-bold ms-1"
            placeholder="Select your country"
            classNamePrefix="select"
            component={SelectField}
            value={selectedCountry}
            options={options}
            customStyles
          />
        </Col>
      </Row>
      <Row className="mb-5">
        <Col md={3}>
          <Field
            type="text"
            label="City"
            labelClasses="fw-bold ms-1"
            placeholder="Enter your city"
            name="billing_city"
            inputClasses="qr-input rounded"
            component={InputField}
            hasErrorMessage={displayErrorMessage}
            disabled
          />
        </Col>
      </Row>
    </>
  )
}

export default UserInfoInputs
