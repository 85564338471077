import React from 'react'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'

const ArrowRight = ({
  size = 12,
}) => (
  <IcomoonReact
    iconSet={iconSet}
    icon="arrow-right"
    size={size}
  />
)

export default ArrowRight
