import { useQuery } from '@tanstack/react-query'
import Company from 'services/Company'
import { QueryKeys } from 'utils/constants'

export default function useGetEntryPoint(id) {
  const entryPoint = useQuery([QueryKeys.ENTRY_POINT, id], () => Company.getCompanyEntryPoint(id), {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retryOnMount: false,
    retry: 1,
  })

  return {
    entryPoint,
  }
}
