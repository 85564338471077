/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import useLocalVideoToggle from 'hooks/useLocalVideoToggle/useLocalVideoToggle'
import { useVideoContext } from 'providers/VideoProvider'
import VideoCallButton from 'features/qrflow/components/VideoCallButton'
import Chat from 'services/Chat'
import useMediaPermissions from 'hooks/useMediaPermissions'
import useRequestMediaPermission from 'providers/VideoProvider/useRequestMediaPermission/useRequestMediaPermission'
import { updateConsumerConversationTracksPermit } from 'redux/consumerConversations/actions'
import useConsumerConversations from '../hooks/useConsumerConversations'

const hasAcquiredLocalTracks = (localTracks) => localTracks.length === 2

const ToggleCustomerVideoButton = () => {
  const [isVideoEnabled, toggleVideo] = useLocalVideoToggle()
  const { askForVideoPermissions } = useRequestMediaPermission()
  const { isCameraEnabled: isCameraPermissionGranted } = useMediaPermissions()
  const { chatRequestId } = useParams()
  const dispatch = useDispatch()
  const {
    id,
    settings: {
      userId,
      customer: {
        isTwoWayVideoEnabled: isCameraEnabled,
      },
      customerKey,
    },
  } = useConsumerConversations()

  const {
    room,
    isTwoWayEnabled,
    setIsTwoWayEnabled,
    isVideoTrackPublished,
    setIsVideoTrackPublished,
    localTracks,
    videoTrack,
  } = useVideoContext()

  const localParticipant = room?.localParticipant

  const onToggleVideo = () => {
    dispatch(updateConsumerConversationTracksPermit(id, customerKey, { isTwoWayVideoEnabled: !isCameraEnabled }))
    Chat.publishVideoStatus(chatRequestId, userId, 'on')
    setIsTwoWayEnabled(true)
    if (isVideoTrackPublished) {
      return toggleVideo()
    }
    if (!isVideoTrackPublished && videoTrack) {
      localParticipant?.publishTrack?.(videoTrack)
      return setIsVideoTrackPublished(true)
    }
    return askForVideoPermissions()
  }

  useEffect(() => {
    if (!isVideoTrackPublished && videoTrack) {
      Chat.publishVideoStatus(chatRequestId, userId, 'on')
      setIsTwoWayEnabled(true)
      localParticipant?.publishTrack?.(videoTrack)
      return setIsVideoTrackPublished(true)
    }
    return undefined
  }, [chatRequestId, isVideoTrackPublished, localParticipant, setIsTwoWayEnabled, setIsVideoTrackPublished, userId, videoTrack])

  useEffect(() => {
    if (!hasAcquiredLocalTracks(localTracks) && isCameraPermissionGranted && isCameraEnabled && !isVideoTrackPublished) {
      askForVideoPermissions()
    }
  }, [askForVideoPermissions, isCameraEnabled, isCameraPermissionGranted, isVideoTrackPublished, localTracks])

  return (
    <VideoCallButton
      className={`qr-video__buttons__btn toggle-camera-button ${
        isVideoTrackPublished
        && isVideoEnabled
        && isTwoWayEnabled
          ? '' : 'inactive'}`}
      icon={isVideoTrackPublished
      && isVideoEnabled
      && isTwoWayEnabled
        ? 'icon-video-call' : 'icon-video-call-end'}
      color={isVideoTrackPublished && isVideoEnabled ? '' : 'white'}
      onClick={onToggleVideo}
      id="step1"
    />
  )
}

export default ToggleCustomerVideoButton
