import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import TableHeader from 'components/UI/TableData/TableHeader/TableHeader'
import ConversationsTableRow from 'routes/Conversations/components/ConversationsTableRow/ConversationsTableRow'
import ArrowLeft from 'components/UI/ArrowLeft'
import ArrowRight from 'components/UI/ArrowRight'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import { useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import useConversations from 'routes/Conversations/hooks/useConversations'
import useCustomerName from 'hooks/useCustomerName'

const TableHeaders = {
  customer: 'CUSTOMER',
  email: 'E-MAIL',
  phone: 'PHONE',
  worker: 'WORKER',
  date: 'DATE',
  entryPointName: 'ENTRY POINT',
  helpfulness: 'HELPFULNESS',
  knowledge: 'KNOWLEDGE',
  professional: 'PROFESSIONAL',
  comment: 'COMMENT',
  empty: '',
}

const Conversations = ({ filters }) => {
  const [currentPage, setCurrentPage] = useState(0)

  const companyId = useSelector((state) => state.company.data?.id)

  const { addToast, removeToast } = useToasts()
  const customerName = useCustomerName()
  const onFetchConversationsError = (error) => {
    removeToast('fetch-conversations-error')
    addToast(`Conversations: ${error.error || error.message || 'Oops, something went wrong.'}`, {
      appearance: 'error',
      id: 'fetch-conversations-error',
    })
  }

  const {
    data,
    isLoading: isLoadingConversations,
  } = useConversations(companyId, currentPage, filters, onFetchConversationsError)

  if (isLoadingConversations) {
    return <LoadingSpinner spinnerSize={50} className="text-center mt-5" />
  }

  const conversations = data?.chat_requests ?? []
  const numberOfPages = data?.pagy?.pages

  return (
    <Row className="w-100 position-relative m-0 p-0">
      <Col md={12} className="text-center p-0">
        <table className="table-data table table-borderless">
          <TableHeader
            headers={Object.values(TableHeaders)}
          />
          <tbody>
            {conversations.map(({ id, chat_conversation: chatConversation, company_service: companyService }) => (
              <ConversationsTableRow
                key={id}
                customer={customerName(chatConversation?.customer?.name)}
                email={chatConversation?.customer?.email}
                phone={chatConversation?.customer?.phone_number}
                worker={chatConversation?.user?.full_name}
                date={chatConversation?.created_at}
                entryPointName={companyService?.name}
                helpfulness={chatConversation?.rating?.rate.helpfulness}
                knowledge={chatConversation?.rating?.rate.knowledge}
                professional={chatConversation?.rating?.rate.professional}
                comment={chatConversation?.rating?.comment}
                headers={TableHeaders}
                id={id}
              />
            ))}
          </tbody>
        </table>
        <Row className="p-0 m-0">
          <Col md={12}>
            <div className="conversations__pagination">
              <ReactPaginate
                previousLabel={<ArrowLeft />}
                nextLabel={<ArrowRight />}
                pageCount={numberOfPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                initialPage={currentPage}
                onPageChange={({ selected }) => setCurrentPage(selected)}
                containerClassName="conversations__pagination__list"
                activeClassName="conversations__pagination__active"
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Conversations
