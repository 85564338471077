import { useState } from 'react'
import User from 'services/User'

export default function useSendVerificationEmail() {
  const [isResendingCode, setIsResendingCode] = useState(false)
  const [resendCodeSuccessMessage, setResendCodeSuccessMessage] = useState('')
  const [resendCodeErrorMessage, setResendCodeErrorMessage] = useState('')

  const clearResendCodeErrorMessage = () => setResendCodeErrorMessage('')
  const clearResendCodeSuccessMessage = () => setResendCodeSuccessMessage('')

  const sendVerificationCode = async (email) => {
    setIsResendingCode(true)
    setResendCodeSuccessMessage('')
    setResendCodeErrorMessage('')
    try {
      await User.forgotPassword(email)
      setResendCodeSuccessMessage('If you provided a valid email, you should receive verification code.')
    } catch (e) {
      if (e.error === 'User not found' && e.status === 404) {
        setResendCodeSuccessMessage('If you provided a valid email, you should receive verification code.')
      } else {
        let errorMessage = 'Resend Error: '
        errorMessage += e.message || e.error || 'We couldn\'t send a verification code'
        setResendCodeErrorMessage(errorMessage)
      }
    } finally {
      setIsResendingCode(false)
    }
  }

  const resetResendCodeStates = () => {
    setIsResendingCode(false)
    setResendCodeSuccessMessage('')
    setResendCodeErrorMessage('')
  }

  return {
    sendVerificationCode,
    resendCodeSuccessMessage,
    resendCodeErrorMessage,
    isResendingCode,
    resetResendCodeStates,
    clearResendCodeErrorMessage,
    clearResendCodeSuccessMessage,
  }
}
