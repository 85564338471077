import React, {
  useState,
  useEffect,
} from 'react'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import ContentContainer from 'components/containers/ContentContainer'
import ContentHeader from 'components/header/ContentHeader'
import CurrentPlan from 'routes/Billing/components/Plan/Current'
import NewPlan from 'routes/Billing/components/Plan/New'
import UserInfoInputs from 'routes/Billing/components/UserInfo/Inputs'
import { useAuth } from 'providers/AuthProvider/AuthProvider'
import Billing from 'services/Billing'
import { useBillingContext } from 'providers/BillingProvider'

const BillingView = () => {
  const [loading, setLoading] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [billingInfo, setBillingInfo] = useState({
    billing_email: '',
    billing_address: '',
    billing_zip_code: '',
    billing_country: {},
    billing_city: '',
  })

  const [billingPlan, setBillingPlan] = useState({
    hasPlan: false,
    totalHours: null,
    usedHours: null,
    cycle: null,
    plan: null,
  })

  const {
    subscriptionPlan,
    subscriptionStats,
    fetchSubscriptionPlan,
    getCustomerData,
  } = useBillingContext()

  const { getCurrentUser } = useAuth()

  const UserInfoFormSchema = Yup.object().shape({
    billing_email: Yup.string().required(),
    billing_address: Yup.string().required(),
    billing_zip_code: Yup.string().required(),
    billing_country: Yup.string().required(),
    billing_city: Yup.string().required(),
  })

  const initialValuesForm = {
    billing_email: billingInfo.billing_email,
    billing_address: billingInfo.billing_address,
    billing_zip_code: billingInfo.billing_zip_code,
    billing_country: billingInfo.billing_country,
    billing_city: billingInfo.billing_city,
  }

  useEffect(() => {
    fetchSubscriptionPlan()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function fetchData() {
      const { customer } = await getCustomerData()
      setBillingInfo({
        billing_email: customer.email || '',
        billing_address: customer.address?.line1 || customer.address?.line2 || '',
        billing_zip_code: customer.address?.postal_code || '',
        billing_city: customer.address?.city || '',
        billing_country: customer.address?.country || '',
      })
    }
    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setLoading(true)
    const { active, plan } = subscriptionPlan
    const {
      startDate, endDate, totalMinutes, minutesSpent,
    } = subscriptionStats
    const startDateLocale = new Date(startDate).toLocaleString('en-us', { month: 'short', day: 'numeric' })
    const endDateLocale = new Date(endDate).toLocaleString('en-us', { month: 'short', day: 'numeric' })
    setBillingPlan({
      hasPlan: active,
      totalHours: totalMinutes / 60,
      usedHours: (minutesSpent / 60).toFixed(2),
      cycle: `${startDateLocale} - ${endDateLocale}`,
      plan: plan || 'trial',
    })

    setLoading(false)
  }, [getCurrentUser, subscriptionPlan, subscriptionStats])

  const getCustomerPortal = async () => {
    try {
      if (isSending) return
      setIsSending(true)
      const result = await Billing.getCustomerPortal()
      window.location.replace(result.billing_portal_url)
      setIsSending(false)
    } catch (e) {
      throw new Error(typeof e === 'string' ? e : e?.error || e?.message)
    }
  }

  return (
    <ContentContainer>
      {loading ? null
        : (
          <Formik
            initialValues={initialValuesForm}
            enableReinitialize
            validationSchema={UserInfoFormSchema}
          >
            {(values) => (
              <Form>
                <ContentHeader
                  title="Billing"
                  subtitle="View, update and edit your billing information."
                />
                {billingPlan.hasPlan ? (
                  <CurrentPlan billingPlan={billingPlan} />
                ) : (
                  <NewPlan />
                )}
                {billingPlan.hasPlan ? (
                  <UserInfoInputs values={values} getCustomerPortal={getCustomerPortal} />
                ) : (
                  null
                )}
              </Form>
            )}
          </Formik>
        )}
    </ContentContainer>
  )
}

export default BillingView
