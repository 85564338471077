/* eslint-disable react-hooks/exhaustive-deps */
import {
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import { useParams } from 'react-router-dom'
import config from 'utils/config'
import { createConsumerChannel, Action } from '@swytchboard/client-sdk-ts'
import useConsumerConversations from '../hooks/useConsumerConversations'

export default function useConsumerConversationChannel(onReceived) {
  const { chatRequestId } = useParams()
  const { state: { consumerToken } } = useConsumerConversations()
  const cableURL = `${config.WEBSOCKETS_URL}?consumer_token=${encodeURIComponent(consumerToken)}`

  const consumerChannel = useMemo(() => createConsumerChannel(cableURL), [cableURL])

  const subscribeAndPerform = useCallback(() => {
    consumerChannel.onMessage(onReceived)
    consumerChannel.subscribe({ id: chatRequestId, channel: 'ConversationChannel' })
    consumerChannel.perform([Action.CONVERSATION_STATUS, Action.ONLINE_USERS])
  }, [consumerChannel, chatRequestId, onReceived])

  useEffect(() => {
    consumerChannel.cable.onopen = () => subscribeAndPerform()
    return () => consumerChannel.cable.disconnect()
  }, [])

  return consumerChannel
}
