import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import {
  chatConfigurationSteps,
} from 'routes/ChatSettingsEntryPoint/constants'
import { useChatConfiguration } from 'routes/ChatSettingsEntryPoint/provider/ChatConfigurationProvider'
import TopChatConfigurationButtons from 'routes/ChatSettingsEntryPoint/components/Buttons/TopChatConfigurationButtons'
import BottomChatConfigurationButtons from
  'routes/ChatSettingsEntryPoint/components/Buttons/BottomChatConfigurationButtons'
import Editable from 'routes/ChatSettingsEntryPoint/steps/ChatBasics/Editable'
import Viewable from 'routes/ChatSettingsEntryPoint/steps/ChatBasics/Viewable'

const ChatBasics = () => {
  const {
    entryPoint,
    handleStateChange,
    modifiedState,
    setModifiedState,
  } = useChatConfiguration()

  const {
    accent_color: accentColor,
    logo1: entryPointLogo1,
    logo2: entryPointLogo2,
  } = entryPoint?.data.config.visual

  const {
    welcome_message: entryPointwelcomeMessage,
  } = entryPoint?.data.config.text_chat

  const [color, setColor] = useState(accentColor)
  const [logo1, setLogo1] = useState(entryPointLogo1)
  const [logo2, setLogo2] = useState(entryPointLogo2)
  const [welcomeMessage, setWelcomeMessage] = useState(entryPointwelcomeMessage)

  const handleSaveButton = () => {
    handleStateChange({
      [chatConfigurationSteps.VISUAL]: {
        accent_color: color,
        logo1,
        logo2,
      },
      [chatConfigurationSteps.TEXT_CHAT]: {
        welcome_message: welcomeMessage,
      },
    })
    setModifiedState(false)
  }

  const handleCancelButton = () => {
    setColor(accentColor)
    setLogo1(entryPointLogo1)
    setLogo2(entryPointLogo2)
    setWelcomeMessage(entryPointwelcomeMessage)
    setModifiedState(false)
  }

  return (
    <>
      <Row>
        <Col className="chat-configuration__buttons top">
          <TopChatConfigurationButtons
            handleSaveButton={handleSaveButton}
            handleCancelButton={handleCancelButton}
            modifiedState={modifiedState}
          />
        </Col>
      </Row>
      <Row className="h-100">
        <Col md={8} className="pe-4 m-0">
          <Editable
            color={color}
            setColor={setColor}
            logo1={logo1}
            setLogo1={setLogo1}
            logo2={logo2}
            setLogo2={setLogo2}
            welcomeMessage={welcomeMessage}
            setWelcomeMessage={setWelcomeMessage}
            setModifiedState={setModifiedState}
          />
        </Col>
        <Col md={4} className="p-0 m-0">
          <Viewable
            color={color}
            logo1={logo1}
            logo2={logo2}
            welcomeMessage={welcomeMessage}
          />
        </Col>
      </Row>
      <Row>
        <Col className="chat-configuration__buttons">
          <BottomChatConfigurationButtons
            modifiedState={modifiedState}
            setModifiedState={setModifiedState}
          />
        </Col>
      </Row>
    </>
  )
}

export default ChatBasics
