import MoreOptionsIcon from 'components/video/MoreOptionsIcon'
import React, { useState } from 'react'
import {
  Col, Dropdown, Row, Spinner,
} from 'react-bootstrap'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'
import { useHistory } from 'react-router-dom'
import Chat from 'services/Chat'
import useActiveConversations from 'hooks/useActiveConversations'
import { ParticipantTypes, MessageEvent } from 'utils/constants'
import useResetRoom from 'hooks/useResetRoom'
import { useDispatch } from 'react-redux'
import { updateActiveConversation } from 'redux/activeConversations/actions'
import { useVideoContext } from 'providers/VideoProvider'
import ConsumerInfo from './ConsumerInfo'

const ConversationHeaderMobile = () => {
  const [isEndingConversation, setIsEndingConversation] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const { id: acceptedRequestId, roomName } = useActiveConversations()
  const { resetRoom } = useResetRoom()
  const {
    room,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    isSharingScreen,
    toggleScreenShare,
  } = useVideoContext()

  const handleEndConversion = async () => {
    setIsEndingConversation(true)
    try {
      await Chat.completeChatRequestWorker(acceptedRequestId, roomName)
      resetRoom(acceptedRequestId)
      dispatch(updateActiveConversation(acceptedRequestId, { [ParticipantTypes.CONSUMER]: {} }))
      if (isSharingScreen) {
        toggleScreenShare()
      }
      removeLocalAudioTrack()
      removeLocalVideoTrack()
      room?.disconnect()
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(MessageEvent.END_CONVERSATION)
      }
    } catch (e) {
      console.log('Error ending conversation, TODO handle errors when needed', e)
      setIsEndingConversation(false)
    }
  }

  const handleLeaveConversation = () => {
    if (window.ReactNativeWebView) {
      return window.ReactNativeWebView.postMessage(MessageEvent.LEAVE_CONVERSATION)
    }
    return history.push('/dashboard')
  }

  return (
    <div className="conversation-header__wrapper">
      <Row className="w-100 p-0 m-0">
        <Col md={7} xs={7} className="p-0 m-0">
          <ConsumerInfo initials />
        </Col>
        <Col md={5} xs={5} className="d-flex p-0 m-0 justify-content-end align-items-center">
          <div className="bg-white rounded-pill m-2 shadow" style={{ padding: '10px 11px 10px 11px', maxWidth: 45, maxHeight: 45 }}>
            <IcomoonReact
              iconSet={iconSet}
              size={20}
              icon="icon-video-call"
            />
          </div>
          {isEndingConversation ? (
            <Spinner animation="border" variant="primary" className="mx-3 text-center" />
          )
            : (
              <Dropdown className="d-flex justify-content-end">
                <Dropdown.Toggle as={MoreOptionsIcon} />
                <Dropdown.Menu className="border-0 shadow-sm my-2 align-items-center" style={{ borderRadius: 10, width: 170 }}>
                  <Dropdown.Item className="pb-4 fw-bold w-100" onClick={handleLeaveConversation} style={{ fontSize: 15 }}>Leave Conversation</Dropdown.Item>
                  <Dropdown.Item className="fw-bold text-danger" style={{ fontSize: 15 }} onClick={handleEndConversion}>End Conversation</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
        </Col>
      </Row>
      <hr />
    </div>
  )
}

export default ConversationHeaderMobile
