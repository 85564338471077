import React from 'react'
import { useMembersContext } from 'routes/TeamMembers/context/TeamMembersContext'
import { useToasts } from 'react-toast-notifications'
import TeamActionDialog from 'routes/TeamMembers/components/TeamActionDialog'

const DeleteTeamMember = ({
  id,
  show,
  onClose,
  fullName,
}) => {
  const { removeTeamMember } = useMembersContext()
  const { addToast } = useToasts()

  const onRemoveMember = async (e) => {
    e.preventDefault()
    try {
      await removeTeamMember(id, fullName)

      addToast('Team member is deleted!', {
        appearance: 'success',
        autoDismiss: true,
        autoDismissTimeout: 2000,
      })
      onClose()
    } catch (error) {
      addToast('Something went wrong please try again!', {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 2000,
      })
    }
  }

  return (
    <TeamActionDialog
      show={show}
      onClose={onClose}
      onConfirm={(e) => onRemoveMember(e, id)}
      title="Delete Teammate"
    >
      <p>
        Are you sure you want to remove
        <strong>
          {' '}
          {fullName}
          {' '}
        </strong>
        from your team?
      </p>
    </TeamActionDialog>
  )
}

export default DeleteTeamMember
