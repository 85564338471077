/* eslint-disable no-nested-ternary */
/* eslint-disable no-return-await */
import React, { useEffect, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import config from 'utils/config'
import Loader from 'components/loader/Loader'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'
import { useVideoContext } from 'providers/VideoProvider'
import { TrackPriority } from 'utils/constants'
import LocalParticipant from 'components/Room/LocalParticipant'
import useEntryPoints from 'routes/ChatSettingsEntryPoint/hooks/useEntryPoints'
import useRemoteParticipants from 'hooks/useRemoteParticipants/useRemoteParticipants'
import useResetRoom from 'hooks/useResetRoom'
import LoadingButton from 'components/buttons/LoadingButton'
import useScreenShare from 'hooks/useScreenShare'
import Meet from 'services/Meet'

const NewScreenShare = () => {
  const [sharableLink, setSharableLink] = useState('')
  const [isEndLoading, setIsEndLoading] = useState(false)
  const history = useHistory()
  const { data: entryPoints } = useEntryPoints()
  const {
    connectToRoom, room, toggleScreenShare, isSharingScreen,
  } = useVideoContext()
  const { remoteParticipants } = useRemoteParticipants()
  const { resetRoom } = useResetRoom()
  const copyToClipboard = async () => await navigator.clipboard.writeText(sharableLink)
  const { id: roomId, meetingLink, room: { token, name } } = useScreenShare()
  const currentViewers = room?.participants?.size ?? 0
  const fullScreenRef = useRef(null)

  useEffect(() => {
    const asyncWrapper = async () => {
      try {
        if (token && name) {
          connectToRoom(
            token,
            {
              name,
              video: { frameRate: 144 },
              audio: false,
              bandwidthProfile: {
                video: {
                  contentPreferencesMode: 'auto',
                  clientTrackSwitchOffControl: 'auto',
                },
              },
              preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
            },
            [],
            TrackPriority.HIGH,
          )
        }
      } catch (e) {
        history.push('/dashboard')
      }
    }
    asyncWrapper()
  }, [connectToRoom, history, name, roomId, token])

  useEffect(() => {
    if (entryPoints) {
      setSharableLink(`${config.FRONTEND_URL}/screenshare/${meetingLink}`)
    }
  }, [entryPoints, meetingLink, roomId])

  const handleEndConversion = async () => {
    setIsEndLoading(true)
    if (isSharingScreen) {
      toggleScreenShare()
    }
    await Meet.endScreenSharing(roomId)
    room?.disconnect()
    resetRoom(roomId)
    setIsEndLoading(false)
    return history.goBack()
  }

  const onRequestFullScreen = () => (fullScreenRef?.current?.requestFullscreen())

  return (
    <Row className="h-100 justify-content-center screen--share">
      <div className={`d-flex screen w-100 justify-content-center align-items-center position-relative ${!isSharingScreen && 'shadow-big rounded-xl bg-lighter'}`}>
        <div className="position-absolute d-flex align-items-center justify-content-center bg-white rounded-3xl shadow-big px-3" style={{ top: 15, zIndex: 1 }}>
          <p className="fw-bold px-2 py-2 m-0">Current Viewers</p>
          <p className="fw-bold px-2 text-danger m-0 py-2">{currentViewers}</p>
        </div>
        {!(isSharingScreen)
          ? (
            <>
              {!remoteParticipants.length && <Loader />}
              <p className="p-4">
                {(() => {
                  if (room) {
                    if (remoteParticipants.length) return 'User joined the screen share'
                    return 'Waiting for users to join the screen share'
                  }
                  return 'Connecting...'
                })()}

              </p>
            </>
          )
          : (room.localParticipant && isSharingScreen) ? (
            <LocalParticipant
              participant={room.localParticipant}
              videoOnly
              videoPriority={TrackPriority.HIGH}
              isLocalParticipant
              isNewScreenShare
              fullScreenRef={fullScreenRef}
            />
          ) : null}
        {isSharingScreen && (
        <div className="d-flex w-100 controls rounded-3xl justify-content-end align-items-center px-5">
          <IcomoonReact
            onClick={onRequestFullScreen}
            iconSet={iconSet}
            size={20}
            icon="icon-maximize"
            color="white"
            className="cursor-pointer"
          />
        </div>
        )}
      </div>
      <div className="d-flex row p-0 my-3">
        <p className="d-flex text-black m-0 px-1 py-2 fw-bold link align-self-center">Public screen sharing link</p>
        <Row className="w-100 align-items-center justify-content-center row-gap-4 m-0">
          <Col sm={6} md={6} lg={7} className="justify-content-center m-0 p-0">
            <Row className="align-items-center gap-2">
              <Col xs={8} sm={8} md={8} lg={8} className="mx-0 py-2 bg-light rounded-3xl">
                <p className="text-black m-0 link">{sharableLink}</p>
              </Col>
              <Col xs={3} sm={3} md={3} lg={3} className="cursor-pointer copy-container rounded-3xl h-100 m-0 py-2">
                <div className="d-flex align-items-center justify-content-center" onClick={copyToClipboard}>
                  <IcomoonReact iconSet={iconSet} size="1.7vh" icon="copy-icon" />
                  <p className="text-black copy m-0 p-0 mx-2">Copy Link</p>
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm={6} md={6} lg={5} className="d-flex gap-3 align-items-end">
            <Col xs={6} sm={6} md={6}>
              <LoadingButton
                onClick={toggleScreenShare}
                className={`button ${isSharingScreen ? 'stop' : 'start'}`}
                disabled={!room}
              >
                <IcomoonReact iconSet={iconSet} size="2vh" icon="icon-share-screen" color={isSharingScreen ? 'white' : 'black'} />
                &nbsp;&nbsp;&nbsp;
                {isSharingScreen ? 'Stop' : 'Start'}
                &nbsp;Screen Sharing
              </LoadingButton>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <LoadingButton
                onClick={handleEndConversion}
                className="button end"
                loading={isEndLoading}
              >
                End Screen Share
              </LoadingButton>
            </Col>
          </Col>
        </Row>
      </div>
    </Row>
  )
}
export default NewScreenShare
