import React from 'react'
import IcomoonReact from 'icomoon-react'
import iconSet from 'assets/fonts/selection.json'

const FullStar = ({
  size = 16,
  color = 'rgb(251, 188, 5)',
}) => (
  <IcomoonReact
    iconSet={iconSet}
    icon="star-full"
    color={color}
    size={size}
  />
)

export default FullStar
