import React from 'react'
import { Col, Row } from 'react-bootstrap'
import DashboardMain from 'routes/Dashboard/DashboardMain'
import UserStats from 'routes/Dashboard/components/UserStats/UserStats'
import { isMobile } from 'utils/helpers'
import MobileDashboardMain from 'routes/Dashboard/components/MobileDashboardMain'

const Dashboard = () => (
  <>
    <UserStats />
    <Row className="d-flex m-0 p-0 w-100">
      <Col md={12} className={isMobile && 'm-0 p-0'}>
        {!isMobile ? <DashboardMain /> : <MobileDashboardMain />}
      </Col>
    </Row>
  </>
)

export default Dashboard
