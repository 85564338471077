import React from 'react'
import { Field, useFormikContext } from 'formik'
import TextFormField from 'components/UI/TextFormField/TextFormField'
import { Alert } from 'react-bootstrap'
import { useForgotPassword } from 'components/ForgotPaswordDialog/providers/ForgotPasswordProvider'

const ChangePasswordForm = () => {
  const {
    isResendingCode,
    sendVerificationCode,
    resendCodeSuccessMessage,
    resendCodeErrorMessage,
    clearResendCodeSuccessMessage,
    clearResendCodeErrorMessage,
    errorChangingPasswordMessage,
    clearErrorChangingPasswordMessage,
  } = useForgotPassword()
  const { values } = useFormikContext()

  const email = values?.email

  const renderAlertInfoMessages = (
    <>
      {errorChangingPasswordMessage && (
        <Alert
          variant="danger"
          dismissible
          onClose={clearErrorChangingPasswordMessage}
        >
          {errorChangingPasswordMessage}
        </Alert>
      )}
      {resendCodeSuccessMessage && (
        <Alert
          variant="success"
          dismissible
          onClose={clearResendCodeSuccessMessage}
        >
          {resendCodeSuccessMessage}
        </Alert>
      )}
      {resendCodeErrorMessage && (
        <Alert
          variant="danger"
          dismissible
          onClose={clearResendCodeErrorMessage}
        >
          {resendCodeErrorMessage}
        </Alert>
      )}
    </>
  )

  return (
    <>
      {renderAlertInfoMessages}
      <div className="mb-3">
        <p className="mb-3">
          A verification code was sent to
          {' '}
          <strong>
            {email}
          </strong>
          .
        </p>
        <Field
          name="token"
          placeholder="Enter code"
          component={TextFormField}
          autoComplete="off"
        />
        <span
          className="ms-2 mt-1 resend-code"
          onClick={() => !isResendingCode && sendVerificationCode(email)}
          aria-hidden="true"
        >
          Click to resend
        </span>
      </div>
      <div className="mb-3">
        <p className="mb-3">
          Choose a new password
        </p>
        <Field
          name="password"
          type="password"
          placeholder="New password"
          component={TextFormField}
          autoComplete="new-password"
        />
      </div>
      <div className="mb-3">
        <p className="mb-3">
          Confirm your new password
        </p>
        <Field
          name="password_confirmation"
          type="password"
          placeholder="Confirm password"
          component={TextFormField}
          autoComplete="new-password"
        />
      </div>
    </>
  )
}

export default ChangePasswordForm
