import { useQuery } from '@tanstack/react-query'
import { useToasts } from 'react-toast-notifications'
import { QueryKeys } from 'utils/constants'
import Ratings from 'services/Ratings'

export default function useCustomerFeedback(currentPage, StartDate, EndDate) {
  const { addToast } = useToasts()

  const customerFeedback = useQuery([QueryKeys.CUSTOMER_FEEDBACK, { currentPage }],
    () => Ratings.getRatings(currentPage, StartDate, EndDate), {
      onError: () => {
        addToast('Something went wrong please try again!', {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        })
      },
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    })

  return {
    customerFeedback,
  }
}
